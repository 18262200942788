<template>
    <div class="rep-container" v-if="show">
        <div class="rep-title">
            <span style="font-size: 16px;font-weight: 600;color: rgb(138, 132, 132);">评估报告</span>
            <span class="rep-close" @click="closeRecInfo"><i class="el-icon-close"></i></span>
        </div>
        <div class="rep-main">
            <el-switch
                style="display: block"
                v-model="isBuffer"
                active-color="#0188e2"
                inactive-color="#0188e2"
                @change="changeBuffer"
                active-text="缓冲商圈"
                inactive-text="等时商圈">
            </el-switch>
            <el-divider></el-divider>
            <!-- 缓冲区 -->
            <div v-if="isBuffer">
                <span style="font-size: 12px;">缓冲商圈距离</span>
                <div class="rep-part">
                    <el-slider 
                        v-model="is5HM" 
                        @change="change5HM"
                        :marks="distanceMarks"
                        :min="100" 
                        :max="5000"
                        show-input
                        input-size="mini">
                    </el-slider>
                </div>
            </div>
            <!-- 等时圈 -->
            <div v-else>
                <span style="font-size: 12px;">等时商圈类型</span>
                <div class="rep-part">
                    <el-radio-group v-model="isWalking" @change="changeWalking"  size="small">
                        <el-radio-button label="walking"><span>步行</span></el-radio-button>
                        <!-- <el-radio-button label="riding"><span>骑行</span></el-radio-button> -->
                        <el-radio-button label="cycling"><span>骑行</span></el-radio-button>
                        <el-radio-button label="driving"><span>车行</span></el-radio-button>
                    </el-radio-group>
                </div>
                <div style="font-size: 12px;">等时商圈时间</div>
                <div class="rep-part">
                    <el-slider 
                        class="toolclass"
                        v-model="isTime" 
                        @change="change10MIN"
                        :marks="marks"
                        :min="isoMin" 
                        :max="isoMax"
                        show-input
                        input-size="mini">
                    </el-slider>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/api/index.js";
export default {
    name: "MapRecommendInfo",
    props: ["isShow"],
    data() {
      return {
        styleObj: {},
        isMapShow: false,
        show: false,
        opt: {},
        recInfo: {},
        recGroups: {},
        isBuffer: false, // 等时圈
        is5HM: 500, // 500m
        isWalking: "walking", // 步行
        isTime: 10,
        isoMin: 1,
        isoMax: 30,
        marks: {
            1: "1min",
            30: "30min",
            label: this.$createElement('strong', '50%')
        },
        distanceMarks: {
            100: "100m",
            5000: "5000m",
            label: this.$createElement('strong', '50%')
        }
      };
    },
    methods: {
        closeRecInfo(){
            this.$store.state.repVisible = false;
        },
        // 判断字符串是否为数字
        isNumber(val) {
            var regPos = /^\d+(\.\d+)?$/; //非负浮点数
            var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
            if(regPos.test(val) || regNeg.test(val)) {
                return true;
            } else {
                return false;
            }
        },
        //格式化数据小数点
        formatData(val) {
            let result = val;
            if (!val) result = val;
            if(this.isNumber(val) && val.indexOf(".") > -1) {
                result = Number(val).toFixed(2);
            } 
            return result;
        },
        changeOrs(val) {
            this.isBuffer = !val;
            if(val) {
                this.$store.state.mapCommand = "isochronousCircle";
            }
        },
        changeBuffer(val) {
            if(val) {
                this.$store.state.mapCommand = "Buffer";
            } else {
                this.$store.state.mapCommand = "isochronousCircle";
            }
        },
        //缓冲区距离
        change5HM(val) {
            this.$store.state.bufferDistance = val/1000;
        },
        //等时圈类型
        changeWalking(val) {
            this.$store.state.isoType = val;
            if(val == "driving") {
                console.log("+++===", val);
                if(this.isTime < 5) {
                    this.isTime = 5;
                    this.change10MIN(this.isTime);
                } else if(this.isTime > 10) {
                    this.isTime = 10;
                    this.change10MIN(this.isTime);
                }
                this.isoMin = 5;
                this.isoMax = 10;
                this.marks = {
                    5: "5min",
                    10: "10min",
                    label: this.$createElement('strong', '50%')
                }
            } else {
                this.isoMin = 1;
                this.isoMax = 30;
                this.marks = {
                    1: "1min",
                    30: "30min",
                    label: this.$createElement('strong', '50%')
                }
            }
        },
        //等时圈时长
        change10MIN(val) {
            this.$store.state.isoTime = val;
        }
    },
    mounted() {
    },
    watch:{
      "$store.state.repVisible": function(value) {
          this.show = value;
          if(this.show == false) {
              this.$store.state.mapCommand = "";
          }
      },
      "$store.state.mapCommand"(val) {
          if(val == "Buffer") {
              this.isBuffer = true;
          } else if(val == "isochronousCircle") {
              this.isBuffer = false;
          }
      }
     
    }
}
</script>
<style>
.rep-container {
    position: absolute;
    z-index: 10;
    background: #ffffffeb;
    text-align: left;
    top: 120px;
    left: 50px;
    height: auto;
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    width: 230px;
    -webkit-box-shadow: 2px 1px 8px 4px #bbb;
    box-shadow: 2px 1px 8px 4px #bbb;
}
.rep-title {
    margin: 0 -10px;
    padding: 0 10px 10px;
    border-bottom: 1px solid #ddd;
}
.rep-close {
    float: right;
    cursor: pointer;
}
.rep-main {
    max-height: 286px;
    margin-right: -10px;
    padding-right: 10px;
    padding-top: 10px;
}
.rep-main .el-divider--horizontal{
    margin-top: 12px; 
}
/* 滚动条 */
.rep-main::-webkit-scrollbar{
  width:8px;
  height:8px;
}
.rep-main::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
.rep-main::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
.rep-main::-webkit-scrollbar-thumb:hover{
  background: #333;
}
.rep-main::-webkit-scrollbar-corner{
  background: #179a16;
}
.rep-main .el-switch__label {
    width: 60px;
    text-align: right;
}
.rep-main .el-switch {
    margin: 5px;
}
.rep-part {
    padding: 15px;
}
.rep-part .el-slider__input.el-input-number.el-input-number--mini {
    display: block;
    float: none;
}
.rep-part .el-slider__runway.show-input {
    width: 100% !important;
}


/* 移动端适配 */
@media screen and (max-width: 500px) {
  .rep-container {
    top: 20px;
  }
}
</style>