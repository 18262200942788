<template>
  <div style="height: 100%; width: 100%; text-align: left"
    :class="showTag ? 'show-tag' : ''"
  >
    <div
      ref="basicMapbox"
      :style="styleObj"
      v-loading="loadingMap"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    ></div>
    <div id="distance" class="distance-container"></div>
    <!-- 工具组件 -->
    <div class="mapboxgl-control-container">
      <!-- 左上组件位置 -->
      <div class="mapboxgl-ctrl-top-left" :style="isMobile ? 'top: 50px' : 'top: 60px'">
        <!-- 定位城市 -->
        <!-- <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-show="isHide == '1'">
          <el-tooltip :disabled="isMobile" effect="dark" content="定位城市" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleShowLocal"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/定位城市.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup" v-if="showLocal">
            <div class="map-title">
              <span>定位城市</span>
              <i class="el-icon-close" @click="showLocal = false"></i>
            </div>
            <div class="map-main">
              <CityPicker></CityPicker>
            </div>
          </div>
        </div> -->
        <!-- 搜索 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="搜索" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleShowSearch"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/11.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup map-popup2" v-if="showSearch">
            <div class="map-title">
              <span>搜索</span>
              <i class="el-icon-close" @click="showSearch = false"></i>
            </div>
            <MapSearch></MapSearch>
          </div>
        </div>
        <!-- 图例筛选 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="图例筛选" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleShowSelect"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/66.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup" v-if="showSelect">
            <div class="map-title">
              <span>图例筛选</span>
              <i class="el-icon-close" @click="showSelect = false"></i>
            </div>
            <div class="map-main">
              <el-tree
                :data="data"
                default-expand-all
                ref="selectMark"
                node-key="id"
                :default-checked-keys="defaultCheckedKeys"
                show-checkbox
                :props="defaultProps"
                @check="handleNodeClick"
              >
                <template slot-scope="{ node, data }">
                  <div class="tree-icon-item-node" @click.stop v-if="data.type === 109 && data.tip">
                    <span>{{ node.label }}</span>
                    <el-tooltip placement="right">
                      <div slot="content" v-html="data.tip"></div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </div>
                  <div class="tree-icon-item-node" @click.stop v-else-if="data.type !== 222">
                    <img
                      :src="data.iconLocal || $FormatURL(data.icon)"
                      style="height:30px;"
                      v-if="!!data.icon"
                    />
                    <span>{{
                      node.label.indexOf("jm") != -1
                        ? node.label.slice(3)
                        : node.label
                    }}</span>
                  </div>
                  <div class="poi-selector" v-else>
                    <div class="poi-button-wrap">
                      <span>{{node.label}}</span>
                      <el-button type="primary" @click="selectPoi" class="poi-leaf" size="mini">
                        <span>选择</span>
                      </el-button>
                    </div>
                    <span class="poi-names">{{ poiSelectNames }}</span>
                  </div>
                </template>
              </el-tree>
            </div>
          </div>
        </div>
        <!-- 图例标注 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="图例标注" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleShowMark"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/1.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup map-popup-marker" v-if="showMark">
            <div class="map-title">
              <span>图例标注</span>
              <i class="el-icon-close" @click="showMark = false"></i>
            </div>
            <MapMark></MapMark>
          </div>
        </div>
        <!-- 足迹 -->
        <!-- <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="足迹" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleShowFootprintBox"
            >
              <img
                class="icon-img"
                src="../../assets/images/marker/footprint.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup" v-if="showFootprintBox">
            <div class="map-title">
              <span>足迹</span>
              <i class="el-icon-close" @click="showFootprintBox = false"></i>
            </div>
            <div class="map-main">
              <div @click="handleShowFootprint" class="add-footprint">
                <img src="../../assets/images/marker/footprint.png">
                <span>新增足迹</span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 屏幕截图 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="屏幕截图" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="printOut('屏幕截图')"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/55.png"
                alt=""
              />
            </button>
          </el-tooltip>
        </div>
        <!--缓冲商圈-->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-show="isHide == '1'">
          <el-tooltip :disabled="isMobile" effect="dark" content="缓冲商圈" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleCommandReport('Buffer')"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/huanchongqu.png"
                alt=""
              />
            </button>
          </el-tooltip>
        </div>
        <!--等时商圈-->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-show="isHide == '1'">
          <el-tooltip :disabled="isMobile" effect="dark" content="等时商圈" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleCommandReport('isochronousCircle')"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/shizhong.png"
                alt=""
              />
            </button>
          </el-tooltip>
        </div>
        <!--自定义-->
        <!-- <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-show="isHide == '1'">
          <el-tooltip :disabled="isMobile" effect="dark" content="自定义" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleCommandReport('ta')"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/sq.png"
                alt=""
              />
            </button>
          </el-tooltip>
        </div> -->
        <!--智能推荐-->
        <!-- 暂时隐藏  && !isIframe -->
        <div
          class="mapboxgl-ctrl mapboxgl-ctrl-group"
          v-show="isHide == '1' && !isIframe"
        >
          <el-tooltip :disabled="isMobile" effect="dark" content="智能推荐" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleShowRecommend"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/tuijian.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup" v-if="showRecommend" :style="isMobile 
            ? 'width: calc(100vw - 80px);top: 0;transform: translateY(-50%)'
            : 'width: 440px'">
            <div class="map-title">
              <span>智能推荐</span>
              <i class="el-icon-close" @click="showRecommend = false"></i>
            </div>
            <div class="map-main">
              <MapRecommend
                :isShow="true"
                @updata-type="updataRecVisible"
              ></MapRecommend>
            </div>
          </div>
        </div>

        <!--标签开关-->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-show="isHide == '1'">
          <el-tooltip :disabled="isMobile" effect="dark" content="标签" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in show-tag"
              style="color:#fff"
              type="button"
              @click="doShowTag"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/tag.png"
                alt=""
              />
            </button>
          </el-tooltip>
        </div>
      </div>
      <!-- 右上组件位置 -->
      <div class="mapboxgl-ctrl-top-right" style="display:none"></div>
      <!-- 左下组件位置 -->
      <div class="mapboxgl-ctrl-bottom-left" style="bottom: 50px">
        <!-- 测量 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="测量" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="handleMeasure"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/33.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="map-popup map-popup-measure" v-if="showMeasure">
            <div class="map-title">
              <span>测量</span>
              <i class="el-icon-close" @click="closeMeasure"></i>
            </div>
            <div class="map-main">
              <el-radio v-model="radio" label="1">测量距离</el-radio><br/>
              <el-radio v-model="radio" label="2">测量面积</el-radio><br/>
              <el-radio v-model="radio" label="3">测量步行距离</el-radio>
            </div>
          </div>
        </div>
        <!-- 定位到当前位置 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="定位到当前位置" placement="left">
            <button
              class="mapboxgl-ctrl-zoom-out"
              type="button"
              @click="goLocation"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/22.png"
                alt=""
              />
            </button>
          </el-tooltip>
        </div>
        <!-- 卫星地图 -->
        <div
          class="mapboxgl-ctrl mapboxgl-ctrl-group"
          :class="mapType == 'satellite-v9' ? 'active-button' : ''"
        >
          <el-tooltip :disabled="isMobile" effect="dark" content="卫星地图" placement="bottom">
            <button
              class="mapboxgl-ctrl-zoom-out button"
              type="button"
              @click="changemap(mapType == 'satellite-v9' ? 'street' : 'satellite-v9')"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/44.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div class="road-button" v-if="mapType == 'satellite-v9'" @click="switchShowRoad">
            <el-checkbox
              v-model="showRoad"
              active-color="#fff"
              active-value="1"
              inactive-value="0"
              @click.stop
              @change="loadRoad"
              size="mini"
            >
            </el-checkbox>
            路网
          </div>
          <div class="map-popup" v-if="showMap" style="width: 160px;bottom:-80px;">
            <div style="font-size: 12px; margin: 5px">
              路网
              <i
                class="el-icon-close"
                style="
                  float: right;
                  cursor: pointer;
                  font-size: 14px;
                  font-weight: 600;
                "
                @click="showMap = false"
              ></i>
            </div>
            <el-switch
              v-model="showRoad"
              active-color="#0188e2"
              active-value="1"
              inactive-value="0"
              @change="loadRoad"
            >
            </el-switch>
            <div style="font-size: 12px; margin: 5px">地图</div>
            <div
              :class="{ currentMap: mapType == 'satellite-v9' }"
              class="el-image changeMap"
              @click="changemap('satellite-v9')"
            >
              <img
                :src="darkUrl"
                class="el-image__inner"
                style="object-fit: fill"
              />
              <i
                class="el-icon-success"
                v-show="mapType == 'satellite-v9'"
                style="position: relative; left: 57px; top: -16px"
              ></i>
            </div>
            <div
              :class="{ currentMap: mapType == 'street' }"
              style="margin-left: 10px"
              class="el-image changeMap"
              @click="changemap('street')"
            >
              <img
                :src="streetUrl"
                class="el-image__inner"
                style="object-fit: fill"
              />
              <i
                class="el-icon-success"
                v-show="mapType == 'street'"
                style="position: relative; left: 57px; top: -16px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 右下组件位置 -->
      <div class="mapboxgl-ctrl-bottom-right" style="right: bottom; bottom: 50px;">
        <!-- 放大 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="放大" placement="left-end">
            <button
              class="mapboxgl-ctrl-zoom-out"
              type="button"
              @click="changeZoom('1')"
            >
              <span
                class="el-icon-plus"
                style="color: #fff"
                aria-hidden="true"
              ></span>
            </button>
          </el-tooltip>
        </div>
        <!-- 缩小 -->
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <el-tooltip :disabled="isMobile" effect="dark" content="缩小" placement="left-end">
            <button
              class="mapboxgl-ctrl-zoom-in"
              type="button"
              @click="changeZoom('0')"
            >
              <span
                class="el-icon-minus"
                style="color: #fff"
                aria-hidden="true"
              ></span>
            </button>
          </el-tooltip>
        </div>
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group" v-show="hasLegend > 0">
          <el-tooltip :disabled="isMobile" effect="dark" content="图例说明" placement="left-start">
            <button
              class="mapboxgl-ctrl-zoom-in"
              style="margin-top: -346px"
              type="button"
              @click="handleShowLegend"
            >
              <img
                class="icon-img"
                src="../../assets/images/Icon/legend.png"
                alt=""
              />
            </button>
          </el-tooltip>
          <div v-if="showLegend">
            <Legend></Legend>
          </div>
        </div>
      </div>
      <!-- 右上角用户+时间浮层 -->
      <div class="user-time-box">
        {{ currentTime }}(v6.4)
      </div>
    </div>

    <!-- 机会点 没用到 -->
    <!-- <MapPPDetailDialog
      :ppVisible="ppVisible"
      :info="info"
      @update-pvisible="upDatePPDetail"
    ></MapPPDetailDialog> -->
    <!-- 门店 没用到 -->
    <!-- <MapStoreDetailDialog
      :storeVisible="storeVisible"
      :info="info"
      @update-svisible="upDateStoreDetail"
    >
    </MapStoreDetailDialog> -->

    <!-- 机会点、门店、竞品 详情和编辑页 -->
    <MapVSDetailDialog
      :vsVisible="vsVisible"
      :isMobile="isMobile"
      :info="info"
      @update-vsvisible="upDateVSDetail"
      @updateStoreLoaction="updateStoreLoaction"
      @delStoreLoactionMarker="delStoreLocationMarker"
    ></MapVSDetailDialog>

    <!-- 商圈 -->
    <MapTADetailDialog
      :taVisible="taVisible"
      :info="info"
      @loadingMap="loadingMapFun"
      @update-tavisible="upDateTADetail"
      @editDrawTa="editDrawTa"
    ></MapTADetailDialog>
    <MapRecommentList></MapRecommentList>
    <MapRecommentInfo></MapRecommentInfo>
    <MapReport></MapReport>

    <!-- 足迹编辑 -->
    <MapFootprint
      :footprintVisible="footprintVisible"
      :info="footprintInfo"
      :isEdit="footprintEdit"
      @footprint-visible="toggleFootprint"
      @create-success="footprintCreated"
      @delete-success="footprintCreated"
    ></MapFootprint>

    <!-- 蜂巢图每个块详情 -->
    <MapHoneycomb
      :honeycombVisible="honeycombVisible"
      :honeycombForm="honeycombForm"
      @honeycomb-visible="toggleHoneycomb"
    ></MapHoneycomb>

    <!-- 点位保护审核 -->
    <MapPPProtectionConfirm
      :protectionVisible="protectionVisible"
      :protectionForDetail="protectionForDetail"
      :ppInfo="protectionInfo"
      @protection-visible="toggleProtectionConfirm"
      @protection-success="protectionSuccess"
    ></MapPPProtectionConfirm>

    <!-- 人口人流数据 -->
    <MapTAPeopleModel
      v-model="taPeopleModelShow"
      :tableId="taPeopleModelDataId"
      :tableData="taPeopleModelData"
      :hideButton="taPeopleModelHideButton"
      @change-show-flag="changeShowFlag"
      @data-refresh="peopleDataRefresh"
    ></MapTAPeopleModel>

    <!-- poi图层选择 -->
    <MapPoiSelectModel
      v-model="poiSelectModelShow"
      :typeBig="poiSelectBigType"
      @small-type="smallTypeChange"
      @do-search-poi="doSearchPoi"
    ></MapPoiSelectModel>
  </div>
</template>

<script>
import "./MapBox.scss";
//计算距离
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language"; //语言包
import MapboxDraw from "@mapbox/mapbox-gl-draw"; //绘图
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"; //引入绘图样式
import "mapbox-gl/dist/mapbox-gl.css"; //引入绘图样式
import "mapbox-gl/dist/mapbox-gl.js"; // js
//mapbox-gl.css
/* 弹框 */
// import MapPPDetailDialog from "@/components/Map/MapPPDetail.vue"; //机会点详情弹框
// import MapPPDetailDialog from "@/components/Dialog/Map/MapPPDetail.vue"; //机会点详情弹框
// import MapStoreDetailDialog from "@/components/Dialog/Map/MapStoreDetail.vue"; //门店详情弹框
import MapVSDetailDialog from "@/components/Dialog/Map/MapVSDetail.vue"; // 竞品详情弹框 => 机会点、门店、竞品 合一块了
import MapTADetailDialog from "@/components/Dialog/Map/MapTADetail.vue"; //商圈详情弹框
import MapRecommentList from "@/components/Map/MapRecommentList.vue"; //机会推荐列表显示
import MapRecommentInfo from "@/components/Map/MapRecommentInfo.vue"; //机会推荐列表显示
import MapReport from "@/components/Map/MapReport.vue"; //机会推荐列表显示
// import CityPicker from "@/components/Map/CityPicker/CityPicker.vue"; // 城市选择
import Legend from "@/components/Map/Legend/Legend.vue"; // 地图图例示例
import MapRecommend from "@/components/Map/MapRecommend.vue";
import MapHoneycomb from "@/components/Dialog/Map/MapHoneycomb.vue";

import MapFootprint from "@/components/Dialog/Map/MapFootprint.vue"; // 足迹

import MapPPProtectionConfirm from "@/components/Dialog/Map/MapPPProtectionConfirm.vue"; // 机会点保护审核

import MapTAPeopleModel from "@/components/Dialog/Map/MapTAPeopleModel.vue";
import MapPoiSelectModel from "@/components/Dialog/Map/MapPoiSelectModel.vue";

// 图片
import streetImg from "@/assets/images/jiedao.png";
import darkImg from "@/assets/images/dark.png";
import grayImg from "@/assets/images/huidutu.png";
import ppImage from "@/assets/images/marker/jihuidian.png";

//引入api
import api from "../../api/index.js";
//常量
import constants from "@/constants/Map.constants.js";
//搜索
import MapSearch from "@/components/Map/MapSearch.vue";
import MapMark from "@/components/Map/MapMark.vue";
import calGeoHash from "../../utils/calGeoHash.js";
import ZoomControl from "../../utils/zoomControl.js";
import RegisterDbltouchEvent from "../../utils/dblTouch.js";
import $ from "jquery";
import html2canvas from "html2canvas"; //自定义商圈样式
import mixins from "./index.js";
import axios from "axios";
// import provinceJson from "@/assets/lib/province.js";
import provinceJson2 from "@/assets/lib/province2.js";
// import xiningJson from "@/assets/lib/xining.js";

export default {
  props: {
    mapWidth: {
      type: String
    },
    mapHeight: {
      type: String
    }
  },
  data() {
    return {
      bufferDistance: 0.5, // 缓冲区距离
      isoTime: 10, // 等时圈 时间
      isoType: "walking", // 等时圈交通工具
      showMap: false, // 地图切换
      showSearch: false, // 搜索切换
      showSelect: false, // 图例筛选
      showMark: false, //标注
      showRoad: 0, // 显示路网
      showLegend: false, // 显示图例示例页面
      hasLegend: 0, // 显示图例按钮
      map: null,
      changeaaa: "",
      zoom: 11,
      marker: "",
      newLocationMarker: null,
      ppVisible: false,
      storeVisible: false,
      vsVisible: "",
      taVisible: false,
      mapType: "street",
      streetUrl: streetImg,
      darkUrl: darkImg,
      grayUrl: grayImg,
      styleObj: {
        width: "100%",
        height:
          document.getElementById("app").offsetHeight -
          (this.isHide == "0" ? 50 : 0) +
          "px"
      },
      bufferMarker: null,
      mapCommand: "", // 缓冲区、等时圈操作分类
      legendType: [
        // {
        //   name: "门店",
        //   type: 3,
        // },
      ],
      info: {}, //传给弹框id和经纬度
      protectionInfo: {}, //传给点位保护审核的id和经纬度
      loadingMap: true,
      bufferPoint: [], //缓冲区边界点
      draw: "", // 图例标注新建商圈画布
      taEditdraw: undefined, // 商圈编辑画布
      editTaTempleId: null, // 编辑商圈 - 绘制的临时实例ID
      data: [
        // {
        //   name: "数据分析",
        //   type: 5555,
        //   id: 5555,
        //   children: [
        //     {
        //       name: "城市活跃度热力图",
        //       type: 5,
        //       id: 5,
        //     },
        //     // {
        //     //   name: "订单流向数据分析",
        //     //   type: 6,
        //     //   id: 6,
        //     // },
        //   ],
        // },
      ],
      defaultProps: {
        // children: 'children',
        label: "name"
      },
      defaultCheckedKeys: [2, 3],
      delIsoMarker: null, // 删除等时圈
      delBufferMarker: null, // 删除缓冲区
      delTAMarker: null, // 删除自定义商圈
      taReportPopUp: null, // 自定义商圈
      isoPopover: null, // 缓冲区popup
      bufferPopover: null, // 缓冲区
      layerList: [],
      style: {},
      isHide: "0",
      showLocal: false,
      showRecommend: false,
      showMeasure: false,
      themeColor: "#0188e2", //this.$store.state.themeColor
      radio: "",
      bufferCoord: [], // 缓冲区中心点
      bufferGeoHash: "", // 缓冲区的geohash
      isoPoint: [], // 等时圈中心点
      isoGeoHash: "", // 等时圈GeoHash
      isIframe: this.$store.state.PMStoreIndex.isIframe, // 是否内嵌为第三方子系统
      taNameMarkerLst: {}, // 商圈名称(地图标记)存储(每个属性对应一种种商圈类型)
      judgeLayerIdArr: [], // 存储机会点、竞品点、门店点标记ID，用于判断是否落在商圈图层内
      isTaInfoPopUp: true, // 图例标注标记点，点击落在商圈图层内，商圈click事件应答权限
      selTaMarker: null, // 重叠商圈 | 重叠商圈+重叠标记点 - 选取弹窗
      pointTaMarker: [0, 0], // 鼠标点击地图点记忆存储 - 用于图层重叠选取
      searchTaNameMarker: undefined, // 被搜索商圈的名称标记点
      selectedMarker: [], // 图例筛选所有已选中的最小子节点
      selectedTa: [], // 存储商圈的图例筛选最小子节点
      selectedPP: [], // 存储机会点图例筛选最小子节点
      canRenderLayer: true, // 拖拽缩放地图后重新渲染图层的权限

      isMobile: false, // 是否移动端

      footprintVisible: false, // 填写足迹
      footprintEdit: false, // 是填写足迹
      footprintInfo: {}, // 足迹参数
      showFootprintBox: false, // 显示添加足迹按钮

      protectionVisible: false, // 显示点位保护审核
      protectionForDetail: false, // 显示点位保护详情

      honeycombVisible: false,
      honeycombForm: {
        competitorNum: 0,
        storeNum: 0,
        haveFootPrint: false
      },

      regionMarkerList: {}, // 地区存储
      honeycombHash: {}, // {['data'+cityCode]: Array} // 蜂巢图数据存储
      gridHash: {}, // {['data'+cityCode]: Array} // 网格图数据存储
      regionEventBind: {}, // 地区事件绑定过的不再重复绑定

      // 测量距离记录id，用于删除（用不到，不再同时全删）
      measureIds: [],

      curMeasureId: 0, // 当前测距记录id
      curMeasureAreaId: 0, // 当前测面积记录id
      curMeasureWalkId: 0, // 当前测步行距离记录id

      isMeasure: false, // 是否在测量中，切换工具后，关闭
      isAreaMeasure: false, // 是否在面积测量中，切换工具后，关闭
      isWalkMeasure: false, // 是否在步行距离测量中，切换工具后，关闭

      measureEventBinded: false, // 测距事件只绑定一次
      measureAreaEventBinded: false, // 测面积事件只绑定一次
      measureWalkEventBinded: false, // 测步行距离事件只绑定一次

      measureEle: null, // 测量提示，全局存储
      measureAreaEle: null, // 测面积提示，全局存储
      measureWalkEle: null, // 测步行距离提示，全局存储

      newTooltip: null, // 测量提示，全局存储
      newAreaTooltip: null, // 测量面积提示，全局存储
      newWalkTooltip: null, // 测量步行距离提示，全局存储

      doubleClick: false, // 双击时，点击取消

      newJsonPoint: [], // 存标记
      newJsonLine: [], // 存标记
      newPoints: [], // 存标记

      newAreaJsonPoint: [], // 存面积标记
      newAreaJsonLine: [], // 存面积标记
      newAreaPoints: [], // 存面积标记

      newWalkJsonPoint: [], // 存面积标记
      newWalkJsonLine: [], // 存面积标记
      newWalkPoints: [], // 存面积标记

      // 是否显示tag，显示的话机会点和门店重绘
      showTag: false,

      // 凤巢图，人口人流数据
      taPeopleModelShow: false,
      taPeopleModelDataId: null,
      taPeopleModelData: undefined,
      taPeopleModelHideButton: false,

      // 定位已成功，不在用粗略定位
      gotLocation: false,
      geoLocationControl: null,
      mapCityName: null,
      
      // 显示当前时间
      currentTime: '',

      // poi图层
      poiSelectModelShow: false,
      poiSelectBigType: [],
      poiSelectCodes: '', // poi小类值，用来显示点位
      poiSelectNames: '', // poi小类名，用来显示选项
      poiIsCommon: false, // 选择的常用小类
      poiSelect: [], // poi点位
      poiLayerClickInited: false, // poiLayer点击初始化记录
      poiImgLoaded: false, // poi图标是否显示了
      poiIds: [],
      poiMarkers: [],
    };
  },
  mixins: [mixins],
  components: {
    // MapPPDetailDialog,
    // MapStoreDetailDialog,
    MapVSDetailDialog,
    MapTADetailDialog,
    MapRecommentList,
    MapRecommentInfo,
    MapSearch,
    MapMark,
    MapReport,
    // CityPicker,
    MapRecommend,
    MapFootprint,
    Legend,
    MapHoneycomb,
    MapTAPeopleModel,
    MapPoiSelectModel,
    MapPPProtectionConfirm
  },
  mounted() {
    this.isHide = sessionStorage.isHide;
    this.layerList = JSON.parse(sessionStorage.layerList);
    mapboxgl.accessToken = constants.iMap.ACCESSTOKEN;
    if (!sessionStorage.lat || !sessionStorage.lng) {
      api
        .searchCusCity({
          cusNo: sessionStorage.cusNo
        })
        .then(res => {
          if (res.code == 200 && res.data != null && res.data.length == 1) {
            sessionStorage.setItem("currentCode", res.data[0].code);
            sessionStorage.setItem("currentCity", res.data[0].cnName);
          }
          let timer = setInterval(() => {
            if (!!sessionStorage.currentCity) {
              this.$http
                .get(constants.iMap.GEOURL, {
                  params: {
                    address: sessionStorage.currentCity,
                    key: constants.iMap.WEBKEY
                  }
                })
                .then(res => {
                  if (res.status == 200 && res.data.infocode * 1 === 10000 && res.data.geocodes.length > 0) {
                    let crdStr = res.data.geocodes[0].location;
                    let crd = crdStr.split(",");
                    sessionStorage.lat = crd[1];
                    sessionStorage.lng = crd[0];
                  } else {
                    this.$message({
                      message: `定位失败，请确认目标城市！`,
                      type: "warning"
                    });
                    sessionStorage.lat = 40.02945;
                    sessionStorage.lng = 116.377887;
                    sessionStorage.currentCity = "北京市";
                    this.$store.state.currentCity = {
                      name: "北京市"
                    };
                  }
                  this.init(); // did
                  clearInterval(timer);
                  timer = null;
                })
                .catch(err => {
                  this.$message({
                    message: `ERROR(${err.msg})`,
                    type: "warning"
                  });

                  // 出错，结束interval
                  this.init(); // did
                  clearInterval(timer);
                  timer = null;
                });
            }
          }, 500);

          // 超过5秒，结束interval
          setTimeout(() => {
            if (timer) {
              this.init(); // did
              clearInterval(timer);
              timer = null;
            }
          }, 5000);
        });
    } else {
      this.init(); // did
    }

    if (document.getElementsByClassName("mapboxgl-ctrl-group").forEach) {
      document.getElementsByClassName("mapboxgl-ctrl-group").forEach(el => {
        el.children[0].style.background =
          this.$store.state.themeColor || "#0188e2";
      });
    }

    this.isMobile = this.$store.state.isMobile;

    setInterval(() => {
      this.currentTime = new Date().toLocaleString().replace(/\//g, '-');
    }, 1000);
  },
  beforeDestroy() {},
  destroyed() {
    this.$store.state.mapCommand = "";
    this.$store.state.loadingMap = false;
    // 缓冲区距离变化
    this.$store.state.bufferDistance = 0.5;
    // 等时圈时间变化
    this.$store.state.isoTime = "10";
    // 等时圈交通工具变化
    this.$store.state.isoType = "walking";
    //商圈报告
    this.$store.state.isDrawPolygon = false;
    this.$store.state.recList = [];
    this.$store.state.recVisible = false;
  },
  methods: {
    /**
     * 地图增加图片资源
     * @param ID  image ID
     * @param URL
     */
    mapLoadImage(ID, URL) {
      if (this.map.hasImage(ID)) return;
      this.map.loadImage(URL, (error, image) => {
        if (error) throw error;
        // 先判断是否加载了该 id 的图片资源，没有则加载
        if (!this.map.hasImage(ID)) {
          this.map.addImage(ID, image);
        }
      });
    },
    // 初始化
    init() {
      let lat = sessionStorage.getItem("lat");
      let lng = sessionStorage.getItem("lng");
      this.map = new mapboxgl.Map({
        container: this.$refs.basicMapbox,
        style: {
          version: 8,
          name: "Mapbox Streets",
          sprite: "mapbox://sprites/mapbox/streets-v8",
          glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
          sources: {
            "osm-tiles": {
              type: "raster",
              tiles: [constants.tiles.VECTORNROAD],
              tileSize: 256
            }
          },
          layers: [
            {
              id: "main",
              type: "raster",
              source: "osm-tiles",
              "source-layer": "osmtiles"
            }
          ]
        },
        center: [lng, lat], // 初始定位坐标 [lng, lat]
        zoom: this.zoom, // 初始缩放大小
        minZoom: 3,
        maxZoom: 17.3,
        maxBounds: new mapboxgl.LngLatBounds(
          [59.55988452620085, 12.957610757272292],
          [142.95624044533258, 53.95034432047234]
        ),
        doubleClickZoom: false, // 禁止双击缩放
        localIdeographFontFamily: "'Noto Sans', 'Noto Sans CJK SC', sans-serif", //字体
        preserveDrawingBuffer: true,
        dragRotate: false
      });
      let language = new MapboxLanguage({
        defaultLanguage: "en"
      });
      this.map.addControl(language);
      // // 添加门店图片
      // this.mapLoadImage(
      //   "store",
      //   constants.baseURL.fileURL + sessionStorage.storeIcon
      // );
      // // 添加机会点图片
      // this.mapLoadImage(
      //   "pp",
      //   constants.baseURL.fileURL + sessionStorage.siteIcon
      // );
      // 添加竞品图片
      this.mapLoadImage("vs", require("../../assets/images/marker/com.png"));
      // 添加搜索定位点图片
      this.mapLoadImage(
        "local",
        require("../../assets/images/marker/dizhi.png")
      );

      // 添加足迹图片
      this.mapLoadImage(
        "dizhi",
        require("../../assets/images/marker/footprint.png")
      );

      this.map.addControl(new mapboxgl.ScaleControl(), "bottom-right");
      this.map.addControl(new ZoomControl(this.map), "bottom-left");

      this.geoLocationControl = new mapboxgl.GeolocateControl({ // 显示用户当前位置
          positionOptions: {
              enableHighAccuracy: true
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true,
          preSuccess: pos => {
            // alert(pos.coords.longitude + '/' + pos.coords.latitude);
            // const newPos = JSON.parse(JSON.stringify(pos));
            // console.log('newPos', newPos, pos, pos.timestamp, pos.coords);
            const newPos = {
              timestamp: pos.timestamp,
              coords: {}
            };
            for(let key in pos.coords) {
              newPos['coords'][key] = pos['coords'][key];
            }
            console.log('newPos', newPos, pos);

            // 如果有高德api定位，就使用
            if (window.amapLongitude && window.amapLatitude) {
              // 高德 => GPS
              // var newGPSPos = this.$GPS.gcj_decrypt(window.amapLatitude, window.amapLongitude);
              newPos.coords.longitude = window.amapLongitude;
              newPos.coords.latitude = window.amapLatitude;
              // newPos.coords.longitude = newGPSPos.lon;
              // newPos.coords.latitude = newGPSPos.lat;

              // alert([window.amapLongitude, lon, window.amapLatitude, lat]);
            } else {
              // GPS => 高德
              const { lat, lon } = this.$GPS.gcj_encrypt(newPos.coords.latitude, newPos.coords.longitude);
              newPos.coords.longitude = lon;
              newPos.coords.latitude = lat;
            }
            // alert(pos.coords.longitude + '/' + pos.coords.latitude);
            return newPos;
          }
      });
      this.map.addControl(this.geoLocationControl);

      this.geoLocationControl.on('geolocate', () => {
          // alert('A geolocate event has occurred.');
          this.gotLocation = true;
      });
      this.$store.state.map = this.map;
      this.map.on("load", async () => {
        const data = await api.getLegendList({
          cusNo: sessionStorage.cusNo
        });

        const poiOften = await api.getPoiDataTypeOften();
        // console.log('poiOften: ', poiOften);
        this.getCompetitorBrand(data, poiOften);

        //mapboxgl.getExtension('WEBGL_lose_context').loseContext();
        let dragendTime = null;
        let zoomendTime = null;
        this.map.on("dragend", e => {
          if (this.$store.state.location.type == "") {
            // 拖拽节流
            clearTimeout(dragendTime);
            dragendTime = setTimeout(() => {
              this.renderMarker(this.legendType, true);
              this.getPoiData(); // 拖动重新加载poi
            }, 1500);
          }
        });
        this.map.on("zoomend", e => {
          if (this.$store.state.location.type == "") {
            // 缩放节流
            clearTimeout(zoomendTime);
            zoomendTime = setTimeout(() => {
              this.renderMarker(this.legendType, true, "", 2);
              this.getPoiData(); // 缩放重新加载poi
              let center = this.map.getCenter();
              let bounds = this.map.getBounds();
              let zoom = parseInt(this.map.getZoom());
              let msg =
                "可视范围：" +
                bounds +
                "</br></br>地图中心：" +
                center +
                "</br></br>缩放级别：" +
                zoom;
              document.getElementsByClassName(
                "mapboxgl-ctrl-zoom"
              )[0].innerHTML = "缩放级别:" + zoom;
            }, 1500);
          }
        });
        this.layerList.map(item => {
          if (item.layCode == "PT142003") {
            this.style = item;
          }
        });
        // 图例标注 - 新增商圈的画布
        this.draw = new MapboxDraw({
          displayControlsDefault: false,
          styles: this.drawStyles,
          // styles: this.editTaDrawStyles,
          controls: {
            //line_string:true,
            polygon: false,
            trash: false
          }
        });
        // 商圈编辑的画布
        this.taEditdraw = new MapboxDraw({
          displayControlsDefault: false, // 默认关闭所有控件，使用controls 选项配置增添控件
          controls: {
            polygon: false, // 控件 - 新增绘制
            trash: true // 控件 - 删除
          },
          styles: this.editTaDrawStyles,
          userProperties: true,
          defaultMode: "simple_select"
        });
        /**
         * 在main图层之上添加一个分界图层，所有商圈图层位于分界图层之下，门店、机
         * 会点等标记图层位于分界图层之上
         */
        this.map.addLayer({
          id: "backgroundLayer0",
          type: "background",
          paint: {
            "background-color": "rgba(255, 255, 255, 0)" // 背景层透明
          }
        });
        this.map.addLayer({
          id: "backgroundLayer",
          type: "background",
          paint: {
            "background-color": "rgba(255, 255, 255, 0)" // 背景层透明
          }
        });
        this.quickDrawMarkerTa();

        // 缩放一下，显示图标
        let tti = setTimeout(() => {
          console.log('this.$store.state：', this.$store.state);

          var flyParams = {
            zoom: this.zoom + 1,
            // speed: 0.5 //缩放速度
          }

          if (this.$store.state.siteId) { // 有机会点ID，说明是弹出点位保护审核框
            setTimeout(() => { // 等1秒，定位会准点么
              if (2 === this.$store.state.siteIdType * 1) { // 非审核，仅显示机会点详情
                return this.toSiteIdDetail(this.$store.state.siteId);
              }
              return this.toSiteIdProtectionConfirm(this.$store.state.siteId);
            }, 1500)
          } else if (this.$store.state.footPrintId) {
            this.footprintInfo = {
              id: this.$store.state.footPrintId,
              longitude: this.$store.state.footPrintLongitude,
              latitude: this.$store.state.footPrintLatitude,
              fromurl: true
            };
            this.footprintEdit = false;
            this.footprintVisible = true;
            // 图标里加上足迹的点
            this.footprintCreated();

            flyParams.center = [
              this.$store.state.footPrintLongitude,
              this.$store.state.footPrintLatitude
            ]; // 中心点
          } else if (this.$store.state.honeycombId) {
            this.getBigData(this.$store.state.honeycombId, 'honeycomb', data => {
              // this.taPeopleModelShow = true;
              // this.taPeopleModelDataId = id;
              // this.taPeopleModelData = data;

              this.flyToDetist({
                type: '11',
                val: {
                  id: this.$store.state.honeycombId * 1,
                  geometry: data.geometry,
                  competitorNum: data.competitorNum,
                  storeNum: data.storeNum,
                  haveFootPrint: data.haveFootPrint,
                  hideButton: true
                },
              });
            });
          } else {
            this.geoLocationControl.trigger(); // 定位
            this.map.stop(); // 地图停止所有动画
          }

          // this.map.flyTo(flyParams);
          this.map.jumpTo(flyParams);
          clearTimeout(tti);
        }, 1000);

        //初始加载首页地图
        this.loadingMap = false;
      });
    },
    // 快速定位并绘制标记点、商圈
    quickDrawMarkerTa() {
      if (this.$store.state.location.type == "") {
        return;
      }
      let data = this.$store.state.location.data;
      // console.log(data);
      let feas = [];
      let mainesStyle = {};
      let linefeas = [];

      switch (this.$store.state.location.type) {
        case "pp":
          feas = [
            {
              type: "Feature",
              properties: {
                id: data.siteID || data.id,
                name: data.siteName,
                avg: 10,
                // icon: data.progress ? data.progress : "pp", // 机会点根据进度显示不同图标
                icon: "pp",
                type: data.sitetype,
                judgePopup: "机会点",
                datas: data
              },
              geometry: {
                type: "Point",
                coordinates: [data.longitude, data.latitude]
              }
            }
          ];
          this.drawPPPoint(feas, "localPP", 0.5);
          break;
        case "store":
          feas = [
            {
              type: "Feature",
              properties: {
                id: data.storeId || data.id,
                name: data.name,
                avg: 9,
                // icon: data.brandCode ? data.brandCode : "store", //应该根据品牌icon品牌
                icon: "store",
                type: data.storeTypeName,
                judgePopup: "门店",
                phone: data.phone,
                datas: data
              },
              geometry: {
                type: "Point",
                coordinates: [data.longitude, data.latitude]
              }
            }
          ];
          this.drawStorePoint(feas, "localStore", 0.5);
          break;
        case "vs":
          // /listView/competitor 接口和 /mark/addressToMap 接口查出的赋值字段不一致
          // let brand = data.brand
          //   ? "vs" + data.brand
          //   : data.mainBrand
          //   ? "vs" + data.mainBrand
          //   : "vs";
          feas = [
            {
              type: "Feature",
              properties: {
                id: data.id,
                name: data.enName,
                avg: 10,
                // icon: brand, //应该根据品牌icon品牌
                icon: "vs",
                type: data.status,
                judgePopup: "竞品",
                datas: data
              },
              geometry: {
                type: "Point",
                coordinates: [data.longitude, data.latitude]
              }
            }
          ];
          this.drawVSPoint(feas, "localVS", 0.5);
          break;
        case "ta":
          this.layerList.map(item => {
            if (item.layCode == "PT142004") {
              mainesStyle = item;
            }
          });
          feas.push({
            type: "Feature",
            properties: {
              taid: data.id,
              name: data.cnName,
              geometry: data.location,
              fillColor: data.fillColor,
              judgePopup: "商圈"
            },
            geometry: {
              type: "Polygon",
              coordinates: this.$arrayStringToArray(data.location)
            }
          });
          linefeas.push({
            type: "Feature",
            properties: {
              taid: data.id,
              name: data.cnName,
              geometry: data.location
            },
            geometry: {
              type: "LineString",
              coordinates: JSON.parse(data.location)
            }
          });
          this.map.addSource("maines1", {
            /* 添加Source，类型是geojson */
            type: "geojson",
            data: {
              /* geojson数据 */
              type: "FeatureCollection",
              features: []
            }
          });
          this.map.addLayer({
            id: "maine1",
            type: "fill" /* fill类型layer */,
            source: "maines1",
            layout: {},
            paint: {
              "fill-color": mainesStyle.fillColor /* fill颜色 */
            }
          });
          //渲染商圈多边形
          this.map.on("click", "maine1", e => {
            this.taVisible = true;
            this.info = {
              lngLat: e.lngLat,
              taid: e.features[0].properties.taid,
              name: e.features[0].properties.name,
              location: JSON.parse(e.features[0].properties.geometry)
            };
          });
          this.map.getSource("maines1").setData({
            /* geojson数据 */
            type: "FeatureCollection",
            features: feas
          });
          this.map.addLayer({
            id: "mainesline1",
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: linefeas
              }
            },
            layout: {
              "line-cap": "round",
              "line-join": "round"
            },
            paint: {
              "line-color": "#0a38f1",
              "line-dasharray": [
                0.2,
                mainesStyle.outlineTpe == "line" ? 0 : 2
              ],
              "line-width": Number(mainesStyle.outlineWidth || 4)
            }
          });
          break;
        default:
          break;
      }
    },
    // 删除图形
    deleteAction(ID) {
      if (this.map.getSource(ID)) {
        this.map.getSource(ID).setData({
          type: "FeatureCollection",
          features: []
        });
      }
    },
    // 清空页面现有标记点、商圈
    // clearMarkerTa() {
    //   let {
    //     competitorList,
    //     ownerBrands,
    //     joinBrands,
    //     siteTypeList,
    //     tABusTypeList
    //   } = this.$store.state.PMStoreIndex.legendList;
    //   //移除机会点
    //   if (Array.isArray(siteTypeList)) {
    //     siteTypeList.forEach(a => {
    //       this.deleteAction("geodataPPPoint" + a.dict_Code);
    //       this.deleteAction("geodataPPPoint" + "jmp" + a.dict_Code);
    //     });
    //   }
    //   // 移除已开门店
    //   if (Array.isArray(ownerBrands)) {
    //     ownerBrands.forEach(b => {
    //       if (Array.isArray(b.subBrandList) && b.subBrandList.length == 0) {
    //         this.deleteAction("geodataStorePoint" + b.brandName);
    //       } else if (
    //         Array.isArray(b.subBrandList) &&
    //         b.subBrandList.length > 0
    //       ) {
    //         b.subBrandList.forEach(bc => {
    //           this.deleteAction("geodataStorePoint" + bc.brandName);
    //         });
    //       }
    //     });
    //   }
    //   if (Array.isArray(joinBrands)) {
    //     joinBrands.forEach(b => {
    //       if (Array.isArray(b.subBrandList) && b.subBrandList.length == 0) {
    //         this.deleteAction("geodataStorePoint" + "jmd" + b.brandName);
    //       } else if (
    //         Array.isArray(b.subBrandList) &&
    //         b.subBrandList.length > 0
    //       ) {
    //         b.subBrandList.forEach(bc => {
    //           this.deleteAction("geodataStorePoint" + "jmd" + bc.brandName);
    //         });
    //       }
    //     });
    //   }
    //   //竞争品牌移除
    //   if (Array.isArray(competitorList)) {
    //     competitorList.forEach(c => {
    //       this.deleteAction("vs" + c.dict_Code + "Source");
    //     });
    //   }
    //   // 调用标记 marker 清除函数 remove 清空地图上商圈显示名称
    //   if (Array.isArray(tABusTypeList)) {
    //     tABusTypeList.forEach(d => {
    //       this.taNameMarkerLst[d.dict_Code].forEach(item => {
    //         // item.marker.remove();
    //         this.removeLayerSource([item.taLayerId, item.taLineLayerId]);
    //       });
    //     });
    //   }
    // },
    // 清空测量的直线距离，可以绘制多个；清空测量面积
    clearMeasureDistanceAndArea(id) {
      var removePoints = (id) => {
        if (!this.map.getSource("points" + id)) {
          return;
        }
        this.map.getSource("points" + id).setData(json);
        this.map.getSource("line-move" + id).setData(json);
        this.map.getSource("line" + id).setData(json);
      }


      let json = {
        type: "FeatureCollection",
        features: []
      };

      if (id) {
        $(".measure-result" + id).remove();
        removePoints(id);
      } else {
        $(".measure-result").remove();
        this.measureIds.forEach(id => {
          removePoints(id);
        });
        this.measureIds = [];
      }
    },
    clearMeasureArea(id) {
      var removePoints = (id) => {
        if (!this.map.getSource("points-area" + id)) {
          return;
        }
        this.map.getSource("points-area" + id).setData(json);
        this.map.getSource("line-area" + id).setData(json);
      }


      let json = {
        type: "FeatureCollection",
        features: []
      };

      if (id) {
        $(".measure-area-result" + id).remove();
        removePoints(id);
      }
    },
    // 测量直线距离
    measureDistance(isClear) {
      this.isAreaMeasure = false;
      this.isWalkMeasure = false;
      this.isMeasure = true;

      // 禁止双击缩放
      this.map.doubleClickZoom.disable();
      this.map.getCanvas().style.cursor = "default";

      // if (isClear) { // 永不清除
      //   this.clearMeasureDistanceAndArea();
      // }

      var curId = this.curMeasureId = this.curMeasureId + 1;
      this.measureIds.push(this.curMeasureId);

      let jsonPoint = this.newJsonPoint = {
        type: "FeatureCollection",
        features: []
      };
      let jsonLine = this.newJsonLine = {
        type: "FeatureCollection",
        features: []
      };
      let points = this.newPoints = [];
      const ele = this.measureEle = document.createElement("div");
      ele.setAttribute("class", "measure-result measure-result" + curId);
      // ele.setAttribute("class", "measure-result");
      ele.setAttribute("data-id", curId);
      ele.style.opacity = 1;
      const option = {
        element: ele,
        anchor: "left",
        offset: [8, 0]
      };
      this.newTooltip = new mapboxgl.Marker(option)
        .setLngLat([0, 0])
        .addTo(this.map);
      // let markers = [];

      let source = this.map.getSource("points" + curId);
      if (source) {
        this.map.getSource("points" + curId).setData(jsonPoint);
        this.map.getSource("line-move" + curId).setData(jsonLine);
        this.map.getSource("line" + curId).setData(jsonLine);
      } else {
        this.map.addSource("points" + curId, {
          type: "geojson",
          data: jsonPoint
        });
        this.map.addSource("line" + curId, {
          type: "geojson",
          data: jsonLine
        });
        this.map.addSource("line-move" + curId, {
          type: "geojson",
          data: jsonLine
        });
        this.map.addLayer({
          id: "line-move" + curId,
          type: "line",
          source: "line-move" + curId,
          paint: {
            "line-color": "#ff0000",
            "line-width": 2,
            "line-opacity": 0.65
          }
        });
        this.map.addLayer({
          id: "line" + curId,
          type: "line",
          source: "line" + curId,
          paint: {
            "line-color": "#ff0000",
            "line-width": 2,
            "line-opacity": 0.65
          }
        });
        this.map.addLayer({
          id: "points" + curId,
          type: "circle",
          source: "points" + curId,
          paint: {
            "circle-color": "#ffffff",
            "circle-radius": 3,
            "circle-stroke-width": 2,
            "circle-stroke-color": "#ff0000"
          }
        });
      }

      let addPoint = coords => {
        jsonPoint = this.newJsonPoint;
        jsonLine = this.newJsonLine;
        if (jsonPoint.features.length > 0) {
          let prev = jsonPoint.features[jsonPoint.features.length - 1];
          jsonLine.features.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [prev.geometry.coordinates, coords]
            }
          });
          this.map.getSource("line" + this.curMeasureId).setData(jsonLine);
        }
        jsonPoint.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords
          }
        });
        this.map.getSource("points" + this.curMeasureId).setData(jsonPoint);
      };

      let getLength = coords => {
        points = this.newPoints;
        let _points = points.concat([coords]);
        let line = turf.lineString(_points);
        let len = turf.length(line);
        if (len < 1) {
          len = Math.round(len * 1000) + "m";
        } else {
          len = len.toFixed(2) + "km";
        }
        return len;
      };

      let addMeasureRes = coords => {
        points = this.newPoints;
        const ele = document.createElement("div");
        ele.setAttribute("class", "measure-result measure-result" + this.curMeasureId);
        const option = {
          element: ele,
          anchor: "left",
          offset: [8, 0]
        };
        ele.innerHTML = points.length === 0 ? "起点" : getLength(coords);
        // let marker =
        new mapboxgl.Marker(option)
          .setLngLat(coords)
          .addTo(this.map);
        // markers.push(marker);
      };

      if (this.measureEventBinded) {
        return;
      }
      this.measureEventBinded = true;
      this.map.on("click", _e => {
        if (!this.isMeasure || this.doubleClick) {
          return
        }

        points = this.newPoints;

        // console.log('points: ', points);
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        if (points.length > 0) {
          addMeasureRes(coords);
        }
        addPoint(coords);
        points.push(coords);
      });

      this.map.on("mousemove", _e => {
        if (!this.isMeasure) {
          return;
        }

        jsonPoint = this.newJsonPoint;
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        if (jsonPoint.features.length > 0) {
          let prev = jsonPoint.features[jsonPoint.features.length - 1];
          let json = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [prev.geometry.coordinates, coords]
            }
          };
          this.map.getSource("line-move" + this.curMeasureId).setData(json);
          this.measureEle.innerHTML = getLength(coords);
        } else {
          this.measureEle.innerHTML = "点击地图开始测量";
        }
        this.newTooltip.setLngLat(coords);
      });

      // 处理双击事件
      let doDblclick = _e => {
        // console.log('doDblclick: ', this.newJsonPoint);
        this.doubleClick = true;
        setTimeout(() => {
          this.doubleClick = false;
        }, 666);
        if (!this.isMeasure) {
          return;
        }

        jsonPoint = this.newJsonPoint;
        jsonLine = this.newJsonLine;
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        addPoint(coords);
        // this.isMeasure = false;
        this.map.getCanvas().style.cursor = "";
        jsonPoint.features = [];
        jsonLine.features = [];
        // tooltip.remove();
        // 添加关闭按钮
        const ele = document.createElement("div");
        ele.setAttribute("class", "measure-result close measure-result" + this.curMeasureId);
        ele.setAttribute("data-id", this.curMeasureId);
        const option = {
          element: ele,
          anchor: "bottom-left",
          offset: [-5, -10]
        };
        ele.innerHTML = "×";
        new mapboxgl.Marker(option).setLngLat(coords).addTo(this.map);
        ele.onclick = __e => {
          __e.stopPropagation();
          this.map.doubleClickZoom.enable();
          let elId = $(__e.target).data('id');
          this.clearMeasureDistanceAndArea(elId);

          // this.measureDistance(); // TEST
        };

        points = this.newPoints = []; // 重新计量
        this.measureDistance(); // 再创建一个测距，不要清空之前的
      }

      if (this.isMobile) {
        new RegisterDbltouchEvent(this.map, doDblclick); // 移动端双击处理
      } else {
        this.map.on("dblclick", doDblclick);
      }

    },
    //测量面积
    measureArea() {
      this.isMeasure = false;
      this.isAreaMeasure = true;
      this.isWalkMeasure = false;
      // 禁止双击缩放
      this.map.doubleClickZoom.disable();
      this.map.getCanvas().style.cursor = "default";

      // let clearMeasure = () => {
        // $(".measure-area-result").remove();
        // let json = {
        //   type: "FeatureCollection",
        //   features: []
        // };
        // let source = this.map.getSource("points");
        // if (source) {
        //   this.map.getSource("points").setData(json);
        //   this.map.getSource("line-move").setData(json);
        //   this.map.getSource("line").setData(json);
        // }
        // let sourceArea = this.map.getSource("points-area");
        // if (sourceArea) {
        //   this.map.getSource("points-area").setData(json);
        //   this.map.getSource("line-area").setData(json);
        // }

        // this.clearMeasureDistanceAndArea();
      //   this.isMeasure = false;
      // };
      // clearMeasure();

      var curId = this.curMeasureAreaId = this.curMeasureAreaId + 1;

      let jsonPoint = this.newAreaJsonPoint = {
        type: "FeatureCollection",
        features: []
      };
      let jsonLine = this.newAreaJsonLine = {
        type: "FeatureCollection",
        features: []
      };
      let points = this.newAreaPoints = [];
      let ele = this.measureAreaEle = document.createElement("div");
      ele.setAttribute("class", "measure-result measure-area-result measure-area-result" + curId);
      ele.style.opacity = 1;
      const option = {
        element: ele,
        anchor: "left",
        offset: [8, 0]
      };
      this.newAreaTooltip = new mapboxgl.Marker(option)
        .setLngLat([0, 0])
        .addTo(this.map);
      let source = this.map.getSource("points-area" + curId);
      if (source) {
        this.map.getSource("points-area" + curId).setData(jsonPoint);
        this.map.getSource("line-area" + curId).setData(jsonLine);
      } else {
        this.map.addSource("points-area" + curId, {
          type: "geojson",
          data: jsonPoint
        });
        this.map.addSource("line-area" + curId, {
          type: "geojson",
          data: jsonLine
        });
        this.map.addLayer({
          id: "line-area" + curId,
          type: "fill",
          source: "line-area" + curId,
          paint: {
            "fill-color": "#ff0000",
            "fill-opacity": 0.1
          }
        });
        this.map.addLayer({
          id: "line-area-stroke" + curId,
          type: "line",
          source: "line-area" + curId,
          paint: {
            "line-color": "#ff0000",
            "line-width": 2,
            "line-opacity": 0.65
          }
        });
        this.map.addLayer({
          id: "points-area" + curId,
          type: "circle",
          source: "points-area" + curId,
          paint: {
            "circle-color": "#ffffff",
            "circle-radius": 3,
            "circle-stroke-width": 2,
            "circle-stroke-color": "#ff0000"
          }
        });
      }

      let getArea = coords => {
        points = this.newAreaPoints;
        let pts = points.concat([coords]);
        pts = pts.concat([points[0]]);
        let polygon = turf.polygon([pts]);
        let area = turf.area(polygon);
        if (area < 1000) {
          area = Math.round(area) + "m²";
        } else {
          area = (area / 1000000).toFixed(2) + "km²";
        }
        return area;
      };

      let addPoint = coords => {
        jsonPoint = this.newAreaJsonPoint;
        jsonPoint.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords
          }
        });
        this.map.getSource("points-area" + this.curMeasureAreaId).setData(jsonPoint);
      };

      if (this.measureAreaEventBinded) {
        return;
      }
      this.measureAreaEventBinded = true;
      this.map.on("click", _e => {
        if (!this.isAreaMeasure) {
          return;
        }

        points = this.newAreaPoints;
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        points.push(coords);
        addPoint(coords);
      });

      // 处理双击事件
      let doDblclick = _e => {
        if (!this.isAreaMeasure) {
          return;
        }

        points = this.newAreaPoints;
        jsonPoint = this.newAreaJsonPoint;
        jsonLine = this.newAreaJsonLine;
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        points.push(coords);
        // this.isAreaMeasure = false; // 继续可以测面积
        this.measureAreaEle.innerHTML = getArea(coords);
        this.newAreaTooltip.setLngLat(coords);
        // 添加关闭按钮
        let _ele = document.createElement("div");
        _ele.setAttribute("class", "measure-result measure-area-result close measure-area-result" + this.curMeasureAreaId);
        _ele.setAttribute("data-id", this.curMeasureAreaId);
        let option = {
          element: _ele,
          anchor: "bottom-left",
          offset: [-5, -10]
        };
        _ele.innerHTML = "×";
        new mapboxgl.Marker(option).setLngLat(coords).addTo(this.map);
        _ele.onclick = __e => {
          __e.stopPropagation();
          this.map.doubleClickZoom.enable();
          // clearMeasure();

          let elId = $(__e.target).data('id');
          this.clearMeasureArea(elId);
        };

        points = [];

        jsonPoint = {
          type: "FeatureCollection",
          features: []
        };
        jsonLine = {
          type: "FeatureCollection",
          features: []
        };

        this.measureArea();
      }

      if (this.isMobile) {
        new RegisterDbltouchEvent(this.map, doDblclick); // 移动端双击处理
      } else {
        this.map.on("dblclick", doDblclick);
      }

      this.map.on("mousemove", _e => {
        if (!this.isAreaMeasure) {
          return;
        }

        let coords = [_e.lngLat.lng, _e.lngLat.lat];

        jsonPoint = this.newAreaJsonPoint;
        let len = jsonPoint.features.length;
        if (len === 0) {
          this.measureAreaEle.innerHTML = "点击地图开始测量";
        } else if (len === 1) {
          this.measureAreaEle.innerHTML = "点击地图继续绘制";
        } else {
          let pts = points.concat([coords]);
          pts = pts.concat([points[0]]);
          let json = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [pts]
            }
          };
          this.map.getSource("line-area" + this.curMeasureAreaId).setData(json);
          this.measureAreaEle.innerHTML = getArea(coords);
        }
        this.newAreaTooltip.setLngLat(coords);
      });
    },
    // 清空测量的步行距离
    clearMeasureWalk(id) {
      var removePoints = (id) => {
        if (!this.map.getSource("points-walk" + id)) {
          return;
        }
        this.map.getSource("points-walk" + id).setData(json);
        this.map.getSource("line-move-walk" + id).setData(json);
        this.map.getSource("line-walk" + id).setData(json);
      }

      let json = {
        type: "FeatureCollection",
        features: []
      };

      if (id) {
        $(".measure-walk-result" + id).remove();
        removePoints(id);
      }
    },
    // 测量步行距离
    measureWalk() {
      this.isAreaMeasure = false;
      this.isMeasure = false;
      this.isWalkMeasure = true;

      // 禁止双击缩放
      this.map.doubleClickZoom.disable();
      this.map.getCanvas().style.cursor = "default";

      var curId = this.curMeasureWalkId = this.curMeasureWalkId + 1;

      let jsonPoint = this.newWalkJsonPoint = {
        type: "FeatureCollection",
        features: []
      };
      let jsonLine = this.newWalkJsonLine = {
        type: "FeatureCollection",
        features: []
      };
      let points = this.newWalkPoints = [];
      const ele = this.measureWalkEle = document.createElement("div");
      ele.setAttribute("class", "measure-result measure-walk-result measure-walk-result" + curId);
      ele.setAttribute("data-id", curId);
      ele.style.opacity = 1;
      const option = {
        element: ele,
        anchor: "left",
        offset: [8, 0]
      };
      this.newTooltip = new mapboxgl.Marker(option)
        .setLngLat([0, 0])
        .addTo(this.map);
      // let markers = [];

      let source = this.map.getSource("points-walk" + curId);
      if (source) {
        this.map.getSource("points-walk" + curId).setData(jsonPoint);
        this.map.getSource("line-move-walk" + curId).setData(jsonLine);
        this.map.getSource("line-walk" + curId).setData(jsonLine);
      } else {
        this.map.addSource("points-walk" + curId, {
          type: "geojson",
          data: jsonPoint
        });
        this.map.addSource("line-walk" + curId, {
          type: "geojson",
          data: jsonLine
        });
        this.map.addSource("line-move-walk" + curId, {
          type: "geojson",
          data: jsonLine
        });
        this.map.addLayer({
          id: "line-move-walk" + curId,
          type: "line",
          source: "line-move-walk" + curId,
          paint: {
            "line-color": "#ff0000",
            "line-width": 2,
            "line-opacity": 0.65
          }
        });
        this.map.addLayer({
          id: "line-walk" + curId,
          type: "line",
          source: "line-walk" + curId,
          paint: {
            "line-color": "#ff0000",
            "line-width": 4,
            "line-opacity": 0.65
          }
        });
        this.map.addLayer({
          id: "points-walk" + curId,
          type: "circle",
          source: "points-walk" + curId,
          paint: {
            "circle-color": "#ffffff",
            "circle-radius": 3,
            "circle-stroke-width": 2,
            "circle-stroke-color": "#ff0000"
          }
        });
      }

      let addPoint = (coords, steps) => {
        jsonPoint = this.newWalkJsonPoint;
        jsonLine = this.newWalkJsonLine;
        if (jsonPoint.features.length > 0) {
          let prev = jsonPoint.features[jsonPoint.features.length - 1];
          let realSteps = null;
          if (steps) {
            realSteps = [];
            steps.forEach(step => {
              step.split(';').forEach(pos => {
                realSteps.push(pos.split(','));
              })
            })

            // 有步行步骤，清空2点间连线
            let json = {
              type: "FeatureCollection",
              features: []
            };
            this.map.getSource("line-move-walk" + this.curMeasureWalkId).setData(json);
          }
          jsonLine.features.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: realSteps || [prev.geometry.coordinates, coords]
            }
          });
          this.map.getSource("line-walk" + this.curMeasureWalkId).setData(jsonLine);
        }
        jsonPoint.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords
          }
        });
        this.map.getSource("points-walk" + this.curMeasureWalkId).setData(jsonPoint);
      };

      let getLength = coords => {
        points = this.newWalkPoints;
        let _points = points.concat([coords]);
        let line = turf.lineString(_points);
        let len = turf.length(line);
        if (len < 1) {
          len = Math.round(len * 1000) + "m";
        } else {
          len = len.toFixed(2) + "km";
        }
        return len;
      };

      let addMeasureRes = coords => {
        points = this.newWalkPoints;
        const ele = document.createElement("div");
        ele.setAttribute("class", "measure-result measure-walk-result measure-walk-result" + this.curMeasureWalkId);
        const option = {
          element: ele,
          anchor: "left",
          offset: [8, 0]
        };
        ele.innerHTML = points.length === 0 ? "起点" : getLength(coords);
        // let marker =
        new mapboxgl.Marker(option)
          .setLngLat(coords)
          .addTo(this.map);
        // markers.push(marker);
      };

      let getDirectionWalking = (coords, cb) => {
        this.$http
        .get(constants.iMap.WALKING, {
          params: {
            origin: `${coords[0][0]},${coords[0][1]}`,
            destination: `${coords[1][0]},${coords[1][1]}`,
            output: "json",
            key: constants.iMap.WEBKEY,
          },
        })
        .then((res) => {
          cb(res);
        });
      }

      if (this.measureWalkEventBinded) {
        return;
      }
      this.measureWalkEventBinded = true;
      this.map.on("click", _e => {
        if (!this.isWalkMeasure || this.doubleClick) {
          return;
        }
        // console.log('this.doubleClick', this.doubleClick);
        points = this.newWalkPoints;

        // 加本地点击有2个点了，开始测试步行距离，结束本次测距
        if (points.length > 0) {
          jsonPoint = this.newWalkJsonPoint;
          jsonLine = this.newWalkJsonLine;
          let coords = [_e.lngLat.lng, _e.lngLat.lat];

          // 获取路径图
          getDirectionWalking([points[0], coords], (res) => {
            // console.log('getDirectionWalking res: ', res);
            const resBody = res.body;
            if (resBody?.route?.paths) {
              var pathDistance = 0;
              var allSteps = [];
              resBody.route.paths.forEach(p => {
                pathDistance += p.distance;
                allSteps = allSteps.concat(p.steps.map(s => s.polyline));
              })

              // console.log('allSteps: ', allSteps);
              this.measureWalkEle.innerHTML = pathDistance >= 1000 ? (pathDistance / 1000).toFixed(2) + "km" : Math.round(pathDistance) + "m";
              addPoint(coords, allSteps);
            } else {
              this.measureWalkEle.innerHTML = getLength(coords);
              addPoint(coords);
            }
            // this.isWalkMeasure = false;
            this.map.getCanvas().style.cursor = "";
            jsonPoint.features = [];
            jsonLine.features = [];
            // tooltip.remove();
            // 添加关闭按钮
            const ele = document.createElement("div");
            ele.setAttribute("class", "measure-result measure-walk-result close measure-walk-result" + this.curMeasureWalkId);
            ele.setAttribute("data-id", this.curMeasureWalkId);
            const option = {
              element: ele,
              anchor: "bottom-left",
              offset: [-5, -10]
            };
            ele.innerHTML = "×";
            new mapboxgl.Marker(option).setLngLat(coords).addTo(this.map);
            ele.onclick = __e => {
              __e.stopPropagation();
              this.map.doubleClickZoom.enable();
              let elId = $(__e.target).data('id');
              this.clearMeasureWalk(elId);
            };

            points = this.newWalkPoints = []; // 重新计量
            this.measureWalk(); // 再创建一个测距，不要清空之前的
          })
          return;
        }

        // console.log('points: ', points);
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        if (points.length > 0) {
          addMeasureRes(coords); // 起点不要label
        }
        this.measureWalkEle.innerHTML = "终点";
        addPoint(coords);
        points.push(coords);
      });

      this.map.on("mousemove", _e => {
        if (!this.isWalkMeasure) {
          return;
        }

        jsonPoint = this.newWalkJsonPoint;
        let coords = [_e.lngLat.lng, _e.lngLat.lat];
        if (jsonPoint.features.length > 0) {
          let prev = jsonPoint.features[jsonPoint.features.length - 1];
          let json = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [prev.geometry.coordinates, coords]
            }
          };
          this.map.getSource("line-move-walk" + this.curMeasureWalkId).setData(json);
          // this.measureWalkEle.innerHTML = getLength(coords);
          this.measureWalkEle.innerHTML = "终点";
        } else {
          this.measureWalkEle.innerHTML = "点击地图开始测量";
        }
        this.newTooltip.setLngLat(coords);
      });
    },
    // 关闭测量
    closeMeasure() {
      this.showMeasure = false;
      this.isMeasure = false;
      this.isAreaMeasure = false;
      this.isWalkMeasure = false;
      if (this.measureEle) {
        this.measureEle.style.opacity = 0;
      }
      if (this.measureAreaEle) {
        this.measureAreaEle.style.opacity = 0;
      }
      if (this.measureWalkEle) {
        this.measureWalkEle.style.opacity = 0;
      }
      // this.clearMeasureDistanceAndArea();
    },
    //快速定位到当前位置
    goLocation() {
      if (this.$store.state.location.type != "") this.resetLocalMarker();

      if (!this.gotLocation && !this.isMobile) {
        //调用高德web服务api获取当前位置ip
        this.$http
          .get(constants.iMap.GEOURL, {
            params: {
              address: localStorage.cityname,
              key: constants.iMap.WEBKEY
            }
          })
          .then(res => {
            if (res.status == 200 && res.data.infocode * 1 === 10000 && res.data.geocodes.length > 0) {
              this.$store.state.city = "";
              sessionStorage.currentCity = res.data.geocodes[0].city;
              sessionStorage.currentCode = res.data.geocodes[0].adcode;
              this.$store.state.currentCity = {
                id: res.data.geocodes[0].adcode,
                name: res.data.geocodes[0].city,
                joinId: ""
              };
              let crdStr = res.data.geocodes[0].location;
              let crd = crdStr.split(",");
              if (!this.gotLocation) {
                // this.map.flyTo({
                this.map.jumpTo({
                  center: crd, // 中心点
                  zoom: 11 // 缩放比例
                });
              }
              return;
            }
            this.$message({
              message: `无法定位当前城市，请手工选择！`,
              type: "warning"
            });
          })
          .catch(err => {
            this.$message({
              message: `ERROR(${err.msg})`,
              type: "warning"
            });
          });

        geoFindMe();
      } else {
        this.geoLocationControl.trigger(); // 定位
      }
      
      function geoFindMe() {
        function success(position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
          console.log('textContent: ', textContent);
          // alert(textContent)
          this.gotLocation = true;

          // this.map.flyTo({
          this.map.jumpTo({
            center: [longitude, latitude], // 中心点
            zoom: 11 // 缩放比例
          });
        }

        function error() {
          console.log("Unable to retrieve your location");
        }

        if (!navigator.geolocation) {
          console.log("Geolocation is not supported by your browser");
        } else {
          console.log("Locating…");
          navigator.geolocation.getCurrentPosition(success, error);
        }
      }
    },
    //底图切换
    changemap(toggleableLayerIds) {
      this.mapType = toggleableLayerIds;
      let tileUrl = "";
      if (toggleableLayerIds == "street") {
        tileUrl = constants.tiles.VECTORNROAD;
      } else if (toggleableLayerIds == "gray") {
        tileUrl = constants.tiles.GRAY;
      } else {
        tileUrl = constants.tiles.SATELLITE;
      }
      // this.legendType.push[{name:"商圈",type:4}]
      this.renderMarker(this.legendType, true);
      this.map.setStyle({
        version: 8,
        name: "Mapbox Streets",
        sprite: "mapbox://sprites/mapbox/streets-v8",
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
        sources: {
          "osm-tiles": {
            type: "raster",
            tiles: [tileUrl],
            tileSize: 256
          }
        },
        layers: [
          {
            id: "main",
            type: "raster",
            source: "osm-tiles",
            "source-layer": "osmtiles"
          }
        ]
      });
      this.map.addLayer({
        id: "backgroundLayer0",
        type: "background",
        paint: {
          "background-color": "rgba(255, 255, 255, 0)" // 背景层透明
        }
      });
      this.map.addLayer({
        id: "backgroundLayer",
        type: "background",
        paint: {
          "background-color": "rgba(255, 255, 255, 0)" // 背景层透明
        }
      });

      if (toggleableLayerIds == "satellite-v9" && this.showRoad) { // 如果是卫星，且选过路网，则显示路网
        this.loadRoad()
      }
    },
    // 路网显示控制 1： 显示路网，0：隐藏路网
    loadRoad() {
      //显示路网，0：隐藏路网
      if (this.showRoad == 1) {
        this.map.addSource("roadLayer", {
          type: "raster",
          tiles: [constants.tiles.ROADNMARK], //['http://webst01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8'],//高德路网+标注
          tileSize: 256
        });
        this.map.addLayer({
          id: "roadLayer",
          type: "raster",
          paint: {
            "raster-opacity": 1.0,
            "raster-opacity-transition": {
              duration: 0
            },
            "raster-fade-duration": 5
          },
          source: "roadLayer",
          minZoom: 0
        });
      } else if (this.map.getSource("roadLayer")) {
        this.map.removeLayer("roadLayer");
        this.map.removeSource("roadLayer");
      }
    },
    // 切换路网显示
    switchShowRoad() {
      this.showRoad = !this.showRoad;
      this.loadRoad();
    },
    // 图例筛选
    handleNodeClick(data) {
      const singleIds = [100, 101, 102]; // 只能选一个的选项
      const preIds = this.selectedMarker.map(s => s.id);

      let checkedNodes = this.$refs.selectMark
        .getCheckedNodes()
        .filter(e => {
          return !e.children;
        });

      let preSingleId;
      preIds.forEach(p => {
        if (singleIds.indexOf(p) > -1) {
          preSingleId = p
        }
      })

      // 控制省、市、区只能选一个
      if (preSingleId) {
        if (checkedNodes.filter(c => singleIds.indexOf(c.id) > -1).length > 1) {
          // 去掉原来的选项
          checkedNodes = this.$refs.selectMark
          .getCheckedNodes()
          .filter(e => {
            return !e.children && e.id !== preSingleId;
          });
          // 选中项更新到组件上
          this.$refs.selectMark.setCheckedNodes(checkedNodes);
        }
      }

      console.log(this.selectedMarker);
      this.selectedMarker = checkedNodes;
      //关闭页面后图例筛选默认选中
      this.defaultCheckedKeys = [];
      this.selectedMarker.map(e => {
        this.defaultCheckedKeys.push(e.id);
      });
      this.renderMarker(this.selectedMarker);
      console.log(this.data);

      // poi筛选变化
      this.poiSelectCodes = this.selectedMarker.filter(s => s.type * 1 === 444).map(s => s.code).join(',');
      this.poiSelectNames = ''; // 全量里面的清空
      this.getPoiData();
    },
    // 选择Poi
    selectPoi() {
      if (this.poiSelectBigType?.lengh > 0) {
        this.poiSelectModelShow = true;
        return;
      }
      this.loadingMap = true;
      api.getPoiDataType({
        type: 222,
        codes: ''
      })
        .then(res => {
          this.loadingMap = false;
          if (res.code == 200) {
            console.log('getPoiDataType: ', res.data);
            this.poiSelectBigType = res.data;
            this.poiSelectModelShow = true;
          } else {
            this.$message.error("接口请求报错");
          }
        })
        .catch(err => {
          this.loadingMap = false;
          console.log(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    // 执行查询poi
    doSearchPoi() {
      this.poiSelectModelShow = false;
      this.getPoiData();
    },
    // 显示Poi数据
    getPoiData() {
      if (!this.poiSelectCodes) { // 清空poi图
        this.loadingMap = false;
        this.clearPoiData();
        return;
      }
      let nw = this.map.getBounds().getNorthWest();
      let se = this.map.getBounds().getSouthEast();
      let ne = this.map.getBounds().getNorthEast();
      let sw = this.map.getBounds().getSouthWest();
      const geometry = `[[${nw.lng},${nw.lat}],[${ne.lng},${ne.lat}],[${se.lng},${se.lat}],[${sw.lng},${sw.lat}]]`;

      api.getPoiData({
        geometry,
        codes: this.poiSelectCodes
      }).then(res => {
          this.loadingMap = false;
          if (res.code == 200) {
            console.log('getPoiDataType: ', res.data);
            this.poiSelect = res.data;
            this.clearPoiData(); // 放这里标记不会明显闪
            this.renderPoiData();
          } else {
            this.$message.error("接口请求报错");
          }
        })
        .catch(err => {
          this.loadingMap = false;
          console.log(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    // 清空Poi数据
    clearPoiData() {
      const sourceName = 'poiPointSource';
      if (this.map.getSource(sourceName)) {
        this.map.getSource(sourceName).setData({
          type: "FeatureCollection",
          features: []
        });
      }
      if (this.poiMarkers.length > 0) {
        this.poiMarkers.forEach(marker => {
          marker.remove();
        });
        this.poiIds = [];
        this.poiMarkers = [];
      }
    },
    // 清空Poi数据
    renderPoiData(iconSize = 0.5) {
      if (this.poiSelect?.length === 0) {
        return;
      }

      if (!this.poiImgLoaded) {
        var imgPoiMarker = require('../../assets/images/marker/poi-marker.png');

        this.mapLoadImage(
          'poi-marker',
          imgPoiMarker
        );
        this.poiImgLoaded = true;
      }

      let feas = [];
      this.poiSelect.map(m => {
        feas.push({
          type: "Feature",
          properties: {
            id: m.id,
            name: m.name,
            avg: 10,
            icon: 'poi-marker',
            type: m.type,
            address: m.address,
            judgePopup: "poi地址",
            datas: m
          },
          geometry: {
            type: "Point",
            coordinates: [m.plonwgs84, m.platwgs84]
          }
        });
      });

      const layerName = 'poiPointLayer';
      const clusterLayerName = 'poiPoinClusterLayer';
      const clusterCountLayerName = 'poiPointClusterCountLayer';
      const sourceName = 'poiPointSource';

      let geojsonPoints = {
        type: "FeatureCollection",
        features: []
      };
      geojsonPoints.features = feas;
      if (this.map.getLayer(layerName)) {
        this.map.getSource(sourceName).setData(geojsonPoints);
      } else {
        this.map.addSource(sourceName, {
          type: "geojson",
          data: geojsonPoints,
          cluster: true, //允许聚合
          clusterMaxZoom: 9, // 缩放等级小于8时取开始聚合
          clusterRadius: 100
        });
        this.map.addLayer({
          id: clusterLayerName,
          type: "circle",
          source: sourceName,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#f28cb1", // 少于100个显示粉色半径20px的圆形
              100, // 分界点：100个点位
              "#ff7e30",
              300,
              "#1a6072"
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20, // 20px
              100, // 分界点： 100个点位
              50,
              300,
              70
            ]
          }
        });
        this.map.addLayer({
          id: clusterCountLayerName,
          type: "symbol",
          source: sourceName,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
        this.map.addLayer({
          id: layerName,
          type: "symbol",
          source: sourceName,
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": iconSize,
            "icon-offset": [0, -20],
            "icon-allow-overlap": true
          }
        });
      }

      if (this.poiLayerClickInited) {
        return;
      }
      this.poiLayerClickInited = true;
      this.map.on("click", layerName, e => {
        /**
         * 当前点击点：多商圈层叠，展示商圈选取弹窗；
         * 当前点击点：多标记点与商圈混合层叠，禁止展示商圈信息，并展示标记点选取弹窗
         */
        // 解构点击点坐标
        let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
        // 筛选点击位置占据的所有图层，包括商圈和标记点
        let filtJudgeLayerId = [...this.judgeLayerIdArr];
        filtJudgeLayerId.splice(
          filtJudgeLayerId.indexOf(layerName),
          1
        );
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: this.filtJudgeLayerId
        });
        // 筛选标记点图层
        let symbols = features.filter(j => {
          // console.log(j);
          return j.layer.type === "symbol" && j.layer.id != "dizhi";
        });
        if (symbols.length > 1) {
          this.showTaMarkerPopup(e, symbols, clickLng, clickLat);
          return;
        }

        const properties = e.features?.[0]?.properties;

        this.showPoiMarker(properties, clickLng, clickLat);
      });

      if (!this.judgeLayerIdArr.includes(layerName)) {
        this.judgeLayerIdArr.push(layerName);
      }
    },
    // 显示poi详情
    showPoiMarker(properties, clickLng, clickLat) {
      if (this.poiIds.indexOf(properties?.id) > -1) {
        return;
      }
      this.poiIds.push(properties?.id);
      let div = document.createElement("div");
      div.innerHTML = `
          <div>名称：${properties?.name}</div>
          <div>类型：${properties?.type}</div>
          <div>地址：${properties?.address}</div>
        `;
      div.className = "ta-marker-popup";

      const poiMarker = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: false
      }).setDOMContent(div);
      poiMarker.on('close', () => {
        if (this.poiIds.indexOf(properties?.id) > -1) {
          this.poiIds.splice(this.poiIds.indexOf(properties?.id), 1);
        }
      });
      poiMarker.setLngLat([clickLng, clickLat]).addTo(this.map);
      this.poiMarkers.push(poiMarker);
    },
    // poi小类更新
    smallTypeChange(smallType, smallTypeName) {
      console.log('smallType: ', smallType, smallTypeName);
      this.poiSelectCodes = smallType?.length > 0 ? smallType.join(',') : '';
      this.poiSelectNames = smallTypeName?.length > 0 ? smallTypeName.join(',') : '';
    },
    //获取图例筛选列表
    getCompetitorBrand(res, poiOften) {
      if (res.code != 200) {
        return;
      }
      // 机会点、门店 添加不同icon 区分normal和超A、终止
      var imgOld = require('../../assets/images/marker/fish-old.png');
      var imgFishNormal = require('../../assets/images/marker/fish-normal.png');
      var imgFishA = require('../../assets/images/marker/fish-a.png');
      var imgFishCancel = require('../../assets/images/marker/fish-cancel.png');

      var imgFishOld = require('../../assets/images/marker/fish-old.png');
      var imgFishOldCancel = require('../../assets/images/marker/fish-old-cancel.png');

      // 机会点
      if (res.data.ownerBrands.length > 0) {
        let ppProgress = {
          name: "机会项目",
          type: 22,
          id: 22,
          children: [
            {
              name: "正常",
              type: 2,
              id: 2,
              children: []
            },
            {
              name: "终止",
              type: 21,
              id: 21,
              children: []
            }
          ]
        };

        res.data.ownerBrands.map(m => {
          let item = {
            type: 2, // 图标类型 - 机会点
            id: "zyp" + m.id,
            code: m.dict_Code, // 机会项目进度类型
            logo: m.dict_Code, // 地图加载图标标记
            icon: `images/com/${m.dict_Code}.png`, // 树形节点图标加载路径
            iconLocal: imgFishA, // 树形节点本地图标加载路径
            // name: this.$i18n.locale == "zh" ? m.type_Name : m.type_EnName, // 树形节点名称
            name: m.type_Name // 树形节点名称
          };
          let itemJoin = {
            type: 21, // 图标类型 - 机会点
            id: "jmp" + m.id,
            code: m.dict_Code, // 机会项目进度类型
            logo: "jmp" + m.dict_Code, // 地图加载图标标记
            icon: `images/com/${m.dict_Code}.png`, // 树形节点图标加载路径
            iconLocal: imgFishCancel, // 树形节点本地图标加载路径
            name: "jmp" + m.type_Name, // 树形节点名称
            judgeCode: "jmp" + m.dict_Code
          };

          this.legendType.push(item); // 存储机会点节点信息，用于初始化渲染机会点
          this.selectedMarker.push(item); // 初始化图例筛选已选择节点

          ppProgress.children[0].children.push(item);
          ppProgress.children[1].children.push(itemJoin);
          this.selectedPP.push(item);
          this.selectedPP.push(itemJoin);

          // 用本地图片
          // this.mapLoadImage(
          //   item.code,
          //   constants.baseURL.fileURL + "images/com/" + m.dict_Code + ".png"
          // );
          // this.mapLoadImage(
          //   itemJoin.code,
          //   constants.baseURL.fileURL + "images/com/" + m.dict_Code + ".png"
          // );

          this.mapLoadImage(
            item.code + '-normal',
            imgFishNormal
          );
          this.mapLoadImage(
            item.code + '-a',
            imgFishA
          );
          this.mapLoadImage(
            item.code + '-cancel',
            imgFishCancel
          );
          this.mapLoadImage(
            item.code + '-old',
            imgOld
          );
        });

        this.data.splice(0, 0, ppProgress); // 机会点选项插入树形目录
      }
      // 直营、加盟门店
      let cusStore = {
        name: "门店",
        type: 3,
        id: 3,
        children: [
          {
            name: "在营",
            type: 3,
            id: 3,
            children: []
          },
          {
            name: "闭店",
            type: 31,
            id: 31,
            children: []
          }
        ]
      };

      // 直营
      if (res.data.ownerBrands.length > 0) {
        let storeThis = this;
        res.data.ownerBrands.map(m => {
          let item = {
            type: 3, // 图标类型 - 门店
            id: m.dict_Code,
            code: m.dict_Code, // 店铺类型
            icon: `images/com/${m.dict_Code}.png`, // 树形节点图标加载路径
            logo: m.type_Name, // 地图加载图标标记
            iconLocal: imgFishOld, // 树形节点本地图标加载路径
            // name: this.$i18n.locale == "zh" ? m.type_Name : m.type_Name // 树形节点名称
            name: m.type_Name // 树形节点名称
          };

          let itemJoin = {
            type: 31, // 图标类型 - 门店
            id: "jmd" + m.dict_Code,
            code: m.dict_Code, // 店铺类型
            icon: `images/com/${m.dict_Code}.png`, // 树形节点图标加载路径
            logo: "jmd" + m.type_Name, // 地图加载图标标记
            iconLocal: imgFishOldCancel, // 树形节点本地图标加载路径
            name: "jmd" + m.type_Name, // 树形节点名称
            judgeCode: "jmd" + m.dict_Code
          };
          // 子节点
          storeThis.legendType.push(item); // 存储门店节点信息，用于初始化渲染门店
          storeThis.selectedMarker.push(item); // 初始化图例筛选已选择节点
          // storeThis.legendType.push(itemJoin); // 存储门店节点信息，用于初始化渲染门店
          // storeThis.selectedMarker.push(itemJoin); // 初始化图例筛选已选择节点
          // cusStore.children[0].children.push(item);
          // cusStore.children.push(item);
          cusStore.children[0].children.push(item);
          cusStore.children[1].children.push(itemJoin);
          // this.mapLoadImage(item.code, constants.baseURL.fileURL + item.icon);
          // this.mapLoadImage(
          //   itemJoin.code,
          //   constants.baseURL.fileURL + itemJoin.icon
          // );

          this.mapLoadImage(
            item.code + '-normal',
            imgFishNormal
          );
          this.mapLoadImage(
            item.code + '-a',
            imgFishA
          );
          this.mapLoadImage(
            item.code + '-cancel',
            imgFishCancel
          );

          if (this.$store.state.location.type == "") {
            // this.requestMarker(3, item.logo, item.code, item);
          } else {
            this.defaultCheckedKeys = [];
          }
        });
      }
      this.data.splice(1, 0, cusStore); // 门店选项插入树形目录
      // // 直营、加盟门店
      // let joinStore = {
      //   name: "加盟门店",
      //   type: 7,
      //   id: 7,
      //   children: [
      //     // {
      //     //   name: "直营门店",
      //     //   type: 31,
      //     //   id: 31,
      //     //   children: [],
      //     // },
      //     // {
      //     //   name: "加盟门店",
      //     //   type: 32,
      //     //   id: 32,
      //     //   children: [],
      //     // },
      //   ]
      // };
      // // 加盟门店
      // if (res.data.ownerBrands.length > 0) {

      //   res.data.ownerBrands.map(m => {

      //     // 子节点
      //     // if (Array.isArray(m.subBrandList) && m.subBrandList.length > 0) {
      //     //   item.children = m.subBrandList.map(child => {
      //     //     this.mapLoadImage(
      //     //       "jmd" + child.brandCode,
      //     //       constants.baseURL.fileURL + `images/com/JM${child.brandCode}.png`
      //     //     );
      //     //     return {
      //     //       type: 7,
      //     //       id: "jmd" + m.brandCode + child.brandCode,
      //     //       code: "jmd" + child.brandCode,
      //     //       logo: "jmd" + child.brandName,
      //     //       name: "jmd" + child.brandName,
      //     //       icon: `images/com/JM${child.brandCode}.png`, // 树形节点图标加载路径
      //     //       judgelabel: "jmd" + child.brandCode,
      //     //       judgeCode: child.brandCode
      //     //     };
      //     //   });
      //     //   this.legendType.push(...item.children); // 第三级分类-门店状态
      //     //   this.selectedMarker.push(...item.children); // 初始化图例筛选已选择节点
      //     // } else {
      //     //   this.legendType.push(item); // 第三级分类-门店状态
      //     //   this.selectedMarker.push(item); // 初始化图例筛选已选择节点
      //     // }
      //     // joinStore.children[1].children.push(item);
      //     joinStore.children.push(item);
      //     this.mapLoadImage(item.code, constants.baseURL.fileURL + item.icon);
      //     if (this.$store.state.location.type == "") {
      //       // this.requestMarker(7, item.logo, item.code, item);
      //     } else {
      //       this.defaultCheckedKeys = [];
      //     }
      //   });
      // }
      // this.data.splice(1, 1, joinStore); // 门店选项插入树形目录
      // 竞品
      if (res.data.competitorList.length > 0) {
        let competitor = {
          name: "关注品牌",
          type: 1,
          id: 1,
          children: [
            {
              name: "全选",
              type: 1,
              id: 1,
              children: []
            }
          ]
        };
        res.data.competitorList.map((m, i) => {
          let theLogoUrl = '';
          try {
            theLogoUrl = require(`../../assets/images/com/vs${m.dict_Code}.png`);
          } catch(e) {
            console.log(e);
          }

          let item = {
            type: 1, // 图标类型 - 竞品
            id: m.id,
            code: m.dict_Code, // 竞品类型
            // icon: m.logo, // 树形节点图标加载路径
            icon: theLogoUrl, // 树形节点图标加载路径
            logo: "vslogo" + m.dict_Code, // 地图加载图标标记
            iconLocal: theLogoUrl,
            // name: this.$i18n.locale == "zh" ? m.type_Name : m.type_EnName // 树形节点名称
            name: m.type_Name // 树形节点名称
          };
          competitor.children[0].children.push(item);
          // this.mapLoadImage(item.logo, constants.baseURL.fileURL + m.logo);

          // console.log('s => ', m.dict_Code);
          // console.log('theLogoUrl: ', item.logo, theLogoUrl);
          // 图标下载到了本地
          this.mapLoadImage(item.logo, theLogoUrl);
        });
        this.data.splice(4, 0, competitor); // 竞品选项插入树形目录
      }

      // 足迹
      let footprint = {
        name: "足迹",
        type: 5,
        id: 5,
        children: [
          {
            name: "足迹",
            type: 5,
            id: 5
          }
        ]
      };
      this.data.splice(2, 0, footprint); // 足迹插入树形目录

      // 省市区边界功能
      // let regions = {
      //   name: "省市区",
      //   type: 100,
      //   id: 100,
      //   children: [
      //     {
      //       name: "省",
      //       type: 100,
      //       id: 100
      //     },
      //     {
      //       name: "市",
      //       type: 101,
      //       id: 101
      //     },
      //     {
      //       name: "区",
      //       type: 102,
      //       id: 102
      //     }
      //     // ,
      //     // {
      //     //   name: "蜂巢图",
      //     //   type: 103,
      //     //   id: 103
      //     // },
      //     // {
      //     //   name: "网格图",
      //     //   type: 104,
      //     //   id: 104
      //     // }
      //   ]
      // };
      // this.data.splice(2, 0, regions); // 省市区边界功能插入树形目录

      // 网点：蜂巢图、网格图
      let regionsBlock = {
        name: "网点",
        type: 103,
        id: 103,
        children: [
          {
            name: "蜂巢图",
            type: 103,
            id: 103,
            children: [
              {
                name: "显示(合伙制)",
                type: 105,
                id: 105
              },
              {
                name: "隐藏(非合伙制)",
                type: 106,
                id: 106
              }
            ]
          }
          // ,
          // {
          //   name: "网格图",
          //   type: 104,
          //   id: 104,
          //   children: [
          //     {
          //       name: "显示",
          //       type: 107,
          //       id: 107
          //     },
          //     {
          //       name: "隐藏",
          //       type: 108,
          //       id: 108
          //     }
          //   ]
          // }
        ]
      };
      this.data.splice(2, 0, regionsBlock); // 蜂巢图、网格图插入树形目录

      // 网点规划：商圈
      let netWorkPlan = {
        name: "网点规划",
        type: 44,
        id: 44,
        children: [
          {
            name: "商圈",
            type: 4,
            id: 4,
            children: []
          }
        ]
      };
      if (res.data.tABusTypeList.length > 0) {
        // 商圈
        /**
         * 商圈初始化
         *     1. 树形控件添加商圈类型节点
         *     2. 名称标记 marker 清空
         */
        res.data.tABusTypeList.map(m => {
          let item = {
            type: 4,
            id: m.id,
            code: m.dict_Code
          };
          // item.name = this.$i18n.locale == "zh" ? m.type_Name : m.type_EnName; //选择语言
          item.name = m.type_Name; //选择语言
          netWorkPlan.children[0].children.push(item);
          this.selectedTa.push(item);
          this.taNameMarkerLst[m.dict_Code] = [];
        });
      }
      // this.data.splice(4, 0, netWorkPlan); // 网点规划选项插入树形目录

      this.data.splice(3, 0, {
        name: "poi图层",
        type: 444,
        id: 444,
        children: [{
          name: "常用",
          type: 444,
          id: 444,
          children: poiOften?.data?.map(d => {
            return {
              name: d.type_Name,
              id: d.id,
              code: d.dict_Code,
              type: 444
            }
          })
        },{
          name: "全量",
          type: 222,
          id: 222,
          disabled: true,
          isLeaf: true,
        }]
      }); // POI按钮插入树形目录

      this.data.splice(3, 0, {
        tip: "蓝色：营业额潜力≥8K<br/>橙色：营业额潜力≥5K",
        name: "AI模型",
        type: 109,
        id: 109,
        children: [{
          name: "AI模型",
          type: 109,
          id: 109
        }]
      }); // POI按钮插入树形目录

      this.$store.commit("PMStoreIndex/updateLegendList", res.data);
    },
    //地图缩放
    changeZoom(type) {
      //0：拉远，1：拉近
      if (type == "0") {
        this.zoom--;
      } else {
        this.zoom++;
      }
      this.map.flyTo({
        zoom: this.zoom,
        speed: 1.2 //缩放速度
      });
    },
    // 打开图例标注的编辑弹窗
    openPointEditPopup(lng, lat, pointType) {
      this.info = {
        id: 0,
        coordinates: [lng, lat]
      };
      switch (pointType) {
        case "2":
        case "21":
          this.vsVisible = "pp";
          break;
        case "3":
        case "31":
          this.vsVisible = "store";
          break;
        case "1":
          this.vsVisible = "vs";
          break;
        case "5": // 5 改成 足迹
          this.footprintInfo = {
            longitude: lng,
            latitude: lat,
            // creator: sessionStorage.loginName
            creator: sessionStorage.userName
          };
          this.footprintVisible = !this.footprintVisible;
          this.footprintEdit = this.footprintVisible
          break;
        default:
          break;
      }
    },
    // 图例标注 - 地图上添加标注点
    addPointToMap(type) {
      const pointType = type.type;
      // 创建机会点、商圈前先加载已有的机会点或商圈
      if (pointType == "2" || pointType == "21" || pointType == "4") {
        let addArr = [];
        if (pointType == 2) {
          this.selectedMarker.push(...this.selectedPP);
          addArr = this.selectedPP;
          // console.log(this.selectedMarker)
        } else {
          this.selectedMarker.push(...this.selectedTa);
          addArr = this.selectedTa;
        }
        // 获取机会点或商圈全选后的所有节点
        if (this.legendType.length == 0) {
          // 图里筛选选项均为未选中状态，直接渲染全部的机会点或商圈
          this.defaultCheckedKeys.push(pointType, "21");
          this.renderMarker([...addArr]);
        } else {
          this.defaultCheckedKeys.push(pointType, "21");
          // 过滤选掉已选中的机会点或商圈
          let fillterArr = this.legendType.filter(e => {
            return e.type != pointType;
          });
          // 机会点或商圈全选后的节点集合
          this.renderMarker([...fillterArr, ...addArr]);
        }
      }
      if (pointType == "4") {
        if (this.$store.state.drawPolygonType != "report") {
          this.$store.state.drawPolygonType = "ta";
        }
        if (!this.map.getLayer("tradingarea")) {
          this.map.addControl(this.draw, "top-right");
          this.draw.changeMode("draw_polygon");
        }
      } else {
        this.$store.state.isDrawPolygon = false;
        this.isTaInfoPopUp = false; // 商圈 click 事件暂时关闭
      }
      // 绑定标注点击事件
      this.bindPointEvent(pointType);
    },
    // 绑定标注点击事件
    bindPointEvent(pointType) {
      let ti = null;
      this.map.once("click", e => {
        if (this.marker) this.marker.remove();
        let el = document.createElement("div");
        this.$bus.$emit("resetMarker"); // 放置后复位选取的标记类型，允许再次选取新的标记点
        switch (pointType) {
          case "2":
          case "21":
            el.className = "jihuidian";
            el.style.backgroundImage = `url(${this.formatURL(
              sessionStorage.siteIcon
            )})`;
            break;
          case "5": // 足迹
            el.className = "footprint";
            el.style.backgroundImage = `url(${require("../../assets/images/marker/footprint.png")})`;
            break;
          case "3":
          case "31":
            el.className = "mendian";
            el.style.backgroundImage = `url(${this.formatURL(
              sessionStorage.storeIcon
            )})`;
            break;
          // case "7":
          //   el.className = "mendian";
          //   el.style.backgroundImage = `url(${this.formatURL(
          //     sessionStorage.storeIcon
          //   )})`;
          //   break;
          case "1":
            el.className = "jingpin";
            break;
          case "4":
            if (!this.map.getLayer("tradingarea")) {
              this.map.on("draw.create", e => {
                let data = this.draw.getAll();
                const polygonData = data.features[0].geometry.coordinates;
                this.drawPloygon(polygonData);
                this.$store.state.isDrawPolygon = false;
              });
              this.map.on("draw.update", this.drawUpdate);
              this.map.on("draw.delete", e => {
                // let data = this.draw.getAll();
                this.map.off("click", "tradingarea");
                this.map.removeLayer("tradingarea").removeSource("tradingarea");
              });
            }
            break;
          default:
            break;
        }
        if (pointType != 4) {
          let el1 = document.createElement("p");
          el.appendChild(el1);
          let el2 = document.createElement("span");
          el1.appendChild(el2);
          el.addEventListener("click", event => {
            event.stopPropagation();
            this.openPointEditPopup(e.lngLat.lng, e.lngLat.lat, pointType);
          });
          this.marker = new mapboxgl.Marker(el, {
            offset: [0, -18],
            draggable: true
          })
            .setLngLat(e.lngLat)
            .addTo(this.map);
          clearTimeout(ti);
          ti = setTimeout(() => {
            this.isTaInfoPopUp = true;
          }, 1000);
          // 标记点拖拽后更新位置信息
          this.marker.on("dragend", k => {
            let lnglat = k.target._lngLat;
            this.openPointEditPopup(lnglat.lng, lnglat.lat, pointType);
          });
        }
        this.openPointEditPopup(e.lngLat.lng, e.lngLat.lat, pointType);
      });
    },
    formatURL(url) {
      let resUrl = "";
      if (url) {
        resUrl = constants.baseURL.fileURL + url.replace(/\\/g, "/");
      }
      return resUrl;
    },
    // 图例标注画布的 update 事件回调函数
    drawUpdate(e) {
      let data = this.draw.getAll();
      this.map.off("click", "tradingarea");
      this.map.removeLayer("tradingarea").removeSource("tradingarea");
      const polygonData = data.features[0].geometry.coordinates;
      if (this.delTAMarker) {
        this.delTAMarker.remove();
        this.delTAMarker = null;
      }
      this.drawPloygon(polygonData);
    },

    /**
     * 新建商圈打开信息弹窗
     * @param {Array} coordinates geojson坐标集合 [[],[]]
     * @param {Number} centerP0 中心点 lng 经度
     * @param {Number} centerP1 中心点 lat 纬度
     * @param {Number} area 多边形面积
     */
    createTaOpenInfo(coordinates, centerP0, centerP1, area) {
      this.taVisible = true;
      this.vsVisible = false;
      this.info = {
        location: coordinates,
        taid: 0,
        area: Math.round(area * 100) / 100, //平方米
        lngLat: {
          lng: centerP0,
          lat: centerP1
        }
      };
    },
    // 图例标注 - 新增商圈的时候，添加一个多边形，便于添加事件
    drawPloygon(points) {
      const drawData = this.draw.getAll();
      const area = turf.area(drawData);
      let sourceData = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: points
        },
        properties: {
          taid: 0,
          name: "新建商圈",
          geometry: points,
          judgePopup: "商圈",
          code: 0
        }
      };
      if (this.map.getSource("tradingarea")) {
        this.map.getSource("tradingarea").setData(sourceData);
      } else {
        this.map.addLayer(
          {
            id: "tradingarea",
            type: "fill",
            source: {
              type: "geojson",
              data: sourceData
            },
            layout: {},
            paint: {
              "fill-color": this.style.fillColor || "#088",
              "fill-outline-color": this.style.fillColor || "#088",
              "fill-antialias": false
            }
          },
          "backgroundLayer"
        );
      }
      // let t = calGeoHash.geoHashList(points);
      // let geoHashList = t.join(",");
      const centerPoint = this.getCenterOfGravityPoint(points[0]);
      // 商圈删除按钮
      let el = document.createElement("div");
      el.className = "delBtn";
      let el1 = document.createElement("i");
      el.appendChild(el1);
      el1.className = "el-icon-close textRed";
      let el2 = document.createElement("span");
      el2.id = "taArea";
      el2.innerHTML = this.$CalculationArea(points); //area;
      el2.className = "text-shadow-w";
      el.appendChild(el2);

      // 关闭自定义商圈
      let closeMaker = e => {
        e.stopPropagation();
        this.draw.deleteAll();
        this.map.removeLayer("tradingarea").removeSource("tradingarea");
        this.delTAMarker.remove();
        this.delTAMarker = null;
        this.map.removeControl(this.draw);
        this.map.off("draw.update", this.drawUpdate);
        this.$store.state.newMarkerType = "";
        this.taVisible = false;
        if (this.$store.state.drawPolygonType == "report") {
          // this.taReportPopUp.remove();
          this.taReportPopUp = null;
        }
        this.$store.state.drawPolygonType = "";
      }
      el.addEventListener("touchend", closeMaker); // 移动端使用
      el.addEventListener("click", closeMaker);
      if (!this.delTAMarker) {
        this.delTAMarker = new mapboxgl.Marker(el, {
          offset: [0, 0]
        })
          .setLngLat(centerPoint)
          .addTo(this.map);
      } else {
        this.delTAMarker.setLngLat(centerPoint);
      }
      this.map.on("click", "tradingarea", e => {
        // 当前有商圈在选址规划时，其余商圈禁止点击打开信息弹窗
        if (!this.canRenderLayer) return;
        // 解构点击点坐标
        // let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
        // 筛选点击位置占据的所有图层，包括商圈和标记点
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: this.judgeLayerIdArr
        });
        // 筛选标记点图层
        let symbols = features.filter(j => {
          return j.layer.type === "symbol";
        });
        if (symbols.length > 0) return;
        // 新增标记点落入商圈图层时，暂时禁用商圈信息展示弹窗
        if (!this.isTaInfoPopUp) return;
        this.createTaOpenInfo(
          e.features[0].geometry.coordinates[0],
          centerPoint[0],
          centerPoint[1],
          area
        );
      });
      // 查看报告暂时隐藏 ↓
      // if (this.$store.state.drawPolygonType == "report" && false) {
      //   if (!this.taReportPopUp) {
      //     let a = document.createElement("a");
      //     a.innerHTML = "查看报告";
      //     a.style.color = "blue";
      //     a.style.cursor = "pointer";
      //     a.addEventListener("click", () => {
      //       if (t.length > 8000) {
      //         this.$message({
      //           type: "warning",
      //           message: "范围太大，请缩小范围再进行操作"
      //         });
      //       } else {
      //         this.toTAReport(points[0], centerPoint, geoHashList);
      //       }
      //     });
      //     this.taReportPopUp = new mapboxgl.Popup({
      //       closeButton: false,
      //       closeOnClick: false
      //     })
      //       .setDOMContent(a)
      //       .setLngLat(centerPoint)
      //       .addTo(this.map);
      //   } else {
      //     this.taReportPopUp.setLngLat(centerPoint);
      //   }
      // }
      this.createTaOpenInfo(points[0], centerPoint[0], centerPoint[1], area);
    },
    /**
     * 新建一个可挪动的mark标记点，用于选取坐标，更新当前门店坐标
     */
    updateStoreLoaction(j) {
      this.map.getCanvas().style.cursor = "pointer";
      this.delStoreLocationMarker();
      let el = document.createElement("div");
      let el1 = document.createElement("p");
      let el2 = document.createElement("span");
      el.className = "jingpin";
      el.appendChild(el1);
      el1.appendChild(el2);
      this.newLocationMarker = new mapboxgl.Marker(el, {
        offset: [0, -18],
        draggable: true
      })
        .setLngLat([j.lng, j.lat])
        .addTo(this.map);
      // 标记点拖拽后更新位置信息
      this.$bus.$emit("storeNewLoaction");
      this.newLocationMarker.on("dragend", k => {
        let lnglat = k.target._lngLat;
        this.$bus.$emit("storeNewLoaction", lnglat);
      });
    },
    /**
     * 门店、竞品坐标信息变更标记点，清除
     */
    delStoreLocationMarker() {
      if (this.newLocationMarker) {
        this.newLocationMarker.remove();
        this.newLocationMarker = null;
      }
    },
    //查询纬度最大的点, 多边形定点
    getTopPoint(points) {
      let lng = 0.0,
        lat = 0.0;
      // for (let i = 0; i < points.length; i++) {
      //   let point = points[i];
      //   if (point[1] > lat) {
      //     lat = point[1];
      //     lng = point[0];
      //   }
      // }
      for (let point of points) {
        if (point[1] > lat) {
          lat = point[1];
          lng = point[0];
        }
      }
      return [lng, lat];
    },
    // 计算多边形的中心，给多边形添加一个删除按钮
    getCenterOfGravityPoint(points) {
      let area = 0.0; //多边形面积
      let Gx = 0.0,
        Gy = 0.0; // 重心的x、y
      for (let i = 1; i <= points.length; i++) {
        let iLat = points[i % points.length][1];
        let iLng = points[i % points.length][0];
        let nextLat = points[i - 1][1];
        let nextLng = points[i - 1][0];
        let temp = (iLat * nextLng - iLng * nextLat) / 2.0;
        area += temp;
        Gx += (temp * (iLat + nextLat)) / 3.0;
        Gy += (temp * (iLng + nextLng)) / 3.0;
      }
      Gx = Gx / area;
      Gy = Gy / area;
      return [Gy, Gx];
    },
    //数组二相对于数组一所新增的数据
    //a: newArray, b: oldArray
    add_msg(a, b) {
      if (b.length == 0) {
        return a
      }

      let list = [];
      a.map(i => {
        for (let t = 0; t < b.length; ) {
          if (b[t].hasOwnProperty("judgelabel")) {
            if (b[t].judgelabel == i.judgelabel) {
              break;
            } else if (b[t].judgelabel != i.judgelabel && t == b.length - 1) {
              list.push(i);
              break;
            }
          } else if (b[t].name == i.name && b[t].type == i.type) {
            break;
          } else if (
            // (b[t].name != i.name || b[t].type != i.type) &&
            t == b.length - 1
          ) {
            list.push(i);
            break;
          }
          t++;
        }
      });
      return list;
    },
    //数组二相对于数组一所删除的数据
    //a: newArray, b: oldArray
    delete_msg(a, b) {
      if (a.length == 0) {
        return b;
      }

      let list = [];
      b.map(i => {
        for (let t = 0; t < a.length; ) {
          if (a[t].hasOwnProperty("judgelabel")) {
            if (a[t].judgelabel == i.judgelabel) {
              break;
            } else if (a[t].judgelabel != i.judgelabel && t == a.length - 1) {
              list.push(i);
              break;
            }
          } else if (a[t].name == i.name && a[t].type == i.type) {
            break;
          } else if (
            // (a[t].name != i.name || a[t].type != i.type) &&
            t == a.length - 1
          ) {
            list.push(i);
            break;
          }
          t++;
        }
      });
      return list;
    },
    //渲染图例
    renderMarker(list, isDrag, deletedTa = "") {
      // console.log(list);
      // console.log(this.legendType);
      // let nw = this.map.getBounds().getNorthWest();
      // let se = this.map.getBounds().getSouthEast();
      // let latitude, minimumLatitude, longitude, minimumLongitude;
      // latitude = Math.max(nw.lat, se.lat);
      // minimumLatitude = Math.min(nw.lat, se.lat);
      // longitude = Math.max(nw.lng, se.lng);
      // minimumLongitude = Math.min(nw.lng, se.lng);
      let legendType = JSON.stringify(this.legendType);
      let del = this.delete_msg(list, JSON.parse(legendType));
      let add = this.add_msg(list, JSON.parse(legendType));
      // console.log("del", del);
      // console.log("add", add);
      // console.log("list", list);
      // console.log("legendType", this.legendType);
      // 移除图例筛选取消显示的标记点、商圈
      const deleteAction = ID => {
        if (this.map.getSource(ID)) {
          this.map.getSource(ID).setData({
            type: "FeatureCollection",
            features: []
          });
        }
      };

      // 删除Tag，机会点和门店使用，4种type
      const deleteTag = type => {
        $(".tag-label-" + type).remove();
      }
      if (del.length > 0) {
        let delList = del;
        for (let item of delList) {
          deleteTag(item.type);
          if (item.type == "2") {
            // 移除零售机会点
            deleteAction("geodataPPPoint" + item.logo);
          } else if (item.type == "21") {
            // 移除加盟机会点
            // console.log(item);
            deleteAction("geodataPPPoint" + item.logo);
          } else if (item.type == "3" || item.type == "31") {
            // 移除门店
            deleteAction("geodataStorePoint" + item.logo);
          } else if (item.type == "1") {
            // 移除竞品
            deleteAction(item.logo + "Source");
          } else if (item.type == "4") {
            // this.hasLegend--;
            // 调用标记 marker 清除函数 remove 清空地图上商圈显示名称
            if (item.name === "商圈") {
              this.layerList.forEach(m => {
                this.taNameMarkerLst[m.layCode].forEach(item => {
                  // item.marker.remove();
                  this.removeLayerSource([item.taLayerId, item.taLineLayerId]);
                });
              });
            } else {
              this.taNameMarkerLst[item.code].forEach(item => {
                // item.marker.remove();
                this.removeLayerSource([item.taLayerId, item.taLineLayerId]);
              });
            }
          }
          // else if (item.type == "5") {
          //   this.hasLegend--;
          //   this.map.removeLayer("baselayer");
          //   this.map.removeSource("hotMap");
          // }
          else if (item.type == "5") { // 删除原足迹点
            deleteAction("geodataFootprintPoint");
          }
          else if (item.type == "6") {
            this.map.removeLayer("room-extrusion");
            this.map.removeSource("mySource");
            this.map.setPitch(0);
          }
          // 省市区、蜂巢图、网格图、预测销售额
          else if ([100, 101, 102, 103, 104, 105, 106, 107, 108, 109].indexOf(item.type) > -1 && this.regionMarkerList[item.type]) {
            this.regionMarkerList[item.type].forEach(item => {
              // item.marker.remove();
              this.removeLayerSource([item.regionLayerId, item.regionLineLayerId]);
            });
          }
        }
      }
      // 商圈被删除，移除其多边形图层
      if (deletedTa) {
        this.removeLayerSource([
          "maine" + deletedTa.taId,
          "mainesline" + deletedTa.taId
        ]);
        // this.taNameMarkerLst[deletedTa.taTypeCode].forEach(item => {
        //   if (item.taLayerId == "maine" + deletedTa.taId) {
        //     item.marker.remove();
        //   }
        // });
        this.judgeLayerIdArr.splice(
          this.judgeLayerIdArr.indexOf("maine" + deletedTa.taId),
          1
        );
      }
      if (add.length > 0 || isDrag) {
        this.loadingMap = !isDrag;
        //拖动加载需要全部渲染图例，不是全部加载只加载新增的图例
        let dragTypeList = isDrag ? list : add;
        let ppSiteTypes = ""; // 零售机会点创建类型，多参数存储
        let ppLogos = []; // 零售机会点icon集合
        let jmppSiteTypes = ""; // 加盟机会点创建类型，多参数存储
        let jmppLogos = []; // 加盟机会点icon集合
        let storeBrands = ""; // 直营门店品牌，多参数存储
        let storeLogos = {}; // 直营门店icon集合
        let joinStoreBrands = ""; // 加盟门店品牌，多参数存储
        let joinStoreLogos = {}; // 加盟门店icon集合
        // console.log(dragTypeList);
        dragTypeList.forEach((m, listLndex) => {
          deleteTag(m.type);
          switch (Number(m.type)) {
            case 1: //竞品
              this.requestMarker(m.type, m.logo, m.code, m);
              break;
            case 2: //零售机会点
              if (!ppSiteTypes) {
                ppSiteTypes = m.code;
              } else {
                ppSiteTypes = ppSiteTypes + "," + m.code;
              }
              // ppLogos.push(m.logo);
              ppLogos[m.code] = m.logo;
              if (
                ppSiteTypes.split(",").length ==
                dragTypeList.filter(aa => aa.type == 2).length
              ) {
                this.requestMarker(2, ppLogos, ppSiteTypes);
              }
              break;
            case 21: //加盟机会点
              if (!jmppSiteTypes) {
                jmppSiteTypes = m.code;
              } else {
                jmppSiteTypes = jmppSiteTypes + "," + m.code;
              }
              jmppLogos[m.code] = m.logo;
              if (
                jmppSiteTypes.split(",").length ==
                dragTypeList.filter(aa => aa.type == 21).length
              ) {
                this.requestMarker(21, jmppLogos, jmppSiteTypes);
              }
              break;
            case 3: //直营门店
              if (!storeBrands) {
                storeBrands = m.code;
              } else {
                storeBrands = storeBrands + "," + m.code;
              }
              storeLogos[m.code] = m.logo;
              if (
                storeBrands.split(",").length ==
                dragTypeList.filter(aa => aa.type == 3).length
              ) {
                this.requestMarker(3, storeLogos, storeBrands);
              }
              break;
            case 31: //加盟门店
              if (!joinStoreBrands) {
                // joinStoreBrands = m.code;
                joinStoreBrands = m.code;
              } else {
                // joinStoreBrands = joinStoreBrands + ',' + m.code;
                joinStoreBrands = joinStoreBrands + "," + m.code;
              }
              // console.log(m);
              // joinStoreLogos[m.code] = m.logo;
              joinStoreLogos[m.code] = m.logo;
              if (
                joinStoreBrands.split(",").length ==
                dragTypeList.filter(aa => aa.type == 31).length
              ) {
                this.requestMarker(31, joinStoreLogos, joinStoreBrands);
              }
              break;
            // case 5: // 热力图
            //   this.hasLegend++;
            //   this.DrawHotMap();
            case 5: // 足迹
              this.requestMarker(m.type, 'footprint', m.code, m);
              break;
            case 4: //商圈
              this.hasLegend++;
              this.requestMarker(m.type, "mendian", m.code, m);
              break;
            case 6:
              this.DrawOrderMap();
              break;
            case 100:
            case 101:
            case 102:
            case 103: // 蜂巢图
            case 105: // 蜂巢图
            case 106: // 蜂巢图
            case 104: // 网格图
            case 107: // 网格图
            case 108: // 网格图
            case 109: // AI模型
              if (!isDrag || [103, 104, 105, 106, 107, 108, 109].indexOf(Number(m.type)) > -1) { // 拖动，缩放的，区域绘制时忽略
                this.renderRegion(m.type, isDrag);
              }
              break;
            default:
              break;
          }
        });
      }
      this.legendType = Array.from(list);

      // setTimeout(() => { // 测试
      //   this.DrawOrderMap();
      // }, 5000)
    },
    /**
     * 图层移除
     * @param layerId {Array} 图层ID集合
     */
    removeLayerSource(layerId) {
      layerId.forEach(e => {
        if (this.map.getLayer(e)) {
          this.map.removeLayer(e);
          this.map.removeSource(e);
        }
      });
    },
    /**
     * 绘制省市区
     *  @param type { Number } 区域分类：省100、市101、区102
     */
    renderRegion(type, isDrag) {
      console.log('renderRegion type:', type);
      var typeToName = {
        100: 'province',
        101: 'city',
        102: 'district'
      };


      let zoom = parseInt(this.map.getZoom());
      // 是蜂巢图、网格图
      const isHoneycombType = [103, 104, 105, 106, 107, 108, 109].indexOf(type) > -1;
      if (!isDrag) {
        // 地图放最小
        if (!isHoneycombType || (isHoneycombType && zoom < 8)) {
          this.zoom = (isHoneycombType ? zoom : 3);
          this.map.flyTo({
            zoom: this.zoom,
            speed: 10 //缩放速度
          });
        }
      }

      if (isHoneycombType) {
        if (zoom < 8) { // 地铁缩放的比较小的时候蜂巢图、网格图不显示
          this.loadingMap = false;
          if (this.regionMarkerList[type] && this.regionMarkerList[type].length > 0) {
            this.regionMarkerList[type].forEach(item => {
              this.removeLayerSource([item.regionLayerId, item.regionLineLayerId]);
            });
            this.regionMarkerList[type] = []
          }
          return;
        }
      }

      var buildRegion = (data, isHoneycomb, cityName) => {
        if (data.length == 0) {
          this.loadingMap = false;
        }

        // 创建前先删除
        this.regionMarkerList[type * 1] && this.regionMarkerList[type * 1].forEach(item => {
          this.removeLayerSource([item.regionLayerId, item.regionLineLayerId]);
        });

        console.log('getResion data:', data);
        this.regionMarkerList[type * 1] = [];

        var feas = [];
        var linefeas = [];
        data.forEach(regionData => {
          // 商圈名称(标志)，先清除清空，再重新写入地图主图层中
          // this.taNameMarkerLst[regionData[0].code].forEach(item => {
          //   item.marker.remove();
          // });
          // this.taNameMarkerLst[regionData[0].code] = [];
          /**
           * 显示名称(名称标记 marker 与各商圈类型绑定)
           *     1. 根据接口返回的商圈点位信息，计算商圈中心点
           *     2. 创建 span 标签并赋值商圈名称
           *     3. 在商圈中心点创建 marker 标记，塞入 span 标签
           *     4. 将 marker 标记存储在标记列表 taNameMarkerLst 对应的商圈类型中
           */
          // const centerPoint = this.getCenterOfGravityPoint(
          //   JSON.parse(regionData.location)
          // );
          // let elName = document.createElement("span");
          // elName.innerHTML = `competitorNum:${regionData.competitorNum}|storeNum:${regionData.storeNum}|haveFootPrint:${regionData.haveFootPrint}`;
          // elName.className = "text-shadow-w";
          // let marker = new mapboxgl.Marker(elName, {
          //   offset: [0, 0]
          // })
          //   .setLngLat(centerPoint)
          //   .addTo(this.map);

          var opacityColor = 'rgba(255, 255, 255, 0.2)';
          // if (regionData.competitorNum >= 5){
          //   // opacityColor = 'rgba(0, 0, 255, 0.5)';
          //   opacityColor = 'rgba(174, 2, 209, 0.5)';
          // } else if (regionData.competitorNum >= 3) {
          //   // opacityColor = 'rgba(255, 255, 0, 0.5)';
          // } else
          if (regionData.competitorNum >= 1) {
            opacityColor = 'rgba(255, 0, 0, 0.5)';
          } else if (regionData.haveFootPrint) {
            opacityColor = 'rgba(174, 2, 209, 0.5)';
          }

          var opacityColorLine = opacityColor
          if (regionData.storeNum >= 1) {
            opacityColor = 'rgba(255, 255, 255, 0)';
            opacityColorLine = 'rgba(255, 255, 255, 0.6)';
          }

          if (109 === type) { // 预测销售额指定颜色
            opacityColor = this.$hex2rgba(regionData.ta4Type, .5);
            opacityColorLine = this.$hex2rgba(regionData.ta4Type, .8);
          }

          feas.push(
            {
              type: "Feature",
              properties: {
                name: regionData.name,
                geometry: regionData.location,
                judgePopup: "地区",
                cityName,
                code: regionData.code,
                color: (isHoneycomb ? opacityColor : '')
              },
              geometry: {
                type: (regionData.type || "Polygon"),
                coordinates: isHoneycomb ? [JSON.parse(regionData.location)] : JSON.parse(regionData.location)
              }
            }
          );


          var theLocation = JSON.parse(regionData.location).slice(0);
          theLocation.push(theLocation[0]); // 线框闭合
          // console.log('theLocation: ', theLocation);
          // !isHoneycomb && linefeas.push(
          linefeas.push(
            {
              type: "Feature",
              properties: {
                code: regionData.code,
                name: regionData.name,
                geometry: JSON.stringify(theLocation),
                color: (isHoneycomb ? opacityColorLine : '')
              },
              geometry: {
                type: "LineString",
                coordinates: theLocation
              }
            }
          );

          // if (109 === type) {
          //   return; // 暂不放文字
          //   const ele = document.createElement("div");
          //   ele.setAttribute("class", "measure-result honeycomb-tag tag-label-" + type + ' tag-label-' + type + '-' + regionData.id);
          //   const option = {
          //     element: ele,
          //     anchor: "center",
          //     // offset: [0, 0]
          //   };
          //   ele.innerHTML = `${regionData.remark || '-'}`;
          //   const centerPoint = this.getCenterOfGravityPoint(theLocation);
          //   new mapboxgl.Marker(option)
          //     .setLngLat(centerPoint)
          //     .addTo(this.map);
          // }
        });


          this.regionMarkerList[type * 1].push({
            // marker,
            regionLayerId: "region" + type,
            regionLineLayerId: "regionline" + type
          });

          /**
           * 地区多边形
           *     1. 区域的多边形图形 id 增加 regionData.code 用于区分每个区域
           */
          if (!this.map.getLayer("region" + type)) {
            this.map.addLayer(
              {
                id: "region" + type,
                type: "fill" /* fill类型layer */,
                source: {
                  type: "geojson",
                  data: {
                    type: "FeatureCollection",
                    features: feas
                  }
                },
                layout: {},
                paint: {
                  /* 由接口返回数据指定(接口未修改，背景色无法根据商圈类型区分) */
                  // "fill-color": ["get", "fillColor"],
                  /* 由 session 存储数据指定 */
                  // "fill-color": mainesStyle.fillColor,
                  "fill-color": (isHoneycomb
                    ? ['get', 'color']
                    : "rgba(255, 0, 0, 0.4)")
                }
              },
              "backgroundLayer0"
            );

            // 蜂巢图点击交互事件
            const regionClick = e => {
              const features = this.map.queryRenderedFeatures(e.point, {
                layers: ["region" + type]
              })
              if (features.length > 0) {
                // 当前六边形区域数据
                const clickedFeature = features[0].properties
                const isHoneycombType103 = [103, 105, 106, 109].indexOf(type) > -1 // 蜂巢图
                const hashData = isHoneycombType103 ? this.honeycombHash[['data', type, clickedFeature.cityName].join('_')] : this.gridHash[['data', type, clickedFeature.cityName].join('_')]
                const selAreaInfo = hashData.find(i => i.code === clickedFeature.code)
                if (selAreaInfo) {
                  this.honeycombForm.competitorNum = selAreaInfo.competitorNum
                  this.honeycombForm.storeNum = selAreaInfo.storeNum
                  this.honeycombForm.haveFootPrint = selAreaInfo.haveFootPrint
                  this.getBigData(selAreaInfo.code, 'honeycomb')
                }
                console.log('Clicked feature =>', clickedFeature)
              } else {
                console.log("No feature clicked")
              }

              // this.honeycombVisible = true
            }

            // 绑定前先解绑，避免地图重复挂载点击事件
            // this.map.off('click', "region" + type, regionClick)
            if (!this.regionEventBind["region" + type] && isHoneycomb) {
              this.map.on('click', "region" + type, regionClick)
              this.regionEventBind["region" + type] = true;
            }
          }

          /**
           * 地区边线
           *     1. 商圈的多边形图形边线 id 增加 data[0].id 用于区分每个商圈
           */
          if (!this.map.getLayer("regionline" + type)) {
            this.map.addLayer(
              {
                id: "regionline" + type,
                type: "line",
                source: {
                  type: "geojson",
                  data: {
                    type: "FeatureCollection",
                    features: linefeas
                  }
                },
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  // "line-color": "rgba(255, 0, 0, 0.6)",
                  "line-color": (isHoneycomb
                    ? ['get', 'color']
                    : "rgba(255, 0, 0, 0.6)"),
                  "line-dasharray": [
                    0.2,
                    0
                  ],
                  "line-width": 1
                }
              },
              // "backgroundLayer"
            );
            /* 商圈边线图层插入背景层之前，确保各标记点 Z 轴位置高于商圈 */
          }
      }

      setTimeout(() => {
        if (isHoneycombType) { // 蜂巢图、网格图
          this.getMapCityName(() => {
            const isHoneycombType103 = [103, 105, 106, 109].indexOf(type) > -1 // 蜂巢图

            var dataApi = isHoneycombType103 ? api.getHoneycomb : api.getGrid
            var queryParams = {
              cityName: this.mapCityName,
              showFlag: ([105, 107].indexOf(type) > -1 ? 1 : 0)
            }

            // AI模型
            if (109 === type) {
              dataApi = api.getHoneyCombPredict
              let nw = this.map.getBounds().getNorthWest();
              let se = this.map.getBounds().getSouthEast();
              let ne = this.map.getBounds().getNorthEast();
              let sw = this.map.getBounds().getSouthWest();
              const geometry = `[[${nw.lng},${nw.lat}],[${ne.lng},${ne.lat}],[${se.lng},${se.lat}],[${sw.lng},${sw.lat}]]`;
              queryParams = {
                cityName: this.mapCityName,
                geometry
              }
            }
            // 接口 蜂巢图
            dataApi(queryParams).then(res => {
              console.log('honeycomb res =>', res)
              if (res.code == 0 || res.code == 200) {
                let data;
                data = res.data.map(item => {
                  // return {...item, location: JSON.stringify([JSON.parse(item.location)])}
                  return {
                    code: item.id,
                    name: item.cnName,
                    competitorNum: item.competitorNum,
                    storeNum: item.storeNum,
                    haveFootPrint: item.haveFootPrint,
                    remark: item.remark, // 109 销售额
                    ta4Type: item.ta4Type, // 109 颜色
                    type: 'Polygon',
                    location: JSON.stringify(JSON.parse(item.location))
                  }
                });
                buildRegion(data, true, this.mapCityName)

                if (isHoneycombType103) {
                  this.honeycombHash[['data', type, this.mapCityName].join('_')] = data;
                } else {
                  this.gridHash[['data', type, this.mapCityName].join('_')] = data;
                }
              }
              this.loadingMap = false;
            })
            .catch(err => {
              console.log(err);
              this.loadingMap = false;
            });
          });
          return;
        }

        // 测试 省份区域图
        if (100 === type) {
          // console.log('provinceJson: ', provinceJson);
          this.loadingMap = false;
          var newProvinceJson = provinceJson2.map(p => {
            return {
              code: p.id,
              name: p.properties.name,
              storeNum: "28",
              type: p.geometry.type,
              location: JSON.stringify(p.geometry.coordinates)
            }
          })
          return buildRegion(newProvinceJson)
          // return buildRegion(provinceJson)
        }
  
        api
          .getRegion({
            type: typeToName[type * 1]
          })
          .then(res => {
            if (res.code == 0 || res.code == 200) {
              let data;
              data = res.data;
              buildRegion(data)
            }
            this.loadingMap = false;
          })
          .catch(err => {
            console.log(err);
            this.loadingMap = false;
          });
      }, 600)
    },
    /**
     * 根据地图获取城市名字
     *  @param cb { Function } 获取城市名成功后回调
     */
    getMapCityName(cb, centerPosition) {
      let ct = this.map.getBounds().getCenter(); // 中心点

      if (centerPosition) { // 飞过来的，取将去往的坐标值
        ct = centerPosition
      }
      this.$http
      .get(constants.iMap.REGEOURL, {
        params: {
          location: `${ct.lng},${ct.lat}`,
          key: constants.iMap.WEBKEY,
        },
      })
      .then((res) => {
        if (res.body && res.body.info === "OK") {
          const city = res.body.regeocode.addressComponent.city;
          const province = res.body.regeocode.addressComponent.province;

          this.mapCityName = typeof city === 'string' ? city : province;

          cb?.();
        }
      });
    },
    /**
     * 标记点、商圈坐标信息查询
     *  @param type { Number } 一级节点分类：机会点、门店、商圈、竞品
     *  @param brandId { String } 二级节点分类
     *  @param name { String } 标记点、商圈在地图上渲染的名称
     *  @param requestItem { Object } 节点数据
     *
     */
    requestMarker(type, name, brandId, requestItem) { 
      let nw = this.map.getBounds().getNorthWest();
      let se = this.map.getBounds().getSouthEast();
      let ne = this.map.getBounds().getNorthEast();
      let sw = this.map.getBounds().getSouthWest();
      let latitude, minimumLatitude, longitude, minimumLongitude;
      latitude = Math.max(nw.lat, se.lat);
      minimumLatitude = Math.min(nw.lat, se.lat);
      longitude = Math.max(nw.lng, se.lng);
      minimumLongitude = Math.min(nw.lng, se.lng);
      let options = {
        latitude,
        longitude,
        minimumLatitude,
        minimumLongitude,
        type,
        cusNo: sessionStorage.cusNo,
        geometry: `[[${nw.lng},${nw.lat}],[${ne.lng},${ne.lat}],[${se.lng},${se.lat}],[${sw.lng},${sw.lat}]]`,
        city: sessionStorage.currentCity,
        // positionID: this.$store.state.PMStoreIndex.positionID
        positionID: sessionStorage.renderPositionId
      };
      if (type == "1") {
        options.brandId = brandId;
      }
      // type值
      // 正常-6 终止-7 在营-8 闭店-9
      if (type == "2" || type == "21") {
        options.brandId = brandId;
        options.type = type == "2" ? 6 : 7
        // options.filter = "";
      }
      if (type == "3" || type == "31") {
        options.brandId = brandId;
        options.type = type == "3" ? 8 : 9
        // options.storeState = requestItem.storeState || "";
        // options.storeState = "";
      }
      // if (type == "7") {
      //   options.brandId = brandId;
      //   // options.storeState = requestItem.storeState || "";
      //   options.storeState = "";
      // }

      // if () {
      //   options.siteType = brandId.replaceAll("jmp", "");
      //   options.filter = "";
      // }
      if (type == "4") {
        options.filter = brandId;
      }
      // console.log(options);
      api
        .renderMarker(options)
        .then(res => {
          if (res.code == 0 || res.code == 200) {
            /**
             * 拖拽、缩放后请求接口数据更新标记点、商圈图层，但异步接口有延时，在延时期间操作图例筛选
             * 选项后会造成已取消显示的图层数据又复现的问题
             * 获取数据后，检查当前数据是否属于当前图例筛选中已选取的类型，不属于测过滤
             */
            let judgeSelectedMarker = new Array(109).fill(0).map(() => []);
            this.selectedMarker.forEach(e => {
              if (judgeSelectedMarker[e.type - 1]) {
                judgeSelectedMarker[e.type - 1].push(e);
              }
            });
            let data;
            data = res.data;
            if (data.length == 0) {
              this.loadingMap = false;
            }
            if (type == "1" && this.canRenderLayer) {
              let feas = [];
              data.map(m => {
                let res = judgeSelectedMarker[0].some(e => {
                  return e.code == m.brand;
                });
                if (res) {
                  let icon = name || "vs";
                  if (!!m.business && !!m.brandCode) {
                    icon = m.business + m.brandCode + "";
                    // this.mapLoadImage(icon, constants.baseURL.fileURL + m.logo); // 竞品用本地icon
                  }
                  feas.push({
                    type: "Feature",
                    properties: {
                      id: m.id,
                      name: m.name,
                      avg: 10,
                      // icon: name ? name : "vs", // 竞品根据品牌显示不同图标
                      icon, // 竞品根据品牌显示不同图标
                      type: m.sitetype,
                      judgePopup: "竞品",
                      datas: m
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [m.longitude, m.latitude]
                    }
                  });
                }
              });
              this.drawVSPoint(feas, name);
            } else if ((type == "2" || type == "21") && this.canRenderLayer) { // 机会点 2：直营  21：加盟
              let ppSiteTypes = brandId ? brandId.split(",") : [];
              ppSiteTypes.forEach(e => {
                let pps = data.filter(k => k.siteFilterType == e);
                let feass = [];
                pps.map(m => {
                  let res = judgeSelectedMarker[type * 1 - 1].some(es => {
                    // return es.code == m.siteFilterType;
                    return true;
                  });
                  if (res) {
                    // 机会点根据进度显示不同图标 ?
                    // let icon = m.siteFilterType ? m.siteFilterType : "pp"
                    let icon = m.siteFilterType ? m.siteFilterType : "105011"

                    if (m.siteStatus === '131010') { // 终止状态
                      icon = icon + '-cancel';
                    } else {
                      // 机会点icon，根据是否超A显示不同
                      icon = icon + (1 === m.level * 1 ? '-a' : '-normal');
                    }
                    let pit = {
                      type: "Feature",
                      properties: {
                        id: m.id,
                        name: m.name,
                        avg: 10,
                        icon: icon,
                        type: m.sitetype,
                        judgePopup: "机会点",
                        datas: m
                      },
                      geometry: {
                        type: "Point",
                        coordinates: [m.longitude, m.latitude]
                      }
                    };
                    feass.push(pit);
                  }
                });
                this.drawPPPoint(feass, name[e], 0.5, type);
                // this.drawPPPoint(feass, 'pp');
              });
            }
            // else if (type == "21" && this.canRenderLayer && false) { // 没用？
            //   let ppSiteTypes = brandId.replaceAll("jmp", "").split(",");
            //   ppSiteTypes.forEach(e => {
            //     let pps = data.filter(k => k.siteFilterType == e);
            //     let feas = [];
            //     // console.log(pps)
            //     // console.log(judgeSelectedMarker[7])
            //     pps.map(m => {
            //       let res = judgeSelectedMarker[20].some(e => {
            //         return e.code == "jmp" + m.siteFilterType;
            //       });
            //       if (res) {
            //         feas.push({
            //           type: "Feature",
            //           properties: {
            //             id: m.id,
            //             name: m.name,
            //             avg: 10,
            //             icon: m.siteFilterType
            //               ? "jmp" + m.siteFilterType
            //               : "pp", // 机会点根据进度显示不同图标
            //             type: m.sitetype,
            //             judgePopup: "机会点",
            //             datas: m
            //           },
            //           geometry: {
            //             type: "Point",
            //             coordinates: [m.longitude, m.latitude]
            //           }
            //         });
            //       }
            //     });
            //     console.log("jmp---", e);
            //     this.drawPPPoint(feas, "jmp" + e);
            //   });
            // }
            else if (type == "5" && this.canRenderLayer) { // 足迹footprint
              // 只显示没有footId数据的点  非机会点和门店下的
              let footprintDatas = data.filter(k => (k.siteFilterType || "footprint") == "footprint" && !k.footId && k.createType === 'ordinary');
              let footprints = [];
              footprintDatas.map(m => {
                let res = judgeSelectedMarker[type * 1 - 1].some(es => {
                  // return es.code == m.siteFilterType;
                  return true;
                });
                if (res) {
                  let pit = {
                    type: "Feature",
                    properties: {
                      id: m.id,
                      name: m.id, // 没有name
                      avg: 10,
                      icon: "dizhi",
                      type: m.sitetype,
                      judgePopup: "足迹",
                      datas: m
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [m.longitude, m.latitude]
                    }
                  };
                  footprints.push(pit);
                }
              });

              this.drawFootprint(footprints);
            } else if ((type == "3" || type == "31") && this.canRenderLayer) {
              let storeBrands = brandId.split(",");
              storeBrands.forEach(e => {
                let stores = data.filter(k => k.brandCode == e);
                let feas = [];
                stores.map(m => {
                  let res = judgeSelectedMarker[type * 1 - 1].some(e => {
                    return e.code == m.brandCode;
                  });

                  // 机会点根据进度显示不同图标 ?
                  let icon = m.brandCode ? m.brandCode : "store"
                  icon = icon + '-old';
                  if ('100003' === m.status) {
                    icon = '105011-cancel'; // 借用机会点终止图标
                  }

                  // 既有店状态为已关店时，图标变灰
                  if (['128004', '128005'].indexOf(m.status) > -1) {
                    var imgFishOldCancel = require('../../assets/images/marker/fish-old-cancel.png');
                    this.mapLoadImage(
                      '128005-old-cancel',
                      imgFishOldCancel
                    );
                    icon = '128005-old-cancel'
                  }

                  if (res) {
                    feas.push({
                      type: "Feature",
                      properties: {
                        id: m.id,
                        name: m.name,
                        avg: 10,
                        // icon: m.brandCode ? m.brandCode : "store", // 门店根据品牌显示不同图标
                        icon: icon, // 门店根据品牌显示不同图标
                        type: m.storetype,
                        judgePopup: "门店",
                        phone: m.phone,
                        datas: m
                      },
                      geometry: {
                        type: "Point",
                        coordinates: [m.longitude, m.latitude]
                      }
                    });
                  }
                });
                this.drawStorePoint(feas, name[e], 0.5, type);
              });
            }
            // else if (type == 31 && this.canRenderLayer && false) { // 没用？
            //   let storeJoinBrands = [];
            //   storeJoinBrands = brandId.split(",");
            //   storeJoinBrands.forEach(e => {
            //     let stores = data.filter(k => k.brandCode == e);
            //     let feas = [];
            //     stores.map(m => {
            //       let res = judgeSelectedMarker[30].some(e => {
            //         // return e.code == m.brandCode;
            //         return e.code == m.brandCode;
            //       });
            //       if (res) {
            //         feas.push({
            //           type: "Feature",
            //           properties: {
            //             id: m.id,
            //             name: m.name,
            //             avg: 10,
            //             icon: m.brandCode ? "jmd" + m.brandCode : "store", // 门店根据品牌显示不同图标
            //             type: m.storetype,
            //             judgePopup: "门店",
            //             phone: m.phone,
            //             datas: m
            //           },
            //           geometry: {
            //             type: "Point",
            //             coordinates: [m.longitude, m.latitude]
            //           }
            //         });
            //       }
            //     });
            //     this.drawStorePoint(feas, name[e], 0.5);
            //   });
            // } 
            else if (type == "4" && this.canRenderLayer) { // 商圈
              let lst = [];
              let temp = {};
              // 一维数组归类二维
              data.forEach((e, i) => {
                if (temp.hasOwnProperty(e.code)) {
                  lst[temp[e.code]].push(e);
                } else {
                  temp[e.code] = lst.length;
                  lst[temp[e.code]] = [e];
                }
              });
              lst.forEach(typeTa => {
                // 商圈名称(标志)，先清除清空，再重新写入地图主图层中
                this.taNameMarkerLst[typeTa[0].code].forEach(item => {
                  // item.marker.remove();
                  this.removeLayerSource([item.taLayerId, item.taLineLayerId]);
                });
                this.taNameMarkerLst[typeTa[0].code] = [];
                // let feas = [];
                // let linefeas = [];
                typeTa.map(m => {
                  let res = judgeSelectedMarker[3].some(j => {
                    return j.code == m.code;
                  });
                  if (res) {
                    /**
                     * 显示名称(名称标记 marker 与各商圈类型绑定)
                     *     1. 根据接口返回的商圈点位信息，计算商圈中心点
                     *     2. 创建 span 标签并赋值商圈名称
                     *     3. 在商圈中心点创建 marker 标记，塞入 span 标签
                     *     4. 将 marker 标记存储在标记列表 taNameMarkerLst 对应的商圈类型中
                     */

                    // 商圈中的名称不显示了
                    // const centerPoint = this.getCenterOfGravityPoint(
                    //   JSON.parse(m.geometry)
                    // );
                    // let elName = document.createElement("span");
                    // elName.innerHTML = m.name;
                    // elName.className = "text-shadow-w";
                    // let marker = new mapboxgl.Marker(elName, {
                    //   offset: [0, 0]
                    // })
                    //   .setLngLat(centerPoint)
                    //   .addTo(this.map);
                    this.taNameMarkerLst[m.code].push({
                      // marker,
                      taLayerId: "maine" + m.id,
                      taLineLayerId: "mainesline" + m.id
                    });

                    let feas = [
                      {
                        type: "Feature",
                        properties: {
                          taid: m.id,
                          name: m.name,
                          geometry: m.geometry,
                          judgePopup: "商圈",
                          code: m.code
                        },
                        geometry: {
                          type: "Polygon",
                          coordinates: [JSON.parse(m.geometry)]
                        }
                      }
                    ];
                    let linefeas = [
                      {
                        type: "Feature",
                        properties: {
                          taid: m.id,
                          name: m.cnName,
                          geometry: m.geometry
                        },
                        geometry: {
                          type: "LineString",
                          coordinates: JSON.parse(m.geometry)
                        }
                      }
                    ];

                    /**
                     * 画商圈多边形
                     *     1. 商圈的多边形图形 id 增加 m.id 用于区分每个商圈
                     */
                    if (!this.map.getLayer("maine" + m.id)) {
                      // let mainesStyle = {};
                      // this.layerList.map(item => {
                      //   if (item.layCode == m.code) {
                      //     mainesStyle = item;
                      //   }
                      // });

                      let fillColor = m.fillColor || '#4e78e7';
                      if(m.code === '135001'){
                        fillColor = '#ff9500'
                      }

                      // 透明
                      fillColor = 'rgba(255, 255, 255, 0)';
                      this.map.addLayer(
                        {
                          id: "maine" + m.id,
                          type: "fill" /* fill类型layer */,
                          source: {
                            type: "geojson",
                            data: {
                              type: "FeatureCollection",
                              features: feas
                            }
                          },
                          layout: {},
                          paint: {
                            /* 由接口返回数据指定(接口未修改，背景色无法根据商圈类型区分) */
                            // "fill-color": ["get", "fillColor"],
                            /* 由 session 存储数据指定 */
                            // "fill-color": mainesStyle.fillColor,
                            "fill-color": fillColor
                          }
                        },
                        "backgroundLayer"
                      ); /* 商圈图层插入背景层之前，确保各标记点 Z 轴位置高于商圈 */
                      this.map.on("click", "maine" + m.id, e => {
                        // 当前有商圈在选址规划时，其余商圈禁止点击打开信息弹窗
                        if (!this.canRenderLayer) return;
                        /**
                         * 当前点击点：多商圈层叠，展示商圈选取弹窗；
                         * 当前点击点：多标记点与商圈混合层叠，禁止展示商圈信息，并展示标记点选取弹窗
                         */
                        // 解构点击点坐标
                        let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
                        // 筛选点击位置占据的所有图层，包括商圈和标记点
                        // console.log(this.judgeLayerIdArr);
                        let features = this.map.queryRenderedFeatures(e.point, {
                          layers: this.judgeLayerIdArr
                        });
                        // 筛选标记点图层
                        let symbols = features.filter(j => {
                          return j.layer.type === "symbol";
                        });
                        // 筛选商圈图层
                        // console.log(features);
                        let fills = features.filter(k => {
                          return k.layer.type === "fill";
                        });
                        this.$store.state.overLapTaId = fills.map(e => {
                          return {
                            taId: e.properties.taid,
                            code: e.properties.code
                          };
                        }); // 当前点击处所有重叠商圈id值存储
                        if (symbols.length > 0) {
                          return;
                        } else if (fills.length > 1) {
                          if (
                            this.pointTaMarker[0] != clickLng &&
                            this.pointTaMarker[1] != clickLat
                          ) {
                            this.showTaMarkerPopup(
                              e,
                              fills,
                              clickLng,
                              clickLat
                            );
                          }
                          return;
                        }
                        // 新增标记点落入商圈图层时，暂时禁用商圈信息展示弹窗
                        if (!this.isTaInfoPopUp) return;
                        // 存储商圈图层id ，用于商圈重叠时选取的甄别
                        if (!this.judgeLayerIdArr.includes("maine" + m.id)) {
                          this.judgeLayerIdArr.push("maine" + m.id);
                        }
                        this.taVisible = true;
                        this.vsVisible = false;
                        this.info = {
                          lngLat: e.lngLat,
                          taid: e.features[0].properties.taid,
                          name: e.features[0].properties.name,
                          location: JSON.parse(
                            e.features[0].properties.geometry
                          )
                        };
                      });
                    }

                    /**
                     * 画商圈边线
                     *     1. 商圈的多边形图形边线 id 增加 m.id 用于区分每个商圈
                     */
                    if (!this.map.getLayer("mainesline" + m.id)) {
                      // let mainesStyle = {};
                      // this.layerList.map(item => {
                      //   if (item.layCode == m.code) {
                      //     mainesStyle = item;
                      //   }
                      // });
                      this.map.addLayer(
                        {
                          id: "mainesline" + m.id,
                          type: "line",
                          source: {
                            type: "geojson",
                            data: {
                              type: "FeatureCollection",
                              features: linefeas
                            }
                          },
                          layout: {
                            "line-cap": "round",
                            "line-join": "round"
                          },
                          paint: {
                            // "line-color": mainesStyle.outlineColor || "#0a38f1",
                            "line-color": m.lineColor || "#0a38f1",
                            "line-dasharray": [
                              0.2,
                              // mainesStyle.outlineType == "line" ? 0 : 2,
                              0
                            ],
                            // "line-width": Number(mainesStyle.outlineWidth || 4)
                            "line-width": Number(2)
                          }
                        },
                        "backgroundLayer"
                      );
                      /* 商圈边线图层插入背景层之前，确保各标记点 Z 轴位置高于商圈 */
                    }
                  }
                });
              });
            }
          }
          this.loadingMap = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingMap = false;
        });
    },
    /**
     * 商圈、标记点图层层叠时，点击展示选取列表
     */
    showTaMarkerPopup(e, layers, clickLng, clickLat) {
      // console.log(layers);
      layers.sort((a, b) => {
        return a.properties.judgePopup == "门店" ? -1 : 1;
      });
      // 地图popup弹窗初始化
      if (this.selTaMarker) {
        this.selTaMarker.remove();
        this.selTaMarker = null;
      }
      // 记忆点击
      this.pointTaMarker = [e.lngLat.lng, e.lngLat.lat];
      // 创建popup所需节点DOM，li 增加key 属性，用于选取节点数据
      let div = document.createElement("div");
      div.innerHTML = `
        <h3>请选择</h3>
        <ul>
          ${(function() {
            let lis = "";
            for (let i in layers) {
              lis += `<li key="${i}">
                  <i class="el-icon-connection"></i>
                  ${layers[i].properties.judgePopup +
                    "：" +
                    layers[i].properties.name || layers[i].properties.landmark}
                </li>`;
            }
            return lis;
          })()}
        </ul>`;
      div.className = "ta-marker-popup";
      // 冒泡监听 li click事件
      div.addEventListener("click", event => {
        let index = event.target.attributes[0].value;
        let judgeType = layers[index].properties.judgePopup;
        if (judgeType === "机会点") {
          this.vsVisible = "pp";
          this.taVisible = false;
          this.info = {
            id: layers[index].properties.id,
            coordinates: layers[index].geometry.coordinates,
            datas: layers[index].properties.datas
          };
        } else if (judgeType === "足迹") {
          this.footprintInfo = {
            id: layers[index].properties.id,
            coordinates: layers[index].geometry.coordinates,
            phone: layers[index].properties.phone,
            datas: layers[index].properties.datas
          };
          this.footprintEdit = false;
          this.footprintVisible = true;
          this.taVisible = false;
          this.vsVisible = '';
        } else if (judgeType === "门店") {
          this.vsVisible = "store";
          this.taVisible = false;
          this.info = {
            id: layers[index].properties.id,
            coordinates: layers[index].geometry.coordinates,
            phone: layers[index].properties.phone,
            datas: layers[index].properties.datas
          };
        } else if (judgeType === "商圈") {
          this.taVisible = true;
          this.vsVisible = false;
          this.info = {
            lngLat: e.lngLat,
            taid: layers[index].properties.taid,
            name: layers[index].properties.name,
            location: JSON.parse(layers[index].properties.geometry)
          };
        } else if (judgeType === "竞品") {
          this.vsVisible = "vs";
          this.taVisible = false;
          this.info = {
            id: layers[index].properties.id,
            coordinates: layers[index].geometry.coordinates,
            datas: layers[index].properties.datas
          };
        } else if (judgeType === "poi地址") {
          // 解构点击点坐标
          let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
          this.selTaMarker.remove();
          this.selTaMarker = null;
          this.showPoiMarker(layers[index].properties, clickLng, clickLat);
        }
      });
      this.selTaMarker = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: false
      }).setDOMContent(div);
      this.selTaMarker.setLngLat([clickLng, clickLat]).addTo(this.map);
    },
    //绘制机会点
    drawPPPoint(feas, name, iconSize = 0.5, tagType) {
      let geojsonPoints = {
        type: "FeatureCollection",
        features: []
      };
      geojsonPoints.features = feas;
      if (this.map.getLayer("pplayer" + name)) {
        this.map.getSource("geodataPPPoint" + name).setData(geojsonPoints);
      } else {
        this.map.addSource("geodataPPPoint" + name, {
          type: "geojson",
          data: geojsonPoints,
          cluster: true, //允许聚合
          clusterMaxZoom: 9, // 缩放等级小于8时取开始聚合
          clusterRadius: 100
        });
        this.map.addLayer({
          id: "ppClusters" + name,
          type: "circle",
          source: "geodataPPPoint" + name,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#f28cb1", // 少于100个显示粉色半径20px的圆形
              100, // 分界点：100个点位
              "#ff7e30",
              300,
              "#1a6072"
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20, // 20px
              100, // 分界点： 100个点位
              50,
              300,
              70
            ]
          }
        });
        this.map.addLayer({
          id: "ppCluster-count" + name,
          type: "symbol",
          source: "geodataPPPoint" + name,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
        this.map.addLayer({
          id: "pplayer" + name,
          type: "symbol",
          source: "geodataPPPoint" + name,
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": iconSize,
            "icon-offset": [0, -20],
            "icon-allow-overlap": true
          }
        });
      }

      // 如果有选tag，显示相关信息（创建时一直有，仅通过顶层容器样式做开关）
      // if (this.showTag) {
        feas.forEach(point => {
          const ele = document.createElement("div");
          ele.setAttribute("class", "measure-result jihuidian-tag tag-label-" + tagType + ' tag-label-' + tagType + '-' + point.properties.id);
          const option = {
            element: ele,
            anchor: "top",
            offset: [0, -50]
          };
          ele.innerHTML = `${point.properties.datas.authNumber || '-'}/${point.properties.datas.statusName || '-'}`;
          new mapboxgl.Marker(option)
            .setLngLat(point.geometry.coordinates)
            .addTo(this.map);
        })
      // }

      this.map.on("click", "pplayer" + name, e => {
        /**
         * 当前点击点：多商圈层叠，展示商圈选取弹窗；
         * 当前点击点：多标记点与商圈混合层叠，禁止展示商圈信息，并展示标记点选取弹窗
         */
        // 解构点击点坐标
        let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
        // 筛选点击位置占据的所有图层，包括商圈和标记点
        let filtJudgeLayerId = [...this.judgeLayerIdArr];
        filtJudgeLayerId.splice(
          filtJudgeLayerId.indexOf("pplayerundefined"),
          1
        );
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: this.filtJudgeLayerId
        });
        // 筛选标记点图层
        let symbols = features.filter(j => {
          // console.log(j);
          return j.layer.type === "symbol" && j.layer.id != "dizhi";
        });
        if (symbols.length > 1) {
          this.showTaMarkerPopup(e, symbols, clickLng, clickLat);
          return;
        }

        // this.ppVisible = true;
        this.vsVisible = "pp";
        this.taVisible = false;
        this.info = {
          id: e.features[0].properties.id,
          coordinates: e.features[0].geometry.coordinates,
          datas: e.features[0].properties.datas
        };
      });
      if (!this.judgeLayerIdArr.includes("pplayer" + name)) {
        this.judgeLayerIdArr.push("pplayer" + name);
      }
    },
    //绘制门店点
    drawStorePoint(feas, name = "storelayer", iconSize = 0.5, tagType) {
      let geojsonPoints = {
        type: "FeatureCollection",
        features: []
      };
      geojsonPoints.features = feas;
      // console.log(feas);
      // console.log(this.map.getSource("geodataStorePoint" + name));
      if (this.map.getLayer(name)) {
        this.map.getSource("geodataStorePoint" + name).setData(geojsonPoints);
      } else {
        this.map.addSource("geodataStorePoint" + name, {
          type: "geojson",
          data: geojsonPoints,
          cluster: true,
          clusterMaxZoom: 9,
          clusterRadius: 100
        });
        this.map.addLayer({
          id: "storeClusters" + name,
          type: "circle",
          source: "geodataStorePoint" + name,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#6b73d7",
              100,
              "#d59c00",
              750,
              "#c30046"
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              50,
              750,
              70
            ]
          }
        });
        this.map.addLayer({
          id: "storeCluster-count" + name,
          type: "symbol",
          source: "geodataStorePoint" + name,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
        this.map.addLayer({
          id: name,
          type: "symbol",
          source: "geodataStorePoint" + name,
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": iconSize,
            "icon-offset": [0, -20],
            "icon-allow-overlap": true,
            "symbol-z-order": "viewport-y"
          }
        });
      }
      // 如果有选tag，显示相关信息（创建时一直有，仅通过顶层容器样式做开关）
      // if (this.showTag) {
        feas.forEach(point => {
          const ele = document.createElement("div");
          ele.setAttribute("class", "measure-result store-tag tag-label-" + tagType + ' tag-label-' + tagType + '-' + point.properties.id);
          const option = {
            element: ele,
            anchor: "top",
            offset: [0, -50]
          };
          // ele.innerHTML = `${point.properties.datas.authNumber || '-'}/${point.properties.datas.sales || '-'}`;
          ele.innerHTML = `${point.properties.datas.sales || '-'}`;
          new mapboxgl.Marker(option)
            .setLngLat(point.geometry.coordinates)
            .addTo(this.map);
        })
      // }
      this.map.on("click", name, e => {
        /**
         * 当前点击点：多商圈层叠，展示商圈选取弹窗；
         * 当前点击点：多标记点与商圈混合层叠，禁止展示商圈信息，并展示标记点选取弹窗
         */
        // 解构点击点坐标
        let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
        // 筛选点击位置占据的所有图层，包括商圈和标记点
        let filtJudgeLayerId = [...this.judgeLayerIdArr];
        filtJudgeLayerId.splice(
          filtJudgeLayerId.indexOf("pplayerundefined"),
          1
        );
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: this.filtJudgeLayerId
        });
        // 筛选标记点图层
        let symbols = features.filter(j => {
          return j.layer.type === "symbol" && j.layer.id != "dizhi";
        });
        if (symbols.length > 1) {
          this.showTaMarkerPopup(e, symbols, clickLng, clickLat);
          return;
        }
        // this.storeVisible = true;
        this.vsVisible = "store";
        this.taVisible = false;
        this.info = {
          id: e.features[0].properties.id,
          coordinates: e.features[0].geometry.coordinates,
          phone: e.features[0].properties.phone,
          datas: e.features[0].properties.datas
        };
      });
      if (!this.judgeLayerIdArr.includes(name)) {
        this.judgeLayerIdArr.push(name);
      }
    },
    //绘制足迹
    drawFootprint(feas, iconSize = .5) {
      let geojsonPoints = {
        type: "FeatureCollection",
        features: []
      };
      geojsonPoints.features = feas;
      if (this.map.getLayer("footprintlayer")) {
        this.map.getSource("geodataFootprintPoint").setData(geojsonPoints);
      } else {
        this.map.addSource("geodataFootprintPoint", {
          type: "geojson",
          data: geojsonPoints,
          cluster: true, //允许聚合
          clusterMaxZoom: 9, // 缩放等级小于8时取开始聚合
          clusterRadius: 100
        });
        this.map.addLayer({
          id: "footprintClusters",
          type: "circle",
          source: "geodataFootprintPoint",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#f28cb1", // 少于100个显示粉色半径20px的圆形
              100, // 分界点：100个点位
              "#ff7e30",
              300,
              "#1a6072"
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20, // 20px
              100, // 分界点： 100个点位
              50,
              300,
              70
            ]
          }
        });
        this.map.addLayer({
          id: "footprintCluster-count",
          type: "symbol",
          source: "geodataFootprintPoint",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
        this.map.addLayer({
          id: "footprintlayer",
          type: "symbol",
          source: "geodataFootprintPoint",
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": iconSize,
            "icon-offset": [0, -20],
            "icon-allow-overlap": true
          }
        });
      }
      this.map.on("click", "footprintlayer", e => {
        /**
         * 当前点击点：多商圈层叠，展示商圈选取弹窗；
         * 当前点击点：多标记点与商圈混合层叠，禁止展示商圈信息，并展示标记点选取弹窗
         */
        // 解构点击点坐标
        let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
        // 筛选点击位置占据的所有图层，包括商圈和标记点
        let filtJudgeLayerId = [...this.judgeLayerIdArr];
        filtJudgeLayerId.splice(
          filtJudgeLayerId.indexOf("footprintlayer"),
          1
        );
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: this.filtJudgeLayerId
        });
        // 筛选标记点图层
        let symbols = features.filter(j => {
          // console.log(j);
          return j.layer.type === "symbol" && j.layer.id != "dizhi";
        });
        if (symbols.length > 1) {
          this.showTaMarkerPopup(e, symbols, clickLng, clickLat);
          return;
        }

        this.footprintInfo = {
          id: e.features[0].properties.id,
          coordinates: e.features[0].geometry.coordinates,
          datas: e.features[0].properties.datas
        };

        this.footprintEdit = false;
        this.footprintVisible = true;
      });
      if (!this.judgeLayerIdArr.includes("footprintlayer")) {
        this.judgeLayerIdArr.push("footprintlayer");
      }
    },
    //绘制竞品点
    drawVSPoint(feas, name = "vslayer", iconSize = 0.38) {
      let geojsonPoints = {
        type: "FeatureCollection",
        features: []
      };
      geojsonPoints.features = feas;
      let layer = name + "layer"; //point 图层
      let clusters = name + "Clusters"; //聚合图层
      let clusterCount = name + "Cluster-count"; //聚合数量图层
      let source = name + "Source"; //数据源
      if (this.map.getLayer(layer)) {
        this.map.getSource(source).setData(geojsonPoints);
      } else {
        this.map.addSource(source, {
          type: "geojson",
          data: geojsonPoints,
          cluster: true,
          clusterMaxZoom: 9,
          clusterRadius: 100
        });
        this.map.addLayer({
          id: clusters,
          type: "circle",
          source: source,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#a2a100",
              150,
              "#00add8",
              600,
              "#894b62"
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              150,
              50,
              600,
              70
            ]
          }
        });
        this.map.addLayer({
          id: clusterCount,
          type: "symbol",
          source: source,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
        this.map.addLayer({
          id: layer,
          type: "symbol",
          source: source,
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": iconSize,
            "icon-offset": [0, -20],
            "icon-allow-overlap": true
          }
        });
      }
      this.map.on("click", layer, e => {
        /**
         * 当前点击点：多商圈层叠，展示商圈选取弹窗；
         * 当前点击点：多标记点与商圈混合层叠，禁止展示商圈信息，并展示标记点选取弹窗
         */
        // 解构点击点坐标
        let [clickLng, clickLat] = [e.lngLat.lng, e.lngLat.lat];
        // 筛选点击位置占据的所有图层，包括商圈和标记点
        let filtJudgeLayerId = [...this.judgeLayerIdArr];
        filtJudgeLayerId.splice(
          filtJudgeLayerId.indexOf("pplayerundefined"),
          1
        );
        let features = this.map.queryRenderedFeatures(e.point, {
          layers: this.filtJudgeLayerId
        });
        // 筛选标记点图层
        let symbols = features.filter(j => {
          return j.layer.type === "symbol" && j.layer.id != "dizhi";
        });
        if (symbols.length > 1) {
          this.showTaMarkerPopup(e, symbols, clickLng, clickLat);
          return;
        }
        // this.vsVisible = true;
        this.vsVisible = "vs";
        this.taVisible = false;
        this.info = {
          id: e.features[0].properties.id,
          coordinates: e.features[0].geometry.coordinates,
          datas: e.features[0].properties.datas
        };
      });
      if (!this.judgeLayerIdArr.includes(layer)) {
        this.judgeLayerIdArr.push(layer);
      }
    },
    //调用极海热力图
    DrawHotMap() {
      let ak = "NWFlZjQ4Y2IxOTM3NDNjM2E1N2VhOGY0NmI2ODM0ZWU";
      let vizConfig = [
        {
          dataUid: "1b5dbae8838911e998dfa0d3c1f46be8",
          dataType: "private",
          vizConfig: {
            type: "polygon-category",
            labelField: null,
            labelColor: "#000000",
            labelFont: "Microsoft YaHei Regular",
            labelSize: 12,
            labelDx: 0,
            labelDy: 0,
            labelHaloColor: "#fff",
            labelAllowOverlap: false,
            labelPlacement: "point",
            blendingMode: "src-over",
            fieldName: "Score",
            fillOpacity: 0.8,
            outlineColor: "#ffffff",
            outlineOpacity: 0,
            outlineWidth: 1,
            fieldFiles: [null, null, null, null, null, null, null, null, null],
            buckets: [
              {
                value: 10,
                color: "#67000d"
              },
              {
                value: 9,
                color: "#9f0e15"
              },
              {
                value: 8,
                color: "#cb2120"
              },
              {
                value: 7,
                color: "#e93e30"
              },
              {
                value: 6,
                color: "#fb6a4a"
              },
              {
                value: 5,
                color: "#fc9374"
              },
              {
                value: 4,
                color: "rgba(33,102,172,0)"
              },
              {
                value: 3,
                color: "rgba(33,102,172,0)"
              },
              {
                value: 2,
                color: "rgba(33,102,172,0)"
              }
            ]
          }
        }
      ];
      this.$http
        .get("https://geohey.com/s/dataviz/config?ak=" + ak, {
          params: {
            configJson: JSON.stringify(vizConfig),
            layerGrouped: true
          }
        })
        .then(data => {
          data = data.data;
          if (data.code == 0) {
            let vizId = data.data.vizId;
            if (this.map.getSource("hotMap") == undefined) {
              //不存在热力图图层时加载，拖动不重复加载，无法控制可是范围内加载
              this.map.addSource("hotMap", {
                type: "raster",
                tiles: [
                  "https://geohey.com/s/dataviz/" +
                    vizId +
                    "/{z}/{x}/{y}.png?ak=" +
                    ak
                ],
                tileSize: 256
              });
              this.map.addLayer({
                id: "baselayer",
                type: "raster",
                source: "hotMap"
              });
            }
            this.loadingMap = false;
          }
        });
    },
    //画订单流向图 3D柱状图
    DrawOrderMap() {
      let url = {
        type: "FeatureCollection",
        features: this.orderFeature
      };
      if (this.map.getLayer("room-extrusion")) {
        //如果已有此图层则替换数据
        //在重复显示时比较有用
        this.map.getSource("mySource").setData(url);
      } else {
        // this.map.setPitch(50);
        this.map.addSource("mySource", {
          type: "geojson",
          data: url
        });
        this.map.addLayer({
          id: "room-extrusion",
          type: "fill-extrusion",
          source: "mySource",
          paint: {
            "fill-extrusion-color": {
              base: 1,
              type: "interval",
              property: "COUNT",
              default: "#a020f0",
              stops: [
                [0, "#fff7ec"], //分段颜色和样式
                [1, "#fee8c8"],
                [4, "#fdd49e"],
                [7, "#fdbb84"],
                [10, "#fc8d59"],
                [15, "#ef6548"],
                [20, "#d7301f"],
                [30, "#EE1289"],
                [40, "#a020f0"],
                [50, "#a1a5e0"]
              ]
            },
            "fill-extrusion-opacity": 0.7,
            "fill-extrusion-height": ["*", ["get", "COUNT"], 10], //将count字段乘以25，提升高度更明显
            "fill-extrusion-height-transition": {
              duration: 500,
              delay: 0
            }
          }
        });
      }
      this.loadingMap = false;
    },
    /**
     * 绘制等时圈
     * @param {Array} point 当前点击坐标
     * @param {Number} isoTime 时间范围-分钟
     * @param {String} isoType 工具类型-walking cycling driving
     */
    async renderIsochronous(point, isoTime, isoType) {
      if (this.mapCommand != "isochronousCircle") {
        return
      }
      let geojsonPolygon = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              id: 1
            },
            geometry: {
              coordinates: [],
              type: "Polygon"
            }
          }
        ]
      };
      this.loadingMap = true;
      try {
        const urlBase = "https://api.mapbox.com/isochrone/v1/mapbox/";
        const profile = isoType;
        const minutes = isoTime;

        const pointLat = point.lat || point[1];
        const pointLng = point.lng || point[0];
        //坐标系转换(GCJ-02 to WGS-84) 高德 -> mapbox
        let { lat, lon } = this.$GPS.gcj_decrypt( pointLat, pointLng );
        let res = await axios({
          method: "get",
          url: `${urlBase}${profile}/${lon},${lat}`,
          params: {
            contours_minutes: minutes,
            polygons: true,
            denoise: 1,
            generalize: 1,
            access_token: constants.iMap.ACCESSTOKEN
          }
        });
        if (res.status != 200) {
          return;
        }
        if (res.data == null) {
          this.$message.error("数据返回为空");
          this.loadingMap = false;
          return;
        }
        let coordinates = res.data.features[0].geometry.coordinates;
        //坐标系转换(WGS-84 to GCJ-02) mapbox -> 高德
        coordinates.forEach(e => {
          e.forEach(f => {
            let { lat, lon } = this.$GPS.gcj_encrypt(f[1], f[0]);
            f[0] = lon;
            f[1] = lat;
          });
        });
        geojsonPolygon.features[0].geometry.coordinates = coordinates;
        let t = calGeoHash.geoHashList(coordinates);
        this.isoGeoHash = t.join(",");
        if (this.map.getSource("isoPolygonSource") == undefined) {
          // #region
          // 查看报告暂时隐藏
          // let a = document.createElement("a");
          // a.innerHTML = "查看报告";
          // a.style.color = "blue";
          // a.style.cursor = "pointer";
          // a.addEventListener("click", () => {
          //   if (t.length > 8000) {
          //     this.$message({
          //       type: "warning",
          //       message: "范围太大，请缩小范围再进行操作",
          //     });
          //     return;
          //   } else {
          //     this.goReport(
          //       point,
          //       isoType,
          //       isoTime,
          //       coordinates,
          //       this.isoGeoHash
          //     );
          //   }
          // });
          // this.isoPopover = new mapboxgl.Popup({
          //   closeButton: false,
          //   closeOnClick: false,
          // }).setDOMContent(a);
          // this.isoPopover.setLngLat(point).addTo(this.map);
          // #endregion
          this.map.addSource("isoPolygonSource", {
            /* 添加Source，类型是geojson */
            type: "geojson",
            data: geojsonPolygon
          });
          this.map.addLayer({
            id: "isoPolygon",
            type: "fill" /* fill类型layer */,
            source: "isoPolygonSource",
            paint: {
              "fill-color": "#cb45fe" /* fill颜色 */,
              "fill-opacity": 0.3 /* fill透明度 */
            }
          });
          let el = document.createElement("div");
          el.className = "delBtn";
          let el1 = document.createElement("i");
          el.appendChild(el1);
          el1.className = "el-icon-close textRed";
          el.addEventListener("click", e => {
            e.stopPropagation();
            this.map
              .removeLayer("isoPolygon")
              .removeSource("isoPolygonSource");
            this.delIsoMarker.remove();
            // this.isoPopover.remove();
            this.delIsoMarker = null;
            this.map.getCanvas().style.cursor = "crosshair";
            this.map.once("click", e => {
              this.renderIsochronous(
                e.lngLat,
                this.isoTime,
                this.isoType
              );
              this.map.getCanvas().style.cursor = "grab";
            });
          });
          let el2 = document.createElement("span");
          el2.id = "isoArea";
          // el2.innerHTML = this.$CalculationArea(coordinates); // 计算等时圈面积;
          el2.className = "text-shadow-w";
          el.appendChild(el2);
          this.delIsoMarker = new mapboxgl.Marker(el, {
            offset: [0, 0]
          })
            .setLngLat([pointLng, pointLat])
            .addTo(this.map);
          this.map.on("click", "isoPolygon", e => {
            e._defaultPrevented = true;
            this.createTaOpenInfo(
              coordinates[0],
              pointLng,
              pointLat,
              turf.area(turf.polygon(coordinates))
            );
          });
          this.loadingMap = false;
        } else {
          // this.isoPopover.setLngLat(point).addTo(this.map);
          this.map.getSource("isoPolygonSource").setData(geojsonPolygon);
          this.delIsoMarker.setLngLat([
            pointLng,
            pointLat
          ]);
          this.loadingMap = false;
          // document.getElementById(
          //   "isoArea"
          // ).innerHTML = this.$CalculationArea(coordinates); // 计算等时圈面积;
        }
        this.createTaOpenInfo(
          coordinates[0],
          pointLng,
          pointLat,
          turf.area(turf.polygon(coordinates))
        );
      } catch (err) {
        console.log(err);
        this.loadingMap = false;
        this.$message.error("数据加载失败");
      }
    },
    /**
     * 绘制缓冲区
     * @param {Array} coords 选取的缓冲区中心点
     * @param {Number} distance 缓冲区设置半径 km
     */
    renderBuffer(coords, distance) {
      if (this.mapCommand == "Buffer") {
        const CirclePoint = this.createGeoJSONCircle(
          [coords.lng || coords[0], coords.lat || coords[1]],
          distance
        );
        let coordinates = CirclePoint.data.features[0].geometry.coordinates;
        this.bufferGeoHash = calGeoHash.geoHashList(coordinates).join(",");
        if (this.map.getLayer("bufferPolygon")) {
          this.map.getSource("bufferPolygonSource").setData(CirclePoint.data);
          this.delBufferMarker.setLngLat([
            coords.lng || coords[0],
            coords.lat || coords[1]
          ]);

          // 查看报告弹窗 ↓ 暂时取消功能
          // this.bufferPopover
          //   .setLngLat([
          //     coords.lng || coords[0],
          //     coords.lat || coords[1],
          //   ])
          //   .addTo(this.map);

          document.getElementById(
            "bufferArea"
          ).innerHTML = this.$CalculationArea(coordinates); //area;
        } else {
          // #region
          // 查看报告暂时隐藏 ↓
          // let a = document.createElement("a");
          // a.innerHTML = "查看报告";
          // a.style.color = "blue";
          // a.style.cursor = "pointer";
          // a.addEventListener("click", () => {
          //   if (t.length > 8000) {
          //     this.$message({
          //       type: "warning",
          //       message: "范围太大，请缩小范围再进行操作",
          //     });
          //     return;
          //   } else {
          //     this.goBufferReport(coords, this.bufferGeoHash);
          //   }
          // });
          // this.bufferPopover = new mapboxgl.Popup({
          //   closeButton: false,
          //   closeOnClick: false,
          // }).setDOMContent(a);
          // this.bufferPopover
          //   .setLngLat([coords.lng || coords[0], coords.lat || coords[1]])
          //   .addTo(this.map);
          // #endregion

          this.map.addSource("bufferPolygonSource", CirclePoint);
          this.map.addLayer({
            id: "bufferPolygon",
            type: "fill",
            source: "bufferPolygonSource",
            layout: {},
            paint: {
              "fill-color": "#feb500",
              "fill-opacity": 0.3
            }
          });
          this.map.on("click", "bufferPolygon", e => {
            e._defaultPrevented = true;
            this.createTaOpenInfo(
              coordinates[0],
              coords.lng || coords[0],
              coords.lat || coords[1],
              turf.area(turf.polygon(coordinates))
            );
          });
          let el = document.createElement("div");
          el.className = "delBtn";
          let el1 = document.createElement("i");
          el.appendChild(el1);
          el1.className = "el-icon-close textRed";
          let el2 = document.createElement("span");
          el2.id = "bufferArea";
          // el2.innerHTML = this.$CalculationArea(coordinates); // 计算缓冲圈面积;;
          el2.className = "text-shadow-w";
          el.appendChild(el2);
          el.addEventListener("click", e => {
            e.stopPropagation();
            this.map
              .removeLayer("bufferPolygon")
              .removeSource("bufferPolygonSource");
            this.delBufferMarker.remove();
            // this.bufferPopover.remove();
            this.delBufferMarker = null;
            this.map.getCanvas().style.cursor = "crosshair";
            this.map.once("click", e => {
              this.renderBuffer(e.lngLat, this.bufferDistance);
              this.map.getCanvas().style.cursor = "grab";
            });
          });
          this.delBufferMarker = new mapboxgl.Marker(el, {
            offset: [0, 0]
          })
            .setLngLat([coords.lng || coords[0], coords.lat || coords[1]])
            .addTo(this.map);
        }
        this.createTaOpenInfo(
          coordinates[0],
          coords.lng || coords[0],
          coords.lat || coords[1],
          turf.area(turf.polygon(coordinates))
        );
      }
    },

    /**
     * 计算圆圈坐标genjson数据
     * @param {Array} center 中心点 [lng,lat]
     * @param {Number} radiusInKm 半径 Km
     * @param {Number} points 圆圈的等分顶点数
     */
    createGeoJSONCircle(center, radiusInKm, points = 32) {
      let coords = {
        latitude: center[1],
        longitude: center[0]
      };
      let km = radiusInKm;
      let ret = [];
      let distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      let distanceY = km / 110.574;
      let theta, x, y;
      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);
        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      this.bufferPoint = ret;
      return {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [ret]
              },
              properties: {
                id: 1
              }
            }
          ]
        }
      };
    },
    // 更新机会点图层
    upDatePPDetail(value) {
      this.ppVisible = value.visible;
      if (!this.ppVisible) {
        this.renderMarker(this.legendType, true);
      }
      // if (!this.ppVisible && value.newSuccess) {
      //   //移除新增的marker
      // }
    },
    // 更新门店点图层
    upDateStoreDetail(value) {
      this.storeVisible = value.visible;
      if (!this.storeVisible) {
        this.renderMarker(this.legendType, true);
      }
      // if (!this.storeVisible && value.newSuccess) {
      //   //移除新增的marker
      //   //this.marker.remove();
      // }
    },
    // 更新竞品点图层
    upDateVSDetail(value) {
      if (this.marker) {
        this.marker.remove();
        this.marker = null;
      }
      this.delStoreLocationMarker();
      this.vsVisible = value.visible;
      // if(this.vsVisible == false) {
      if (value.newSuccess) {
        this.renderMarker(this.legendType, true);
      }
      // if(this.vsVisible == false && value.newSuccess) {
      if (this.vsVisible == "" && value.newSuccess) {
        //移除新增的marker
        // this.marker.remove();
      }
    },
    // 更新商圈图层
    upDateTADetail(value) {
      this.taVisible = value.visible;
      if (value.newSuccess) {
        // 图里标注 - 新建商圈清除复位
        if (this.map.getLayer("tradingarea")) {
          this.renderMarker(this.legendType, true);
          this.draw.deleteAll();
          this.map.removeLayer("tradingarea").removeSource("tradingarea");
          this.delTAMarker.remove();
          this.delTAMarker = null;
          this.map.removeControl(this.draw);
          this.$store.state.newMarkerType = "";
          if (this.$store.state.drawPolygonType == "report") {
            this.taReportPopUp.remove();
            this.taReportPopUp = null;
          }
          this.$store.state.drawPolygonType = "";
        } else {
          this.renderMarker(this.legendType, true, value.deletedTa);
        }
        this.$store.state.location.type = "";
        // 编辑商圈清除复位
        if (this.editTaTempleId) {
          this.taEditdraw.deleteAll();
          this.map.removeControl(this.taEditdraw);
          this.editTaTempleId = null;
          this.map.off("draw.update", this.taEditdrawUpdate);
          this.canRenderLayer = true; // 允许图层渲染
        }
        // 等时圈清除复位
        if (this.map.getLayer("isoPolygon")) {
          this.map.removeLayer("isoPolygon").removeSource("isoPolygonSource");
          this.delIsoMarker.remove();
          // this.isoPopover.remove();
          this.delIsoMarker = null;
        }
        // 缓冲区清除复位
        if (this.map.getLayer("bufferPolygon")) {
          this.map
            .removeLayer("bufferPolygon")
            .removeSource("bufferPolygonSource");
          this.delBufferMarker.remove();
          // this.bufferPopover.remove();
          this.delBufferMarker = null;
        }
        this.map.getCanvas().style.cursor = "default";
      }
    },
    //画地址
    drawAddressPoint(feas) {
      let geojsonPoints = {
        type: "FeatureCollection",
        features: []
      };
      geojsonPoints.features = feas;
      if (this.map.getLayer("dizhi")) {
        this.map.getSource("dizhi").setData(geojsonPoints);
      } else {
        this.map.addSource("dizhi", {
          type: "geojson",
          data: geojsonPoints,
          cluster: true,
          clusterMaxZoom: 8,
          clusterRadius: 50
        });
        this.map.addLayer({
          id: "dizhi",
          type: "symbol",
          source: "dizhi",
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": 1,
            "icon-offset": [0, -10],
            "icon-allow-overlap": true
          }
        });
      }
    },
    //地位搜索图例
    flyToDetist(data) {
      // 清除地图可视区现有的商圈、标记点
      // this.clearMarkerTa(); // 不要移除
      var that = this;
      // 移除快速定位的商圈名称标记
      if (this.searchTaNameMarker) {
        this.searchTaNameMarker.remove();
        this.searchTaNameMarker = undefined;
      }
      // 赋值，防止地图拖拽缩放后，渲染图例筛选已选的标记点、商圈等
      // this.$store.state.location.type = "quickFind";
      let { type, val } = data;
      let lng = val.longitude;
      let lat = val.latitude;
      let zoom = 15;
      let centerPoint = []; // 商圈多边形的中心点坐标
      if (type == "4" || type == "11") {
        // 渲染商圈前的数据处理
        centerPoint = this.getCenterOfGravityPoint(JSON.parse(val.geometry));
        lng = centerPoint[0];
        lat = centerPoint[1];
      }

      // this.map.flyTo({
      this.map.jumpTo({
        center: [lng, lat], // 中心点
        zoom, // 缩放比例
        // speed: 0.5 //缩放速度
      });

      setTimeout(() => {
        that.renderMarker(that.selectedMarker, true);
      }, 1000);

      let feas = [];
      switch (type) {
        case '0':
          // 地址标记点
          feas.push({
            type: "Feature",
            properties: {
              id: "0",
              name: val.cnname,
              avg: 10,
              icon: "local",
              datas: val
            },
            geometry: {
              type: "Point",
              coordinates: [val.longitude, val.latitude]
            }
          });
          this.drawAddressPoint(feas);
          break;
        case '1':
          // 竞品标记点
          feas = [
            {
              type: "Feature",
              properties: {
                id: val.id,
                name: val.name,
                avg: 10,
                // icon: "vs" + val.brand,
                icon: val.business + val.brandCode,
                judgePopup: "竞品",
                datas: val
              },
              geometry: {
                type: "Point",
                coordinates: [val.longitude, val.latitude]
              }
            }
          ];
          this.drawVSPoint(feas, "localVS", 0.5);
          break;
        case '2':
          // 机会点标记点
          feas = [
            {
              type: "Feature",
              properties: {
                id: val.id,
                name: val.name,
                avg: 10,
                icon: val.siteFilterType || "local",
                judgePopup: "机会点",
                datas: val
              },
              geometry: {
                type: "Point",
                coordinates: [val.longitude, val.latitude]
              }
            }
          ];
          this.drawPPPoint(feas, "localPP", 0.5, type);
          break;
        case '21':
          // 机会点标记点
          feas = [
            {
              type: "Feature",
              properties: {
                id: val.id,
                name: val.name,
                avg: 10,
                icon: "jmp" + val.siteFilterType || "local",
                judgePopup: "机会点",
                datas: val
              },
              geometry: {
                type: "Point",
                coordinates: [val.longitude, val.latitude]
              }
            }
          ];
          this.drawPPPoint(feas, "localPP", 0.5, type);
          break;
        case '3':
          // 已开门店标记点
          feas = [
            {
              type: "Feature",
              properties: {
                id: val.id,
                name: val.name,
                avg: 9,
                icon: val.brandCode || "local",
                judgePopup: "门店",
                datas: val
              },
              geometry: {
                type: "Point",
                coordinates: [val.longitude, val.latitude]
              }
            }
          ];
          this.drawStorePoint(feas, "localStore", 0.5, type);
          break;
        case '31':
          // 已开门店标记点
          feas = [
            {
              type: "Feature",
              properties: {
                id: val.id,
                name: val.name,
                avg: 9,
                icon: val.brandCode ? "jmd" + val.brandCode : "local",
                judgePopup: "门店",
                datas: val
              },
              geometry: {
                type: "Point",
                coordinates: [val.longitude, val.latitude]
              }
            }
          ];
          this.drawStorePoint(feas, "localStore", 0.5, type);
          break;
        case '4':
          // 商圈多边形
          // // 绘制商圈名称标记
          // let elName = document.createElement("span");
          // elName.innerHTML = val.name;
          // elName.className = "text-shadow-w";
          // this.searchTaNameMarker = new mapboxgl.Marker(elName, {
          //   offset: [0, 0]
          // })
          //   .setLngLat(centerPoint)
          //   .addTo(this.map);
          // 绘制商圈多边形
          feas = [
            {
              type: "Feature",
              properties: {
                taid: val.id,
                name: val.name,
                geometry: val.geometry,
                judgePopup: "商圈"
              },
              geometry: {
                type: "Polygon",
                coordinates: this.$arrayStringToArray(val.geometry)
              }
            }
          ];
          if (!this.map.getLayer("localTaID")) {
            this.map.addSource("localTa", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: feas
              }
            });
            this.map.addLayer(
              {
                id: "localTaID",
                type: "fill",
                source: "localTa",
                layout: {},
                paint: {
                  "fill-color": "rgba(235,180,35,0.7)"
                }
              },
              "backgroundLayer"
            ); /* 商圈图层插入背景层之前，确保各标记点 Z 轴位置高于商圈 */
            this.map.on("click", "localTaID", e => {
              this.taVisible = true;
              this.vsVisible = false;
              this.info = {
                lngLat: e.lngLat,
                taid: e.features[0].properties.taid,
                name: e.features[0].properties.name,
                location: JSON.parse(e.features[0].properties.geometry)
              };
            });
          } else {
            this.map.getSource("localTa").setData({
              type: "FeatureCollection",
              features: feas
            });
          }
          // 绘制商圈多边形边框
          var linefeas = [
            {
              type: "Feature",
              properties: {
                taid: val.id,
                name: val.cnName,
                geometry: val.geometry
              },
              geometry: {
                type: "LineString",
                coordinates: JSON.parse(val.geometry)
              }
            }
          ];
          if (!this.map.getLayer("localTaLineID")) {
            this.map.addLayer(
              {
                id: "localTaLineID",
                type: "line",
                source: {
                  type: "geojson",
                  data: {
                    type: "FeatureCollection",
                    features: linefeas
                  }
                },
                layout: {
                  "line-cap": "round",
                  "line-join": "round"
                },
                paint: {
                  "line-color": "blue",
                  "line-dasharray": [0.2, 2],
                  "line-width": 4
                }
              },
              "backgroundLayer"
            );
          } else {
            this.map.getSource("localTaLineID").setData({
              type: "FeatureCollection",
              features: linefeas
            });
          }
          break;
        case '11':
          // 单个蜂巢，飞过来后先获取城市，再调接口
          this.getMapCityName(() => {
            this.flyToDetistOfHoneycomb(val);
          }, { lng, lat });
          break;
      }
    },
    // 飞向单个蜂巢图
    flyToDetistOfHoneycomb(val) {
      var opacityColor = 'rgba(255, 255, 255, 0.2)';
      // if (val.competitorNum >= 5){
      //   // opacityColor = 'rgba(0, 0, 255, 0.5)';
      //   opacityColor = 'rgba(174, 2, 209, 0.5)';
      // } else if (val.competitorNum >= 3) {
      //   // opacityColor = 'rgba(255, 255, 0, 0.5)';
      // } else
      if (val.competitorNum >= 1) {
        opacityColor = 'rgba(255, 0, 0, 0.5)';
      } else if (val.haveFootPrint) {
        opacityColor = 'rgba(174, 2, 209, 0.5)';
      }

      var opacityColorLine = opacityColor
      if (val.storeNum >= 1) {
        opacityColor = 'rgba(255, 255, 255, 0)';
        opacityColorLine = 'rgba(255, 255, 255, 0.6)';
      }

      var json = {
        type: "FeatureCollection",
        features: []
      };
      if (this.map.getSource("localHoneycombID")) {
        this.map.getSource("localHoneycombID").setData(json);
        this.map.getSource("localHoneycombLineID").setData(json);
      }

      var geometryArr = JSON.parse(val.geometry);
      geometryArr.push(geometryArr[0]);

      // 绘制多边形
      var feasArr = [{
        type: "Feature",
        properties: {
          code: val.id,
          name: val.id,
          competitorNum: val.competitorNum || 0,
          storeNum: val.storeNum || 0,
          haveFootPrint: val.haveFootPrint,
          geometry: val.geometry,
          judgePopup: "蜂巢",
          color: opacityColor
        },
        geometry: {
          type: "Polygon",
          coordinates: [geometryArr] // 这个地方要3个中括号开始
        }
      }];

      var honeycombLayerId = val.hideButton ? "localHideButtonHoneycombID" : "localHoneycombID";
      if (!this.map.getLayer(honeycombLayerId)) {
        // this.map.addSource("localHoneycomb", {
        //   type: "geojson",
        //   data: {
        //     type: "FeatureCollection",
        //     features: feasArr
        //   }
        // });
        this.map.addLayer(
          {
            id: honeycombLayerId,
            type: "fill",
            // source: "localHoneycomb",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: feasArr
              }
            },
            layout: {},
            paint: {
              "fill-color": ['get', 'color']
            }
          },
          "backgroundLayer0"
        );

        this.map.on("click", honeycombLayerId, e => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: [honeycombLayerId]
          })
          const clickedFeature = features[0].properties;
          if (clickedFeature) {
            this.honeycombForm.competitorNum = clickedFeature.competitorNum
            this.honeycombForm.storeNum = clickedFeature.storeNum
            this.honeycombForm.haveFootPrint = clickedFeature.haveFootPrint
            this.getBigData(clickedFeature.code, 'honeycomb', null, honeycombLayerId === 'localHideButtonHoneycombID')
          }
        });

        if (val.hideButton) { // url上获取的honeycombId，立刻弹出人口数据
          this.honeycombForm.competitorNum = val.competitorNum
          this.honeycombForm.storeNum = val.storeNum
          this.honeycombForm.haveFootPrint = val.haveFootPrint
          this.getBigData(val.id, 'honeycomb', null, true)
        }
      } else {
        this.map.getSource(honeycombLayerId).setData({
          type: "FeatureCollection",
          features: feasArr
        });
      }
      // 绘制商圈多边形边框
      var linefeasArr = [{
        type: "Feature",
        properties: {
          code: val.id,
          name: val.id,
          geometry: val.geometry,
          color: opacityColorLine
        },
        geometry: {
          type: "LineString",
          coordinates: geometryArr
        }
      }];
      if (this.map.getLayer("localHoneycombLineID")) {
        return this.map.getSource("localHoneycombLineID").setData({
          type: "FeatureCollection",
          features: linefeasArr
        });
      }
      this.map.addLayer(
        {
          id: "localHoneycombLineID",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: linefeasArr
            }
          },
          layout: {
            "line-cap": "round",
            "line-join": "round"
          },
          paint: {
            "line-color": ['get', 'color'],
            "line-dasharray": [0.2, 0],
            "line-width": 1
          }
        },
        // "backgroundLayer"
      );
    },
    //调整地图大小
    // setMapSize() {},
    // goReport(point, type, minutes, coords, geoHash) {
    //   this.loadingMap = true;
    //   api
    //     .isochronousReport({
    //       cusNo: sessionStorage.cusNo,
    //       longitude: point.lng || point[0],
    //       latitude: point.lat || point[1],
    //       minutes: minutes,
    //       type: type,
    //       geometry: `${JSON.stringify(coords[0])}`,
    //       geohash: geoHash,
    //       userName: sessionStorage.userName,
    //       fromType: "PC"
    //     })
    //     .then(res => {
    //       if (res.code == 200) {
    //         if (res.data == 500) {
    //           this.$message({
    //             message: res.message,
    //             type: "warning"
    //           });
    //         } else {
    //           sessionStorage.res = JSON.stringify(res.data);
    //           sessionStorage.info = encodeURIComponent(
    //             JSON.stringify({
    //               latitude: point.lat || point[1],
    //               longitude: point.lng || point[0],
    //               type,
    //               minutes,
    //               geometry: `${JSON.stringify(coords[0])}`,
    //               geoHash
    //             })
    //           );
    //           let routeUrl = this.$router.resolve({
    //             name: "report",
    //             params: {
    //               info: encodeURIComponent(
    //                 JSON.stringify({
    //                   reportID: res.data.reportID
    //                 })
    //               )
    //             }
    //           });
    //           window.open(routeUrl.href, "_blank");
    //         }
    //       }
    //       this.loadingMap = false;
    //     })
    //     .catch(err => {
    //       this.$message.error(err);
    //       this.loadingMap = false;
    //     });
    // },
    // goBufferReport(center, geoHash) {
    //   this.loadingMap = true;
    //   api
    //     .bufferReport({
    //       cusNo: sessionStorage.cusNo,
    //       geometry: `${JSON.stringify(this.bufferPoint)}`,
    //       geohash: geoHash,
    //       userName: sessionStorage.userName,
    //       fromType: "PC"
    //     })
    //     .then(res => {
    //       if (res.code == 200) {
    //         if (res.data == 500) {
    //           this.$message({
    //             message: res.message,
    //             type: "warning"
    //           });
    //         } else {
    //           sessionStorage.res = JSON.stringify(res.data);
    //           sessionStorage.info = encodeURIComponent(
    //             JSON.stringify({
    //               geometry: `${JSON.stringify(this.bufferPoint)}`,
    //               longitude: center.lng || center[0],
    //               latitude: center.lat || center[1],
    //               type: "buffer",
    //               geoHash
    //             })
    //           );
    //           let routeUrl = this.$router.resolve({
    //             name: "report",
    //             params: {
    //               info: encodeURIComponent(
    //                 JSON.stringify({
    //                   reportID: res.data.reportID
    //                 })
    //               )
    //             }
    //           });
    //           window.open(routeUrl.href, "_blank");
    //         }
    //       }
    //       this.loadingMap = false;
    //     })
    //     .catch(err => {
    //       this.$message.error(err);
    //       this.loadingMap = false;
    //     });
    // },

    //ta 导出报告
    toTAReport(location, center, geoHash) {
      this.loadingMap = true;
      api
        .taReport({
          cusNo: sessionStorage.cusNo,
          geometry: JSON.stringify(location),
          geohash: geoHash,
          userName: sessionStorage.userName,
          fromType: "PC"
        })
        .then(res => {
          if (res.code == 200) {
            if (res.data == 500) {
              this.$message({
                message: res.message,
                type: "warning"
              });
            } else {
              sessionStorage.res = JSON.stringify(res.data);
              sessionStorage.info = encodeURIComponent(
                JSON.stringify({
                  geometry: JSON.stringify(location), //`${this.info.location}`,
                  type: "ta",
                  longitude: center[0],
                  latitude: center[1],
                  geoHash
                })
              );
              let routeUrl = this.$router.resolve({
                name: "report",
                params: {
                  info: encodeURIComponent(
                    JSON.stringify({
                      reportID: res.data.reportID
                    })
                  )
                }
              });
              window.open(routeUrl.href, "_blank");
            }
          }
          this.loadingMap = false;
        })
        .catch(err => {
          this.$message.error(err);
          this.loadingMap = false;
        });
    },
    // 工具框显示控制
    leftToolPopUpControl(index) {
      let tools = [
        "showLocal",
        "showSearch",
        "showSelect",
        "showMark",
        "showMap",
        "showRecommend",
        "showMeasure",
        "showFootprintBox"
      ];
      tools.forEach((e, i) => {
        this[e] = i == index ? !this[e] : false;
      });
    },
    // 定位城市
    handleShowLocal() {
      this.leftToolPopUpControl(0);
    },
    // 搜索
    handleShowSearch() {
      this.leftToolPopUpControl(1);
      this.resetLocalMarker();
    },
    // 图例筛选
    handleShowSelect() {
      this.leftToolPopUpControl(2);
      this.resetLocalMarker();
    },
    // 图例标注
    handleShowMark() {
      this.leftToolPopUpControl(3);
      this.resetLocalMarker();
    },

    // 足迹显示
    handleShowFootprintBox() {
      this.leftToolPopUpControl(7);
      this.resetLocalMarker();
    },

    // 添加足迹
    handleShowFootprint() {
      this.addPointToMap({
        name: "足迹",
        type: "5",
        url: ppImage
      });
    },

    // 创建足迹
    showFootprint(type) {
      var footprintParams = {
        longitude: this.ruleForm.longitude,
        latitude: this.ruleForm.latitude,
        siteId: this.ruleForm.siteID,
        // creator: sessionStorage.loginName
        creator: sessionStorage.userName
      }
      if ('pp' !== type) {
        footprintParams = {
          storeId: this.ruleForm.storeId,
          longitude: this.ruleForm.longitude,
          latitude: this.ruleForm.latitude,
          // creator: sessionStorage.loginName
          creator: sessionStorage.userName
        }
      }

      this.footprintInfo = footprintParams;
      this.footprintEdit = true;
      this.footprintVisible = true;
    },
    // 关闭足迹弹窗
    toggleFootprint(data) {
      this.footprintVisible = data.visible;
      if (!this.footprintVisible) {
        // 关闭点
        // this.$store.state.newMarkerType = "";
        if (this.marker) {
          this.marker.remove();
          this.marker = null;
        }
        // this.delStoreLocationMarker();
      }
    },
    // 足迹创建(或删除)成功 (图例没选中，选上),重新绘制足迹列表
    footprintCreated() {
      var preKeys = this.defaultCheckedKeys;
      if (preKeys.indexOf(5) === -1) {
        this.defaultCheckedKeys.push(5);
        this.selectedMarker.push({
          name: "足迹",
          type: 5,
          id: 5
        });
        this.legendType = Array.from(this.selectedMarker);
      }
      this.renderMarker(this.legendType, true);
      if (this.marker) {
        this.marker.remove();
        this.marker = null
      }
    },
    // 关闭蜂巢图弹窗
    toggleHoneycomb() {
      this.honeycombVisible = false;
      // if (!this.honeycombVisible) {
      //   if (!!this.marker) {
      //     this.marker.remove();
      //     this.marker = null
      //   }
      // }
    },

    // 地图切换
    handleShowMap() {
      this.leftToolPopUpControl(4);
    },
    // 智能推荐
    handleShowRecommend() {
      this.leftToolPopUpControl(5);
    },
    // 测量
    handleMeasure() {
      this.leftToolPopUpControl(6);

      if (!this.showMeasure) { // 点button关闭的话，是触发测量关闭功能
        return this.closeMeasure();
      }
      if (this.radio == 1) { // 打开时，如果是测距，开启测距功能
        this.isAreaMeasure = false;
        this.isWalkMeasure = false;
        this.measureDistance();
      } else if (this.radio == 2) {
        this.isMeasure = false;
        this.isWalkMeasure = false;
        this.measureArea();
      } else if (this.radio == 3) {
        this.isMeasure = false;
        this.isAreaMeasure = false;
        this.measureWalk();
      }
    },
    handleShowLegend() {
      this.showLegend = !this.showLegend;
    },
    loadingMapFun(val) {
      this.loadingMap = val;
    },
    // 快速定位标记点功能初始化
    resetLocalMarker() {
      // 快速定位标记点标志复位
      this.$store.state.location.type = ""; // 放开限制，允许地图拖拽、缩放渲染图例筛选已选项目
      [
        "geodataPPPointlocalPP", // 移除快速定位的机会点
        "geodataStorePointlocalStore", // 移除快速定位的门店
        "localVSSource", // 移除快速定位的竞品
        "localTa", // 移除快速定位的商圈
        "localTaLineID" // 移除快速定位的商圈边框
      ].forEach(e => {
        if (this.map.getSource(e)) {
          this.map.getSource(e).setData({
            type: "FeatureCollection",
            features: []
          });
        }
      });
      // 移除快速定位的商圈名称标记
      if (this.searchTaNameMarker) {
        this.searchTaNameMarker.remove();
        this.searchTaNameMarker = undefined;
      }
    },
    //下载图片
    dataURLToBlob(dataurl) {
      //ie 图片转格式
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime
      });
    },
    downloadResult(name) {
      let canvasID = this.$refs.basicMapbox; //document.body
      let a = document.createElement("a");
      html2canvas(canvasID, {
        useCORS: true, // 允许图片跨域
        taintTest: true // 在渲染前测试图片
      }).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", name + ".png");
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    printOut(name) {
      // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
      $(window).scrollTop(0); // jQuery 的方法
      document.body.scrollTop = 0; // IE的
      document.documentElement.scrollTop = 0; // 其他
      this.downloadResult(name);
    },
    //嵌入网页时用来显示菜单栏
    handleCommandReport(command) {
      if (command == "ta") {
        this.$store.state.isDrawPolygon = true;
        this.$store.state.drawPolygonType = "report";
        this.$store.state.repVisible = false;
      } else {
        this.$store.state.mapType = "";
        this.$store.state.mapCommand = command;
        this.$store.state.repVisible = true;
      }
    },
    // 是否显示tag设置
    doShowTag() {
      this.showTag = !this.showTag;
      this.renderMarker(this.legendType, true);
    },
    updataRecVisible(val) {
      //this.recVisible = val;
      this.showRecommend = val;
    },
    // 当前编辑商圈重新规划区域
    editDrawTa(taInfo) {
      // 清除商圈多边形图层(包括重叠的干扰商圈),只保留当前编辑图层的名称标记
      let overLapTa = this.$store.state.overLapTaId;
      this.canRenderLayer = false;
      overLapTa.forEach(e => {
        this.removeLayerSource(["maine" + e.taId, "mainesline" + e.taId]);
        if (e.taId != taInfo.taId) {
          // this.taNameMarkerLst[e.code].forEach(item => {
          //   if (item.taLayerId == "maine" + e.taId) {
          //     item.marker.remove();
          //   }
          // });
        }
        this.judgeLayerIdArr.splice(
          this.judgeLayerIdArr.indexOf("maine" + e.taId),
          1
        );
      });
      // 地图放大，进入编辑视口区域
      this.centerPoint = this.getCenterOfGravityPoint(
        JSON.parse(taInfo.location)
      );
      this.flyTo(this.centerPoint, 13);
      // 编辑绘制商圈多边形
      this.map.addControl(this.taEditdraw);
      let feature = {
        type: "FeatureCollection",
        features: [
          {
            id: "editTaFeatureID",
            type: "Feature",
            properties: {
              class_id: 1
            },
            geometry: {
              type: "Polygon",
              coordinates: [JSON.parse(taInfo.location)]
            }
          }
        ]
      };
      this.editTaTempleId = this.taEditdraw.set(feature); // 创建
      // 多边形更新坐标点以及新的面积值
      this.map.on("draw.update", this.taEditdrawUpdate);
    },
    // 商圈编辑画布 update事件回调函数
    taEditdrawUpdate(e) {
      const data = this.taEditdraw.getAll();
      const area = Math.round(turf.area(data) * 100) / 100; // 平方米
      const location = data.features[0].geometry.coordinates[0]; // 获取新的坐标点
      this.$bus.$emit("updateTaInfo", {
        location,
        area
      }); // 通知MapTADetail 组件更新当前商圈数据
    },
    flyTo(centerPoint, zoom) {
      this.map.flyTo({
        center: [centerPoint[0], centerPoint[1]], // 中心点
        zoom // 缩放比例
      });
    },
    // 获取人口人流数据
    getBigData(id, type, cb, hideButton) {
      this.loadingMap = true;
      console.log('this.mapCityName: ', this.mapCityName);
      api.getHoneycombBigData({
        id,
        type,
        cityName: this.mapCityName
      })
        .then(res => {
          this.loadingMap = false;
          if (res.code == 200) {
            if (cb) {
              return cb(res.data);
            }
            this.taPeopleModelShow = true;
            this.taPeopleModelDataId = id;
            this.taPeopleModelData = res.data;
            this.taPeopleModelHideButton = hideButton;
          } else {
            this.$message.error("接口请求报错");
          }
        })
        .catch(err => {
          this.loadingMap = false;
          console.log(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    // 修改人流数据的showFlag值
    changeShowFlag(params) {
      console.log('params showFlag', params);
      this.loadingMap = true;
      api.updateHoneycombStatus({
        id: params.tableId,
        showFlag: 1 === params.showFlag * 1 ? '0' : '1'
      })
        .then(res => {
          this.loadingMap = false;
          if (res.code == 200) {
            this.getBigData(params.tableId, 'honeycomb');
            // 修改了showFlag刷新当前蜂巢数据
            this.honeycombHash = {};
            this.gridHash = {};
            let preZoom = parseInt(this.map.getZoom());
            this.zoom = preZoom - 1;
            this.map.flyTo({
              zoom: this.zoom,
              speed: 0.001 //缩放速度
            });
            setTimeout(() => {
              this.zoom = preZoom;
              this.map.flyTo({
                zoom: this.zoom,
                speed: 0.1 //缩放速度
              });
            }, 10);
          } else {
            this.$message.error("接口请求报错");
          }
        })
        .catch(err => {
          this.loadingMap = false;
          console.log(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    peopleDataRefresh(params) {
      this.getBigData(params.tableId, 'honeycomb');
    },
    changeType(siteInfo, val, valName, list, key, name) {
      let obj = {};
      obj = list.find((item) => {
        // 遍历list的数据
        return item[key] === val; // 筛选出匹配数据
      });
      siteInfo[valName] = obj ? obj[name] : '';
    },
    // 机会点点位保护审核
    toSiteIdProtectionConfirm(siteId, forDetail) {
      let ops = {
          cusNo: sessionStorage.cusNo,
          siteId: siteId,
          longitude: "",
          latitude: "",
          positionId: sessionStorage.renderPositionId
      }

      this.protectionVisible = false;
      api.initPipelineInfo(ops).then((res) => {
        const siteInfo = res.data.site;
        console.log('initPipelineInfo: ', siteInfo);

        this.zoom = this.zoom === 13 ? 12 : 13;
        this.map.flyTo({
          center: [siteInfo.longitude, siteInfo.latitude],
          zoom: this.zoom,
          speed: 0.5 //缩放速度
        });

        // // 触发机会点点击，出弹窗
        // this.vsVisible = "pp";
        // this.taVisible = false;

        var levelList = [ // 是否超A
          { value: 1, label: '是' },
          { value: 0, label: '否' }
        ]
        var siteTypeList = res.data.siteTypeList;
        this.changeType(siteInfo, siteInfo['level'], 'levelName', levelList, 'value', 'label');
        this.changeType(siteInfo, siteInfo['siteType'], 'siteTypeName', siteTypeList, 'dict_Code', 'type_Name');

        this.protectionInfo = {
          siteId: siteId,
          coordinates: [siteInfo.longitude, siteInfo.latitude],
          datas: JSON.stringify(siteInfo),
          protectionConfirm: true, // 弹出点位保护审核
        };

        // 直接弹出审核
        this.protectionVisible = true;
        this.protectionForDetail = !!forDetail;
      })
    },
    // 显示机会点详情
    toSiteIdDetail(siteId) {
      this.toSiteIdProtectionConfirm(siteId, true);
    },
    // 关闭点位保护审核
    toggleProtectionConfirm() {
      this.protectionVisible = false;
    },
    // 审核成功
    protectionSuccess() {
      // 机会点打开，重新更新数据
      if (this.vsVisible === "pp") {
        var preInfo = JSON.stringify(this.info);
        // this.info = {};
        this.$nextTick(() => {
          this.info = JSON.parse(preInfo); // 重新赋值，刷新机会点数据
        });
      }
    }
  },
  computed: {
    markerType() {
      return this.$store.state.markerType;
    },
    newMarkerType() {
      return this.$store.state.newMarkerType;
    }
  },
  watch: {
    showSearch(val) {
      if (!val) {
        if (this.map.getSource("dizhi")) {
          this.map.getSource("dizhi").setData({
            type: "FeatureCollection",
            features: []
          });
        }
        this.resetLocalMarker();
      }
    },
    // showFootprintBox(val) { // 足迹弹框取消，地图上图标也消失 TO CHECK
    //   console.log('showFootprintBox val :>> ', val);
    //   var footprintIndex = this.legendType.map(l => l.type).indexOf(5);
    //   var preLegendType = JSON.parse(JSON.stringify(this.legendType));

    //   if (val) {
    //     this.selectedMarker.push({
    //       name: "足迹",
    //       type: 5,
    //     });

    //     if (footprintIndex === -1) {
    //       preLegendType.push({
    //         name: "足迹",
    //         type: 5,
    //         logo: '134001' // TO CHECK
    //       });
    //     }
    //   } else {
    //     if (footprintIndex > -1) {
    //       preLegendType.splice(footprintIndex, 1);
    //     }
    //   }

    //   this.renderMarker(preLegendType, true);
    // },
    mapHeight(val) {
      this.styleObj = {
        width: this.mapWidth,
        height: val
      };
    },
    mapWidth(val) {
      this.styleObj = {
        width: val,
        height: this.mapHeight
      };
    },
    markerType(val) {
      this.renderMarker(val);
    },
    newMarkerType(val) {
      if (val) this.addPointToMap(val);
    },
    "$store.state.sItem"(val) {
      if (val.sType != "" && val.sLng != "" && val.sLat != "") {
        this.flyToDetist(val);
      } else {
        alert("位置信息获取失败！");
      }
    },
    "$store.state.mapCommand"(val) {
      this.mapCommand = val;
      if (["Buffer", "isochronousCircle", "mapMark"].indexOf(val) > -1) {
        this.map.getCanvas().style.cursor = "crosshair"; /* 设置光标格式 */
        this.map.on("mousemove", () => {
          //this.map.getCanvas().style.cursor = "url(../assets/images/marker/dizhi.png),auto";
        });
      } else {
        this.map.getCanvas().style.cursor = "pointer"; /* 设置光标格式 */
      }
      switch (val) {
        case "Buffer": //缓冲区  #feb500
          this.map.once("click", e => {
            if (this.isMobile) { // 移动端关闭，选择评估报告弹窗
              this.$store.state.repVisible = false;
            }
            this.bufferCoord = e.lngLat;
            this.renderBuffer(e.lngLat, this.bufferDistance);
            this.map.getCanvas().style.cursor = "grab";
          });
          break;
        case "isochronousCircle": //等时圈  cb45fe
          //使用极海地图绘制
          this.map.once("click", e => {
            if (this.isMobile) { // 移动端关闭，选择评估报告弹窗
              this.$store.state.repVisible = false;
            }
            this.isoPoint = e.lngLat; // 等时圈中心点存储(首次点击生成坐标)
            this.renderIsochronous(e.lngLat, this.isoTime, this.isoType);
            this.map.getCanvas().style.cursor = "grab";
          });
          break;
        default:
          break;
      }
    },
    "$store.state.loadingMap"(val) {
      this.loadingMap = val;
    },
    // 缓冲区距离变化
    "$store.state.bufferDistance"(val) {
      this.bufferDistance = val;
      this.renderBuffer(this.bufferCoord, this.bufferDistance);
    },
    // 等时圈时间变化
    "$store.state.isoTime"(val) {
      this.isoTime = val;
      this.renderIsochronous(this.isoPoint, this.isoTime, this.isoType);
    },
    // 等时圈交通工具变化
    "$store.state.isoType"(val) {
      this.isoType = val;
      this.renderIsochronous(this.isoPoint, this.isoTime, this.isoType);
    },
    //商圈报告
    "$store.state.isDrawPolygon"(val) {
      if (val && this.$store.state.drawPolygonType == "report") {
        const type = {
          type: "4",
          name: "商圈"
        };
        this.addPointToMap(type);
      }
    },
    "$store.state.themeColor"(val) {
      this.themeColor = val;
      if (document.getElementsByClassName("mapboxgl-ctrl-group").forEach) {
        document.getElementsByClassName("mapboxgl-ctrl-group").forEach(el => {
          el.children[0].style.background = val;
        });
      }
    },
    radio(val) {
      if (this.measureEle) this.measureEle.style.opacity = 0;
      if (this.measureAreaEle) this.measureAreaEle.style.opacity = 0;
      if (this.measureWalkEle) this.measureWalkEle.style.opacity = 0;

      if (val == 1) {
        this.measureDistance();
        if (this.measureEle) this.measureEle.style.opacity = 1;
      } else if (val == 2) {
        this.measureArea();
        if (this.measureAreaEle) this.measureAreaEle.style.opacity = 1;
      } else if (val == 3) {
        this.measureWalk();
        if (this.measureWalkEle) this.measureWalkEle.style.opacity = 1;
      }
    },
    "$store.state.isMobile"(val) {
      this.isMobile = val
    }
  }
};
</script>
<style>
.el-tree-node__content {
  height: 32px;
}

.el-message-box {
  max-width: 96%;
}

/* 移动端适配 */
@media screen and (max-width: 500px) {
  /* 下拉框点击2次才选中bug */ 
  .el-scrollbar > .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
<style scoped lang="scss">
::v-deep .el-tree {
  > .el-tree-node {
    > .el-tree-node__children > .el-tree-node > .el-tree-node__content {
      height: auto;
    }
  }

  .el-tree-node__content {
    height: auto;
    min-height: 27px;
  }

  .tree-icon-item-node {
    font-size: 12px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: break-spaces;
  }

  .el-checkbox.is-disabled {
    display: none!important;
  }
  .poi-selector {
    display: flex;
    flex-wrap: wrap;
    line-height: 20px;
    font-size:12px;
    padding: 5px 0;
  }
  .poi-button-wrap {
    display: flex;
    .el-button {
      margin: 0 5px;
    }
  }
  .poi-leaf, .poi-leaf:hover {
    width: 45px;
    background-color: #409EFF;
    border-color: #409EFF;
    height: 20px;
  }
  .poi-names {
    font-size: 11px;
    line-height: 18px;
    white-space: normal;
  }
}

::v-deep .active-button {
  .button {
    border: 2px solid rgba(0, 0, 0, 0.85);
  }
  .road-button {
    position: absolute;
    left: 40px;
    top: 0;
    height: 29px;
    line-height: 19px;
    padding: 5px;
    box-sizing: border-box;
    width: 55px;
    color: #fff;
    background: #0188e2 !important;
    font-size: 13px;
    cursor: pointer;
    .el-checkbox, input {
      pointer-events: none;
    }
  }
}

::v-deep .mapbox-gl-draw_trash {
  border-radius: 5px;
  background-color: rgb(236, 120, 120);

  &:hover {
    background-color: rgb(238, 83, 83) !important;
  }
}
</style>
