import * as turf from "@turf/turf";
import apiIframe from "@/postMessage/api/index.js";
import calGeoHash from "../../utils/calGeoHash.js";
export default {
  data() {
    return {
      // 图例标注 - 商圈画布样式配置
      drawStyles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          id: "gl-draw-line",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000",
            "line-dasharray": [0.2, 2],
            "line-width": 4,
          },
        },
        // polygon fill
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "fill-color": "#91e05b",
            "fill-outline-color": "#91e05b",
            "fill-opacity": 0.1,
          },
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000",
            "line-dasharray": [0.2, 2],
            "line-width": 4,
          },
        },
        // vertex point halos
        {
          id: "gl-draw-polygon-and-line-vertex-halo-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 7,
            "circle-color": "#FFF",
          },
        },
        // vertex points
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 6,
            "circle-color": "#000",
          },
        },
        // radius label
        {
          id: "gl-draw-radius-label",
          type: "symbol",
          filter: ["==", "meta", "currentPosition"],
          layout: {
            "text-field": "{radiusFeet} \n {radiusMiles}",
            "text-anchor": "left",
            "text-offset": [1, 0],
            "text-size": 22,
          },
          paint: {
            "text-color": "rgba(0, 0, 0, 1)",
            "text-halo-color": "rgba(255, 255, 255, 1)",
            "text-halo-width": 3,
            "icon-opacity": {
              base: 1,
              stops: [
                [7.99, 1],
                [8, 0],
              ],
            },
            "text-halo-blur": 1,
          },
        },
        // INACTIVE (static, already drawn)
        // line stroke
        {
          id: "gl-draw-line-static",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["==", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000",
            "line-width": 3,
          },
        },
        // polygon fill
        {
          id: "gl-draw-polygon-fill-static",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "mode", "static"],
          ],
          paint: {
            "fill-color": "#000",
            "fill-outline-color": "#000",
            // 'fill-opacity': 0.1,
          },
        },
        // polygon outline
        {
          id: "gl-draw-polygon-stroke-static",
          type: "line",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#000",
            "line-width": 3,
          },
        },
      ],
      // 商圈编辑画布样式配置
      editTaDrawStyles: [{
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          paint: {
            //绘制面时 - 显示的填充颜色及透明度
            "fill-color": [
              "case",
              ["==", ["get", "user_class_id"], 1],
              "#f27610",
              "#f27610",
            ],
            "fill-outline-color": "#f27610",
            "fill-opacity": 0.3,
          },
        },
        {
          id: "gl-draw-polygon-stroke-inactive",
          type: "line",
          filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            //绘制面时 - 显示未选中时的描边颜色、线条类型、宽度
            "line-color": "#ff8800",
            "line-dasharray": [1, 0],
            "line-width": 3,
          },
        },
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: [
            "all",
            ["==", "active", "true"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            //绘制面时 - 显示选中时的描边颜色、线条类型、宽度
            "line-color": "#0099ff",
            "line-dasharray": [1, 2],
            "line-width": 3,
          },
        },
        {
          id: "gl-draw-polygon-and-line-vertex-halo-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            //绘制面时 - 显示未选中的转折顶点的大小，外部边框颜色
            "circle-radius": 8,
            "circle-color": "#f3f3f3",
          },
        },
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            //绘制面时 - 显示未选中的转折顶点的大小，内部填充颜色
            "circle-radius": 5,
            "circle-color": [
              "case",
              ["all", ["==", "select", "true"]],
              "#ff0000",
              "#a00ceb",
            ],
          },
        },
        {
          id: "gl-draw-point-halo-active.cold",
          type: "circle",
          filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "active", "true"],
            ["!=", "meta", "midpoint"],
          ],
          paint: {
            //绘制面时 - 显示选中的转折顶点的大小，外部边框颜色
            "circle-radius": 8,
            "circle-color": "#f88686",
          },
        },
        {
          id: "gl-draw-point-active.cold",
          type: "circle",
          filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "active", "true"],
            ["!=", "meta", "midpoint"],
          ],
          paint: {
            //绘制面时 - 显示选中的转折顶点的大小，内部填充颜色
            "circle-radius": 5,
            "circle-color": "#ff0000",
          },
        },
        {
          id: "gl-draw-polygon-halo-midpoint",
          type: "circle",
          filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "meta", "midpoint"],
          ],
          paint: {
            //绘制面时 - 显示的两个转折顶点之间，中心点的大小，外部边框颜色
            "circle-radius": 6,
            "circle-color": "#f3f3f3",
          },
        },
        {
          id: "gl-draw-polygon-midpoint",
          type: "circle",
          filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "meta", "midpoint"],
          ],
          paint: {
            //绘制面时 - 显示的两个转折顶点之间，中心点的大小，内部填充颜色
            "circle-radius": 4,
            "circle-color": "#a00ceb",
          },
        },
      ],
      // 订单流向图feature模拟数据
      orderFeature: [{
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75693246722221, 31.971016676928997],
                [118.75877916812897, 31.971016676928997],
                [118.75886499881744, 31.968529673715707],
                [118.75531509518623, 31.968013151332542],
                [118.75590115785599, 31.96908146203785],
                [118.75693246722221, 31.971016676928997],
              ],
            ],
          },
          properties: {
            COUNT: 40,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75913858413696, 31.97024361978913],
                [118.76139231026173, 31.97021403984283],
                [118.76141846179962, 31.96895176363762],
                [118.75916540622711, 31.96859338552639],
                [118.75911243259907, 31.969101371863758],
                [118.75913858413696, 31.97024361978913],
              ],
            ],
          },
          properties: {
            COUNT: 20,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75898033380508, 31.97168961246507],
                [118.7615767121315, 31.971801673102235],
                [118.76149758696556, 31.970353406814155],
                [118.75910371541977, 31.970315863079435],
                [118.75898033380508, 31.97168961246507],
              ],
            ],
          },
          properties: {
            COUNT: 19,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75524736940861, 31.974371066526828],
                [118.75713162124157, 31.97443079254981],
                [118.75712223351002, 31.97353489812437],
                [118.75530034303665, 31.973489960967328],
                [118.75524736940861, 31.974371066526828],
              ],
            ],
          },
          properties: {
            COUNT: 30,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75534057617188, 31.973408618968875],
                [118.75711351633072, 31.973422839603174],
                [118.7572456151247, 31.971839785064418],
                [118.755362033844, 31.97171293474095],
                [118.75534057617188, 31.97193591815244],
                [118.75534057617188, 31.973408618968875],
              ],
            ],
          },
          properties: {
            COUNT: 10,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75727578997612, 31.975067298894743],
                [118.75948593020439, 31.974930783160445],
                [118.75945374369621, 31.974615089747104],
                [118.75874698162079, 31.97354115519512],
                [118.75873424112797, 31.97278916600054],
                [118.75866316258907, 31.972592919697803],
                [118.7573267519474, 31.972571304137347],
                [118.75727578997612, 31.975067298894743],
              ],
            ],
          },
          properties: {
            COUNT: 15,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75091224908829, 31.973802814135738],
                [118.75190801918507, 31.973661177106123],
                [118.75270463526249, 31.9735246592804],
                [118.75232577323914, 31.971546834500728],
                [118.75044353306293, 31.971600873995428],
                [118.75053338706493, 31.972064474669025],
                [118.75091224908829, 31.973802814135738],
              ],
            ],
          },
          properties: {
            COUNT: 50,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75107921659946, 31.974277211269353],
                [118.75278174877167, 31.974020673075884],
                [118.75273682177067, 31.973704976532204],
                [118.75095717608929, 31.97390122045732],
                [118.75107921659946, 31.974277211269353],
              ],
            ],
          },
          properties: {
            COUNT: 1,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75321727246046, 31.973052817981525],
                [118.75344894826412, 31.973004752034967],
                [118.7534797936678, 31.972192746589087],
                [118.75345934182405, 31.971716632174363],
                [118.75296514481306, 31.971742798622103],
                [118.75321727246046, 31.973052817981525],
              ],
            ],
          },
          properties: {
            COUNT: 30,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.75039458274841, 31.97131019802302],
                [118.7525175511837, 31.971421690285652],
                [118.75162973999977, 31.967391955366267],
                [118.74921843409538, 31.96714734407962],
                [118.74929085373878, 31.96881637651559],
                [118.74975889921188, 31.970262391673188],
                [118.75039458274841, 31.97131019802302],
              ],
            ],
          },
          properties: {
            COUNT: 7,
          },
        },
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [118.76172691583633, 31.97185969429208],
                [118.76305058598518, 31.971847179920914],
                [118.76286819577217, 31.96914858657655],
                [118.76152843236923, 31.968929009513403],
                [118.76172691583633, 31.97185969429208],
              ],
            ],
          },
          properties: {
            COUNT: 3,
          },
        }
      ],
      testDrawTaLocation: [],
      testDrawTaRadius: undefined,
      testDrawTaExpand: undefined,
    }
  },
  mounted() {
    // 测试随机点生成商圈 -- 拾取坐标
    this.$bus.$on('pickLocation', this.drawTestTa);
    // 测试随机点生成商圈 -- 创建商圈
    this.$bus.$on('createTa', this.createTestTa);
    // 测试随机点生成商圈 -- 删除商圈
    this.$bus.$on('deleteTa', this.deleteTestTa);
    // 通信快速定位
    this.$bus.$on("quickFind", (e) => {
      let {
        type,
        code
      } = e.data.data;
      apiIframe
        .addressToMap({
          type,
          code,
          cusNo: sessionStorage.cusNo
        })
        .then((res) => {
          if (res.code == 200 && res.data.length != 0) {
            let val = res.data[0];
            if (val.latitude != null && val.longitude) {
              sessionStorage.lat = val.latitude;
              sessionStorage.lng = val.longitude;
              this.$store.state.location = {
                type,
                data: val,
              };
              // 图例筛选清空默认选项
              this.defaultCheckedKeys = [];
              // 地图现有标记点、商圈清除
              this.clearMarkerTa();
              // 绘制快速定位的标记点、商圈
              this.quickDrawMarkerTa();
              this.map.flyTo({
                center: [sessionStorage.lng, sessionStorage.lat], // 中心点
                zoom: 13, // 缩放比例
              });
            }
          } else {
            this.$message({
              message: "地图查找失败！",
              type: "warning",
              duration: 5 * 1000,
            });
          }
        });
    });

    // 定位机会点，审批
    this.$bus.$on("approvalPP", (e) => {
      if (e.data.data) {
        this.toSiteIdProtectionConfirm(e.data.data);
      }
    })
  },
  methods: {
    // 通信行为处理
    // 拾取坐标点
    drawTestTa(data) {
      let {
        drawTaMode,
        radiusVal,
        expandVal
      } = data.data.data;
      this.testDrawTaRadius = radiusVal;
      this.testDrawTaExpand = expandVal;
      this.deleteTaByPoint();
      if (drawTaMode == 'one') {
        this.pickOnePoint(1);
      } else if (drawTaMode == 'two') {
        this.pickOnePoint(2);
      } else if (drawTaMode == 'many') {
        this.pickOnePoint(6);
      }

    },
    // 创建多边形图层
    createTestTa(data) {
      let {
        drawTaMode,
        radiusVal,
        expandVal,
        expandType
      } = data.data.data;
      this.testDrawTaRadius = radiusVal;
      this.testDrawTaExpand = expandVal;
      this.createCycleTa(drawTaMode);
      if (expandType == 'cycle') {
        this.manyPointsCreateCycleTa(drawTaMode);
      } else {
        this.manyPointsCreatePloygonTa(drawTaMode);
      }
    },

    // 删除多表型图层
    deleteTestTa(data) {
      let drawTaMode = data.data.data;
      this.deleteTaByPoint();
    },

    // 拾取坐标点
    async pickOnePoint(k = 1) {
      for (let i = 0; i < k; i++) {
        // 鼠标样式变更
        this.map.getCanvas().style.cursor = "crosshair";
        // 拾取并绘制一个坐标点(中心点)
        await new Promise((resolve, reject) => {
          this.map.once('click', (e) => {
            // 坐标点存储
            this.testDrawTaLocation.push([e.lngLat.lng, e.lngLat.lat]);
            // 坐标点转为geojson数据集
            let features = this.testDrawTaLocation.map(k => {
              return {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: k,
                },
              }
            })
            this.map.getCanvas().style.cursor = "default";
            if (!this.map.getLayer('pickPointLayer')) {
              this.map.addSource('pickPointSource', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features,
                }
              });
              this.map.addLayer({
                id: 'pickPointLayer',
                source: 'pickPointSource',
                type: 'circle',
                paint: {
                  'circle-radius': 5,
                  'circle-color': '#ff0000'
                }
              });
            } else {
              this.map.getSource('pickPointSource').setData({
                type: 'FeatureCollection',
                features,
              })
            }
            resolve(this.testDrawTaLocation);
          })
        })

      }

    },
    // 创建商圈
    // 创建商圈 -- 画圆
    createCycleTa(taMode) {
      // 中心点获取
      let centerPoint = undefined;
      let radius = undefined;
      if (taMode == 'one') {
        centerPoint = this.testDrawTaLocation[0];
        radius = this.testDrawTaRadius;
      } else if (taMode == 'two') {
        // 计算中心点
        let [p1, p2] = this.testDrawTaLocation;
        let lng = (p1[0] + p2[0]) / 2;
        let lat = (p1[1] + p2[1]) / 2;
        centerPoint = [lng, lat];
        // 计算两点间距离
        let line = turf.lineString(this.testDrawTaLocation);
        let length = turf.length(line);
        // 实际半径：线段长度 / 2 + 扩展延申
        radius = length / 2 + parseFloat(this.testDrawTaExpand);
      } else {
        return false;
      }
      // 填充多边形、边框
      this.addCenterLayer(centerPoint);
      if (!this.map.getLayer('cycleTa')) {
        this.map.addSource('cycleTaSource', this.createGeoJSONCircle(
          centerPoint,
          radius
        ))
        this.map.addLayer({
          id: "cycleTa",
          type: "fill",
          source: "cycleTaSource",
          layout: {},
          paint: {
            "fill-color": "#feb500",
            "fill-opacity": 0.3,
          },
        });

        this.map.addLayer({
          id: "cycleTaLines",
          type: "line",
          source: "cycleTaSource",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#0a38f1",
            "line-dasharray": [0.8, 2, ],
            "line-width": 3,
          },
        })
      } else {
        this.map.getSource('cycleTaSource').setData(
          this.createGeoJSONCircle(
            centerPoint,
            radius
          ).data
        )
      }
    },
    // 创建商圈 -- 多点画圆
    manyPointsCreateCycleTa(taMode) {
      if (taMode != 'many') return false;
      let local = this.testDrawTaLocation;
      // 找出多边形的外接矩形(无转角) 
      let bound = calGeoHash.getBoundingBox(local);
      let BpolygonPoints = [
        [bound.xMin, bound.yMin],
        [bound.xMin, bound.yMax],
        [bound.xMax, bound.yMax],
        [bound.xMax, bound.yMin],
        [bound.xMin, bound.yMin],
      ];
      let centerPoint = [
        (BpolygonPoints[0][0] + BpolygonPoints[2][0]) / 2,
        (BpolygonPoints[0][1] + BpolygonPoints[2][1]) / 2
      ];
      let radius = turf.length(turf.lineString([BpolygonPoints[0], BpolygonPoints[2]])) / 2 + parseFloat(this.testDrawTaExpand)

      this.addCenterLayer(centerPoint);
      if (!this.map.getLayer('polygonTa')) {
        this.map.addSource('polygonTaSource', this.createGeoJSONCircle(
          centerPoint,
          radius,
        ))
        this.map.addLayer({
          id: "polygonTa",
          type: "fill",
          source: "polygonTaSource",
          layout: {},
          paint: {
            "fill-color": "#feb500",
            "fill-opacity": 0.3,
          },
        });
        this.map.addLayer({
          id: "polygonTaLines",
          type: "line",
          source: "polygonTaSource",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#0a38f1",
            "line-dasharray": [0.8, 2, ],
            "line-width": 3,
          },
        })
      } else {
        this.map.getSource('polygonTaSource').setData(
          this.createGeoJSONCircle(
            centerPoint,
            radius
          ).data);
      }
    },
    // 创建商圈 -- 多点画多边形
    manyPointsCreatePloygonTa(taMode) {
      if (taMode != 'many') return false;
      let local = this.testDrawTaLocation;
      let clockWisePoints = this.locationClockwiseSort(local);
      console.log(local);
      console.log(clockWisePoints);
      let feature = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [{
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [clockWisePoints],
            },
            properties: {
              id: 1,
            },
          }, ],
        },
      }
      if (!this.map.getLayer('polygonTa')) {
        this.map.addSource('polygonTaSource', feature)
        this.map.addLayer({
          id: "polygonTa",
          type: "fill",
          source: "polygonTaSource",
          layout: {},
          paint: {
            "fill-color": "#feb500",
            "fill-opacity": 0.3,
          },
        });
        this.map.addLayer({
          id: "polygonTaLines",
          type: "line",
          source: "polygonTaSource",
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#0a38f1",
            "line-dasharray": [0.8, 2, ],
            "line-width": 3,
          },
        })
      } else {
        this.map.getSource('polygonTaSource').setData(
          feature.data);
      }
    },
    /**
     * 坐标点顺时针排序:
     *       ① 经度由小到大排序
     *       ② 剔除经度最小点(包含经度相同纬度不同的点)
     *       ③ 剩余点纬度从大到小排列
     *       ④ 坐标合并
     */
    locationClockwiseSort(data) {
      // debugger;
      let local = [...data];
      local.sort((a, b) => {
        return a[0] - b[0];
      });

      let minLng = local.filter((e, i) => {
        return e[0] == local[0][0];
      })
      if (minLng.length > 1) {
        local.splice(0, minLng.length);
        let temp = minLng.splice(1, minLng.length);
        temp.reverse();
        local.sort((a, b) => {
          return b[1] - a[1];
        });
        local.unshift(...minLng);
        local.push(...temp);
      } else {
        minLng = [local[0]];
        local.splice(0, 1);
        local.sort((a, b) => {
          return b[1] - a[1];
        });
        local.unshift(...minLng);
      }
      return local
    },
    // 圆形中心点绘制
    addCenterLayer(center) {
      if (!this.map.getLayer('cycleCenter')) {
        this.map.addSource('cycleCenterSource', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [{
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: center,
              },
            }],
          }
        });
        this.map.addLayer({
          id: 'cycleCenter',
          source: 'cycleCenterSource',
          type: 'circle',
          paint: {
            'circle-radius': 5,
            'circle-color': '#0000ff'
          }
        });
      } else {
        this.map.getSource('cycleCenterSource').setData({
          type: 'FeatureCollection',
          features: [{
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: center,
            },
          }],
        })
      }
    },

    // 删除商圈
    deleteTaByPoint() {
      // 清空拾取坐标
      this.testDrawTaLocation = [];
      // 删除拾取坐标点geojson数据
      if (this.map.getLayer('pickPointLayer')) {
        this.map.getSource('pickPointSource').setData({
          'type': 'Point',
          'coordinates': []
        })
      };
      // 删除圆形商圈填充面、填充边线geojson数据
      if (this.map.getLayer('cycleTa')) {
        this.map.getSource('cycleTaSource').setData({
          type: "FeatureCollection",
          features: [],
        })
      };

      if (this.map.getLayer('polygonTa')) {
        this.map.getSource('polygonTaSource').setData({
          type: "FeatureCollection",
          features: [],
        })
      };
      // 删除中心点
      if (this.map.getLayer('cycleCenter')) {
        this.map.getSource('cycleCenterSource').setData({
          'type': 'Point',
          'coordinates': []
        })
      };
    }
  },

}