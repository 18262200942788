/**
 * 该 api 封装文件适配第三方嵌套系统
 */
import { fetch } from "../../api/fetch.js";
import qs from "qs";
import Vue from "vue";
import { VM } from "../../main.js";
import { baseURL } from "../../constants/Map.constants.js";
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

/**
 * 指定父页面通讯时的地址,防止数据泄露；设置'*'忽略该项安全检查
 */
let targetOrigin = baseURL.FAAPI;

/**
 * 接口跨域通讯数据类
 */
class Actions {
  constructor(api, action, data) {
    this.api = api;
    this.action = action;
    this.data = data;
  }
}

/**
 * 生成 postMessage 跨域通讯的参数实例对象
 * @param {*} api    通信信息：请求接口
 * @param {*} action 通信信息：动作行为
 * @param {*} data   通信信息：携带数据
 * @return
 */
function $_GetAction(api = "", action = "", data = null) {
  return new Actions(api, action, data);
}

/**
 * PostMessage 单向通信封装
 * @param {*} param 通信参数实例对象
 * @returns
 */
function $_PostMessage(Data) {
  // postMeaage 传递信息，向外层Iframe抛出 message 事件
  window.parent.postMessage(Data, targetOrigin);
}

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export default {
  // 址见加载完成信号
  sitesVisionOnLoad(state) {
    $_PostMessage({
      api: "sitesVisionOnLoad",
      action: "Request",
      data: state,
    });
  },
  // 请求查看锐力视图
  showView(data) {
    $_PostMessage({
      api: "showView",
      action: "Request",
      data,
    });
  },
  // 保存机会点信息
  savePipelineInfo(data) {
    return fetch({
      url: "/mark/pipeline",
      method: "post",
      data: qs.stringify(data),
    });
    // let api = "savePipelineInfo";
    // let actionRequest = $_GetAction(api, "Request", data);
    // window.parent.postMessage(actionRequest, targetOrigin);
    // return new Promise((resolve, reject) => {
    //   /**
    //    * 每次点击保存动作，会再次订阅一个同名消息事件，造成$on多次响应触发,
    //    * 解决：先解绑总线事件，再订阅
    //    */
    //   debugger;
    //   Vue.prototype.$bus.$off(api); // 解决多次触发
    //   Vue.prototype.$bus.$on(api, async (e) => {
    //     if (e.data.action == "Permit") {
    //       let returnData = e.data.data;
    //       if (returnData.hasOwnProperty("PPID")) {
    //         returnData.ppid = returnData.PPID;
    //       }
    //       VM.$store.commit("MapVSDetail/updatePPData", e.data.data);
    //       try {
    //         const res = await fetch({
    //           url: "/mark/pipeline",
    //           method: "post",
    //           data: qs.stringify(e.data.data),
    //         });
    //         resolve(res);
    //       } catch (err) {
    //         reject(err);
    //       }
    //     } else if (e.data.action == "Cancel") {
    //       reject("Cancelled");
    //     }
    //   });
    // });
  },
  //机会点详情(初始化)
  initPipelineInfo(data) {
    return fetch({
      url: "/mark/siteDetail",
      method: "post",
      data: qs.stringify(data),
    });
  },
  /**
   * 保存竞品信息 - 调用锐力保存接口isDelete字段传0
   * 删除竞品信息 - 调用锐力保存接口isDelete字段传1
   */
  async saveCompetitorInfo(data) {
    let api = "saveCompetitorInfo";
    let actionRequest = $_GetAction(api, "Request", data);
    window.parent.postMessage(actionRequest, targetOrigin);
    return new Promise((resolve, reject) => { // 应该是权限之类的：TO CHECK
      // Vue.prototype.$bus.$off(api);
      // Vue.prototype.$bus.$on(api, async (e) => {
      //   if (e.data.action == "Permit") {
      //     // ID/id字段大小写处理，统一为id
      //     let returnData = e.data.data;
      //     if (returnData.hasOwnProperty("ID")) {
      //       returnData.id = returnData.ID;
      //     }
      //     VM.$store.commit("MapVSDetail/updateVSData", returnData);
      //     try {
      //       const res = await fetch({
      //         url: "/mark/competitor",
      //         method: "post",
      //         data: qs.stringify(returnData),
      //       });
      //       resolve(res);
      //     } catch (err) {
      //       reject(err);
      //     }
      //   } else if (e.data.action == "Cancel") {
      //     reject("Cancelled");
      //   }
      // });

      (async () => {
        // ID/id字段大小写处理，统一为id
        let returnData = data;
        if (returnData.hasOwnProperty("ID")) {
          returnData.id = returnData.ID;
        }
        VM.$store.commit("MapVSDetail/updateVSData", returnData);
        try {
          const res = await fetch({
            url: "/mark/competitor",
            method: "post",
            data: qs.stringify(returnData),
          });
          resolve(res);
        } catch (err) {
          reject(err);
        }
      })();
    });
  },
  //删除竞品信息 - 调用锐力保存接口isDelete字段传1
  async deleteCompetitorInfo(data) {
    let api = "saveCompetitorInfo";
    let actionRequest = $_GetAction(api, "Request", data);
    window.parent.postMessage(actionRequest, targetOrigin);
    return new Promise((resolve, reject) => {
      Vue.prototype.$bus.$off(api);
      Vue.prototype.$bus.$on(api, async (e) => {
        if (e.data.action == "Permit") {
          resolve(e.data.data);
        } else if (e.data.action == "Cancel") {
          reject("Cancelled");
        }
      });
    });
  },
  //商圈详情保存 - 调用锐力保存接口pHome字段传1
  async saveTAInfo(data) {
    let api = "saveTAInfo";
    let actionRequest = $_GetAction(api, "Request", data);
    window.parent.postMessage(actionRequest, targetOrigin);
    return new Promise((resolve, reject) => {
      Vue.prototype.$bus.$off(api);
      Vue.prototype.$bus.$on(api, async (e) => {
        if (e.data.action == "Permit") {
          VM.$store.commit("MapTADetail/updateTAData", e.data.data);
          try {
            const res = await fetch({
              url: "/mark/business",
              method: "post",
              data: qs.stringify(e.data.data),
            });
            resolve(res);
          } catch (err) {
            reject(err);
          }
        } else if (e.data.action == "Cancel") {
          reject("Cancelled");
        }
      });
    });
  },
  //商圈删除 - 调用锐力保存接口pHome字段传0
  async deleteTAInfo(data) {
    let api = "saveTAInfo";
    let actionRequest = $_GetAction(api, "Request", data);
    window.parent.postMessage(actionRequest, targetOrigin);
    return new Promise((resolve, reject) => {
      Vue.prototype.$bus.$off(api);
      Vue.prototype.$bus.$on(api, (e) => {
        if (e.data.action == "Permit") {
          resolve(e.data.data);
        } else if (e.data.action == "Cancel") {
          reject("Cancelled");
        }
      });
    });
  },
  // 锐力调用查询机会点、竞品、门店、商圈信息
  addressToMap(data) {
    return fetch({
      url: "/mark/addressToMap",
      method: "post",
      data: qs.stringify(data),
    });
  },
};
