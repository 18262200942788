<!-- components/Dialog/Map/MapTADetail | 适配商圈详情基本信息编辑 -->
<template>
  <div id="root">
    <!-- 商圈 -->
    <el-row>
      <!-- 商圈编号 -->
      <!-- <el-col :span="24">
        <el-form-item
          :label="smartTA.code.label"
          label-width="100px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.code"
            placeholder="请输入"
            disabled
          ></el-input>
          <span v-else>{{ myFormData.code }}</span>
        </el-form-item>
      </el-col> -->
      <!-- 名称 -->
      <el-col :span="24">
        <el-form-item
          :label="smartTA.cnName.label"
          label-width="100px"
          prop="cnName"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.cnName"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ myFormData.cnName }}</span>
        </el-form-item>
      </el-col>
      <!-- 商圈类型 -->
      <el-col :span="12">
        <el-form-item
          :label="smartTA.tabusType.label"
          label-width="100px"
          prop="tabusType"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.tabusType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in myFormLst[smartTA.tabusType.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
            changeType(
              myFormData.tabusType,
              myFormLst[smartTA.tabusType.list],
              "dict_Code",
              "type_Name"
            )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 商圈理想型 -->
      <el-col :span="12">
        <el-form-item
          :label="smartTA.ta2Type.label"
          label-width="100px"
          prop="tabusType"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.ta2Type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in myFormLst[smartTA.ta2Type.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
            changeType(
              myFormData.ta2Type,
              myFormLst[smartTA.ta2Type.list],
              "dict_Code",
              "type_Name"
            )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 商圈成熟度 -->
      <el-col :span="12">
        <el-form-item
          :label="smartTA.ta3Type.label"
          label-width="100px"
          prop="tabusType"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.ta3Type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in myFormLst[smartTA.ta3Type.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
            changeType(
              myFormData.ta3Type,
              myFormLst[smartTA.ta3Type.list],
              "dict_Code",
              "type_Name"
            )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 物业系类 -->
      <el-col :span="12">
        <el-form-item
          :label="smartTA.ta4Type.label"
          label-width="100px"
          prop="tabusType"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.ta4Type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in myFormLst[smartTA.ta4Type.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
            changeType(
              myFormData.ta4Type,
              myFormLst[smartTA.ta4Type.list],
              "dict_Code",
              "type_Name"
            )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 备注 -->
      <el-col :span="24">
        <el-form-item :label="smartTA.remark.label" label-width="100px">
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.remark"
            placeholder="请输入"
            disabled
          ></el-input>
          <span v-else>{{ myFormData.storeNum }}</span>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import smartTA from "./SmartTAConfig.json";
export default {
  name: "MapTADetailBasic",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    formLst: {
      type: Object
    },
    formData: Object
  },
  data() {
    return {
      smartTA // Smart_TA 表字段配置
    };
  },
  mounted() {},
  methods: {
    /**
     * 依据code码和查询列表获取页面显示名称
     */
    changeType(Code, List, itemCode, itemName) {
      if (!Code) return "";
      if (!List) return "";
      let obj = {};
      obj = List.find(e => {
        // 遍历list的数据
        return e[itemCode] == Code; // 筛选出匹配数据
      });
      return obj ? obj[itemName] : "";
    },
    ...mapMutations("MapTADetail", { updateTAData: "updateTAData" })
  },
  computed: {
    myIsEdit() {
      return this.isEdit;
    },
    myFormData() {
      return this.formData;
    },
    myFormLst() {
      return this.formLst;
    }
  },
  watch: {
    /**
     * 编辑数据保存
     */
    myFormData: {
      deep: true,
      handler(newV, oldV) {
        this.updateTAData(newV);
      }
    }
  }
};
</script>
<style scoped>
.el-checkbox {
  margin-right: 0px !important;
}

.el-checkbox >>> .el-checkbox__label {
  width: 38px !important;
}

#root .el-form-item {
  margin-bottom: 16px;
}
</style>
