<template>
  <div class="tamodel-root">
    <el-dialog
      :visible.sync="myShow"
      width="100%"
      :modal="false"
      :close-on-click-modal="false"
      v-draggable
      @close="$emit('visibleChange',false)">
      <template v-slot:title>
        <span style="font-weight: bold;color: #02283f">
          人口数据
          <!-- {{ tableId }} -->
          <!-- ({{ 1 === myTableData.showFlag * 1 ? "展示中" : "隐藏中" }}) -->
        </span>
        <el-button
          @click="changeShowFlag"
          size="mini"
          style="float:right;margin:-5px 50px 0 0;"
          v-if="tableId && !hideButton"
          :type="1 !== myTableData.showFlag * 1 ? 'primary' : 'warning'"
        >
          <span style="font-size: 12px">
            {{ 1 !== myTableData.showFlag * 1 ? "展示" : "隐藏" }}
          </span>
        </el-button>
        <el-button
          v-if="tableId && !hideButton"
          @click="isEdit = !isEdit"
          size="mini"
          style="float:right;margin:-5px 20px 0 0;"
        ><span
            style="font-size: 12px"
            v-if="!isEdit"
          >编辑</span><span
            style="font-size: 12px"
            v-else
          >退出编辑</span></el-button>
        <el-button
          v-if="tableId && isEdit && !hideButton"
          @click="saveBigDataForm"
          size="mini"
          type="primary"
          style="float:right;margin:-5px 10px 0 0;"
        >
          <span style="font-size: 12px">保存</span>
        </el-button>
      </template>
      <el-collapse v-model="showName">
        <!-- 蜂巢有tableId，显示蜂巢属性，商圈不显示 -->
        <el-collapse-item name="1" v-if="tableId">
          <template slot="title">
            蜂巢属性
          </template>
          <el-form
            :model="bigDataForm"
            ref="bigDataForm"
            label-width="100px"
            size="small"
            :rules="basicBigDataCheck"
            class="bigdata-form"
          >
            <el-row>
              <!-- 商圈类型 -->
              <el-col :sm="12" :xs="24">
                <el-form-item
                  label="商圈类型:"
                  prop="taType"
                >
                  <el-select
                    v-if="isEdit"
                    v-model="bigDataForm.taType"
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option
                      v-for="item in taTypeList"
                      :key="item.id"
                      :label="item.type_Name"
                      :value="item.dict_Code"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ myTableData.taType }}</span>
                </el-form-item>
              </el-col>
              <!-- 建议开店类型 -->
              <el-col :sm="12" :xs="24">
                <el-form-item
                  label="建议开店类型:"
                  prop="suggestedStoreType"
                >
                  <el-select
                    v-if="isEdit"
                    v-model="bigDataForm.suggestedStoreType"
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option
                      v-for="item in suggestedStoreTypeList"
                      :key="item.id"
                      :label="item.type_Name"
                      :value="item.dict_Code"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ myTableData.suggestedStoreType }}</span>
                </el-form-item>
              </el-col>
              <!-- 经营模式 -->
              <el-col :sm="12" :xs="24">
                <el-form-item
                  label="经营模式:"
                  prop="managementModel"
                >
                  <el-select
                    v-if="isEdit"
                    v-model="bigDataForm.managementModel"
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option
                      v-for="item in managementModelList"
                      :key="item.id"
                      :label="item.type_Name"
                      :value="item.dict_Code"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ myTableData.managementModel }}</span>
                </el-form-item>
              </el-col>
              <!-- 目标商业 -->
              <el-col :sm="8" :xs="24">
                <el-form-item
                  v-if="(!isEdit && myTableData.suggestedStoreType === '商场店') || bigDataForm.suggestedStoreType * 1 === 140001"
                  label="目标商业:"
                  prop="targetBusiness"
                >
                  <el-input
                    v-if="isEdit"
                    size="mini"
                    style="width: 172px;"
                    v-model="bigDataForm.targetBusiness"
                    placeholder="请输入目标商业"
                  ></el-input>
                  <span v-else>{{ myTableData.targetBusiness }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            人口数据
            <!-- <i class="header-icon el-icon-info"></i> -->
          </template>
          <el-table
            :data="[myTableData]"
            size="mini"
            min-width="50px"
            width="50px"
            style="width: 100%">
            <el-table-column
              prop="homepopu"
              label="居住人口">
            </el-table-column>
            <el-table-column
              prop="workpopu"
              label="工作人口">
            </el-table-column>
            <el-table-column
              prop="totalpopu"
              label="总人口">
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- 蜂巢有tableId，不显示，商圈显示 -->
        <el-collapse-item name="3" v-if="!tableId">
          <template slot="title">
            人口画像
          </template>
          <el-table
            :data="[myTableData]"
            size="mini"
            style="width: 100%">
            <el-table-column
              label="居住人口">
            </el-table-column>
            <el-table-column
              prop="homeportr"
              label="总数">
            </el-table-column>
            <el-table-column
              prop="homemale"
              label="男性">
            </el-table-column>
            <el-table-column
              prop="homefemale"
              label="女性">
            </el-table-column>
            <!-- <el-table-column
              prop="hage_0_19"
              label="<19岁">
            </el-table-column> -->
            <el-table-column
              prop="hage_19_25"
              label="19~25岁">
            </el-table-column>
            <el-table-column
              prop="hage_26_35"
              label="26~35岁">
            </el-table-column>
            <el-table-column
              prop="hage_36_45"
              label="36~45岁">
            </el-table-column>
            <el-table-column
              prop="hage_46_55"
              label="46~55岁">
            </el-table-column>
            <el-table-column
              prop="hage_55_"
              label=">55岁">
            </el-table-column>
            <el-table-column
              prop="hcarpopu"
              label="有车">
            </el-table-column>
            <el-table-column
              prop="hbabypopu"
              label="有孩子">
            </el-table-column>
          </el-table>
          <el-table
            :data="[myTableData]"
            size="mini"
            style="width: 100%">
            <el-table-column
              label="工作人口">
            </el-table-column>
            <el-table-column
              prop="workportr"
              label="总数">
            </el-table-column>
            <el-table-column
              prop="workmale"
              label="男性">
            </el-table-column>
            <el-table-column
              prop="workfemale"
              label="女性">
            </el-table-column>
            <!-- <el-table-column
              prop="wage_0_19"
              label="<19岁">
            </el-table-column> -->
            <el-table-column
              prop="wage_19_25"
              label="19~25岁">
            </el-table-column>
            <el-table-column
              prop="wage_26_35"
              label="26~35岁">
            </el-table-column>
            <el-table-column
              prop="wage_36_45"
              label="36~45岁">
            </el-table-column>
            <el-table-column
              prop="wage_46_55"
              label="46~55岁">
            </el-table-column>
            <el-table-column
              prop="wage_55_"
              label=">55岁">
            </el-table-column>
            <el-table-column
              prop="wcarpopu"
              label="有车">
            </el-table-column>
            <el-table-column
              prop="wbabypopu"
              label="有孩子">
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>

</template>

<script>
//引入api
import api from "../../../api/index.js";
export default {
  name: "MAPTAModel",
  model: {
    prop: 'show',
    event: 'visibleChange'
  },
  props: {
    // 展示权限
    show: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: Number
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: () => ({
        name: '人口人流',
        title: '数据',
        numberTable1: [{
          homepopu: '50000',
          workpopu: '50000',
          totalpopu: '100000',
        }],
        // numberTable1: [{
        //   day: '',
        //   total: '0',
        //   p24_5: '0%',
        //   p5_10: '0%',
        //   p10_14: '0%',
        //   p14_17: '0%',
        //   p17_20: '0%',
        //   p20_24: '0%',
        // }],
        numberTable2: [{
          week: '',
          total: '0',
          p24_5: '0%',
          p5_10: '0%',
          p10_14: '0%',
          p14_17: '0%',
          p17_20: '0%',
          p20_24: '0%',
        }],
        numberTable3: [{
          holiday: '',
          total: '0',
          p24_5: '0%',
          p5_10: '0%',
          p10_14: '0%',
          p14_17: '0%',
          p17_20: '0%',
          p20_24: '0%',
        }],
        peopleTable1: [{
          homeportr: '50000',
          homemale: '20000',
          homefemale: '20000',
          hage_0_19: '5000',
          hage_19_25: '5000',
          hage_26_35: '5000',
          hage_36_45: '5000',
          hage_46_55: '5000',
          hage_55_: '5000',
          hcarpopu: '20000',
          hbabypopu: '20000',
        }],
        peopleTable2: [{
          workportr: '50000',
          workmale: '20000',
          workfemale: '20000',
          wage_0_19: '5000',
          wage_19_25: '5000',
          wage_26_35: '5000',
          wage_36_45: '5000',
          wage_46_55: '5000',
          wage_55_: '5000',
          wcarpopu: '20000',
          wbabypopu: '20000',
        }],
        peopleTable3: [{
          total: '0',
          middle: '',
          college: '',
        }],
        otherTable1: [{
          distance: '',
          car: '80%',
          l1: '',
          l2: '',
          l3: '',
          l4: '',
          l5: '',
        }],
        otherTable2: [{
          distance: '',
          car: '80%',
          l1: '',
          l2: '',
          l3: '',
          l4: '',
          l5: '',
        }],
      })
    }
  },
  data() {
    return {
      isEdit: false,
      myShow: false,
      myTableData: this.tableData || {},
      showName: ['1', '2', '3'],
      bigDataForm: {
        taType: "", // 商圈类型
        suggestedStoreType: "", // 建议开店类型
        managementModel: "", // 经营模式
        targetBusiness: "", // 目标商业
      },
      suggestedStoreTypeList: [], // 建议开店类型列表
      taTypeList: [], // 列表
      managementModelList: [], // 经营模式列表
      basicBigDataCheck: {
        taType: [
          { required: true, message: "请选择商圈类型", trigger: ['change', "submit"] }
        ],
        suggestedStoreType: [
          { required: true, message: "请选择建议开店类型", trigger: ['change', "submit"] }
        ],
        managementModel: [
          { required: true, message: "请选择经营模式", trigger: ['change', "submit"] }
        ],
        targetBusiness: [
          { required: true, message: "请填写目标商业", trigger: ['change', "submit"] }
        ]
      }
    }
  },
  mounted() {
  },
  watch: {
    show(newV, oldV) {
      this.myShow = newV;
    },
    tableData: {
      handler(newV, oldV) {
        // if (!newV) {
        //   return;
        // }
        this.myTableData = newV || {};
        this.taTypeList = this.myTableData.taTypeList || [];
        this.suggestedStoreTypeList = this.myTableData.suggestedStoreTypeList || [];
        this.managementModelList = this.myTableData.managementModelList || [];

        this.bigDataForm.targetBusiness = this.myTableData.targetBusiness || [];

        const taType = this.taTypeList.find(t => this.myTableData.taType === t.type_Name);
        this.bigDataForm.taType = taType ? taType.dict_Code : '';

        const suggestedStoreType = this.suggestedStoreTypeList.find(t => this.myTableData.suggestedStoreType === t.type_Name);
        this.bigDataForm.suggestedStoreType = suggestedStoreType ? suggestedStoreType.dict_Code : '';

        const managementModel = this.managementModelList.find(t => this.myTableData.managementModel === t.type_Name);
        this.bigDataForm.managementModel = managementModel ? managementModel.dict_Code : '';

        this.isEdit = !this.bigDataForm.taType; // 没有值的时候，换编辑状态，有值非编辑状态
        if (this.hideButton) { // url过来的隐藏按钮，且不显示输入框
          this.isEdit = false;
        }
        this.$nextTick(() => {
          this.$refs['bigDataForm']?.clearValidate();
        });
      },
      deep: true
    }
  },
  methods: {
    changeShowFlag() {
      console.log('myTableData.showFlag', this.myTableData.showFlag, this.tableId);
      this.$emit('change-show-flag', {
        showFlag: this.myTableData.showFlag,
        tableId: this.tableId
      })
    },
    // 保存 bigData 数据
    saveBigDataForm() {
      this.$refs["bigDataForm"].validate((valid) => {
        if (valid) {
          api.updateHoneycombStatus({
            id: this.tableId,
            ...this.bigDataForm
          }).then(res => {
              if (res.code == 200) {
                // this.$message({
                //   type: "success",
                //   message: "蜂巢属性熟悉保存成功",
                // });
                // 刷新数据
                this.$emit('data-refresh', {
                  tableId: this.tableId
                });
                this.isEdit = false;
              } else {
                this.$message.error("接口请求报错");
              }
            })
            .catch(err => {
              console.log(err);
              if (err === "Cancelled") {
                this.$message.error("操作已取消");
              } else {
                this.$message.error("接口请求报错");
              }
            });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    }
  }

}
</script>

<style scoped lang="scss">
.tamodel-root {
  &::v-deep .el-dialog__body {
    padding-top: 0;
    .el-collapse-item__header {
      background: #f5f5f5;
      height: 40px;
      padding: 0 5px;
    }
    .el-table__header-wrapper .cell {
      font-weight: bold;
      color: #666;
      p {
        margin: 0;
        text-align: center;
      }
      .p2 {
        font-weight: normal;
      }
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-table {
      .el-table__header, .el-table__body {
        width: auto !important;
      }
      .el-table__cell>.cell {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
    .bigdata-form {
      padding: 10px 0;
      overflow: hidden;
      .el-form-item__label {
        font-size: 11px;
      }
    }
  }
  &::v-deep .el-dialog {
    margin-top: 0 !important;
    max-width: 700px;
  }
}

h3 {
  padding: 0;
  margin: 10px 0;
}
/* 移动端适配 */
@media screen and (max-width: 500px) {
  div.tamodel-root ::v-deep .el-dialog{
    max-width: calc(100vw - 20px);
  }
}
</style>