import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";
import * as turf from "@turf/turf";
import { point } from "@turf/turf";
const fn = {
  install(Vue) {
    /**
     * 面坐标字符串转换为数组
     * str: String "[[],[],[]]"
     * return: Array [[],[],[]]
     */
    Vue.prototype.$arrayStringToArray = function renderMarker(str) {
      var arr = JSON.parse(str);
      // var arr = [];
      // arr = str
      //   .replace("[[", "")
      //   .replace("]]", "")
      //   .split("],[");
      // arr = [
      //   arr.map((a) => {
      //     return a.split(",");
      //   }),
      // ];
      return arr;
    };
    /**
     * 格式化时间
     * fmt: String "yyyy-MM-dd"
     * date: Date new Date()
     */
    Vue.prototype.$Format = function(fmt = "yyyy-MM-dd", date = new Date()) {
      if (!date.getMonth) { // 有可能传入的毫秒数
        date = new Date(date)
      }
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    };
    // 设置数字小数位数
    Vue.prototype.$DecimalPrecision = function(decimal, precisionNum) {
      var precisionMultiply = Math.pow(10, precisionNum || 0);
      var result = decimal && precisionNum ? Math.round(decimal * precisionMultiply) / precisionMultiply : decimal;
      return result;
    };
    //获取公司品牌
    Vue.prototype.$Company = function() {
      var result = [];
      api
        .getCompany({
          cusNo: sessionStorage.cusNo,
        })
        .then((res) => {
          if (res.code == 200) {
            result = res.data;
          }
        });
      return result;
    };
    //获取随机颜色
    Vue.prototype.$Color16 = function() {
      //十六进制颜色随机
      var r = Math.floor(Math.random() * 256);
      var g = Math.floor(Math.random() * 256);
      var b = Math.floor(Math.random() * 256);
      var color = "#" + r.toString(16) + g.toString(16) + b.toString(16);
      return color;
    };
    /**
     * 通过坐标查询省市区信息
     * location: String  "124.33222,32,343433"
     */
    Vue.prototype.$AddressComponent = function(location) {
      let AddressComponent = {
        province: "",
        city: "",
        district: "",
      };
      this.$http
        .get(constants.iMap.GEOURL, {
          params: {
            location,
            batch: false,
            key: constants.iMap.WEBKEY,
            extensions: "all",
            roadlevel: 0,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            var ac = res.data.regeocode.addressComponent;
            AddressComponent.province = ac.province; //省份
            AddressComponent.city = ac.city.length == 0 ? ac.province : ac.city; //市，当为直辖市时
            AddressComponent.district = ac.district; // 区县
            //return AddressComponent;
          }
        });
      return AddressComponent;
    };
    /** 省市区查询
     * JoinCode：上级编码
     * TypeID： 101 省， 102 市， 103 区
     */
    Vue.prototype.$SearchSmartDataarea = function(JoinCode, TypeID) {
      let list = [];
      api
        .searchSmartDataarea({
          JoinCode,
          TypeID,
        })
        .then((res) => {
          if (res.code == 200) {
            list = res.data;
          }
          return list;
        });
    };
    /**拼接URL
     * url: string
     */
    Vue.prototype.$FormatURL = function(url) {
      let resUrl = "";
      if (url) {
        resUrl = constants.baseURL.fileURL + url;
      }
      return resUrl;
    };
    /**
     * 计算多边形面积
     * @param  points  [[[],[]]] 多边形边界点
     */
    Vue.prototype.$CalculationArea = function(points) {
      var polygon = turf.polygon(points);
      var area = turf.area(polygon);
      if (area < 1000) {
        area = Math.round(area) + "m²";
      } else {
        area = (area / 1000000).toFixed(2) + "km²";
      }
      return area;
    };
    /**计算多边形的中心，给多边形添加一个删除按钮
     *
     * @param {多边形边界点} points
     */
    Vue.prototype.$GetCenterOfGravityPoint = function(points) {
      let area = 0.0; //多边形面积
      let Gx = 0.0,
        Gy = 0.0; // 重心的x、y
      for (let i = 1; i <= points.length; i++) {
        let iLat = Number(points[i % points.length][1]);
        let iLng = Number(points[i % points.length][0]);
        let nextLat = Number(points[i - 1][1]);
        let nextLng = Number(points[i - 1][0]);
        let temp = (iLat * nextLng - iLng * nextLat) / 2.0;
        area += temp;
        Gx += (temp * (iLat + nextLat)) / 3.0;
        Gy += (temp * (iLng + nextLng)) / 3.0;
      }
      Gx = Gx / area;
      Gy = Gy / area;
      return [Gy, Gx];
    };
    /**
     * 数据字典-TypeID
     * @param {字典表表ID} typeID
     */
    Vue.prototype.$DataTypeByTypeID = (typeID) => {
      let result = [];
      let loading = false;
      api
        .dataTypeByTypeID({
          typeID,
        })
        .then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            loading = true;
            result = res.data;
          }
          //return result;
        });
      if (loading == true) {
        return result;
      }
    };
    /**
     *
     * @param {Array} list  数据源
     * @param {*} code  需要查询的code
     * @param {String} key  列表的code对应的字段
     * @param {String} value   数据的name对应的字段
     */
    Vue.prototype.$Code2Name = (list, code, key, value) => {
      let result = "";
      if (list == null || list == undefined || list.length.length == 0) {
        return "";
      }
      for (let i = 0; list.length > i; ) {
        let item = list[i];
        if ((item[key] = code)) {
          result = item[value];
          break;
        }
        continue;
      }
      return result;
    };
    // MapApi 坐标系转换
    Vue.prototype.$GPS = {
      PI: Math.PI,
      x_pi: (Math.PI * 3000.0) / 180.0,
      delta: function(lat, lon) {
        var a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
        var ee = 0.006693421622965943; //  ee: 椭球的偏心率。
        var dLat = this.transformLat(lon - 105.0, lat - 35.0);
        var dLon = this.transformLon(lon - 105.0, lat - 35.0);
        var radLat = (lat / 180.0) * this.PI;
        var magic = Math.sin(radLat);
        magic = 1 - ee * magic * magic;
        var sqrtMagic = Math.sqrt(magic);
        dLat =
          (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * this.PI);
        dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * this.PI);
        return { lat: dLat, lon: dLon };
      },

      //WGS-84 to GCJ-02
      gcj_encrypt: function(wgsLat, wgsLon) {
        if (this.outOfChina(wgsLat, wgsLon))
          return { lat: wgsLat, lon: wgsLon };

        var d = this.delta(wgsLat, wgsLon);
        return { lat: wgsLat + d.lat, lon: wgsLon + d.lon };
      },

      //GCJ-02 to WGS-84
      gcj_decrypt: function(gcjLat, gcjLon) {
        if (this.outOfChina(gcjLat, gcjLon))
          return { lat: gcjLat, lon: gcjLon };

        var d = this.delta(gcjLat, gcjLon);
        return { lat: gcjLat - d.lat, lon: gcjLon - d.lon };
      },

      //GCJ-02 to WGS-84 exactly
      gcj_decrypt_exact: function(gcjLat, gcjLon) {
        var initDelta = 0.01;
        var threshold = 0.000000001;
        var dLat = initDelta,
          dLon = initDelta;
        var mLat = gcjLat - dLat,
          mLon = gcjLon - dLon;
        var pLat = gcjLat + dLat,
          pLon = gcjLon + dLon;
        var wgsLat,
          wgsLon,
          i = 0;
        while (1) {
          wgsLat = (mLat + pLat) / 2;
          wgsLon = (mLon + pLon) / 2;
          var tmp = this.gcj_encrypt(wgsLat, wgsLon);
          dLat = tmp.lat - gcjLat;
          dLon = tmp.lon - gcjLon;
          if (Math.abs(dLat) < threshold && Math.abs(dLon) < threshold) break;

          if (dLat > 0) pLat = wgsLat;
          else mLat = wgsLat;
          if (dLon > 0) pLon = wgsLon;
          else mLon = wgsLon;

          if (++i > 10000) break;
        }
        return { lat: wgsLat, lon: wgsLon };
      },

      //GCJ-02 to BD-09
      bd_encrypt: function(gcjLat, gcjLon) {
        var x = gcjLon,
          y = gcjLat;
        var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * this.x_pi);
        var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * this.x_pi);
        bdLon = z * Math.cos(theta) + 0.0065;
        bdLat = z * Math.sin(theta) + 0.006;
        return { lat: bdLat, lon: bdLon };
      },

      //BD-09 to GCJ-02
      bd_decrypt: function(bdLat, bdLon) {
        var x = bdLon - 0.0065,
          y = bdLat - 0.006;
        var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi);
        var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi);
        var gcjLon = z * Math.cos(theta);
        var gcjLat = z * Math.sin(theta);
        return { lat: gcjLat, lon: gcjLon };
      },

      //WGS-84 to Web mercator
      //mercatorLat -> y mercatorLon -> x
      mercator_encrypt: function(wgsLat, wgsLon) {
        var x = (wgsLon * 20037508.34) / 180;
        var y =
          Math.log(Math.tan(((90 + wgsLat) * this.PI) / 360)) / (this.PI / 180);
        y = (y * 20037508.34) / 180;
        return { lat: y, lon: x };
      },

      // Web mercator to WGS-84
      // mercatorLat -> y mercatorLon -> x
      mercator_decrypt: function(mercatorLat, mercatorLon) {
        var x = (mercatorLon / 20037508.34) * 180;
        var y = (mercatorLat / 20037508.34) * 180;
        y =
          (180 / this.PI) *
          (2 * Math.atan(Math.exp((y * this.PI) / 180)) - this.PI / 2);
        return { lat: y, lon: x };
      },

      // two point's distance
      distance: function(latA, lonA, latB, lonB) {
        var earthR = 6371000;
        var x =
          Math.cos((latA * this.PI) / 180) *
          Math.cos((latB * this.PI) / 180) *
          Math.cos(((lonA - lonB) * this.PI) / 180);
        var y =
          Math.sin((latA * this.PI) / 180) * Math.sin((latB * this.PI) / 180);
        var s = x + y;
        if (s > 1) s = 1;
        if (s < -1) s = -1;
        var alpha = Math.acos(s);
        var distance = alpha * earthR;
        return distance;
      },
      outOfChina: function(lat, lon) {
        if (lon < 72.004 || lon > 137.8347) return true;
        if (lat < 0.8293 || lat > 55.8271) return true;
        return false;
      },
      transformLat: function(x, y) {
        var ret =
          -100.0 +
          2.0 * x +
          3.0 * y +
          0.2 * y * y +
          0.1 * x * y +
          0.2 * Math.sqrt(Math.abs(x));
        ret +=
          ((20.0 * Math.sin(6.0 * x * this.PI) +
            20.0 * Math.sin(2.0 * x * this.PI)) *
            2.0) /
          3.0;
        ret +=
          ((20.0 * Math.sin(y * this.PI) +
            40.0 * Math.sin((y / 3.0) * this.PI)) *
            2.0) /
          3.0;
        ret +=
          ((160.0 * Math.sin((y / 12.0) * this.PI) +
            320 * Math.sin((y * this.PI) / 30.0)) *
            2.0) /
          3.0;
        return ret;
      },
      transformLon: function(x, y) {
        var ret =
          300.0 +
          x +
          2.0 * y +
          0.1 * x * x +
          0.1 * x * y +
          0.1 * Math.sqrt(Math.abs(x));
        ret +=
          ((20.0 * Math.sin(6.0 * x * this.PI) +
            20.0 * Math.sin(2.0 * x * this.PI)) *
            2.0) /
          3.0;
        ret +=
          ((20.0 * Math.sin(x * this.PI) +
            40.0 * Math.sin((x / 3.0) * this.PI)) *
            2.0) /
          3.0;
        ret +=
          ((150.0 * Math.sin((x / 12.0) * this.PI) +
            300.0 * Math.sin((x / 30.0) * this.PI)) *
            2.0) /
          3.0;
        return ret;
      },
    };

    // 颜色HEX => RGBA
    Vue.prototype.$hex2rgba = function(hex, alpha = 1) {
      let r = 0, g = 0, b = 0;
      
      // 3 digits
      if (hex.length == 4) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
      // 6 digits
      } else if (hex.length == 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
      }
      
      return "rgba(" + +r + "," + +g + "," + +b + "," + alpha + ")";
    }
  },
};

export default fn;
