import Vue from "vue";
import Vuex from "vuex";
import user from './modules/user';
import {
  Loading
} from "element-ui";
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
import {
  PMStoreIndex,
  MapVSDetail,
  MapTADetail
} from '../postMessage/store/index'
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    map: "", //地图
    markerType: [{
      type: "3",
      name: "门店"
    }],
    newMarkerType: "",
    screenWidth: "", //屏幕宽度
    screenHeight: "", //屏幕高度
    isMapOrList: true, //是否在地图或者列表模式的首页
    sItem: {
      sType: "", //地图搜索 专用
      sLng: "",
      sLat: "",
      name: ""
    },
    mapCommand: "", //当前地图左边菜单选择的值
    //请求计数
    apiCount: 0,
    //loading实例
    loadingInstance: null,
    markerList: [], //Marker点
    infoList: [], //点数据
    vsMarkerList: [], //竞品，有多个竞品
    loadingMap: false, // 地图loading效果控制
    recList: [], // 机会推荐列表
    recVisible: false, //机会推荐列表显示控制
    recInfoVisible: false, //机会推荐商圈详情显示控制
    recInfo: {},
    mapType: "",
    currentCity: "",
    repVisible: false, //评估报告弹框
    isoType: "walking", //等时圈交通类型
    isoTime: "10", // 等时圈时间 单位min
    bufferDistance: 0.5, //缓冲区距离 单位KM
    isDrawPolygon: false, // 是否在绘制多边形
    drawPolygonType: "", // 绘制的多边形类型 report：查看商圈评估报告，ta：创建商圈
    location: {
      data: {},
      type: ""
    }, // 从list列表快速定位到地图上位置
    userInfo: {},
    layerList: [],
    themeColor: "#0188e2", // 初试主题色
    overLapTaId:[], // 点击区域所有重叠商圈的id值
    isMobile: false, // 是否是移动端
    honeycombId: false, // 蜂巢id
    siteId: false, // 机会点id
    siteIdType: '', // 机会点id类型：2 详情   1 审核
    footPrintId: false, // 足迹ID
    footPrintLongitude: '', // 足迹经度
    footPrintLatitude: '', // 足迹纬度
  },
  mutations: {
    /* 打开Loading */
    startLoading(state) {
      state.loadingInstance = Loading.service({
        fullscreen: true
      });
    },
    startLoading2(state) {
      state.loadingInstance = Loading.service({
        lock: true,
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.5)'
      });
    },
    /* 关闭loading */
    closeLoading(state) {
      state.loadingInstance.close();
    },
    /* 更新请求线程池 */
    updateApiCount(state, handle) {
      if (handle == "add") {
        state.apiCount++;
        this.commit("startLoading");
      } else {
        state.apiCount--;
        if (state.apiCount <= 0) {
          this.commit("closeLoading");
        }
      }
    },
    /* 更新移动端判断状态 */
    updateIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    /* 更新蜂巢ID */
    updateHoenycombId(state, honeycombId) {
      state.honeycombId = honeycombId;
    },
    /* 更新机会点ID */
    updateSiteId(state, siteId) {
      state.siteId = siteId;
    },
    /* 更新机会点ID类型 */
    updateSiteIdType(state, siteIdType) {
      state.siteIdType = siteIdType;
    },
    /* 更新足迹ID */
    updateFootPrintId(state, {footPrintId, longitude, latitude}) {
      state.footPrintId = footPrintId;
      state.footPrintLongitude = longitude;
      state.footPrintLatitude = latitude;
    },
  },
  actions: {},
  modules: {
    user,
    PMStoreIndex,
    MapVSDetail,
    MapTADetail
  },
  M_THEME_COLOR: ""
});