<template>
  <el-container class="list-container">
    <router-view />
  </el-container>
</template>
<script>
export default {
  data(){
    return {
      
    }
  }
}
</script>
<style>
.list-container{
background-color:#eee;
padding: 5px;
}
</style>