/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Map from "../views/Map.vue";
import List from "../views/List.vue";
import List2 from "../views/List2.vue";
import PersonalInfo from "../views/PersonalInfo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: Home,
    //  redirect: "/home",
    meta: {
      requireAuth: true, // 判断是否需要登录
    },
    children: [
      {
        path: "/",
        name: "Map",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: Map,
      },
      {
        path: "/planning/map",
        name: "Map",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: Map,
      },
      {
        path: "/Map",
        name: "Map",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: Map,
      },
      {
        path: "/Map2",
        name: "Map2",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: Map,
      },
      {
        path: "pipeline",
        name: "pipeline",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/List/pipeline.vue"),
      },
      {
        path: "store",
        name: "store",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/List/store.vue"),
      },
      {
        path: "competitor",
        name: "competitor",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/List/competitor.vue"),
      },
      {
        path: "ta",
        name: "ta",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/List/ta.vue"),
      },
      {
        path: "/List2",
        name: "List2",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: List2,
        children: [
          {
            path: "",
            name: "list",
            meta: {
              requireAuth: true, // 判断是否需要登录
            },
            component: List,
          },
          {
            path: "pipeline",
            name: "pipeline",
            meta: {
              requireAuth: true, // 判断是否需要登录
            },
            component: () => import("../components/List/pipeline.vue"),
          },
          {
            path: "store",
            name: "store",
            meta: {
              requireAuth: true, // 判断是否需要登录
            },
            component: () => import("../components/List/store.vue"),
          },
          {
            path: "competitor",
            name: "competitor",
            meta: {
              requireAuth: true, // 判断是否需要登录
            },
            component: () => import("../components/List/competitor.vue"),
          },
        ],
      },
      {
        path: "/personalInfo",
        name: "personalInfo",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: PersonalInfo,
      },
      {
        path: "/ManagementCockpit",
        name: "ManagementCockpit",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../views/ManagementCockpit.vue"),
      },
    ],
  },
  {
    path: "/List",
    name: "List",
    meta: {
      requireAuth: true, // 判断是否需要登录
    },
    component: List,
    children: [
      {
        path: "",
        name: "list",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: List,
      },
      {
        path: "pipeline",
        name: "pipeline",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/List/pipeline.vue"),
      },
      {
        path: "store",
        name: "store",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/List/store.vue"),
      },
      {
        path: "competitor",
        name: "competitor",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/List/competitor.vue"),
      },
      {
        path: "ta",
        name: "ta",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/List/ta.vue"),
      },
    ],
  },
  {
    path: "/report/:info",
    name: "report",
    title: "",
    meta: {
      requireAuth: true, // 判断是否需要登录
    },
    component: () => import("../views/AnalysisReport.vue"),
  },
  {
    path: "/system",
    name: "system",
    meta: {
      requireAuth: true, // 判断是否需要登录
    },
    component: () => import("../views/System.vue"),
    children: [
      {
        path: "",
        name: "companyMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/CompanyMaintain.vue"),
      },
      {
        path: "companyMaintain",
        name: "companyMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/CompanyMaintain.vue"),
      },
      {
        path: "brandMaintain",
        name: "brandMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/BrandMaintain.vue"),
      },
      {
        path: "userMaintain",
        name: "userMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/UserMaintain.vue"),
      },
      {
        path: "dataMaintain",
        name: "dataMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/DataMaintain.vue"),
      },
      {
        path: "fieldMaintain",
        name: "fieldMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/FieldMaintain.vue"),
      },
      {
        path: "layerMaintain",
        name: "layerMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/LayerMaintain.vue"),
      },
      {
        path: "cityMaintain",
        name: "cityMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/CityMaintain.vue"),
      },
      {
        path: "compititerMaintain",
        name: "compititerMaintain",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/CompititerMaintain.vue"),
      },
      {
        path: "otherApplication",
        name: "otherApplication",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/OtherApplication.vue"),
      },
      {
        path: "reportStatistics",
        name: "reportStatistics",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/ReportStatistics.vue"),
      },
      {
        path: "logStatistics",
        name: "logStatistics",
        meta: {
          requireAuth: true, // 判断是否需要登录
        },
        component: () => import("../components/System/LogStatistics.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "error",
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/LinkError",
    name: "linkerror",
    component: () => import("../views/LinkError.vue"),
  },
];
const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
