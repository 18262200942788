import geoHash from "../assets/lib/geohash.js";
import * as turf from "@turf/turf";
var geoHashList = (points,len = 7) => {
    var list = [];
    var boundingBox = getBoundingBox(points[0]);
    var BpolygonPoints = [[
        [boundingBox.xMin, boundingBox.yMin],
        [boundingBox.xMin, boundingBox.yMax],
        [boundingBox.xMax, boundingBox.yMax],
        [boundingBox.xMax, boundingBox.yMin],
        [boundingBox.xMin, boundingBox.yMin],
    ]];
    var firsthash, geohash = "";
    firsthash = geohash = geoHash.encodeGeoHash(boundingBox.yMin, boundingBox.xMin).substr(0, len);
    var geohashPathPoints = getGeohashPathPoints(geohash);
    var inRing = checkRingRingClip(geohashPathPoints, BpolygonPoints);
    while(inRing)
    {
        while(inRing)
        {
            geohash = geoHash.calculateAdjacent(geohash, "right");
            geohashPathPoints = getGeohashPathPoints(geohash);
            inRing = checkRingRingClip(geohashPathPoints, BpolygonPoints);
            isInTarget(geohash, geohashPathPoints, points, list);
        }
        firsthash = geohash = geoHash.calculateAdjacent(firsthash, "top");
        geohashPathPoints = getGeohashPathPoints(geohash);
        inRing = checkRingRingClip(geohashPathPoints, BpolygonPoints);
        isInTarget(geohash, geohashPathPoints, points, list);
    }
    return list;
}
// 多边形的外接矩形
var getBoundingBox = (data) => {
    var bounds = {}, latitude, longitude;
    for (var j = 0; j < data.length; j++) {
        longitude = Number(data[j][0]);
        latitude = Number(data[j][1]);
        bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
        bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
        bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
        bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
    }
    return bounds;
}
// hash小格子的四个边界点
var getGeohashPathPoints = (data)  => {
    var decodeGeo = geoHash.decodeGeoHash(data);
    var polylinePoints = [
         [decodeGeo.longitude[0], decodeGeo.latitude[0]],
        [decodeGeo.longitude[1], decodeGeo.latitude[0]],
        [decodeGeo.longitude[1], decodeGeo.latitude[1]],
        [decodeGeo.longitude[0], decodeGeo.latitude[1]],
        [decodeGeo.longitude[0], decodeGeo.latitude[0]]
     ];
     return polylinePoints;
}
//判断多边形1是否和多边形2有重叠
var checkRingRingClip = (polygon1, polygon2)  => {
    var flag = false;
    polygon1.map(point => {
        let pt = turf.point(point);
        let poly = turf.polygon(polygon2);
        if(turf.booleanPointInPolygon(pt, poly)) {
            flag = true;
        }
    });
    return flag;
}
var isInTarget = (geohash, geohashPathPoints, polygonPoints, list)  => {
    if (checkRingRingClip(geohashPathPoints, polygonPoints))
    {
        list.push(geohash);
    }
}

export default {
    getBoundingBox, getGeohashPathPoints, checkRingRingClip, isInTarget, geoHashList
};