<template>
  <div class="mapsearch1-container">
    <div class="mapsearch1-content">
      <el-row>
        <div class="search-typelist">
          <label
            class="search-typelabel"
            v-for="(item, index) in Options"
            :key="index"
            @click="selectItem(item)"
            :class="[searchType.name == item.name ? 'search-is-checked' : '']"
          >
            <span>{{ item.name }}</span>
          </label>
        </div>
        <div class="search-dropdown">
          <el-dropdown trigger="click" @command="selectDropItem">
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu
              slot="dropdown"
              style="max-height: 200px; overflow-y: auto"
            >
              <el-dropdown-item
                v-for="(item, index) in dropOptions"
                :key="index"
                :command="item"
              >
                <span style="font-size: 11px">{{
                  item.name
                }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-row>
      <!--  地址 -->
      <el-row style="padding-top: 10px" v-if="searchType.name == '地址'">
        <el-autocomplete
          style="width: 100%"
          popper-class="my-autocomplete search"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
          id="search"
        >
          <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
          <template slot-scope="{ item }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.cnname"
              placement="right-start"
              v-if="(item.cnname && item.cnname.length >= 28) && !isMobile"
            >
              <div class="name">{{ item.cnname }}</div>
            </el-tooltip>
            <div class="name name-all" v-else>{{ item.cnname }}</div>
          </template>
        </el-autocomplete>
      </el-row>
      <!-- 机会点 -->
      <el-row style="padding-top: 10px" v-else-if="searchType.name == '机会点'">
        <el-autocomplete
          style="width: 100%"
          popper-class="my-autocomplete"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
          <template slot-scope="{ item }">
            <el-image
              :src="ppUrl"
              style="width: 20px; top: 8px; position: relative"
            ></el-image>
            <div
              style="
                display: inline-block;
                margin-left: 10px;
                top: -10px;
                position: relative;
              "
            >
              <span class="addr">{{ item.name | ellipsisName }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-row>
      <!-- 门店 -->
      <el-row style="padding-top: 10px" v-else-if="searchType.name == '门店'">
        <el-autocomplete
          style="width: 100%"
          popper-class="my-autocomplete"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
          <template slot-scope="{ item }">
            <el-image
              :src="storeUrl"
              style="width: 20px; top: 8px; position: relative"
            ></el-image>
            <div
              style="
                display: inline-block;
                margin-left: 10px;
                top: -10px;
                position: relative;
              "
            >
              <div class="name" style="margin-bottom: -14px">
                {{ item.name | ellipsisName }}
              </div>
            </div>
          </template>
        </el-autocomplete>
      </el-row>
      <!-- 蜂巢 -->
      <el-row style="padding-top: 10px" v-else-if="searchType.name == '蜂巢'">
        <el-autocomplete
          style="width: 100%"
          popper-class="my-autocomplete"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
          <template slot-scope="{ item }">
            <div
              style="
                display: inline-block;
                margin-left: 10px;
                top: -10px;
                position: relative;
              "
            >
              <div class="name" style="margin-bottom: -14px">
                {{ item.id | ellipsisName }}
              </div>
            </div>
          </template>
        </el-autocomplete>
      </el-row>
      <!-- 商圈 -->
      <el-row style="padding-top: 10px" v-else-if="searchType.name == '商圈'">
        <el-autocomplete
          style="width: 100%"
          popper-class="my-autocomplete"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
          <template slot-scope="{ item }">
            <el-image
              :src="taUrl"
              style="width: 20px; top: 8px; position: relative"
            ></el-image>
            <div
              style="
                display: inline-block;
                margin-left: 10px;
                top: -10px;
                position: relative;
              "
            >
              <div class="name" style="margin-bottom: -14px">
                {{ item.name | ellipsisName }}
              </div>
              <span class="addr">{{ item.address | ellipsisAddress }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-row>
      <!-- 竞品 -->
      <el-row style="padding-top: 10px" v-else-if="searchType.type == '1'">
        <el-autocomplete
          style="width: 100%"
          popper-class="my-autocomplete"
          v-model="state"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
        >
          <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick"
          >
          </i>
          <template slot-scope="{ item }">
            <el-image
              :src="vsUrl"
              style="width: 20px; top: 8px; position: relative"
            ></el-image>
            <div
              style="
                display: inline-block;
                margin-left: 10px;
                top: -10px;
                position: relative;
              "
            >
              <div class="name" style="margin-bottom: -14px">
                {{ item.name | ellipsisName }}
              </div>
            </div>
          </template>
        </el-autocomplete>
      </el-row>
    </div>
  </div>
</template>
<script>
// import CheckBoxSingle from "@/components/CheckBoxSingle.vue";
import ppImg from "@/assets/images/marker/search-jihuidian.png";
import storeImg from "@/assets/images/marker/search-mendian.png";
import taImg from "@/assets/images/marker/search-shangquan.png";
import vsImg from "@/assets/images/marker/search-jingpin.png";
import api from "../../api/index";
import constants from "@/constants/Map.constants.js";
export default {
  name: "MapSearch",
  props: ["isShow"],
  // components: {
  //   CheckBoxSingle,
  // },
  filters: {
    ellipsisName(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },
    ellipsisAddress(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 15) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      styleObj: {},
      property: {},
      restaurants: [],
      state: "",
      isMapShow: false,
      Options: [
        {
          name: "地址",
          type: "0",
        },
        {
          name: "机会点",
          type: "2",
        },
        {
          name: "门店",
          type: "3",
        },
        {
          name: "蜂巢",
          type: "11",
        },
        {
          name: "商圈",
          type: "4",
        },
      ],
      searchType: {
        name: "地址",
        type: "0",
      },
      dropOptions: [
        {
          name: "商圈",
          type: "4",
        },
      ],
      ppUrl: ppImg,
      storeUrl: storeImg,
      taUrl: taImg,
      vsUrl: vsImg,
      show: false,
      amap: null,
      defaultOpt: {
        name: "地址",
        type: "0",
      },
      isMobile: !!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    };
  },
  methods: {
    //获取竞品品牌
    getCompetitorBrand() {
      api
        .getCompetitorBrand({
          cusNo: sessionStorage.cusNo,
        })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            if (res.data.length > 0) {
              res.data.map((m) => {
                var item = {
                  type: "1",
                  brandid: m.dict_Code,
                };
                item.name =
                  this.$i18n.locale == "zh"
                    ? "竞品-" + m.type_Name
                    : "竞品-" + m.type_Enname; //选择语言
                this.dropOptions.push(item); // 将竞品插入在指定位置
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //调用查询接口
    getSearchList() {
      var nw = this.$store.state.map.getBounds().getNorthWest();
      var se = this.$store.state.map.getBounds().getSouthEast();
      var ne = this.$store.state.map.getBounds().getNorthEast();
      var sw = this.$store.state.map.getBounds().getSouthWest();
      var latitude, minimumLatitude, longitude, minimumLongitude;
      latitude = Math.max(nw.lat, se.lat);
      minimumLatitude = Math.min(nw.lat, se.lat);
      longitude = Math.max(nw.lng, se.lng);
      minimumLongitude = Math.min(nw.lng, se.lng);
      let options = {
        latitude,
        minimumLatitude,
        longitude,
        minimumLongitude,
        name: this.state,
        type: `${this.searchType.type}`,
        cusNo: sessionStorage.cusNo,
        geometry: `[[${nw.lng},${nw.lat}],[${ne.lng},${ne.lat}],[${se.lng},${se.lat}],[${sw.lng},${sw.lat}]]`,
        city: sessionStorage.currentCity,
        // positionID:this.$store.state.PMStoreIndex.positionID
        positionID:sessionStorage.renderPositionId
      };
      if (this.searchType.type == "1") {
        //竞品品牌code
        options.brandId = this.searchType.code;
      }
      api
        .renderMarker(options)
        .then((res) => {
          if (res.code == 200) {
            // console.log(res);
            this.restaurants = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    querySearch(queryString, cb) {
      if (queryString != "" && queryString != undefined) {
        // this.getSearchList();
        // if (["地址", "蜂巢"].indexOf(this.searchType.name) === -1) {
        if (["地址"].indexOf(this.searchType.name) === -1) {
          var nw = this.$store.state.map.getBounds().getNorthWest();
          var se = this.$store.state.map.getBounds().getSouthEast();
          var ne = this.$store.state.map.getBounds().getNorthEast();
          var sw = this.$store.state.map.getBounds().getSouthWest();
          var latitude, minimumLatitude, longitude, minimumLongitude;
          latitude = Math.max(nw.lat, se.lat);
          minimumLatitude = Math.min(nw.lat, se.lat);
          longitude = Math.max(nw.lng, se.lng);
          minimumLongitude = Math.min(nw.lng, se.lng);
          let options = {
            latitude,
            minimumLatitude,
            longitude,
            minimumLongitude,
            name: this.state,
            type: `${this.searchType.type}`,
            cusNo: sessionStorage.cusNo,
            geometry: `[[${nw.lng},${nw.lat}],[${ne.lng},${ne.lat}],[${se.lng},${se.lat}],[${sw.lng},${sw.lat}]]`,
            city: sessionStorage.currentCity,
            // positionID:this.$store.state.PMStoreIndex.positionID
            positionID:sessionStorage.renderPositionId
          };
          if (this.searchType.type == "1") {
            //竞品品牌code
            options.brandId = this.searchType.brandid;
          }
          api
            .renderMarker(options)
            .then((res) => {
              if (res.code == 200) {
                this.restaurants = res.data;
                console.log('this.restaurants: ', this.restaurants);
                cb(this.restaurants);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (this.searchType.name === "地址") {
          this.searchAddress(queryString, cb);
        } else if (this.searchType.name === "蜂巢") {
          this.searchHoneycomb(queryString, cb);
        }
      } else {
        cb([]);
      }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.sitename
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      // console.log(item);
      this.$store.state.sItem = {
        type: this.searchType.type,
        val: item,
      };

      if (this.searchType.name == '蜂巢') {
        return this.state = item.id + '';
      }
      this.state = item.cnname || item.storename || item.name;
    },
    handleIconClick(ev) {
      // console.log(ev);
    },
    closeMapSearch() {
      this.show = false;
    },
    selectItem(value) {
      this.searchType = value;
    },
    //下来选择最后一个选项
    selectDropItem(value) {
      this.$set(this.Options, 4, value);
      this.selectItem(value);
    },
    searchAddress(queryString, cb) {
      var that = this;
      var nw = this.$store.state.map.getBounds().getNorthWest();
      var se = this.$store.state.map.getBounds().getSouthEast();
      var ne = this.$store.state.map.getBounds().getNorthEast();
      var sw = this.$store.state.map.getBounds().getSouthWest();
      var latitude, minimumLatitude, longitude, minimumLongitude;
      latitude = Math.max(nw.lat, se.lat);
      minimumLatitude = Math.min(nw.lat, se.lat);
      longitude = Math.max(nw.lng, se.lng);
      minimumLongitude = Math.min(nw.lng, se.lng);
      this.$http
        .get(constants.iMap.INPUTTIPSURL, {
          params: {
            keywords: queryString,
            location: `${(longitude + minimumLongitude) / 2},${
              (latitude + minimumLatitude) / 2
            }`,
            datatype: "all",
            key: constants.iMap.WEBKEY,
          },
        })
        .then((res) => {
          var list = this.formatSearchTips(res.data.tips);
          cb(list);
        });
    },
    //格式化地址搜索热词
    formatSearchTips(tips) {
      let newTips = [];
      tips.map((e) => {
        if (e.id.length > 0 && !!e.location) {
          newTips.push({
            address: e.district + e.address,
            longitude: e.location.split(",")[0],
            latitude: e.location.split(",")[1],
            cnname: e.district + e.address + ` - ${e.name}`,
          });
        }
      });
      return newTips;
    },
    // 搜索蜂巢图
    searchHoneycomb(queryString, cb) {
      api.getHoneycomb({
        cityName: '上海市', // TEST
        showFlag: 1
      }).then(res => {
        console.log('honeycomb res =>', res)
        if (res.code == 0 || res.code == 200) {
          let data;
          data = res.data.map(item => {
            var location = JSON.parse(item.location);
            return {
              code: item.id,
              // name: item.cnName + ' 编号：' + item.id,
              name: item.id,
              competitorNum: item.competitorNum,
              storeNum: item.storeNum,
              longitude: location[0][0],
              latitude: location[0][1],
              geometry: item.location
            }
          });
          cb(data);
        }
      })
    }
  },
  mounted() {
    this.styleObj = {
      width: "20%", //(this.$store.state.screenWidth-240) + "px"
    };
    this.getCompetitorBrand();
  },
  watch: {
    "$store.state.screenWidth": function (value) {
      this.styleObj = {
        width: "20%", //(value - 240) + "px"
      };
    },
    show(val) {
      if (!val) this.$emit("updata-type", val);
    },
    isShow(val) {
      this.show = val;
      if (val && this.Options.length <= 4) {
        this.getCompetitorBrand();
      }
    },
    "$store.state.isMobile"(val) {
      this.isMobile = val
    }
  },
};
</script>
<style>
.mapsearch1-title {
  background: #eee;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 5px 10px 5px 20px;
}
.mapsearch1-close {
  float: right;
  cursor: pointer;
}

.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.my-autocomplete li .name {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
}
.my-autocomplete li .addr {
  font-size: 11px;
  color: #b4b4b4;
}
.my-autocomplete li .highlighted .addr {
  color: #ddd;
}
.my-autocomplete li .name-all {
  text-overflow: unset;
  overflow: auto;
  font-size: 13px;
  white-space: pre-wrap;
}
.search-typelist {
  width: 92%;
  display: inline-block;
  /* border-bottom: 1px solid #ddd; */
}
.search-typelabel {
  font-size: 12px;
  width: 19%;
  /* width: 48%; */
  display: inline-block;
  height: 32px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.search-typelabel:nth-child(5) {
  min-width: 24%;
  width: auto;
}
.search-typelabel:hover {
  color: #0188e2;
}
.search-is-checked {
  color: #0188e2;
  border-bottom: 1px solid #0188e2;
}
.search-typelabel span {
  width: 100%;
  text-align: center;
  display: inline-block;
  border-right: 1px solid #ddd;
}
.search-dropdown {
  display: inline-block;
}
.search-dropdown .el-dropdown-menu.el-popper {
  max-height: 200px;
  overflow-y: auto;
}
.search-dropdown .el-dropdown-menu.el-popper li {
  font-size: 11px;
}
</style>