<template>
  <div class="tamodel-root">
    <el-dialog
      :visible.sync="myShow"
      width="70%"
      @close="$emit('visibleChange',false)">
      <template v-slot:title>
        <span style="font-weight: bold;color: #02283f">
          <span v-show="!!myTableData.name">{{ myTableData.name }} / </span>
          <span v-show="!!myTableData.title">{{ myTableData.title }}</span>
        </span>
      </template>
      <table v-if="Array.isArray(myTableData.body) && myTableData.body.length > 0">
        <thead>
        <tr>
          <th>大店类型</th>
          <th>名称</th>
          <th>锐力门店(含加盟)</th>
          <th>竞品</th>
          <th>进行中开店</th>
          <th>剩余机会点{{ myTableData.opportunitySum ? `(合计:${myTableData.opportunitySum})` : '' }}</th>
          <th>参考数量</th>
          <th>子品牌</th>
          <th>门店名称</th>
          <th>门店数量</th>
        </tr>
        </thead>
        <tbody>
        <!-- 行 -->
        <tr v-for="(e, i) in myTableData.body" :key="i" :style="{background:e.rowBgColor}">
          <!-- 大店类型 -->
          <td
            :rowspan="e.bigTypeRowSpan"
            v-if="e.bigTypeRowSpan != -1"
          >
            {{ e.bigTypeName }}
          </td>
          <!-- 名称 -->
          <td
            :rowspan="e.nameRowSpan"
            v-if="e.nameRowSpan != -1"
          >
            {{ e.name }}
          </td>
          <!-- 锐力门店(含加盟) -->
          <td
            :rowspan="e.realityRowSpan"
            v-if="e.realityRowSpan != -1"
          >
            {{ e.reality }}
          </td>
          <!-- 竞品 -->
          <td
            :rowspan="e.competitorRowSpan"
            v-if="e.competitorRowSpan != -1"
          >
            {{ e.competitor }}
          </td>
          <!-- 进行中开店 -->
          <td
            :rowspan="e.ongoingRowSpan"
            v-if="e.ongoingRowSpan != -1"
          >
            {{ e.ongoing }}
          </td>
          <!-- 剩余机会点 -->
          <td
            :rowspan="e.opportunityRowSpan"
            v-if="e.opportunityRowSpan != -1"
            :style="{color:(e.opportunity != 0 && !!e.opportunity) ? '#f70309' : '#464646'}"
          >
            {{ e.opportunity }}
          </td>
          <!-- 参考数量 -->
          <td
            :rowspan="e.referenceRowSpan"
            v-if="e.referenceRowSpan != -1"
          >
            {{ e.reference }}
          </td>
          <!-- 子品牌 -->
          <td>{{ e.storeType }}</td>
          <!-- 门店名称 -->
          <td>{{ e.storeName }}</td>
          <!-- 门店数量 -->
          <td>{{ e.stroeNumber }}</td>
        </tr>
        </tbody>
      </table>
      <h4 v-else style="text-align: center">暂无数据</h4>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "MAPTAModel",
  model: {
    prop: 'show',
    event: 'visibleChange'
  },
  props: {
    // 展示权限
    show: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: () => ({
        title: '',
        body: []
      })
    }
  },
  data() {
    return {
      myShow: false,
      myTableData: this.tableData,
    }
  },
  mounted() {
  },
  watch: {
    show(newV, oldV) {
      this.myShow = newV;
    },
    tableData: {
      handler(newV, oldV) {
        this.myTableData = newV;
      },
      deep: true
    }
  }

}
</script>

<style scoped lang="scss">
.tamodel-root {
  &::v-deep .el-dialog__body {
    padding-top: 0;
  }
}

h3 {
  padding: 0;
  margin: 10px 0;
}


table {
  margin-top: 0.2rem;
  width: 100%;
  border-color: rgb(216, 216, 216);

  thead {
    border-bottom: 0;

    th {
      font-size: 0.2692rem;
      font-weight: 600;
      color: rgb(54, 118, 129);
      height: 0.6rem;
      text-align: center;
      background: #e7e6e6;
    }
  }

  td {
    font-size: 0.25rem;
    font-family: Inter, Roboto, Oxygen, Fira Sans, Helvetica Neue, sans-serif;
    font-weight: 600;
    border-right: 0;
    height: 0.6rem;
    text-align: center;
    color: rgb(70, 70, 70);
    border: 1px solid #ebeef5;
  }
}
</style>