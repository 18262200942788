<template>

  <el-container style="height: 100%;">
    <el-main style="padding:0">
      <mapbox mapWidth="100%" :mapHeight="mapHeight"></mapbox>
    </el-main>
  </el-container>
</template>
<script>
import mapbox from "../components/MapBox/MapBox.vue"; //MapBox
export default {
  components: {
    mapbox
  },
  data(){
    return {
      map: null,
      mapHeight: "",
      isHide: "0"
    };
  },
  mounted() {
      this.isHide = sessionStorage.isHide;
  },
  watch:{
    "$store.state.screenHeight":function(value){
        this.mapHeight = (value- (this.isHide=="0"?50: 0)) + "px";
      }
  }
}
</script>