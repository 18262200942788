<template>
    <div class="mapsearch2-container">
        <div class="mapsearch2-content">
            <el-row v-for="(item, index) in Options" :key="index"  class="mapsearch2-item" :class="[markerList.name==item.name?'marker-isChecked':'']">
              <div @click="changeMarkerType(item)">
                <img :src="item.url">
                <span>{{ item.name }}</span>
              </div>
            </el-row>
        </div>
    </div>
</template>
<script>
// import CheckBoxSingle from "@/components/CheckBoxSingle.vue";
import api from "../../api/index";
import ppImage from "../../assets/images/marker/jihuidian.png";
import storeImage from "../../assets/images/marker/红.png";
import comImage from "../../assets/images/marker/com.png";
import rectImage from "../../assets/images/marker/TA.png";
import footprintImage from "../../assets/images/marker/footprint.png"; // 足迹

//常量
import constants from "@/constants/Map.constants.js";
export default {
    name: "MapMark",
    props: ["isShow"],
    components:{
      // CheckBoxSingle
    },
    data() {
      return {
        checkboxGroup1: [],
        isMapShow: false,
        Options: [
          {
            name: "机会点",
            type: "2",
            // url: constants.baseURL.API+sessionStorage.siteIcon//ppImage
            url: ppImage
          },
          // {
          //   name: "门店",
          //   type: "3",
          //   url: constants.baseURL.API+sessionStorage.storeIcon
          // },
          // {
          //   name:"商圈",
          //   type: "4",
          //   url: rectImage
          // },
          {
            name:"足迹",
            type: "5",
            url: footprintImage
          },
          {
            name:"竞品",
            type: "1",
            url: comImage
          }
        ],
        defaultOpt: {
          name: "机会点",
          type: "2"
        },
        markerList: "",
        show: false
      };
    },
    methods: {
      //获取竞品品牌
      getCompetitorBrand(){
        api.getCompetitorBrand({
                cusNo: sessionStorage.cusNo
        }).then(res => {
          if(res.code == 200 || res.code == 0){
            if(res.data.length > 0){
              res.data.map((m) => {
                var item = {
                  type: "1",
                  brandid: m.dict_Code
                };
                item.name = this.$i18n.locale == "zh"?m.type_Name : m.type_Enname; //选择语言
               // this.Options.push(item); // 将竞品插入在指定位置
              });
            }
          }
        }).catch(err => {
          console.log(err);
        });
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      // closeMapSearch(){
      //     this.show = false;
      //     this.$store.state.newMarkerType = "";
      // },
      changeMarkerType(val){
        this.markerList = val;
        this.$store.state.newMarkerType = val;
      },
      resetMarker() {
        this.markerList = '';
        this.$store.state.newMarkerType = '';
      }
    },
    mounted() {
      this.$bus.$on('resetMarker',this.resetMarker);
      //this.getCompetitorBrand();
    },
    watch:{
      //弹框时获取数值
      isShow(val){
        if(val == true){
          var that = this;
          setTimeout(() => {
            that.markerList = that.$refs.markerList.selectedList;
          }, 0);
        }
        this.show = val;
      },

      show(val) {
        this.$emit("updata-type", val);
      }
    }
}
</script>
<style>

.mapsearch2-title{
    background: #eee;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 5px 10px 5px 20px;
}
.mapsearch2-close{
    float: right;
    cursor: pointer;
}
.el-checkbox__label{
  width: 50px;
  font-size: 12px;
}
.el-checkbox-button__inner{
  font-size: 12px;
}
.mapsearch2-content span {
  font-size: 12px;
  padding-left: 10px;
  line-height: 3;
}
.mapsearch2-content img {
  width: 20px;
  vertical-align: middle;
}
.mapsearch2-item {
  cursor: pointer;
}
.mapsearch2-item:hover {
  background: #eee;
  color: #0188e2
}
.marker-isChecked {
  color: #0188e2
}

</style>
