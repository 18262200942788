<template>
  <div
    v-if="visible"
    class="pp-Detail"
    :class="{ rootWidth: isIframe && isEdit }"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(197, 197, 197, 0.6)"
  >
    <div class="pp-title">
      <el-row>
        <el-col :sm="18" :xs="18">
          <strong v-if="type == 'vs'">竞品详情</strong>
          <strong v-else-if="type == 'pp'">机会项目详情</strong>
          <strong v-else>门店详情</strong>

          <span v-if="type == 'pp' && ruleForm.approvalProtectStatus === 'open'" style="font-size:14px; padding: 2px 0 0 5px;">-</span>
          <span v-if="type == 'pp' && ruleForm.approvalProtectStatus === 'open'" style="color: red;font-size:14px; padding: 2px 0 0 5px;">等待{{ ruleForm.approver || ' ' }}点位保护审批中</span>
        </el-col>
        <el-col
          :sm="6" :xs="6"
          style="text-align: right"
        >
          <i
            class="el-icon-close"
            style="cursor: pointer; font-weight: 600"
            @click="closePPDetail"
          ></i>
        </el-col>
      </el-row>
    </div>
    <div style="overflow: hidden">
      <!-- 这是啥？ -->
      <!-- <el-button
        size="mini"
        v-if="isIframe && type == 'pp' && formData.siteCode"
        @click="showView(type)"
      ><span style="font-size: 12px">查看视图</span></el-button> -->
      <!-- 门店不能编辑基本信息，只能保存位置信息、坐标数据 -->
      <el-button
        size="mini"
        v-if="
          (type == 'pp' && ruleForm.siteID) ||
          (type == 'store' && ruleForm.storeCode) ||
          (type == 'vs' && ruleForm.code && isEdit)
        "
        @click="editStoreLocation"
      ><span style="font-size: 12px">位置变更</span></el-button>
      <el-button
        @click="isEdit = !isEdit"
        size="mini"
        v-if="isAllowEdit"
      ><span
          style="font-size: 12px"
          v-if="!isEdit"
        >编辑</span><span
          style="font-size: 12px"
          v-else
        >退出编辑</span></el-button>
      <el-button
        @click="saveDetail"
        v-if="(isEdit && isAllowEdit) || type == 'store'"
        size="mini"
      ><span style="font-size: 12px">保存</span>
      </el-button>
      <!-- <el-button
        size="mini"
        v-if="isPP2Store && type == 'pp'"
        @click="showPP2Store"
        ><span style="font-size: 12px">转开业</span></el-button
      > -->
      <el-button
        size="mini"
        v-if="type == 'vs' && ruleForm.code"
        @click="delSiteVs"
      ><span style="font-size: 12px">删除</span></el-button>

      <!-- 保存之后：上会准备 -->
      <!-- 状态按钮对应：
        131004 新址 => 上会准备
        131005 待上会 => 上会结果
        131006 已上会 => 没有
        131007 已推进 => 没有
        131008 已签约 => 没有
        131009 已开业 => 没有
        131010 终止 => 没有
      -->

      <!-- {{ type }} - {{ ruleForm.approvalProtectStatus }} - {{ ruleForm.siteID }} - {{ ruleForm.siteStatus }} -->
      <el-button
        size="mini"
        v-if="type == 'pp' && 'close' === ruleForm.approvalProtectStatus && ruleForm.siteID && ruleForm.siteStatus === '131004'"
      >
        <span
          style="font-size: 12px"
          @click="showExpectedMeetingTime()"
        >上会准备</span>
      </el-button>
      <el-button
        size="mini"
        v-if="type == 'pp' && 'close' === ruleForm.approvalProtectStatus && ruleForm.siteID && ruleForm.siteStatus === '131005'"
      >
        <span
          style="font-size: 12px"
          @click="showMeetingResult()"
        >上会结果</span>
      </el-button>
      <!-- <el-button
        size="mini"
        v-if="type == 'pp' && ruleForm.siteID && ruleForm.siteStatus === '131006'"
      >
        <span
          style="font-size: 12px"
          @click="pushProcess()"
        >流程推进</span>
      </el-button> -->

      <!-- 点位保护 -->
      <el-button
        size="mini"
        v-if="type == 'pp' && ruleForm.siteID && 1 === ruleForm.protectionStatus * 1"
        :type="1 !== ruleForm.protectionStatus * 1 ? '' : 'warning'"
        @click="handleShowProtection"
      >
        <span
          style="font-size: 12px;"
        >{{ 1 !== ruleForm.protectionStatus * 1 ? '点位保护' : '解除点位保护' }}</span>
      </el-button>


      <!-- 点位保护审核 -->
      <el-button
        v-if="'show' === ruleForm.approvalFlag"
        size="mini"
        @click.stop="$parent.toSiteIdProtectionConfirm(info.id)"
      >
        <span
          style="font-size: 12px;"
        >审核点位保护</span>
      </el-button>

      <!-- 足迹 -->
      <el-button
        size="mini"
        v-if="(type === 'pp' && ruleForm.siteID) || (type === 'store' && ruleForm.storeId)"
        @click="showFootprint(type)"
      >
        <span
          style="font-size: 12px;"
        >足迹</span>
      </el-button>

      <!-- 保存之后：终止 -->
      <el-button
        size="mini"
        style="float: right"
        type="danger"
        v-if="type == 'pp' && ruleForm.siteID"
      >
        <span
          style="font-size: 12px"
          v-if="ruleForm.siteStatus !== '131010'"
          @click="stopMeeting()"
        >终止</span>
        <span
          style="font-size: 12px"
          v-else
          @click="showMeetingResult()"
        >重新上会</span>
      </el-button>
    </div>
    <div class="pp-main">
      <el-collapse
        accordion
        v-model="activeName"
      >
        <el-collapse-item name="1">
          <template slot="title">
            基本信息<i class="header-icon el-icon-info"></i>
          </template>
          <el-form
            :model="ruleForm"
            ref="basicRuleForm"
            label-width="100px"
            class="demo-ruleForm"
            size="small"
            :rules="basicMsgCheck"
            @submit.native.prevent
          >
            <!-- 锐力机会点（合并了，不再用单独组件） -->
            <!-- <MapVSDetailPPBasic
              v-if="isIframe && type == 'pp'"
              :formData="formData"
              :formLst="formLst"
              :isEdit="isEdit"
              :imageURL="imageURL"
              :headersObj="headersObj"
              :levelList="levelList"
              :selectionTypeList="selectionTypeList"
            ></MapVSDetailPPBasic> -->
            <!-- 锐力竞品 -->
            <MapVSDetailVSBasic
              v-if="isIframe && type == 'vs'"
              :formData="formData"
              :formLst="formLst"
              :isEdit="isEdit"
            ></MapVSDetailVSBasic>
            <!-- 锐力门店 -->
            <!-- <MapVSDetailStoreBasic
              v-else-if="isIframe && type == 'store'"
              :formData="formData"
              :formLst="formLst"
              :isEdit="isEdit"
            ></MapVSDetailStoreBasic> -->
            <div v-else>
              <!-- 机会点 -->
              <el-row v-if="type == 'pp'">
                <!-- 品牌 -->
                <!-- <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="品牌:"
                    prop="mainBrand"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.mainBrand"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.mainBrand,
                          'mainBrandName',
                          brandList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in brandList"
                        :key="item.dict_Code"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.mainBrandName }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 机会项目名称 -->
                <!-- <el-col :sm="24" :xs="24">
                  <el-form-item
                    label="机会项目名称:"
                    prop="siteName"
                  >
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.siteName"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.siteName }}</span>
                  </el-form-item>
                </el-col> -->

                <!-- 地标名称 -->
                <el-col :sm="24" :xs="24">
                  <el-form-item
                    label="地标名称:"
                    prop="landmark"
                  >
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.landmark"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.landmark }}</span>
                  </el-form-item>
                </el-col>
                <!-- 业主姓名 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="业主姓名:"
                    prop="ownersName"
                  >
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.ownersName"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.ownersName }}</span>
                  </el-form-item>
                </el-col>
                <!-- ，业主电话 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="业主电话:"
                    prop="ownersTelephone"
                  >
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.ownersTelephone"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.ownersTelephone }}</span>
                  </el-form-item>
                </el-col>
                <!-- 经营性质 -->
                <!-- <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="经营性质:"
                    prop="busType"
                    style="height: 32px;"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.busType"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.busType,
                          'busTypeName',
                          busTypeList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in busTypeList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.busTypeName }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 机会点编号 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="机会点编号:"
                    prop="siteCode"
                  >
                    <span>{{ ruleForm.siteCode }}</span>
                  </el-form-item>
                </el-col>
                <!-- 状态 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="状态:">
                    <!-- <el-select
                      v-if="isEdit"
                      v-model="ruleForm.siteStatus"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.siteStatus,
                          'siteStatusName',
                          statusList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in statusList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select> -->
                    <span>{{ ruleForm.siteStatusName || '' }}</span>
                  </el-form-item>
                </el-col>
                <!-- 评分 -->
                <!-- <el-col :sm="12" :xs="24">
                  <el-form-item label="评分:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.score"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.score }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 预计开业时间 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="铺位有效期:">
                    <el-date-picker
                      v-if="isEdit"
                      type="date"
                      format="yyyy-MM-dd"
                      placeholder="请选择"
                      v-model="ruleForm.storeValidityPeriod"
                      style="width: 100%"
                      :editable="false"
                    ></el-date-picker>
                    <span v-else>{{ formatDate(ruleForm.storeValidityPeriod) }}</span>
                  </el-form-item>
                </el-col>

                <!-- 一级类型 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="一级类型:"
                    prop="siteType"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.siteType"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.siteType,
                          'siteTypeName',
                          siteTypeList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in siteTypeList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.siteTypeName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 二级类型 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="二级类型:"
                    prop="siteSecondDaryType"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.siteSecondDaryType"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.siteSecondDaryType,
                          'siteSecondDaryTypeName',
                          siteSecondDaryTypeList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in siteSecondDaryTypeList.filter(t => t.def1 === ruleForm.siteType)"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.siteSecondDaryTypeName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 合作商业 -->
                <!-- 合作商业做逻辑判断: 当开发店型为131001(商场店)的时候 选择合作商业 -->
                <el-col :sm="12" :xs="24" v-if="'131001' === ruleForm.siteType">
                  <el-form-item
                    label="合作商业:"
                    prop="cooperativeBusiness"
                    :rules="[{ required: true, message: '请选择合作商业', trigger: ['change', 'submit'] }]"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.cooperativeBusiness"
                      ref="siteCooperativeBusinessSelect"
                      placeholder="请选择"
                      filterable
                      @change="
                        changeType(
                          ruleForm.cooperativeBusiness,
                          'cooperativeBusinessName',
                          siteCooperativeBusinessList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in siteCooperativeBusinessList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.cooperativeBusinessName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 是否对接收银系统 -->
                <!-- 是否对接收银系统做逻辑判断: 当开发店型为131001(商场店)的时候 选择是否对接收银系统 -->
                <!-- <el-col :sm="12" :xs="24" v-if="'131001' === ruleForm.siteType">
                  <el-form-item
                    label="对接收银系统:"
                    prop="receivingSilverSystem"
                    :rules="[{ required: true, message: '请选择是否对接收银系统', trigger: ['change', 'submit'] }]"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.receivingSilverSystem"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.receivingSilverSystem,
                          'receivingSilverSystemName',
                          levelList,
                          'value',
                          'label'
                        )
                      "
                    >
                      <el-option
                        v-for="item in levelList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.receivingSilverSystemName }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 楼层 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="楼层:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.floor"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.floor }}</span>
                  </el-form-item>
                </el-col>
                <!-- 是否超A、大门头 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="是否超A,大门头:"
                    prop="level"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.level"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.level,
                          'levelName',
                          levelList,
                          'value',
                          'label'
                        )
                      "
                    >
                      <el-option
                        v-for="item in levelList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.levelName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 选址/测评 -->
                <!-- <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="选址/测评:"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.selectionType"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.selectionType,
                          'selectionTypeName',
                          selectionTypeList,
                          'value',
                          'label'
                        )
                      "
                    >
                      <el-option
                        v-for="item in selectionTypeList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.selectionTypeName }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 授权号 -->
                <!-- 授权号做逻辑必填:当选址/测评为选址时授权号必填 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="授权号:"
                    :rules="(2 === ruleForm.selectionType * 1
                      ? [{ required: true, message: '请选择授权号', trigger: ['change', 'submit'] }]
                      : undefined
                    )"
                    prop="authNumber"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.authNumber"
                      ref="authNumberSelect"
                      @visible-change="handleAuthNumberVisibleChange"
                      placeholder="请选择"
                      filterable
                      @change="
                        changeType(
                          ruleForm.authNumber,
                          'authNumber',
                          empowerList,
                          'authCode',
                          'authCode'
                        )
                      "
                    >
                      <img style="display:none" @load="handleAuthNumberVisibleChange(false)" src="@/assets/images/Icon/legend.png" />
                      <el-option
                        v-for="item in empowerList"
                        :key="item.id"
                        :label="item.authCode + ' ' + item.franchiseeName"
                        :value="item.authCode"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.authNumber }}</span>
                  </el-form-item>
                </el-col>
                <!-- 开发经理名称 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="开发经理:">
                    <span>{{ ruleForm.creator }}</span>
                  </el-form-item>
                </el-col>
                <!-- 照片 -->
                <el-col :sm="24" :xs="24">
                  <el-form-item label="照片:" prop="image">
                    <el-upload
                      v-if="isEdit"
                      style="display:inline-block;"
                      class="avatar-uploader"
                      :action="imageURL"
                      :headers="headersObj"
                      :file-list="imageFileList"
                      :show-file-list="true"
                      list-type="picture-card"
                      multiple
                      :limit="5"
                      :on-exceed="handleExceed"
                      :on-remove="handleRemove"
                      :on-success="handleImageSuccess"
                      :before-upload="beforeImageUpload">
                      <!-- <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar1">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                      <i class="el-icon-plus"></i>
                      <div slot="tip" class="el-upload__text">最多上传5个，不大于20M</div>
                    </el-upload>
                    <div v-else-if="ruleForm.image">
                      <!-- <img v-for="img in imageFileList" :src="img.url" :key="img.name" class="upload-image"> -->
                      <el-image v-for="img in imageFileList" :src="img.url" :key="img.name" class="upload-image" :preview-src-list="imageFileList.map(i => i.url)" alt=""></el-image>
                    </div>
                  </el-form-item>
                </el-col>
                <!-- 备注 -->
                <el-col>
                  <el-form-item label="备注:" prop="remark">
                    <el-input
                      type="textarea"
                      v-if="isEdit"
                      v-model="ruleForm.remark"
                      placeholder="商务条件、面积、工程条件等"
                    ></el-input>
                    <span v-else>{{ ruleForm.remark }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 店铺 -->
              <el-row v-else-if="type == 'store'">
                <!-- 品牌 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="品牌:"
                    prop="brand"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.brand"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.brand,
                          'mainBrandName',
                          brandList,
                          'brandId',
                          'cnName'
                        )
                      "
                    >
                      <el-option
                        v-for="item in brandList"
                        :key="item.brandId"
                        :label="item.cnName"
                        :value="item.brandId"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.mainBrandName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 门店编码 -->
                <!-- <el-col :sm="12" :xs="24">
                  <el-form-item label="门店编码:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.storeCode"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.storeCode }}</span>
                  </el-form-item>
                </el-col> -->

                <!-- 授权号 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="授权号:">
                    <span>{{ ruleForm.authNumber }}</span>
                  </el-form-item>
                </el-col>
                <!-- 经营性质 -->
                <!-- <el-col :sm="12" :xs="24">
                  <el-form-item label="经营性质:">
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.storeType"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.storeType,
                          'storeTypeName',
                          busTypeList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in busTypeList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.storeTypeName }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 门店名称 -->
                <!-- <el-col>
                  <el-form-item label="门店名称:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.storeName"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.storeName }}</span>
                  </el-form-item>
                </el-col> -->
                <!-- 门店名称 -->
                <el-col>
                  <el-form-item label="地标:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.landmark"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.landmark }}</span>
                  </el-form-item>
                </el-col>
                <!-- 经营状态 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="经营状态:">
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.status"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.status,
                          'statusName',
                          statusList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in statusList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.statusName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 门店类型 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="门店类型:">
                    <span>{{ ruleForm.storeTypeName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 客户签约时间 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="客户签约时间:">
                    <span>{{ ruleForm.signDate }}</span>
                  </el-form-item>
                </el-col>
                <!-- 客户到期时间 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="客户到期时间:">
                    <span>{{ ruleForm.contractExpireDate }}</span>
                  </el-form-item>
                </el-col>
                <!-- 落位时间 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="落位时间:">
                    <span>{{ ruleForm.positionDate }}</span>
                  </el-form-item>
                </el-col>
                <!-- 餐位数 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="餐位数:">
                    <span>{{ ruleForm.numberOfMeals }}</span>
                  </el-form-item>
                </el-col>
                <!-- 门店价格带 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="门店价格带:">
                    <span>{{ ruleForm.storePriceBand }}</span>
                  </el-form-item>
                </el-col>
                <!-- 日均外卖单量 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="">
                    <div class="fake-label">
                      日均外卖单量
                      <el-tooltip effect="dark" content="近三十日日均" placement="top">
                        <i class="el-icon-question"></i>
                      </el-tooltip>:
                    </div>
                    <span>{{ ruleForm.dailyAveQuantity }}</span>
                  </el-form-item>
                </el-col>
                <!-- 日均销售额 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="">
                    <div class="fake-label">
                      日均销售额
                      <el-tooltip effect="dark" content="近三十日日均" placement="top">
                        <i class="el-icon-question"></i>
                      </el-tooltip>:
                    </div>
                    <span>{{ ruleForm.sales }}</span>
                  </el-form-item>
                </el-col>
                <!-- 堂食日均实收 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="">
                    <div class="fake-label">
                      堂食日均实收
                      <el-tooltip effect="dark" content="近三十日日均" placement="top">
                        <i class="el-icon-question"></i>
                      </el-tooltip>:
                    </div>
                    <span>{{ ruleForm.dineSales }}</span>
                  </el-form-item>
                </el-col>
                <!-- 外卖日均实收 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="">
                    <div class="fake-label">
                      外卖日均实收
                      <el-tooltip effect="dark" content="近三十日日均" placement="top">
                        <i class="el-icon-question"></i>
                      </el-tooltip>:
                    </div>
                    <span>{{ ruleForm.takeoutSales }}</span>
                  </el-form-item>
                </el-col>
                <!-- 开业时间 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="开业日期:">
                    <el-date-picker
                      v-if="isEdit"
                      type="date"
                      placeholder="请选择"
                      v-model="ruleForm.openDate"
                      style="width: 100%"
                      :editable="false"
                    ></el-date-picker>
                    <span v-else>{{ ruleForm.openDate }}</span>
                  </el-form-item>
                </el-col>
                <!-- 备注 -->
                <el-col>
                  <el-form-item label="备注:">
                    <el-input
                      type="textarea"
                      v-if="isEdit"
                      v-model="ruleForm.remark"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.remark }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 竞品 -->
              <el-row v-else>
                <!-- 品牌 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item
                    label="品牌:"
                    prop="mainBrand"
                  >
                    <el-select
                      v-if="isEdit"
                      v-model="ruleForm.mainBrand"
                      placeholder="请选择"
                      @change="
                        changeType(
                          ruleForm.mainBrand,
                          'brandName',
                          brandList,
                          'dict_Code',
                          'type_Name'
                        )
                      "
                    >
                      <el-option
                        v-for="item in brandList"
                        :key="item.id"
                        :label="item.type_Name"
                        :value="item.dict_Code"
                      ></el-option>
                    </el-select>
                    <span v-else>{{ ruleForm.brandName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 编号 -->
                <el-col :sm="12" :xs="24">
                  <el-form-item label="编号:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.code"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.code }}</span>
                  </el-form-item>
                </el-col>
                <!-- 门店名称 -->
                <el-col>
                  <el-form-item
                    label="门店名称:"
                    prop="cnName"
                  >
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.cnName"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.cnName }}</span>
                  </el-form-item>
                </el-col>
                <!-- 门店电话 -->
                <el-col>
                  <el-form-item label="门店电话:">
                    <el-input
                      v-if="isEdit"
                      v-model="ruleForm.phone"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.phone }}</span>
                  </el-form-item>
                </el-col>
                <!-- 备注 -->
                <el-col>
                  <el-form-item label="备注:">
                    <el-input
                      type="textarea"
                      v-if="isEdit"
                      v-model="ruleForm.remark"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ ruleForm.remark }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            位置信息<i class="header-icon el-icon-info"></i>
          </template>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            size="small"
          >
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item label="城市:">
                  {{ ruleForm.cityName }}
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="区县:">
                  {{ ruleForm.districtName }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="详细地址:">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: isMobile ? 4 : 1, maxRows: 10 }"
                    v-if="isEdit || type == 'store'"
                    v-model="ruleForm.address"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.address }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item label="经度:">
                  <!-- <el-input v-if="isEdit" v-model="ruleForm.d4" placeholder="请输入"></el-input> -->
                  <span>{{ ruleForm.longitude }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="纬度:">
                  <!-- <el-input v-if="isEdit" v-model="ruleForm.d4" placeholder="请输入"></el-input> -->
                  <span>{{ ruleForm.latitude }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="3" v-if="type == 'pp'">
          <template slot="title">
            流程信息<i class="header-icon el-icon-info"></i>
          </template>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            size="small"
          >
            <el-row>
              <!-- 预计上会时间 -->
              <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="预计上会时间:">
                  <span>{{ formatDate(ruleForm.expectedMeetingTime) }}</span>
                </el-form-item>
              </el-col>
              <!-- 上会结果 -->
              <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="上会结果:">
                  <span>{{ ruleForm.meetingResultName }}</span>
                </el-form-item>
              </el-col>
              <!-- 计划流程推进时间：保存后，且上会结果为 通过 -->
              <!-- <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="计划流程推进时间:">
                  <span>{{ formatDate(ruleForm.plannedPromotionTime) }}</span>
                </el-form-item>
              </el-col> -->
              <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="预计签约时间:">
                  <span>{{ formatDate(ruleForm.plannedSigningTime) }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="实际上会时间:">
                  <span>{{ formatDate(ruleForm.actuallyMeetingTime) }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="上会备注:">
                  <span>{{ ruleForm.meetingRemark }}</span>
                </el-form-item>
              </el-col>
              <!-- 授权号 -->
              <el-col :sm="12" :xs="24" v-show="ruleForm.siteID">
                <el-form-item label="授权号:">
                  <span>{{ ruleForm.authNumber }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="4" v-if="type == 'pp' && ruleForm.protectionTimeStart">
          <template slot="title">
            点位保护信息<i class="header-icon el-icon-info"></i>
          </template>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            size="small"
          >
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item label="保护起始时间:">
                  <span>{{ formatDate(ruleForm.protectionTimeStart) }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="保护结束时间:">
                  <span>{{ formatDate(ruleForm.protectionTimeEnd) }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="保护范围(米):">
                  <span>{{ ruleForm.scopeOfProtection }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :xs="24">
                <el-form-item label="点位保护照片:">
                  <!-- <span>{{ ruleForm.protectionTimeFile }}</span> -->
                  <img v-for="img in protectionTimeFileList" :src="img.url" class="upload-image" :key="img.name" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="5" v-if="type == 'pp'">
          <template slot="title">
            日志信息<i class="header-icon el-icon-info"></i>
          </template>
          <el-table
            :data="ppSiteLogList"
            size="mini"
            style="width: 100%">
            <el-table-column
              prop="operationDescription"
              label="操作描述">
            </el-table-column>
            <el-table-column
              prop="creator"
              label="操作人">
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="操作时间">
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-dialog
      :visible.sync="showPP2StoreForm"
      width="30%"
      class="pp2storeForm"
      :show-close="false"
      center
    >
      <span slot="title">确认转开业</span>
      <el-divider></el-divider>
      <el-form
        :model="pp2storeForm"
        :rules="rules"
        ref="pp2storeForm"
      >
        <el-form-item
          label="门店编码"
          :label-width="'100px'"
          prop="storeCode"
        >
          <el-input
            type="mini"
            v-model="pp2storeForm.storeCode"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="门店名称"
          :label-width="'100px'"
          prop="storeName"
        >
          <el-input
            type="mini"
            v-model="pp2storeForm.storeName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开业时间"
          :label-width="'100px'"
          prop="openDate"
        >
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            v-model="pp2storeForm.openDate"
            style="width: 100%"
            :editable="false"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="showPP2StoreForm = false"
          style="width: 40%; margin-right: 20px"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="confirmPP2Store"
          style="width: 40%"
        >确 定</el-button>
      </div>
    </el-dialog>

    <!-- 上会信息填写 -->
    <el-dialog
      :visible.sync="meetingFormShow"
      class="pp-main meeting-form"
      :show-close="false"
      center
    >
      <span slot="title">{{ ['131004', '131010'].indexOf(ruleMeetingForm.siteStatus) > -1 ? '上会准备' : '上会结果' }}</span>
      <el-form
        :model="ruleMeetingForm"
        :rules="rulesMeeting"
        label-width="110px"
        ref="meetingForm"
        size="small"
      >
        <el-row>
          <!-- 预计上会时间 -->
          <el-col>
            <el-form-item
              label="预计上会时间:"
              prop="expectedMeetingTime"
            >
              <el-date-picker
                v-if="['131004', '131010'].indexOf(ruleMeetingForm.siteStatus) > -1"
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                v-model="ruleMeetingForm.expectedMeetingTime"
                style="width: 100%"
                :editable="false"
              ></el-date-picker>
              <span v-else>{{ formatDate(ruleMeetingForm.expectedMeetingTime) }}</span>
            </el-form-item>
          </el-col>
          <!-- 上会结果 -->
          <el-col :sm="24" :xs="24" v-if="ruleMeetingForm.siteStatus === '131005'">
            <el-form-item
              label="上会结果:"
              prop="meetingResult"
            >
              <el-select
                v-model="ruleMeetingForm.meetingResult"
                placeholder="请选择"
                @change="
                  changeType(
                    ruleMeetingForm.meetingResult,
                    'meetingResultName',
                    empowerList,
                    'value',
                    'label'
                  )
                "
              >
                <el-option
                  v-for="item in meetingResultList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 计划流程推进时间：保存后，且上会结果为 通过 -->
          <!-- <el-col :sm="24" :xs="24" v-if="ruleMeetingForm.siteStatus === '131005' && ruleMeetingForm.meetingResult * 1 === 1">
            <el-form-item
              label="计划流程推进时间:"
              prop="plannedPromotionTime"
            >
              <el-date-picker
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                v-model="ruleMeetingForm.plannedPromotionTime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col> -->
          <!-- 计划流程推进时间：保存后，且上会结果为 通过 -->
          <el-col :sm="24" :xs="24" v-if="ruleMeetingForm.siteStatus === '131005' && ruleMeetingForm.meetingResult * 1 === 1">
            <el-form-item
              label="预计签约时间:"
              prop="plannedSigningTime"
            >
              <el-date-picker
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                v-model="ruleMeetingForm.plannedSigningTime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <!-- 选址/测评 -->
          <el-col :sm="24" :xs="24" v-if="ruleMeetingForm.siteStatus === '131005' && ruleMeetingForm.meetingResult * 1 === 1">
            <el-form-item
              label="选址/测评:"
              :rules="[{ required: true, message: '请选择授权号', trigger: ['change', 'submit'] }]"
              prop="selectionType"
            >
              <el-select
                v-model="ruleMeetingForm.selectionType"
                placeholder="请选择"
                @change="
                  changeType(
                    ruleMeetingForm.selectionType,
                    'selectionTypeName',
                    selectionTypeList,
                    'value',
                    'label'
                  )
                "
                :editable="true"
              >
                <el-option
                  v-for="item in selectionTypeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 授权号 -->
          <!-- 授权号做逻辑必填:上会结果通过时授权号必填 -->
          <el-col :sm="24" :xs="24" v-if="ruleMeetingForm.siteStatus === '131005' && ruleMeetingForm.meetingResult * 1 === 1">
            <el-form-item
              label="授权号:"
              :rules="[{ required: true, message: '请选择授权号', trigger: ['change', 'submit'] }]"
              prop="authNumber"
            >
              <el-select
                v-model="ruleMeetingForm.authNumber"
                ref="authNumberSelectMeeting"
                @visible-change="handleAuthNumberMeetingVisibleChange"
                placeholder="请选择"
                filterable
                @change="
                  changeType(
                    ruleMeetingForm.authNumber,
                    'authNumber',
                    empowerList,
                    'authCode',
                    'authCode'
                  )
                "
              >
                <img style="display:none" @load="handleAuthNumberMeetingVisibleChange(false)" src="@/assets/images/Icon/legend.png" />
                <el-option
                  v-for="item in empowerList"
                  :key="item.id"
                  :label="item.authCode + ' ' + item.franchiseeName"
                  :value="item.authCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 实际上会时间 与上会结果无关 -->
          <el-col :sm="24" :xs="24" v-if="ruleMeetingForm.siteStatus === '131005'">
            <el-form-item
              label="实际上会时间:"
              prop="actuallyMeetingTime"
            >
              <el-date-picker
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                v-model="ruleMeetingForm.actuallyMeetingTime"
                style="width: 100%"
                :editable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- 备注 -->
          <el-col v-if="ruleMeetingForm.siteStatus === '131005'">
            <el-form-item
              label="上会备注:"
              prop="meetingRemark"
            >
              <el-input
                type="textarea"
                v-model="ruleMeetingForm.meetingRemark"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="cancelMeetingInfo"
          size="small"
          style="width: 100px; margin-right: 20px"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="confirmMeetingInfo"
          size="small"
          style="width: 100px"
        >确 定</el-button>
      </div>
    </el-dialog>

    <!-- 点位保护 信息填写 -->
    <el-dialog
      :visible.sync="protectionShow"
      class="pp-main meeting-form"
      :show-close="false"
      center
    >
      <span slot="title">{{ 1 === ruleForm.protectionStatus * 1 ? '解除点位保护' : '点位保护信息'}}</span>
      <el-form
        :model="ruleForm"
        :rules="rulesProtection"
        label-width="110px"
        ref="protectionForm"
        size="small"
      >
        <el-row>
          <!-- 保护起始时间 -->
          <el-col>
            <el-form-item
              label="保护起始时间:"
              prop="protectionTimeStart"
            >
              <el-date-picker
                v-if="0 === ruleForm.protectionStatus * 1"
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                v-model="ruleForm.protectionTimeStart"
                style="width: 100%"
                :editable="false"
              ></el-date-picker>
              <span v-else>{{ formatDate(ruleForm.protectionTimeStart) }}</span>
            </el-form-item>
          </el-col>
          <!-- 保护结束时间 -->
          <el-col>
            <el-form-item
              label="保护结束时间:"
              prop="protectionTimeEnd"
            >
              <el-date-picker
                v-if="0 === ruleForm.protectionStatus * 1"
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                v-model="ruleForm.protectionTimeEnd"
                style="width: 100%"
                :editable="false"
              ></el-date-picker>
              <span v-else>{{ formatDate(ruleForm.protectionTimeEnd) }}</span>
            </el-form-item>
          </el-col>
          <!-- 备注 -->
          <el-col>
            <el-form-item
              label="保护范围(米):"
              prop="scopeOfProtection"
            >
              <el-slider 
                  v-if="0 === ruleForm.protectionStatus * 1"
                  v-model="ruleForm.scopeOfProtection"
                  :marks="distanceMarks"
                  :min="10" 
                  :max="5000"
                  show-input
                  input-size="mini">
              </el-slider>
              <span v-else>{{ ruleForm.scopeOfProtection }}</span>
            </el-form-item>
          </el-col>
          <!-- 点位保护照片 -->
          <el-col :sm="24" :xs="24">
            <el-form-item label="点位保护照片:">
              <el-upload
                v-if="0 === ruleForm.protectionStatus * 1"
                style="display:inline-block;"
                class="avatar-uploader"
                :action="imageURL"
                :headers="headersObj"
                :file-list="protectionTimeFileList"
                :show-file-list="true"
                list-type="picture-card"
                multiple
                :limit="5"
                :on-exceed="handleExceed"
                :on-remove="handleProtectionTimeFileRemove"
                :on-success="handleProtectionTimeFileImageSuccess"
                :before-upload="beforeImageUpload">
                <!-- <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar1">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__text">最多上传5个，不大于20M</div>
              </el-upload>
              <div v-else>
                <img v-for="img in protectionTimeFileList" :src="img.url" class="upload-image" :key="img.name" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="cancelProtection"
          size="small"
          style="width: 100px; margin-right: 20px"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="confirmProtection"
          size="small"
          style="width: 100px"
        >确 定</el-button>
      </div>
    </el-dialog>

    <MapFootprint
      :footprintVisible="footprintVisible"
      :info="footprintInfo"
      :isEdit="footprintEdit"
      @footprint-visible="toggleFootprint"
    ></MapFootprint>
  </div>
</template>
<script>
import api from "@/api/index.js";
import apiIframe from "@/postMessage/api/index.js";
import MapFootprint from "./MapFootprint.vue"; // 足迹
// import MapVSDetailPPBasic from "../../../postMessage/components/MapVSDetail/MapVSDetailPPBasic.vue";
import MapVSDetailVSBasic from "../../../postMessage/components/MapVSDetail/MapVSDetailVSBasic.vue";
// import MapVSDetailStoreBasic from "../../../postMessage/components/MapVSDetail/MapVSDetailStoreBasic.vue";
import IframeMixin from "../../../postMessage/components/MapVSDetail/Mixin";
import constants from "@/constants/Map.constants.js";

export default {
  name: "MapVSDetail",
  props: ["vsVisible", "info", "isMobile"],
  components: {
    MapFootprint,
    // MapVSDetailPPBasic,
    MapVSDetailVSBasic,
    // MapVSDetailStoreBasic,
  },
  data() {
    return {
      isEdit: false,
      activeName: "1",
      visible: false,
      type: "",
      newSuccess: this.info.id ? true : false,
      ruleForm: {
        // 原字段
        province: "", //省份
        city: "", //城市
        district: "", //区县
        provinceName: "", //省份
        cityName: "", //城市
        districtName: "", //区县
        address: "", //地址
        siteCode: "", // 机会点编码

        //机会点
        mainBrand: "", // 品牌 写死“鱼你”还是不传？不传筛选图例的时候没有brandId
        // mainBrandName: "", // 品牌
        busType: "103002", // 经营性质 [店铺 同] // 固定不可选
        busTypeName: "加盟", // 经营性质 [店铺 同]
        floor: "", // 评分
        storeValidityPeriod: "", // 铺位有效期
        // siteName: "", // 机会点名称
        landmark: "", // 地标名称
        ownersName: "", // 业主姓名
        ownersTelephone: "", // 业主电话
        siteType: "", // 类型
        siteTypeName: "", // 类型
        siteSecondDaryType: "", // 二级类型
        siteSecondDaryTypeName: "", // 二级类型
        cooperativeBusiness: "", // 合作商业
        cooperativeBusinessName: "", // 合作商业
        receivingSilverSystem: "", // 是否对接收银系统
        receivingSilverSystemName: "", // 是否对接收银系统
        score: "", // 楼层
        level: "", // 是否超A、大门头
        levelName: "", // 是否超A、大门头
        selectionType: "", // 选址/测评
        selectionTypeName: "", // 选址/测评
        authNumber: "", // 授权号
        // authNumberName: "", // 授权号 CHECK 没这个吧？
        image: "", // 照片
        expectedMeetingTime: "", // 预计上会时间
        meetingResult: "", // 上会结果
        meetingResultName: "", // 上会结果
        // plannedPromotionTime: "", // 计划流程推进时间
        plannedSigningTime: "", // 预计签约时间
        actuallyMeetingTime: "", // 实际上会时间
        meetingRemark: "", // 上会备注
        // siteStatus: "", // 状态
        siteStatusName: "", // 状态
        remark: "", // 备注 [店铺、竞品 同]
        // 机会点 - 点位保护:
        protectionTimeStart: "", // 保护时间起始时间
        protectionTimeEnd: "", // 保护时间起始时间
        scopeOfProtection: "", // 保护范围(米)
        protectionStatus: null, // 是否点位保护
        protectionTimeFile: "", // 点位保护照片

        // 店铺、竞品
        brand: "", // 品牌 [店铺、竞品 同]
        brandName: "", // 品牌 [店铺、竞品 同]
        storeCode: "", // 编号 [店铺]
        code: "", // 编号 [竞品]
        storeType: "", // 经营性质 [店铺]
        storeTypeName: "", // 经营性质 [店铺]
        status: "", // 经营状态 [店铺]
        statusName: "", // 经营状态 [店铺]
        openDate: "", // 开业时间 [店铺]
        storeName: "", // 门店名称
        phone: "", // 门店电话 [竞品]
        cnName: "", // 门店名称
      },
      rules: {
        storeCode: [
          {
            required: true,
            message: "请输入门店编码",
            trigger: "blur",
          },
        ],
        storeName: [
          {
            required: true,
            message: "请输入门店名称",
            trigger: "blur",
          },
        ],
        openDate: [
          {
            required: true,
            message: "请输入开业时间",
            trigger: "blur",
          },
        ],
      },
      basicMsgCheck: {
        // createType: [
        //   { required: true, message: "请选择创建类型", trigger: "blur" },
        // ],
        siteType: [
          { required: true, message: "请选择一级类型", trigger: ['change', "submit"] },
        ],
        siteSecondDaryType: [
          { required: true, message: "请选择二级类型", trigger: ['change', "submit"] },
        ],
        level: [
          { required: true, message: "请选择是否超A、大门头", trigger: ['change', "submit"] },
        ],
        busType: [
          { required: true, message: "请选择经营主体", trigger: ['change', "submit"] },
        ],
        // siteName: [
        //   { required: true, message: "请输入名称", trigger: ['change', "submit"] },
        // ],
        landmark: [
          { required: true, message: "请输入地标名称", trigger: ['change', "submit"] },
        ],
        ownersName: [
          { required: true, message: "业主姓名", trigger: ['change', "submit"] },
        ],
        ownersTelephone: [
          { required: true, message: "业主电话", trigger: ['change', "submit"] },
        ],
        mainBrand: [
          { required: true, message: "请选择品牌", trigger: ['change', "submit"] },
        ],
        image: [
          { required: true, message: "请上传图片", trigger: ['change', "submit"] },
        ],
        remark: [
          { required: true, message: "请输入备注", trigger: ['change', "submit"] },
        ],
      },
      ruleMeetingForm: {
        meetingResult: "", // 上会结果code
        meetingResultName: "", // 上会结果名
        expectedMeetingTime: "", // 预计上会时间
        // plannedPromotionTime: "", // 计划流程推进时间
        plannedSigningTime: "", // 预计签约时间
        actuallyMeetingTime: "", // 实际上会时间
        meetingRemark: "", // 上会备注
        siteStatus: "", // 状态
        selectionType: "", // 选址/测评
        authNumber: "", // 授权号
      }, // 上会信息clone ruleForm的对应字段
      rulesMeeting: {
        expectedMeetingTime: [
          { required: true, message: "请选择预计上会时间", trigger: ["submit"] },
        ],
        meetingResult: [
          { required: true, message: "上会结果", trigger: ["submit"] },
        ],
        // plannedPromotionTime: [
        //   { required: true, message: "计划流程推进时间", trigger: ["submit"] },
        // ],
        plannedSigningTime: [
          { required: true, message: "请选择预计签约时间", trigger: ["change", "submit"] },
        ],
        actuallyMeetingTime: [
          { required: true, message: "请选择实际上会时间", trigger: ["change", "submit"] },
        ]
      },
      rulesProtection: {
        protectionTimeStart: [
          { required: true, message: "请选择保护起始时间", trigger: ["submit"] },
        ],
        protectionTimeEnd: [
          { required: true, message: "请选择保护结束时间", trigger: ["submit"] },
        ],
        scopeOfProtection: [
          { required: true, message: "请填写保护范围", trigger: ["submit"] },
        ]
      },
      distanceMarks: {
          10: "10m",
          5000: "5000m",
          label: this.$createElement('strong', '50%')
      },
      pp2storeForm: {
        openDate: null,
        siteId: null,
        storeCode: null,
        storeName: null,
      },
      busTypeList: [], // 经营性质
      siteTypeList: [], // 类型
      siteSecondDaryTypeList: [], // 二级类型
      brandList: [], // 品牌
      statusList: [], // 经营状态
      levelList: [ // 是否超A
        { value: 1, label: '是' },
        { value: 0, label: '否' }
      ],
      selectionTypeList: [ // 选址/测评
        { value: 1, label: '选址' },
        { value: 2, label: '测评' }
      ],
      meetingResultList: [ // 上会结果： 1通过 0拒绝
        { value: '1', label: '通过' },
        { value: '0', label: '拒绝' }
      ],
      siteCooperativeBusinessList: [], // 合作商业列表
      empowerList: [], // 授权号
      ppSiteLogList: [], // 机会点日志信息
      imageFileList: [], // 机会点图片转换后列表
      protectionTimeFileList: [], // 机会点，点位保护图片转换后列表
      isAllowEdit: true,
      isPP2Store: false,
      showPP2StoreForm: false,
      progressList: [],
      isProgress: false, // 编辑项目进度管理
      isIframe: this.$store.state.PMStoreIndex.isIframe, // 是否内嵌为第三方子系统
      Api: this.$store.state.PMStoreIndex.isIframe ? apiIframe : api, // 父系统对接接口&原始接口
      formData: {},
      formLst: {},
      loading: false,
      saveDetailShake: null,
      savePPDetailLock: false, // 机会点控制，3秒内不重复点击
      imageURL: "", // 图片上传地址
      headersObj: {}, // 图片上传请求header

      meetingFormShow: false, // 机会点 显示上会信息填写框

      protectionShow: false, // 机会点 显示点位保护信息填写

      footprintVisible: false, // 填写足迹
      footprintEdit: false, // 是填写足迹
      footprintInfo: {}, // 足迹参数

      // approvalFlag: false // 是否显示，点位保护审核按钮
    };
  },
  mixins: [IframeMixin],
  mounted() {
    this.visible = this.vsVisible;
    this.$bus.$off("storeNewLoaction");
    this.$bus.$on("storeNewLoaction", (e) => {
      if (!!e) {
        this.ruleForm.latitude = e.lat;
        this.ruleForm.longitude = e.lng;

        // 获取地址，更新
        this.AddressComponent([e.lng, e.lat]);
        this.$http
          .get(constants.iMap.REGEOURL, {
            params: {
              location: `${this.ruleForm.longitude},${this.ruleForm.latitude}`,
              key: constants.iMap.WEBKEY,
            },
          })
          .then((res) => {
            if (res.body && res.body.info === "OK") {
              // console.log('res.body: ', res.body)
              this.ruleForm.address = res.body.regeocode.formatted_address;
            }
          });
      }
    });

    this.imageURL = this.formatURL("common/uploadImage");
    this.headersObj = {
        "time": new Date().getTime(),
        "token": sessionStorage.token || "",
        "timestamp": sessionStorage.timestamp || ""
    }
  },
  methods: {
    /**
     * 通知 mapBox 组件，当前门店需要创建标记点变更坐标信息
     */
    editStoreLocation() {
      if ('pp' === this.type) {
        this.isEdit = true;
      }
      let lnglat = {
        lng: this.ruleForm.longitude,
        lat: this.ruleForm.latitude,
      };
      this.$emit("updateStoreLoaction", lnglat);
      if (this.isMobile) {
        this.activeName = '';
      }
    },
    /**
     * postmessage 通信，请求查看父系统对应视图
     * @param {String} type 视图类型：pp、vs、store
     * @param {String} code 查询值code
     */
    showView(type) {
      let code = undefined;
      if (type == "pp") code = this.formData.siteCode;
      this.Api.showView({
        type,
        code,
      });
    },
    //关闭弹框
    closePPDetail() {
      this.visible = false;
      this.$emit("update-vsvisible", {
        visible: this.visible,
        newSuccess: this.newSuccess,
      });
    },
    saveDetail(evt, otherParams, noCheck) {
      if (this.type == "pp" && this.savePPDetailLock) {
        return;
      }
      try {
        if (this.saveDetailShake != null) clearTimeout(this.saveDetailShake);
        this.saveDetailShake = setTimeout(() => {
          this.ruleForm.cusNo = sessionStorage.cusNo;

          // 执行保存
          const doSave = () => {
            switch (this.type) {
              case 'store':
                // 店铺详情保存
                this.saveStoreInfo();
                break;
              case 'pp':
                //  机会项目详情保存
                this.savePPInfo(otherParams);
                this.savePPDetailLock = true;
                setTimeout(() => {
                  this.savePPDetailLock = false;
                }, 3000);
                break;
              case 'vs':
                //  竞品详情保存
                this.saveCompititerInfo(0);
                break;
            }
          }

          // 终止不要验证
          if ((otherParams && ['待上会', '终止'].indexOf(otherParams.siteStatusName) > -1) || noCheck) {
            return doSave();
          }

          this.$refs["basicRuleForm"].validate((valid) => {
            if (valid) {
              doSave();
            } else {
              return false;
            }
          });
          this.saveDetailShake = null;
        }, 200);
      } catch (error) {
        console.log(error)
      }

    },
    // 显示预计上会时间
    showExpectedMeetingTime() {
      this.cancelMeetingInfo(); // 清空上次填写的信息
      this.meetingFormShow = true;
    },
    // 显示上会结果
    showMeetingResult() {
      this.cancelMeetingInfo(); // 清空上次填写的信息
      this.meetingFormShow = true;
    },
    // 取消上会填写
    cancelMeetingInfo() {
      this.meetingFormShow = false;
      // 把上会信息清空，如果是原来就有的“预计上会时间”不清空
      this.ruleMeetingForm['siteStatus'] = this.ruleForm['siteStatus'];
      // ['expectedMeetingTime', 'meetingResult', 'plannedPromotionTime', 'actuallyMeetingTime', 'meetingRemark'].forEach(key => {
      ['expectedMeetingTime', 'meetingResult', 'plannedSigningTime', 'actuallyMeetingTime', 'meetingRemark', 'selectionType', 'authNumber'].forEach(key => {
        this.ruleMeetingForm[key] = this.ruleForm[key]
        if (this.ruleMeetingForm.siteStatus === '131005') {
          if ('expectedMeetingTime' !== key && 'authNumber' !== key) {
            this.ruleMeetingForm[key] = null
          }
        } else {
          this.ruleMeetingForm[key] = null
        }
      })

      this.$nextTick(() => {
        this.$refs['meetingForm'] && this.$refs['meetingForm'].clearValidate();
      })
    },
    // 确定上会信息
    confirmMeetingInfo() {
      this.$refs["meetingForm"].validate((valid) => {
        if (valid) {
          // 终止状态，点击重新上会，改为待上会状态
          var changeParams = {
            siteStatus: '131005',
            siteStatusName: '待上会'
          }
          // 待上会状态，点击确认，改为已上会状态
          if ('131005' === this.ruleMeetingForm.siteStatus) {
            changeParams = this.ruleMeetingForm.meetingResult * 1 === 1 ? {
              siteStatus: '131006',
              siteStatusName: '已上会'
            } : {
              siteStatus: '131010',
              siteStatusName: '终止'
            }
          }

          // ['expectedMeetingTime', 'meetingResult', 'plannedPromotionTime', 'actuallyMeetingTime', 'meetingRemark'].forEach(key => {
          ['expectedMeetingTime', 'meetingResult', 'plannedSigningTime', 'actuallyMeetingTime', 'meetingRemark', 'selectionType', 'authNumber'].forEach(key => {
            if (['expectedMeetingTime', 'plannedPromotionTime', 'plannedSigningTime', 'actuallyMeetingTime'].indexOf(key) > -1 && this.ruleMeetingForm[key]) {
              changeParams[key] = this.formatDate(this.ruleMeetingForm[key])
            } else {
              changeParams[key] = this.ruleMeetingForm[key]
            }
          })
          this.saveDetail(null, changeParams, true);
        } else {
          return false;
        }
      });
    },
    // 流程推进 => TODO，状态先改下
    pushProcess() {
      this.saveDetail(null, {
        siteStatus: '131007',
        siteStatusName: '已推进'
      }, true);
    },
    // 终止
    stopMeeting() {
      this.$confirm("此操作将终止上会, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.saveDetail(null, {
          siteStatus: '131010',
          siteStatusName: '终止'
        });
      });
    },
    // 显示点位保护
    handleShowProtection() {
      if (1 === this.ruleForm.protectionStatus * 1) {
        // return this.$confirm("此操作将解除点位保护, 是否继续?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // }).then(() => {
        //   this.saveDetail(null, {
        //     protectionStatus: 0
        //   });
        // });
      } else {
        this.cancelProtection(); // 开始点位保护，则清空上次填写的信息
      }
      this.protectionShow = true;
    },
    // 取消点位保护填写
    cancelProtection() {
      this.protectionShow = false;
      // 如果是添加，需要把点位保护填写的信息清空
      if (1 !== this.ruleForm.protectionStatus * 1) {
        ['protectionTimeStart', 'protectionTimeEnd', 'scopeOfProtection', 'protectionTimeFile'].forEach(key => {
          this.ruleForm[key] = null
        })
        this.ruleForm['scopeOfProtection'] = 10
      }
    },
    // 确定点位保护信息
    confirmProtection() {
      this.$refs["protectionForm"].validate((valid) => {
        if (valid) {
          this.saveDetail(null, {
            protectionStatus: (1 !== this.ruleForm.protectionStatus * 1 ? 1 : 0)
          }, true);
          this.protectionShow = false;
        } else {
          return false;
        }
      });
    },
    // 创建足迹
    showFootprint(type) {
      var footprintParams = {
        longitude: this.ruleForm.longitude,
        latitude: this.ruleForm.latitude,
        siteId: this.ruleForm.siteID,
        address: this.ruleForm.address,
        // creator: sessionStorage.loginName
        // creator: sessionStorage.renderPositionId
        creator: sessionStorage.userName
      }
      if ('pp' !== type) {
        footprintParams = {
          storeId: this.ruleForm.storeId,
          longitude: this.ruleForm.longitude,
          latitude: this.ruleForm.latitude,
          address: this.ruleForm.address,
          // creator: sessionStorage.loginName
          // creator: sessionStorage.renderPositionId
          creator: sessionStorage.userName
        }
      }

      this.footprintInfo = footprintParams;
      this.footprintEdit = true;
      this.footprintVisible = true;
    },
    // 关闭足迹弹窗
    toggleFootprint(data) {
      this.footprintVisible = data.visible;
    },
    /**
     * 保存机会点
     * 测试内容：postMessage 跨域通信，联通址见与锐力系统
     *    ———— 2.0封装测试
     * @method savePPInfo
     */
    savePPInfo(otherParams) {
      this.loading = true;
      let FormData = {};
      // if (this.isIframe) {
      //   FormData = this.$store.state.MapVSDetail.PPData;
      //   FormData.cusNo = sessionStorage.cusNo;
      //   console.log(this.$store.state.PMStoreIndex.userName)
      //   FormData.creator = this.$store.state.PMStoreIndex.userName || sessionStorage.loginName;;
      //   FormData.creatorPositionID = this.$store.state.PMStoreIndex.positionID;
      // } else {
        this.ruleForm.cusNo = sessionStorage.cusNo;
        FormData = this.ruleForm;
        // FormData.creator = sessionStorage.renderPositionId;
        FormData.creator = sessionStorage.userName;
      // }

      // 日期key处理
      // ['storeValidityPeriod', 'expectedMeetingTime', 'plannedPromotionTime', 'actuallyMeetingTime', 'protectionTimeStart', 'protectionTimeEnd'].forEach(dateKey => {
      ['storeValidityPeriod', 'expectedMeetingTime', 'plannedSigningTime', 'actuallyMeetingTime', 'protectionTimeStart', 'protectionTimeEnd'].forEach(dateKey => {
        FormData[dateKey] = this.formatDate(FormData[dateKey])
      })

      FormData.mainBrand = '105011'; // CHECK 只有一个品牌

      // 添加token
      FormData.access_token = constants.iMap.ACCESSTOKEN;

      var newParams = Object.assign({}, FormData);
      if (otherParams) {
        newParams = Object.assign(newParams, otherParams);
      }
      this.Api.savePipelineInfo(newParams)
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            if (res.data == 0) {
              this.$message.error("无创建权限");
            } else {
              this.newSuccess = true;
              this.ruleForm.siteID = res.data;
              this.$message({
                message: "保存成功",
                type: "success",
              });
              if (this.isIframe) {
                this.formData.siteCode =
                  this.$store.state.MapVSDetail.PPData.SiteCode;
                // Object.assign(this.formData, this.$store.state.MapVSDetail.PPData);
              }
              // console.log("formData", this.formData);
              this.$emit("update-vsvisible", {
                visible: this.type,
                newSuccess: this.newSuccess,
              });

              if (otherParams || !newParams.siteID) {
                // 重新获取下信息
                this.initPPInfo(true);
              }
            }
          } else if (res.code == -2) {
            this.newSuccess = false;
            this.$message.error(res.message);
          } else {
            this.newSuccess = false;
            this.$message.error("保存失败");
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          if (err.code == -2) {
            this.newSuccess = false;
            this.$message.error(err.message);
          } else if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },

    // 日期格式化：yyyy-MM-dd
    formatDate(value, format) {
      format = format || "yyyy-MM-dd";
      if (
        value && value.toString() &&
        value.toString().indexOf("-") == -1
      ) {
        value = this.$Format(
          "yyyy-MM-dd",
          value
        );
      }
      else if (value) { value = value.slice(0, 10); }
      return value
    },

    //保存store
    saveStoreInfo() {
      this.ruleForm.cusNo = sessionStorage.cusNo;
      if (
        this.ruleForm.openDate != null &&
        this.ruleForm.openDate.toString().indexOf("-") == -1
      ) {
        this.ruleForm.openDate = this.$Format(
          "yyyy-MM-dd",
          this.ruleForm.openDate
        );
      } else if (this.ruleForm.openDate != null) {
        this.ruleForm.openDate.slice(0, 10);
      }
      api
        .saveStoreInfo(this.ruleForm)
        .then((res) => {
          if (res.code == 200) {
            this.newSuccess = true;
            this.ruleForm.storeId = res.data;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.$emit("update-vsvisible", {
              visible: this.type,
              newSuccess: this.newSuccess,
            });
          } else {
            this.$message.error("保存失败");
            this.newSuccess = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$message.error("接口请求报错");
        });
    },
    /**
     * 保存、删除竞品
     * @param {Number} isDelete 0执行删除，1执行保存
     */
    saveCompititerInfo(isDelete = 0) {
      this.loading = true;
      let FormData = {};
      if (this.isIframe) {
        FormData = this.$store.state.MapVSDetail.VSData;
        FormData.cusNo = sessionStorage.cusNo;
        // FormData.creator = this.$store.state.PMStoreIndex.userName;
        // FormData.creator = sessionStorage.renderPositionId;
        FormData.creator = sessionStorage.userName;
        FormData.creatorID = this.$store.state.PMStoreIndex.positionID;
        FormData.isDelete = isDelete;
      } else {
        this.ruleForm.cusNo = sessionStorage.cusNo;
        FormData = this.ruleForm;
        FormData.isDelete = isDelete;
      }
      this.Api.saveCompetitorInfo(FormData)
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.newSuccess = true;
            this.ruleForm.id = res.data;
            this.$message({
              message: isDelete == 0 ? "保存成功" : "删除成功",
              type: "success",
            });
            if (this.isIframe) {
              this.formData.code = this.$store.state.MapVSDetail.VSData.Code;
              // Object.assign(this.formData, this.$store.state.MapVSDetail.VSData);
            }
            this.$emit("update-vsvisible", {
              visible: isDelete == 0 ? this.type : false,
              newSuccess: this.newSuccess,
            });
            if (isDelete == 1) this.visible = false;
          } else {
            this.$message.error(`${isDelete == 0 ? "保存失败" : "删除失败"}`);
            this.newSuccess = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    //初始化竞品详情
    initVSInfo() {
      try {
        this.loading = true;
        this.formData = {};
        this.formLst = {};
        var that = this;
        let ops = {
          cusNo: sessionStorage.cusNo,
          id: this.info.id,
          longitude: this.info.coordinates[0],
          latitude: this.info.coordinates[1],
        };
        that.isAllowEdit =
          that.info.id == 0 || JSON.parse(that.info.datas).isEdit == 1
            ? true
            : false;
        this.$emit("delStoreLoactionMarker"); // 清除门店、竞品坐标变更用的标记点
        api.initVSInfo(ops).then((res) => {
          if (res.code == 200) {
            let competitor = res.data.competitor;
            that.brandList = res.data.brandList;
            if (competitor != null) {
              that.ruleForm = competitor;
            } else {
              that.ruleForm = JSON.parse(that.info.datas);
              that.ruleForm.cnName = that.ruleForm.name;
              that.ruleForm.mainBrand = that.ruleForm.brand;
              that.ruleForm.brandName = that.changebrandName(
                that.ruleForm.brand
              );
              that.ruleForm.cityName = that.ruleForm.city;
              that.ruleForm.districtName = that.ruleForm.district;
              that.ruleForm.provinceName = that.ruleForm.provineName
            }
            // 址见嵌套为子系统时，竞品初始化
            if (this.isIframe) {
              this.formData = res.data.competitor;
              this.formLst = res.data;
            }
          } else {
            this.$message.error("数据获取失败");
          }
          this.loading = false;

          this.$nextTick(() => {
            this.doClearValidate();
          });
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    //查询机会点详情
    initPPInfo(isRefresh) {
      try {
        this.loading = true;
        this.formData = {};
        this.formLst = {};
        var that = this;
        let ops = {
          cusNo: sessionStorage.cusNo,
          siteId: this.info.id,
          longitude: this.info.coordinates[0],
          latitude: this.info.coordinates[1],
          positionId: sessionStorage.renderPositionId
        };

        if (isRefresh && !this.info.id) { // 没有，说明是新建的，用新建返回的siteID作为id
          ops = {
            cusNo: sessionStorage.cusNo,
            siteId: this.ruleForm.siteID,
            longitude: this.ruleForm.longitude,
            latitude: this.ruleForm.latitude,
            positionId: sessionStorage.renderPositionId
          };
        }
        this.Api.initPipelineInfo(ops).then((res) => {
          // 机会点？
          if (res.code == 200 && res.data.site != null) {
            let site = res.data.site;
            if (site != null) that.ruleForm = site;

            // 上会相关参数设置
            that.meetingFormShow = false;

            // that.approvalFlag = res.data.approvalFlag; // 是否显示，点位保护审核按钮

            that.busTypeList = res.data.busTypeList;
            that.siteTypeList = res.data.siteTypeList;
            that.siteSecondDaryTypeList = res.data.siteSecondDaryTypeList;
            that.brandList = res.data.mainBrandList;
            that.isAllowEdit =
              that.info.id == 0 || JSON.parse(that.info.datas).isEdit == 1
                ? true
                : false;
            // that.isPP2Store = that.info.id != 0 && site.progressName != "100%";
            that.progressList = res.data.progressList;
            that.statusList = res.data.statusList;
            that.siteCooperativeBusinessList = res.data.siteCooperativeBusiness;
            that.empowerList = res.data.empowerList;
            that.ppSiteLogList = res.data.siteLogs;

            // TO CHECK：详情字段Name赋值
            that.ruleForm['busType'] = '103002'; // 经营性质是加盟 且不可选
            that.changeType(that.ruleForm['busType'], 'busTypeName', that.busTypeList, 'dict_Code', 'type_Name');

            that.changeType(that.ruleForm['level'], 'levelName', that.levelList, 'value', 'label');
            that.changeType(that.ruleForm['selectionType'], 'selectionTypeName', that.selectionTypeList, 'value', 'label');
            // that.changeType(that.ruleForm['authNumber'], 'authNumberName', that.empowerList, 'value', 'label');
            that.changeType(that.ruleForm['siteType'], 'siteTypeName', that.siteTypeList, 'dict_Code', 'type_Name', true);
            that.changeType(that.ruleForm['siteSecondDaryType'], 'siteSecondDaryTypeName', that.siteSecondDaryTypeList, 'dict_Code', 'type_Name');
            that.changeType(that.ruleForm['cooperativeBusiness'], 'cooperativeBusinessName', that.siteCooperativeBusinessList, 'dict_Code', 'type_Name');

            // 是否对接收银系统
            that.ruleForm['receivingSilverSystem'] = that.ruleForm['receivingSilverSystem'] ? that.ruleForm['receivingSilverSystem'] * 1 : null;
            that.changeType(that.ruleForm['receivingSilverSystem'], 'receivingSilverSystemName', that.levelList, 'value', 'label');
            if ([0, 1, '0', '1'].indexOf(that.ruleForm['meetingResult']) > -1) {
              that.changeType(that.ruleForm['meetingResult'] + '', 'meetingResultName', that.meetingResultList, 'value', 'label');
            }

            // that.ruleForm['siteStatus'] = '131005'; // TEST
            let siteStatusList = res.data.siteStatusList;
            if (siteStatusList) {
              that.changeType(that.ruleForm['siteStatus'], 'siteStatusName', siteStatusList, 'dict_Code', 'type_Name');
            }

            // 图片拆分
            that.imageFileList = site.image ? site.image.split(',').map((img, idx) => {
              return {
                name: 'img' + idx,
                url: img
              }
            }) : []
            that.protectionTimeFileList = site.protectionTimeFile ? site.protectionTimeFile.split(',').map((img, idx) => {
              return {
                name: 'img' + idx,
                url: img
              }
            }) : []

            // 址见嵌套为子系统时，机会点初始化
            if (that.isIframe) {
              // that.formData = site;
              that.formData = that.ruleForm;
              that.formLst = res.data;
              // if (!that.info.id) that.formData.progress = "";
            }
            // that.AddressComponent(that.info.coordinates);  //获取省市区信息

          } else { // 非机会点？
            that.ruleForm = JSON.parse(that.info.datas);
            // that.ruleForm.siteName = that.ruleForm.name;
            that.ruleForm.cityName = that.ruleForm.city;
            that.ruleForm.districtName = that.ruleForm.district;
            that.progressList = res.data.progressList;
            that.statusList = res.data.statusList;
            that.empowerList = res.data.empowerList;
          }
          that.loading = false;

          if (that.info.protectionConfirm) { // 点位保护审核
            
          }

          that.$nextTick(() => {
            that.doClearValidate();
          });
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    //插叙省市区信息
    AddressComponent(location = this.info.coordinates) {
      api.searchAddresByLonLat({
        latitude: location[1],
        longitude: location[0]
      }).then(res => {
        if (res.code == 200) {
          this.ruleForm.province = res.data.provinceCode;
          this.ruleForm.provinceName = res.data.provinceName;
          this.ruleForm.city = res.data.cityCode;
          this.ruleForm.cityName = res.data.cityName;
          this.ruleForm.district = res.data.districtCode;
          this.ruleForm.districtName = res.data.districtName;
        }
      });
    },
    //store 查询
    initStoreInfo() {
      try {
        this.loading = true;
        var that = this;
        let ops = {
          cusNo: sessionStorage.cusNo,
          storeId: this.info.id,
          longitude: this.info.coordinates[0],
          latitude: this.info.coordinates[1],
        };
        this.$emit("delStoreLoactionMarker"); // 清除门店、竞品坐标变更用的标记点
        api.initStoreInfo(ops).then((res) => {
          if (res.code == 200) {
            let store = res.data.store;
            that.brandList = res.data.brandList;
            if (store != null) {
              that.ruleForm = store;
            } else {
              that.ruleForm = JSON.parse(that.info.datas);
              that.ruleForm.storeName = that.ruleForm.name;
              that.ruleForm.brandName = that.changebrandNameForStore(
                that.ruleForm.brand
              );
              that.ruleForm.cityName = that.ruleForm.city;
              that.ruleForm.districtName = that.ruleForm.district;
              that.ruleForm.storeCode = that.ruleForm.code;
            }
            that.busTypeList = res.data.busTypeList;
            that.statusList = res.data.statusList;
            that.isAllowEdit =
              // that.info.id == 0 || JSON.parse(that.info.datas).isEdit == 1
              that.info.id == 0 ? true : false;
            // 址见嵌套为子系统时，门店点初始化
            if (this.isIframe) {
              this.formData = res.data.store;
              this.formLst = res.data;
            }
          } else {
          }
          this.loading = false;

          this.$nextTick(() => {
            this.doClearValidate();
          });
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    // 删除按钮
    delSiteVs() {
      if (this.type == "pp") {
        this.$confirm("此操作将删除机会点信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.delSite();
        });
      } else if (this.type == "vs") {
        this.$confirm("此操作将删除竞品信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.saveCompititerInfo(1);
        });
      }
    },
    //删除PP
    delSite() {
      api
        .deleteSite({
          siteId: this.info.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.newSuccess = true;
            this.visible = false;
            this.$emit("update-vsvisible", {
              visible: false,
              newSuccess: this.newSuccess,
            });
            this.$message({
              type: "success",
              message: "删除成功",
            });
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((error) => {
          this.$message.error("删除失败");
        });
    },
    //修改项目进度管理
    // changeProgress() {
    //   if (!this.isEdit) this.isProgress = !this.isProgress;
    //   else this.isProgress = this.isEdit;
    // },
    // saveProgress() {
    //   api
    //     .updateProgress({
    //       progress: this.ruleForm.progress,
    //       siteId: this.ruleForm.siteID,
    //     })
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.$message({
    //           type: "success",
    //           message: "进度修改成功",
    //         });
    //         this.isProgress = false;
    //       } else {
    //         this.$message.error("进度修改失败");
    //       }
    //     });
    // },
    //机会点转门店
    showPP2Store() {
      this.showPP2StoreForm = true;
    },
    confirmPP2Store() {
      this.pp2storeForm.siteId = this.ruleForm.siteID;
      if (
        !!this.pp2storeForm.openDate &&
        this.pp2storeForm.openDate.toString().indexOf("-") == -1
      ) {
        this.pp2storeForm.openDate = this.$Format(
          "yyyy-MM-dd",
          this.pp2storeForm.openDate
        );
      }
      this.$refs["pp2storeForm"].validate((valid) => {
        if (valid) {
          api.pipelineTurnStore(this.pp2storeForm).then((res) => {
            if (res.code == 200) {
              this.type = false;
              this.newSuccess = true;
              this.$emit("update-vsvisible", {
                visible: this.type,
                newSuccess: this.newSuccess,
              });
              this.$message({
                type: "success",
                message: "机会点转开业成功",
              });
              this.showPP2StoreForm = false;
            } else {
              this.$message.error("机会点转开业失败");
            }
          });
          this.showPP2StoreForm = false;
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 通用下拉项字段值设置 val：code， valName: name, list: 列表， key: 列表的code的key, name: 列表的name的key
    changeType(val, valName, list, key, name, isInit) {
      let obj = {};
      obj = list.find((item) => {
        // 遍历list的数据
        return item[key] === val; // 筛选出匹配数据
      });
      this.ruleForm[valName] = obj ? obj[name] : '';

      if (!isInit && valName === 'siteTypeName') {
        this.ruleForm.siteSecondDaryType = '';
        this.$nextTick(() => {
          this.$refs["basicRuleForm"].clearValidate();
        });
      }
    },
    // // 获取name => 使用通用方法 changeType
    // changeSiteType(val) {
    //   let obj = {};
    //   obj = this.busTypeList.find((item) => {
    //     // 遍历list的数据
    //     return item.dict_Code === val; // 筛选出匹配数据
    //   });
    //   this.ruleForm.siteTypeName = obj.type_Name;
    // },
    // // 获取levelName => 使用通用方法 changeType
    // changeLevel(val) {
    //   let obj = {};
    //   obj = this.levelList.find((item) => {
    //     // 遍历list的数据
    //     return item.value === val; // 筛选出匹配数据
    //   });
    //   this.ruleForm.levelName = obj.label;
    // },
    // // 获取levelName => 使用通用方法 changeType
    // changeSelectionType(val) {
    //   let obj = {};
    //   obj = this.selectionTypeList.find((item) => {
    //     // 遍历list的数据
    //     return item.value === val; // 筛选出匹配数据
    //   });
    //   this.ruleForm.selectionTypeName = obj.label;
    // },
    // // 获取authNumber => 使用通用方法 changeType
    // changeAuthNumber(val) {
    //   let obj = {};
    //   obj = this.empowerList.find((item) => {
    //     // 遍历list的数据
    //     return item.value === val; // 筛选出匹配数据
    //   });
    //   this.ruleForm.authNumberName = obj.label;
    // },
    //获取name
    changebrandName(val) {
      let obj = {};
      obj = this.brandList.find((item) => {
        // 遍历list的数据
        return item.dict_Code === val; // 筛选出匹配数据
      });
      return obj.type_Name;
    },
    //获取name
    changebrandNameForStore(val) {
      let obj = {};
      obj = this.brandList.find((item) => {
        // 遍历list的数据
        return item.brandCode === val; // 筛选出匹配数据
      });
      return obj.cnName;
    },
    // // 经营状态 => 使用通用方法 changeType
    // changeStatusType(val) {
    //   let obj = {};
    //   obj = this.statusList.find((item) => {
    //     // 遍历list的数据
    //     return item.dict_Code === val; // 筛选出匹配数据
    //   });
    //   this.ruleForm.statusName = obj.type_Name;
    // },
    formatURL(url) {
        let resUrl = "";
        if(url) {
            resUrl = constants.baseURL.API + url;
        }
        return resUrl;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 机会点图片
    handleImageSuccess(res, file, fileList) {
      // this.$message({
      //     message: '上传成功',
      //     type: "success"
      // });
      // this.ruleForm.image = res.data;
      var newFileList = fileList.map(f => {
        return {
          name: f.name,
          url: f.response ? f.response.data : f.url
        }
      })
      this.ruleForm.image = newFileList.map(nF => nF.url).join(','); // 参数合成
      this.imageFileList = fileList;
    },
    handleRemove(file, fileList) {
      var newFileList = fileList.map(f => {
        return { 
          name: f.name,
          url: f.response ? f.response.data : f.url
        }
      })
      this.ruleForm.image = newFileList.map(nF => nF.url).join(','); // 参数合成
      this.imageFileList = fileList;
    },
    beforeImageUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024  < 20;

        if (!isLt2M) {
            this.$message.error('上传照片大小不能超过 20M!');
        }
        return isLt2M;
    },
    // 机会点点位保护图片
    handleProtectionTimeFileImageSuccess(res, file, fileList) {
        // this.$message({
        //     message: '上传成功',
        //     type: "success"
        // });
        // this.ruleForm.image = res.data;
        var newFileList = fileList.map(f => {
          return { 
            name: f.name,
            url: f.response ? f.response.data : f.url
          }
        })
        this.ruleForm.protectionTimeFile = newFileList.map(nF => nF.url).join(','); // 参数合成
        this.protectionTimeFileList = fileList;
    },
    handleProtectionTimeFileRemove(file, fileList) {
      var newFileList = fileList.map(f => {
        return { 
          name: f.name,
          url: f.response ? f.response.data : f.url
        }
      })
      this.ruleForm.protectionTimeFile = newFileList.map(nF => nF.url).join(','); // 参数合成
      this.protectionTimeFileList = fileList;
    },
    doClearValidate() {
      if (this.$refs["basicRuleForm"]) { // 清空验证
        this.$refs["basicRuleForm"].clearValidate();
      }
    },
    // 授权号，手机端不能输入处理
    handleAuthNumberVisibleChange(visible) {
      this.$nextTick(() => {
        if (!visible) {
          // setTimeout(() => {
            const input = this.$refs.authNumberSelect.$el.querySelector('.el-input__inner');
            if (input) {
              input.removeAttribute('readonly');
            }
          // }, 200);
        } else {
          this.$refs.authNumberSelect.focus();
        }
      });
    },
    // 上会结果，授权号，手机端不能输入处理
    handleAuthNumberMeetingVisibleChange(visible) {
      this.$nextTick(() => {
        if (!visible) {
          // setTimeout(() => {
            const input = this.$refs.authNumberSelectMeeting.$el.querySelector('.el-input__inner');
            if (input) {
              input.removeAttribute('readonly');
            }
          // }, 200);
        } else {
          this.$refs.authNumberSelectMeeting.focus();
        }
      });
    }
  },
  watch: {
    vsVisible(val) {
      this.visible = val != "";
      this.type = val;
    },
    info(val) {
      // console.log(this.type);
      // console.log(val);
      if (this.type) {
        this.activeName = '1';
        if (val.id == "0") {
          this.isEdit = true;
        } else {
          if (this.type == "vs") {
            let datas = JSON.parse(val.datas);
            this.isEdit = datas.isEdit == 0 ? false : true;
          } else {
            this.isEdit = false;
          }
        }
        if (this.type == "store") {
          this.initStoreInfo();
        } else if (this.type == "pp") {
          this.initPPInfo();
        } else if (this.type == "vs") {
          this.initVSInfo();
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.rootWidth {
  // width: 460px !important;

  &::v-deep .el-col .is-error {
    margin-bottom: 20px !important;
  }
}

.pp-Detail ::v-deep {
  .el-upload-list--picture-card .el-upload-list__item,
  .el-upload--picture-card {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
  .el-upload-list__item-status-label {
    line-height: 20px;
  }

  .upload-image {
    width: 120px;
    height: 120px;
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #c0ccda;
    overflow: hidden;
    margin: 0 6px 6px 0;
  }
  .el-select, .el-input {
    height: 32px;
  }

  .pp-main {
    max-height: calc(100vh - 230px)
  }
}

/* 移动端适配 */
@media screen and (max-width: 500px) {
   .pp-Detail {
      top: 50px;
      width: calc(100vw - 55px) !important;
      box-shadow: 2px 1px 8px 4px rgba(0, 0, 0, 0.2);
      .pp-main {
        max-height: calc(100vh - 180px);
      }
      .el-select {
        width: 100%;
      }
    }
}
</style>
<style>
/* .ppDetail {
  font-size: 13px;
  border-radius: 5px;
}

.ppDetail .el-dialog__header {
  background: #eee;
  padding: 18px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */

/* .pp-main {
  max-height: 100vh;
  overflow-y: scroll;
} */

.pp-main .el-divider--horizontal {
  margin-top: 12px;
}

.pp-main .el-form-item__label {
  font-size: 11px;
  padding-right: 6px;
}
.pp-main .fake-label {
  position: absolute;
  color: #606266;
  left: -94px;
}
.pp-main .el-form-item__content {
  font-size: 11px;
}
/* 
.pp-main .el-form-item--small.el-form-item {
  margin-bottom: 7px;
} */

.pp-partIcon {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background: #0188e2;
  display: inline-block;
  margin: 3px 5px;
}

/* .ppDetail .el-dialog__footer {
  box-shadow: 0 0 10px #ddd;
} */

.pp2storeForm .el-form-item__label {
  font-size: 11px;
}

.pp2storeForm .el-dialog--center {
  margin-top: 12vh !important;
}

.pp2storeForm .el-dialog__body {
  padding-top: 0 !important;
}

.pp2storeForm .el-divider--horizontal {
  margin-top: 10px;
  margin-left: -25px;
  padding-left: 50px;
}

.pp2storeForm .el-input>input {
  height: 35px;
}

.pp2storeForm .el-dialog__title {
  font-size: 16px !important;
}

.pp2storeForm form {
  padding-right: 20px;
}

.pp-Detail div.meeting-form {
  max-height: 100% !important;
}
.meeting-form .el-dialog {
  width: 450px;
}
.meeting-form .el-dialog .el-dialog__header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}
.meeting-form .el-dialog .el-slider__input.el-input-number--mini {
  float: none;
}
.meeting-form .el-dialog .el-slider__runway.show-input {
    width: 90% !important;
}
.meeting-form .el-dialog .el-slider__marks-text {
  white-space: nowrap;
}


/* 移动端适配 */
@media screen and (max-width: 500px) {
    .meeting-form .el-dialog {
      width: calc(100vw - 20px);
    }
}
</style>