export default {
  namespaced: true,
  actions: {},
  mutations: {
    // 机会点基本信息更新
    updatePPData(state,value) {
      state.PPData = value;
    },
    // 竞品基本信息更新
    updateVSData(state,value) {
      state.VSData = value;
    },
    // 门店基本信息更新
    updateStoreData(state,value) {
      state.StoreData = value;
    }
  },
  state: {
    PPData: {}, // 机会点基本信息存储
    VSData: {}, // 竞品基本信息存储
    StoreData: {}, // 竞品基本信息存储
  }
}