<template>
  <div class="mapsearch-container" v-if="show" :style="styleObj">
    <el-collapse accordion v-model="activeNames" class="recomment">
      <el-collapse-item name="1">
        <template slot="title">
          <span class="mapsearch-close" @click="closeMapSearch">
            <i class="el-icon-close"></i>
          </span>
          <i class="el-icon-mlist"></i>{{ $t("map.mapSelect") }}
        </template>
        <div class="mapsearch-content">
          <el-row>
            <CheckBoxMutl
              :Options="options"
              :defaultOpt="defaultOpt"
              ref="markerList"
            ></CheckBoxMutl>
          </el-row>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import CheckBoxMutl from "@/components/CheckBoxMutl.vue";
import api from "../../api/index.js";
export default {
  name: "MapSelect",
  props: ["isShow"],
  components: {
    CheckBoxMutl,
  },
  data() {
    return {
      activeNames: ["1"], //控制折叠板显示隐藏
      styleObj: {},
      checkboxGroup1: [],
      isMapShow: false,
      options: [
        {
          name: "机会点",
          type: "2",
        },
        {
          name: "门店",
          type: "3",
        },
        {
          name: "商圈",
          type: "4",
        },
        // {
        //   name:"销售驱动",
        //   type: "0"
        // },
        {
          name: "商圈活跃度热力图",
          type: "5",
        },
      ],
      markerList: [],
      defaultOpt: [
        {
          name: "门店",
          type: "3",
        },
      ],
      show: false,
    };
  },
  methods: {
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    closeMapSearch() {
      this.show = false;
      this.activeNames = ["1"];
      this.$store.state.markerType = this.markerList;
    },
    //获取竞品品牌
    getCompetitorBrand() {
      api
        .getCompetitorBrand({
          cusNo: sessionStorage.cusNo,
        })
        .then((res) => {
          if (res.code == 200 || res.code == 0) {
            if (res.data.length > 0) {
              res.data.map((m, i) => {
                var item = {
                  type: "1",
                  brandid: m.dict_Code,
                  logo: m.logo.slice(13).split(".")[0],
                };
                item.name =
                  this.$i18n.locale == "zh" ? m.type_Name : m.type_EnName; //选择语言
                this.options.splice(4 + i, 0, item); // 将竞品插入在指定位置
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.styleObj = {
      width: this.$store.state.screenWidth - 220 + "px",
    };
    this.getCompetitorBrand();
    this.show = this.isShow;
    //this.markerList = this.defaultOpt;
    this.markerList = this.$store.state.markerType;
  },
  watch: {
    //弹框时获取数值
    isShow(val) {
      console.log("val==", val);
      this.show = val;
    },
    show(val) {
      if (val == true) {
        var that = this;
        setTimeout(() => {
          that.markerList = that.$refs.markerList.selectedList;
        }, 0);
      } else {
        this.defaultOpt = this.markerList;
        this.$store.state.markerType = this.markerList;
      }
      this.$emit("updata-type", val);
    },
    //监听多选框数据变化，写入store
    markerList(val) {
      this.$store.state.markerType = val;
    },
    "$store.state.screenWidth": function (value) {
      this.styleObj = {
        width: value - 240 + "px",
      };
    },
  },
};
</script>
<style>
.mapsearch-container {
  position: absolute;
  z-index: 10;
  background: #fff;
  text-align: left;
}
.mapsearch-title {
  background: #eee;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 5px 10px 5px 20px;
}
.mapsearch-close {
  float: right;
  cursor: pointer;
}
.mapsearch-content {
  padding: 20px 10px 20px 20px;
  font-size: 13px;
}
.el-checkbox__label {
  width: 50px;
  font-size: 12px;
}
.el-checkbox-button__inner {
  font-size: 12px;
}
</style>