<template>
  <div>
    <label
      v-for="(item, index) in Options"
      :key="index"
      class="checkboxmutl el-checkbox-button"
      :class="{ 'is-checked': isCheckedOrNot(item) }"
      @change="changeSelected(item)"
    >
      <input
        type="checkbox"
        class="el-checkbox-button__original"
        :value="item"
      />
      <span
        class="el-checkbox-button__inner checkboxmutl-span"
        style="
          font-size: 12px;
          padding: 10px 20px;
          box-shadow: 0px 0 0 0 #ec7aa3;
        "
        >{{ item.name }}</span
      >
    </label>
  </div>
</template>
<script>
export default {
  name: "CheckBoxMutl",
  props: {
    Options: {
      default: [],
    },
    defaultOpt: {
      default: [],
    },
  },
  data() {
    return {
      selectedList: [], //选中的值
    };
  },
  methods: {
    //判断选项是否选中
    isCheckedOrNot(item) {
      let result = false;
      this.selectedList.map((m) => {
        if (m.type == item.type && m.name == item.name) result = true;
      });
      this.defaultOpt.map((m) => {
        if (m.type == item.type && m.name == item.name) result = true;
      });
      return result;
    },
    //复选方法
    changeSelected(value) {
      if (this.selectedList.length == 0) {
        this.selectedList.push(value);
      } else {
        for (var index = 0; index < this.selectedList.length; ) {
          var m = this.selectedList[index];
          if (m.type == value.type && m.name == value.name) {
            this.selectedList.splice(index, 1);
            break;
          } else if (index == this.selectedList.length - 1) {
            this.selectedList.push(value);
            break;
          }
          index++;
        }
      }
    },
  },
  mounted() {
    this.selectedList = this.defaultOpt;
  },
};
</script>
<style>
.checkboxmutl {
  padding: 0 5px;
  margin-bottom: 6px;
}
.checkboxmutl-span {
  font-size: 12px;
  padding: 10px 20px;
  border: 1px solid #dcdfe6 !important;
  border-radius: 4px !important;
}
</style>