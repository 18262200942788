<template>
  <div id="city-picker">
    <div class="city">
      <el-popover
        placement="top"
        v-model="visible">
        <div class="city-components" v-if="showCity">
          <span style="margin-left: 10px;">当前城市：</span>
          <el-select
            style="width: 60%;margin: 10px;"
            v-model="cityInput"
            filterable
            remote
            reserve-keyword
            placeholder="请选择城市"
            :remote-method="inputSearch"
            :loading="loading"
            @change="selectDepCity">
            <el-option
              v-for="(item, index) in searchList"
              :key="index"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
          <ul class="filter-tabar clearfix">
              <li v-for="(item,index) in cityListKey" :key="index" :class="{active:upCityListIndex==index}" @mouseover="upCityListKey(index)">{{item}}</li>
          </ul>
          <div class="city-content">
              <ul v-for="(item, i2) in upCityList" :key="i2" class="clearfix">
                  <label for="">{{item.ckey}}</label>
                  <li v-for="(ritem, i3) in item.cityList" :key="i3" @click="selectDepCity(ritem)">{{ritem.name}}</li>
              </ul>
          </div>
        </div>
        <div class="city-search city-components" v-if="showSearchCity&&false">
            <ul class="clearfix">
                <li v-for="(item, index) in searchList" :key="index" @click="selectDepCity(item)">{{item.name}}</li>
            </ul>
        </div>
        <div slot="reference" style="display: inline-block;width: auto;
          position: relative;cursor: pointer;
          top: -7px;
          left: 20px;white-space:nowrap;" @click="showCityDialog">
          <span style="color:#fff; margin-right: 4px;margin-left: 15px;" :style="objStyle">{{city}}</span>
          <img src="../../../assets/images/Icon/1.png" style="width: 20px;" />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import './CityPicker.scss'
import cityList from "china-city-data";
import pinyin from "pinyin"; //汉字转拼音
import api from "@/api/index.js"; // 引入api
import constants from "@/constants/Map.constants.js";
export default {
  name: 'app',
  props:{
    isInput: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      //isInput: true, // 选择城市类型，true：输入框，false：非输入
      showCity: false, // 全部城市列表
      showSearchCity: false, // 插叙城市列表
      cityList: cityList,
      upCityListIndex: '',
      upCityList: '',
      city: "",
      cityInput: "", // 输入城市
      loading: false, // 是否在远程搜索
      searchList: [], // 查询城市列表
      dataList: [],
      searchAllList: [], // 格式搜索列表
      map: null,
      visible: false,
      crd: "",
      objStyle: {}
    }
  },
  computed: {
    cityListData(){
      let map = {}; // 处理过后的数据对象
      let temps = []; // 临时变量
      if(this.dataList.length == 0) {
        // 城市列表为空时调用查询
        this.getCityList();
      }
      this.dataList.map(item=>{
        let citySpell = ""; 
        if(item.cnName){
          citySpell = pinyin(item.cnName, {
            style: pinyin.STYLE_NORMAL // 拼音风格
          }).join("");
          let ekey = citySpell.charAt(0).toUpperCase(); // 根据key值的第一个字母分组，并且转换成大写
          temps = map[ekey] || []; // 如果map里面有这个key了，就取，没有就是空数组
          let m = {
              id: item.code, // 城市code
              name: item.cnName,
              spell: citySpell,
              joinId: item.joinCode // 省份code
          };
          temps.push(m);
          this.setSearchAllList(m); // 变量复制不能在computed里面进行
          map[ekey] = temps;
        }
      })
      let list = [];
      for(let gkey in map) {
          list.push({
              ckey: gkey,
              cityList: map[gkey]
          })
      }

      list = list.sort((li1, li2)=> li1.ckey.charCodeAt(0) - li2.ckey.charCodeAt(0));


      let chunk = 4;
      let result =[];
      for (var i = 0, j = list.length; i < j; i += chunk) {
          result.push(list.slice(i, i + chunk));
      }
      return result;
    },
    cityListKey(){
      let cityListKey = [];
      this.cityListData.map(item=>{
          let ckeys  = '';
          item.map(ritem=>{
              ckeys += ritem.ckey;
          })
          cityListKey.push(ckeys);
      })
      this.setInitData();
      return cityListKey;
    }
  },
  methods: {
    //所有数据列表， 用于搜索
    setSearchAllList(m) {
      this.searchAllList.push(m);
    },
    //不能再computed里面对页面变量赋值，另写方法赋值，初始落焦在热门城市里
    setInitData(){
      this.upCityList = this.cityListData[0];
    },
    upCityListKey(index){
        this.upCityListIndex = index;
        this.upCityList = this.cityListData[index];
    },
    //隐藏城市列表
    hideCityDialog(){
        let that = this;
        //延迟加载失去焦点事件，避免先相应失去焦点事件而点击事件无法响应
        setTimeout(() => {
            that.showCity = false;
            that.showSearchCity = false;
        }, 200);
    },
    showCityDialog(){
      this.showCity = true;
    },
    //点击选择城市
    selectDepCity(val) {
      this.city = val.name;
      this.cityInput = val.name;
      this.showCity = false;
      this.visible = false;
      this.showSearchCity = false;
      this.$emit("select-city",val); //将城市code传递给父组件
      this.$store.state.currentCity = val;
      sessionStorage.currentCity = val.name;
      sessionStorage.currentCode = val.id;
    },
    //显示输入框删除按钮控制
    clearCity() {
      this.showCity = true;
      this.showSearchCity = false;
      this.$emit("select-city","");
      this.$store.state.currentCity = "";
    },
    //输入搜索城市
    inputSearch(val) {
      var that = this;
      this.searchList = [];
      this.loading = true;
      if(val.length > 0) {
        this.searchAllList.map(item => {
          if(item.name.indexOf(val)> -1 || item.spell.toUpperCase().indexOf(val.toUpperCase()) > -1) {
            that.searchList.push(item);
          }
        });
       // this.showCity = false;
        this.showSearchCity = true;
        this.loading = false;
      } else {
        this.showCity = true;
        this.showSearchCity = false;
      }
    },
    //获取所有城市列表
    getCityList() {
      api.searchCusCity({
        cusNo: sessionStorage.cusNo
      }).then(res => {
        if(res.code == 200 && res.data != null) {
          this.dataList = res.data;
          if(this.dataList.length == 1) {
              sessionStorage.setItem('currentCode', this.dataList[0].code);
              sessionStorage.setItem('currentCity', this.dataList[0].cnName);
          }
          for(var i = 0; i<this.dataList.length; i++) {
            if(this.dataList[i].code == sessionStorage.getItem('currentCode')) {
              this.city = this.dataList[i].cnName;
              this.cityInput = this.dataList[i].cnName;
              this.$emit("select-city", {
                id: this.dataList[i].code, // 城市code
                name: this.dataList[i].cnName,
                spell: pinyin(this.dataList[i].cnName, {
                  style: pinyin.STYLE_NORMAL // 拼音风格
                }).join(""),
                joinId: this.dataList[i].joinCode //省份code
              });
              this.$store.state.currentCity = {
                id: this.dataList[i].code, // 城市code
                name: this.dataList[i].cnName,
                spell: pinyin(this.dataList[i].cnName, {
                  style: pinyin.STYLE_NORMAL // 拼音风格
                }).join(""),
                joinId: this.dataList[i].joinCode //省份code
              };
              break;
            }
          }
        }
      })
    },
    //快速定位到当前城市
    goLocation(city){
        if(!!city) {
            var that = this;
            //调用高德web服务api获取当前位置ip
            this.$http.get(constants.iMap.GEOURL,{params: {
                address: city,
                key: constants.iMap.WEBKEY
            }}).then(res => {
                if(res.status == 200 || res.info == "OK") {
                  var crdStr = res.data.geocodes[0].location;
                  var crd = crdStr.split(",");
                  that.crd = crd;
                  if(!that.map) {
                      that.map = that.$store.state.map;
                  }
                  setTimeout(()=>{
                      if(that.map != "") {
                          that.map.flyTo({
                              center: that.crd, // 中心点
                              zoom: 11 // 缩放比例
                          });
                      }
                  },500);
                  sessionStorage.lat = crd[1];
                  sessionStorage.lng = crd[0];
                } else {
                that.$message({
                    message: `ERROR(${res.data.infocode}): ${res.data.info})`,
                    type: 'warning'
                })
                }
            })
            .catch(err => {
                console.log(err);
            })
          }
        }
  },
   created() {
       var that = this;
       if(!sessionStorage.lng || !sessionStorage.lat) {
            this.$http.get(constants.iMap.IPURL,{params: {
                key: constants.iMap.WEBKEY
            }}).then(res => {
                if(res.status == 200 || res.info == "OK") {
                    var crdStr = res.data.rectangle.split(";");
                    var sw = crdStr[0].split(",");
                    var ne = crdStr[1].split(",");
                    var crd = [(Number(sw[0])+Number(ne[0]))/2,(Number(sw[1])+Number(ne[1]))/2];
                    that.crd = crd;
                    localStorage.cityname = sessionStorage.currentCity = sessionStorage.cityname = res.data.city;
                    sessionStorage.setItem('currentCode', res.data.adcode);
                    sessionStorage.lat = crd[1];
                    sessionStorage.lng = crd[0];
                } else {
                    that.$message({
                        message: `ERROR(${res.data.infocode}): ${res.data.info})`,
                        type: 'warning'
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    },
  mounted() {
    this.getCityList();
    //this.map = this.$store.state.map;
    this.cityInput = sessionStorage.currentCity;
    this.city = sessionStorage.currentCity;
    this.objStyle = {
        color: sessionStorage.isHide=="1"?"#a19e9e":"#fff"
    }
  },
  watch: {
    city(val) {
      if (this.$store.state.location.type == "") {
        this.goLocation(val);
      }
    },
    "$store.state.currentCity": function(val) {
      this.selectDepCity(val);
    }
  }
}
</script>