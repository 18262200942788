<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    center
    class="honeycomb-detail"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(197, 197, 197, 0.6)"
  >
    <div class="honeycomb-title">
      <el-row>
        <el-col :span="6">
          <span
            style="font-size: 16px;font-weight: 600;color: rgb(138, 132, 132);"
            >基本信息</span
          >
        </el-col>
        <el-col :span="18" style="text-align:right;">
          <i
            class="el-icon-close"
            style="cursor:pointer;font-weight:600;"
            @click="closeDetail"
          ></i>
        </el-col>
      </el-row>
    </div>
    <div class="honeycomb-main">
      <el-form
        :model="honeycombForm"
        ref="saveForm"
        label-width="64px"
        size="small"
        :rules="rules"
      >
        <el-form-item label="店铺数量:" prop="storeNum">
          <span>{{ honeycombForm.storeNum }}</span>
        </el-form-item>
        <el-form-item label="竞品数量:" prop="competitorNum">
          <span>{{ honeycombForm.competitorNum }}</span>
        </el-form-item>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button
          @click="closeDetail"
          size="small"
          style="width: 100px; margin-right: 20px"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="saveDetail"
          size="small"
          style="width: 100px"
          >确 定</el-button
        >
      </div> -->
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";

export default {
  name: "MapHoneycomb",
  props: {
    honeycombVisible: {
      type: Boolean,
    },
    honeycombForm: {
      type: Object,
    },
  },
  data() {
    return {
      visible: false,
      rules: {
        storeNum: [
          {
            required: false,
            message: "请填写店铺数量",
            trigger: "submit",
          },
        ],
        competitorNum: [
          {
            required: false,
            message: "请填写竞品数量",
            trigger: "submit",
          },
        ],
      },
      loading: false,
    };
  },
  mounted() {
    this.visible = this.honeycombVisible;
  },
  methods: {
    //关闭弹框
    closeDetail() {
      this.visible = false;
      this.$emit("honeycomb-visible");
    },
    saveDetail(evt, otherParams) {
      try {
        this.$refs["saveForm"].validate((valid) => {
          if (valid) {
            this.createHoneycomb();
          } else {
            return false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    createHoneycomb() {
      console.log("honeycombForm =>", this.honeycombForm);
    },
  },
  watch: {
    honeycombVisible(val) {
      this.visible = val != "";
    },
  },
};
</script>
<style scoped lang="scss">
.honeycomb-detail {
  &::v-deep .el-dialog {
    width: 340px;

    .honeycomb-main .el-divider--horizontal {
      margin-top: 12px;
    }

    .honeycomb-main .el-form-item__label {
      font-size: 11px;
      padding-right: 6px;
      text-align: left;
    }

    .honeycomb-main .el-form-item__content {
      font-size: 11px;
    }

    .el-dialog__header {
      padding: 0;
      font-weight: bold;

      .el-icon-close {
        cursor: pointer;
        font-weight: 600;
        float: right;
        margin-top: 3px;
      }
    }

    .el-dialog__body {
      padding: 10px 10px 15px;
    }

    .honeycomb-image {
      max-height: 100px;
      max-width: 400px;
    }
    .dialog-footer {
      text-align: center;
    }
  }
  &::v-deep .el-col .is-error {
    margin-bottom: 20px !important;
  }
}

.honeycomb-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

/* 移动端适配 */
@media screen and (max-width: 500px) {
  .honeycomb-detail {
    top: 50px;
    width: 100% !important;
    z-index: 1000;
    box-shadow: 2px 1px 8px 4px rgba(0, 0, 0, 0.2);

    &::v-deep .el-dialog {
      width: 90% !important;
    }
    .honeycomb-main {
      max-height: calc(100vh - 180px);
    }
    .el-select {
      width: 100%;
    }
  }
}
</style>
