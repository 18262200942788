import axios from "axios";
import constants from "../constants/Map.constants.js";
// import router from "../router";
import { Message } from 'element-ui';

export function fetch(options) {

    return new Promise((resolve, reject) => {
        const instance = axios.create({
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "time": new Date().getTime(),
                "token": sessionStorage.token || "",
                "timestamp": sessionStorage.timestamp || "",
                "mode": "no-cors"
            },
            timeout: 180000,
            baseURL: constants.baseURL.API
        });
        if (options.method.toLowerCase() == "post") {
            instance(options)
                .then(response => {
                    //请求成功
                    if (response.status == 200) {
                        if (response.data.code == 200) {
                            resolve(response.data);
                        } else {
                            // Message({
                            //     message: response.data.message,
                            //     type: 'error',
                            //     duration: 5 * 1000
                            // });
                            reject(response.data);
                        }
                    } else {
                        Message({
                            message: "后台程序出错，请联系管理员处理", //登录超时，请重新登录   
                            type: 'error',
                            duration: 5 * 1000
                        });
                    }
                })
                .catch(err => {
                    //请求失败返回错误信息
                    reject(err);
                    Message({
                        message: "服务器异常",
                        type: 'error',
                        duration: 5 * 1000
                    })
                })
        } else if (options.method.toLowerCase() == "get") {
            instance.get(options.url, {
                params: options.data
            }).then(response => {
                //请求成功
                if (response.status == 200) {
                    if (response.data.code == 200) {
                        resolve(response.data);
                    } else {
                        Message({
                            message: response.data.message,
                            type: 'error',
                            duration: 5 * 1000
                        });
                    }
                } else {
                    Message({
                        message: "后台程序出错，请联系管理员处理", //登录超时，请重新登录   
                        type: 'error',
                        duration: 5 * 1000
                    });
                }
            }).catch(err => {
                //请求失败返回错误信息
                reject(err);
                Message({
                    message: "服务器异常",
                    type: 'error',
                    duration: 5 * 1000
                });
            })
        }
    });
};