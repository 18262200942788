<template>
    <div id='state-legend' class='legend'>
        <div v-for="(item, i) in legendList" :key="i">
            <h4>{{item.name}}</h4>
            <div v-for="(children, j) in item.children" :key="j"><span :style="{'background-color': children.color}"></span>{{children.name}}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Legend",
    data() {
        return {
            legendList: [
                    {
                        name: "商圈评分",
                        children: [
                            {
                                name: "8-10",
                                color: "#67000d"
                            },
                            {
                                name: "6-8",
                                color: "#9f0e15"
                            },
                            {
                                name: "4-6",
                                color: "#cb2120"
                            },
                            {
                                name: "2-4",
                                color: "#e93e30"
                            },
                            {
                                name: "0-2",
                                color: "#fb6a4a"
                            }
                        ]
                    },
                    {
                        name: "热力图评分",
                        children: [
                             {
                                name: "10",
                                color: "#67000d"
                            },
                            {
                                name: 9,
                                color: "#9f0e15"
                            },
                            {
                                name: 8,
                                color: "#cb2120"
                            },
                            {
                                name: 7,
                                color: "#e93e30"
                            },
                            {
                                name: 6,
                                color: "#fb6a4a"
                            },
                            {
                                name: 5,
                                color: "#fc9374"
                            },
                        ]
                    }
                ]
        };
    },
    method: {}
}
</script>
<style>
.legend {
    background-color: #ffffffd9;
    border-radius: 3px;
    bottom: 30px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.10);
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 10px;
    position: absolute;
    right: 50px;
    z-index: 1;
    width: 77px;
}
 
.legend h4 {
    margin: 0 0 10px;
}
 
.legend div span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}
</style>