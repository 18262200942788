import MapVSDetail from "./MapVSDetail";
import MapTADetail from "./MapTADetail";

const PMStoreIndex = {
  namespaced: true,
  state: {
    isIframe: window.top !== window.self, // 址见是否内嵌为子系统标志
    positionID:"", // 主系统传入的账号positionID
    userName:"", // 主系统传入的账号userName
    legendList:undefined, // 图例筛选- 标记点、多边形商圈列表数据
  },
  mutations: {
    updataMainSysCreator(state,data) {
      state.positionID = data.positionID;
      state.userName = data.userName;
    },
    updateLegendList(state,data) {
      state.legendList = data;
    }
  }
}

export {
  PMStoreIndex,
  MapVSDetail,
  MapTADetail
}