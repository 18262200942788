<!-- components/Dialog/Map/MapVSDetail | 适配竞品详情基本信息编辑 -->
<template>
  <div>
    <!-- 竞品 -->
    <el-row>
      <!-- 编号 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.code.label"
          label-width="90px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.code"
            disabled
          ></el-input>
          <span v-else>{{ myFormData.code }}</span>
        </el-form-item>
      </el-col>
      <!-- 状态 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.status.label"
          label-width="80px"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in myFormLst[smartCompetitor.status.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
          changeType(
          myFormData.status,
          myFormLst[smartCompetitor.status.list],
          "dict_Code",
          "type_Name"
          )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 名称 -->
      <el-col :span="24">
        <el-form-item
          :label="smartCompetitor.cnName.label"
          label-width="90px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.cnName"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ myFormData.cnName }}</span>
        </el-form-item>
      </el-col>
      <!-- 品牌 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.mainBrand.label"
          label-width="90px"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.mainBrand"
            placeholder="请选择"
            @change="mainBrandChange"
          >
            <el-option
              v-for="item in myFormLst[smartCompetitor.mainBrand.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
          changeType(
          myFormData.mainBrand,
          myFormLst[smartCompetitor.mainBrand.list],
          "dict_Code",
          "type_Name"
          )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 子品牌 -->
      <!-- <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.subBrand.label"
          label-width="80px"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.subBrand"
            placeholder="请选择"
            @change="subBrandChange"
          >
            <el-option
              v-for="item in subBrandList"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
            changeType(
              myFormData.subBrand,
              myFormLst[smartCompetitor.subBrand.list],
              "dict_Code",
              "type_Name"
            )
          }}</span>
        </el-form-item>
      </el-col> -->
      <!-- 子品牌类型 -->
      <!-- <el-col :span="24">
        <el-form-item
          :label="smartCompetitor.subBrandType.label"
          label-width="90px"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.subBrandType"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in subBrandTypeList"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
          changeType(
          myFormData.subBrandType,
          myFormLst[smartCompetitor.subBrandType.list],
          "dict_Code",
          "type_Name"
          )
          }}</span>
        </el-form-item>
      </el-col> -->
      <!-- 面积 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.areaScope.label"
          label-width="90px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.areaScope"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{
          changeType(
          myFormData.areaScope,
          myFormLst[smartCompetitor.areaScope.list],
          "dict_Code",
          "type_Name"
          )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 楼层 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.floor.label"
          label-width="80px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.floor"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ myFormData.floor }}</span>
        </el-form-item>
      </el-col>
      <!-- 经营主体 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.busCom.label"
          label-width="90px"
        >
          <el-select
            v-if="myIsEdit"
            v-model="myFormData.busCom"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in myFormLst[smartCompetitor.busCom.list]"
              :key="item.id"
              :label="item.type_Name"
              :value="item.dict_Code"
            ></el-option>
          </el-select>
          <span v-else>{{
          changeType(
          myFormData.busCom,
          myFormLst[smartCompetitor.busCom.list],
          "dict_Code",
          "type_Name"
          )
          }}</span>
        </el-form-item>
      </el-col>
      <!-- 开业时间 -->
      <el-col :span="12">
        <el-form-item
          :label="smartCompetitor.openDate.label"
          label-width="80px"
        >
          <el-date-picker
            v-if="myIsEdit"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="请选择"
            v-model="myFormData.openDate"
            style="width: 100%"
          ></el-date-picker>
          <span v-else>{{ myFormData.openDate }}</span>
        </el-form-item>
      </el-col>
      <!-- 销售MPSA -->
      <el-col :span="24">
        <el-form-item
          :label="smartCompetitor.sales.label"
          label-width="90px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.sales"
            placeholder="请输入"
          >
            <template slot="append">（万元）</template>
          </el-input>
          <span v-else>{{ myFormData.sales }}</span>
        </el-form-item>
      </el-col>
      <!-- 备注 -->
      <el-col :span="24">
        <el-form-item
          :label="smartCompetitor.remark.label"
          label-width="90px"
        >
          <el-input
            v-if="myIsEdit"
            v-model="myFormData.remark"
            placeholder="请输入"
          ></el-input>
          <span v-else>{{ myFormData.remark }}</span>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import smartCompetitor from "./SmartCompetitorConfig.json";
export default {
  name: "MapVSDetailVS",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formLst: {
      type: Object,
    },
    formData: Object,
  },
  data() {
    return {
      smartCompetitor, // smart_competitor 表字段配置
      subBrandList: undefined,
      subBrandTypeList: undefined,
    };
  },
  mounted() { },
  methods: {
    /**
     * 依据code码和查询列表获取页面显示名称
     */
    changeType(Code, List, itemCode, itemName) {
      if (!Code) return "";
      if (!List) return "";
      let obj = {};
      obj = List.find((e) => {
        // 遍历list的数据
        return e[itemCode] == Code; // 筛选出匹配数据
      });
      return obj ? obj[itemName] : "";
    },
    // 主品牌变更
    mainBrandChange(e) {
      this.myFormData.subBrand = "";
      this.myFormData.subBrandType = "";
      let mainBrandDef1 = this.myFormLst[
        this.smartCompetitor.mainBrand.list
      ].filter((j) => {
        return j.dict_Code == e;
      });
      this.filterSubBrandByMain(mainBrandDef1[0].def1);
    },
    // 子品牌变更
    subBrandChange(e) {
      this.myFormData.subBrandType = "";
      this.filterSubBrandTypeBySubBrand(e);
    },
    // 由主品牌筛选子品牌列表
    filterSubBrandByMain(e) {
      this.subBrandList = this.myFormLst[
        this.smartCompetitor.subBrand.list
      ].filter((j) => {
        return j.def1 == e;
      });
    },
    // 由子品牌筛选子品牌类型列表
    filterSubBrandTypeBySubBrand(e) {
      this.subBrandTypeList = this.myFormLst[
        this.smartCompetitor.subBrandType.list
      ].filter((j) => {
        return j.def1 == e;
      });
    },
    ...mapMutations("MapVSDetail", { updateVSData: "updateVSData" }),
  },
  computed: {
    myIsEdit() {
      return this.isEdit;
    },
    myFormData() {
      return this.formData;
    },
    myFormLst() {
      return this.formLst;
    },
  },
  watch: {
    /**
     * 编辑数据保存
     */
    myFormData: {
      deep: true,
      handler(newV, oldV) {
        this.updateVSData(newV);
      },
    },
    // 初始化接口成功返回数据，子品牌列表初始化
    myFormLst(newV) {
      if (!!newV.brandList) {
        let mainBrandDef1 = newV.brandList.filter((e) => {
          return e.dict_Code == this.myFormData.mainBrand;
        });
        let def1 = mainBrandDef1.length > 0 ? mainBrandDef1[0].def1 : "";
        this.filterSubBrandByMain(def1);
        this.filterSubBrandTypeBySubBrand(this.myFormData.subBrand);
      }
    },
  },
};
</script>
<style scoped>
.el-checkbox {
  margin-right: 0px !important;
}

.el-checkbox>>>.el-checkbox__label {
  width: 38px !important;
}

.el-input>>>.el-input-group__append {
  padding: 0px !important;
}
</style>