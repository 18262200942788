// 总线事件列表
let busList = {
  savePipelineInfo:busEmit, // 保存机会点
  saveCompetitorInfo:busEmit, // 保存竞品
  saveTAInfo:busEmit, // 保存商圈
  quickFind:busEmit, // 通信快速定位
  approvalPP:busEmit, // 通信快速定位
  pickLocation:busEmit, // 测试随机点生成商圈 -- 拾取坐标
  createTa:busEmit, // 测试随机点生成商圈 -- 创建商圈
  deleteTa:busEmit, // 测试随机点生成商圈 -- 删除商圈
}

// 总线事件发布 —— 执行函数
function busEmit(busName,data,VM) {
  VM.$bus.$emit(busName, data);
}

/**
 * 事件总线 —— 发布事件
 * @param {*} _this VM实例
 */
 function $_bus(_this) {
  window.addEventListener('message',
    event => {
      try {
        busList[event.data.api](event.data.api,event,_this);
      } catch (error) {

      }
    }
  )
};

export {
  $_bus
}