<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    center
    class="footprint-detail"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(197, 197, 197, 0.6)"
    :modal="false"
    :close-on-click-modal="false"
  >
    <div class="footprint-title" slot="title">
      <!-- <strong>{{ canEdit ? '创建足迹' : '足迹详情'}}</strong> -->
      <strong>足迹信息</strong>
      <i
        class="el-icon-close"
        @click="closeDetail"
      ></i>
    </div>
    <div
      class="footprint-main"
      v-if="!(this.info && this.info.fromurl)"
    >
      <div
        slot="footer"
        class="dialog-footer"
      >
        <!-- <el-button
          @click="closeDetail"
          size="small"
          style="width: 100px; margin-right: 20px"
        >取 消</el-button> -->
        <el-button
          type="primary"
          @click="saveDetail"
          size="mini"
          style="width: 60px"
        >保存</el-button>
      </div>
      <el-form
        :model="footprintForm"
        ref="saveForm"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <!-- 足迹 -->
        <el-row>
          <!-- 备注 -->
          <el-col>
            <el-form-item label="备注:" prop="remark">
              <el-input
                type="textarea"
                v-model="footprintForm.remark"
                placeholder="请输入"
              ></el-input>
              <!-- <span v-else>{{ footprintForm.remark }}</span> -->
            </el-form-item>
          </el-col>
          <!-- 照片 -->
          <el-col :sm="24" :xs="24">
            <el-form-item label="照片:" prop="image">
              <el-upload
                style="display:inline-block;"
                class="avatar-uploader"
                :class="imageFileList.length > 2 ? 'image-exceed' : ''"
                :action="imageURL"
                :headers="headersObj"
                :file-list="imageFileList"
                :show-file-list="true"
                list-type="picture-card"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :on-remove="handleRemove"
                :on-success="handleImageSuccess"
                :before-upload="beforeImageUpload">
                <!-- <img v-if="footprintForm.image" :src="footprintForm.image" class="footprint-image"> -->
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__text">最多上传3张</div>
              </el-upload>
              <!-- <img v-else-if="footprintForm.image" :src="footprintForm.image" class="footprint-image"> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="footprint-list">
      <div class="list-title">
        {{ `我的足迹(${footprintMyNum})` }}
      </div>
      <div class="list-items">
        <div class="list-item" v-for="(item, index) in footprintList" :key="index">
          <div class="list-item-name">
            {{ item.updater }}
            <div class="list-item-updateTime">{{ item.updateTime }}</div>
          </div>
          <div class="list-item-content">
            {{ item.remark }}
            <div style="position: relative;">
              <el-image style="margin-right: 10px;" v-for="(img, index) in item.image.split(',')" :src="img" :key="index" :preview-src-list="item.image.split(',')" alt=""></el-image>
              <!-- 创建者是自己才可以删除 => 后端判断 -->
              <el-button
                v-if="item.creator == footprintVal.userId || isSystemAdmin"
                type="danger"
                @click="deleteFootPrint(item.id)"
                size="mini"
                style="position: absolute; right: 0; bottom: 0;"
              >删 除</el-button>
            </div>
          </div>
        </div>
        <div class="list-item-empty" v-if="footprintList.length === 0">暂无足迹</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";

export default {
  name: "MapFootprint",
  props: ["footprintVisible", "info", "isEdit"],
  data() {
    return {
      activeName: "1",
      visible: false,
      type: "",
      footprintForm: {
        image: "", // 照片
        remark: "", // 备注 [店铺、竞品 同]
      },
      imageFileList: [], // 足迹图片转换后列表
      footprintList: [], // 原有足迹信息
      footprintMyNum: 0,
      rules: {
        storeCode: [
          {
            required: false,
            message: "请上传图片",
            trigger: "submit",
          },
        ],
        image: [
          { required: true, message: "请上传图片", trigger: ['change', "submit"] },
        ],
        remark: [
          {
            required: false,
            message: "请填写备注",
            trigger: "submit",
          },
        ]
      },
      loading: false,
      saveDetailShake: null,
      imageURL: "", // 图片上传地址
      headersObj: {}, // 图片上传请求header
      hasFootprint: false, // 是否已有足迹信息

      footprintVal: {}, // 存初始参数
      footId: null, // 父id
      isSystemAdmin: ['1700446610396', '1710147167859'].indexOf(sessionStorage.renderPositionId) > -1
    };
  },
  computed: {
    canEdit() {
      // return this.isEdit && !this.hasFootprint
      // return this.isEdit
      return true;
    }
  },
  mounted() {
    this.visible = this.footprintVisible;

    this.imageURL = this.formatURL("common/uploadImage");
    this.headersObj = {
      "time": new Date().getTime(),
      "token": sessionStorage.token || "",
      "timestamp": sessionStorage.timestamp || ""
    };
  },
  methods: {
    //关闭弹框
    closeDetail() {
      this.visible = false;
      this.$emit("footprint-visible", {
        visible: this.visible
      });
    },
    saveDetail(evt, otherParams) {
      try {
        if (this.saveDetailShake != null) clearTimeout(this.saveDetailShake);
        this.saveDetailShake = setTimeout(() => {
          this.footprintForm.cusNo = sessionStorage.cusNo;
          this.$refs["saveForm"].validate((valid) => {
            if (valid) {
              this.createFootprint()
            } else {
              return false;
            }
          });
          this.saveDetailShake = null;
        }, 200);
      } catch (error) {
        console.log(error)
      }

    },
    // 创建足迹
    createFootprint(type) {
      this.loading = true;
      const footprintParams = Object.assign({}, this.info, this.footprintForm);
      console.log('info, footprintParams :>> ', this.info, footprintParams);

      // 经纬度用起始统一的值
      footprintParams.longitude = this.$DecimalPrecision(this.footprintVal.longitude, 6);
      footprintParams.latitude = this.$DecimalPrecision(this.footprintVal.latitude, 6);
      footprintParams.creator = this.footprintVal.userId;
      footprintParams.userId = this.footprintVal.userId;
      footprintParams.address = this.footprintVal.address;

      // 在原有基础上新增的时候需要传将原足迹id赋值到footId上
      if (this.footId) {
        footprintParams.footId = this.footId;
        delete footprintParams.id;
        delete footprintParams.datas;
      }
      api.saveFootprint(footprintParams).then(res => {
          this.loading = false;
          if (res.code == 200) {
            if (res.data == 0) {
              this.$message.error("无创建权限");
            } else {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.imageFileList = [];
              this.$emit("create-success");
              // this.closeDetail();
              this.initFootprint(res.data);
            }
          } else {
            this.$message.error("保存失败");
          }
      }).catch(e => {
        this.loading = false;
      })
    },
    // 查询足迹详情
    initFootprint(resData) {
      console.log('footprintVal, this.footprintVal :>> ', this.footprintVal);
      // if (footprintVal.datas) {
      //   const data = JSON.parse(footprintVal.datas);
      //   this.footprintForm.image = data.image;
      //   this.footprintForm.remark = data.remark;
      // }

      // 清空输入值
      this.footprintForm.image = '';
      this.footprintForm.remark = '';
      if (!this.footprintVal.id) { // 如果是新增的，且没返回id，关闭当前弹窗，用sign请求足迹列表
        // resData = resData || 19; // test
        if (resData) { // 有返回值
          this.footprintVal.id = resData;
          // 足迹时，用第一个创建时，返回的id作为父id（这里应该不用判断，只有标注时footprintVal才有可能没有id参数值）
          if ('footPrint' === this.footprintVal.idType) {
            this.footId = resData;
          }
        } else {
          this.closeDetail(); // 关闭弹窗先
          return;
        }
      }

      this.getFootprint();
    },
    // 执行获取足迹列表
    getFootprint() {
      api.footprintInfo({
        id: this.footprintVal.id,
        idType: this.footprintVal.idType,
        userId: this.footprintVal.userId
      }).then(res => {
        console.log('res: ', res)
        if (res.code == 200 && res.data) {
          this.hasFootprint = true;
          // this.footprintForm.image = res.data.image;
          // this.footprintForm.remark = res.data.remark;
          if (res.data && res.data[0]) { // 至少有一条数据
            this.footprintMyNum = res.data[0]['num'] || 0;
            if ('footPrint' !== this.footprintVal.idType) { // 机会点和既有店的用第一个足迹id做父id
              this.footId = res.data[0]['id'];
            }
          }

          this.footprintList = res.data ? res.data.reverse() : [];
        }
      });
    },
    formatURL(url) {
        let resUrl = "";
        if(url) {
            resUrl = constants.baseURL.API + url;
        }
        return resUrl;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      var newFileList = fileList.map(f => {
        return { 
          name: f.name,
          url: f.response ? f.response.data : f.url
        }
      })
      this.footprintForm.image = newFileList.map(nF => nF.url).join(','); // 参数合成
      this.imageFileList = fileList;
    },
    handleImageSuccess(res, file, fileList) {
        // this.footprintForm.image = res.data;
        var newFileList = fileList.map(f => {
        return {
          name: f.name,
          url: f.response ? f.response.data : f.url
        }
      })
      this.footprintForm.image = newFileList.map(nF => nF.url).join(','); // 参数合成
      this.imageFileList = fileList;
    },
    beforeImageUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024  < 20;

        if (!isLt2M) {
        this.$message.error('上传照片大小不能超过 20M!');
        }
        return isLt2M;
    },
    // 删除足迹
    deleteFootPrint(footId) {
      this.$confirm("确定删除当前足迹?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.deleteFootprint({
          Id: footId,
          userId: this.footprintVal.userId
        }).then(res => {
          console.log('res: ', res)
          if (res.code == 200 && res.data && ['只可删除本人创建的足迹', '删除失败,此足迹不存在!'].indexOf(res.data) === -1) {
            if (footId === this.footprintVal.id) {
              this.$emit("create-success");
              this.closeDetail(); // 删除主足迹，关闭弹窗
            } else {
              this.getFootprint(); // 删除其他足迹，刷新本足迹数据
            }
          } else {
            this.$message.error(res.data || '删除出错');
          }
        }).catch(e => {
          this.$message.error(e.message || '删除出错');
        });
      });
    }
  },
  watch: {
    footprintVisible(val) {
      this.visible = val != "";
      if (!this.visible) {
        this.footId = null;
        this.footprintList = [];
        this.footprintMyNum = 0;
      }
      this.type = val;
    },
    info(val) {
      // console.log(this.type);
      console.log(val);

      this.footprintForm = {
        image: "", // 照片
        remark: "", // 备注 [店铺、竞品 同]
      };

      var footprintId = val.id || val.storeId || val.siteId; // 足迹id、门店id、机会点id
      var idType = 'footPrint';
      if (val.storeId) {
        idType = 'store'
      } else if (val.siteId) {
        idType = 'site'
      } else if (val.id) {
        this.footId = val.id
      }

      this.footprintVal = {
        longitude: this.$DecimalPrecision(val.longitude ? val.longitude : this.info.coordinates[0], 6),
        latitude: this.$DecimalPrecision(val.latitude ? val.latitude : this.info.coordinates[1], 6),
        // userId: sessionStorage.loginName,
        userId: sessionStorage.userName,
        idType: idType,
        id: footprintId,
        address: val.address
      }
      if (footprintId) { // 详情
        this.initFootprint();
        this.visible = true;
      } else {
        this.footprintList = [];
      }

      if (!val.address) { // 没有传地址，用经纬度获取一下
        this.$http
          .get(constants.iMap.REGEOURL, {
            params: {
              location: `${this.footprintVal.longitude},${this.footprintVal.latitude}`,
              key: constants.iMap.WEBKEY,
            },
          })
          .then((res) => {
            if (res.body && res.body.info === "OK") {
              // console.log('res.body: ', res.body)
              this.footprintVal.address = res.body.regeocode.formatted_address;
            }
          });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.footprint-detail {
  &::v-deep .el-dialog {
    position: absolute;
    top: 122px;
    right: 15px;
    background: #ffffffe6;
    padding: 10px;
    width: 520px;
    margin: 0 !important;
    max-height: calc(100vh - 150px);
    &__body {
      padding: 0;
      max-height: calc(100vh - 204px);
      overflow: auto;
    }
    .footprint-title {
      text-align: left;
    }

    .footprint-main {
      padding: 10px 0 20px;
      border-bottom: 1px solid #ddd;
    }

    .footprint-main .el-divider--horizontal {
      margin-top: 12px;
    }

    .footprint-main .el-form-item__label {
      font-size: 11px;
      padding-right: 6px;
      text-align: left;
    }

    .footprint-main .el-form-item__content {
      font-size: 11px;
    }

    .el-dialog__header {
      padding: 0 0 10px;
      border-bottom: 1px solid #ddd;
      font-weight: bold;

      .el-icon-close {
        cursor: pointer;
        font-weight: 600;
        float: right;
        margin-top: 3px;
      }
    }
    .avatar-uploader {
      .el-upload-list__item,
      .el-upload--picture-card {
        text-align: center;
        width: 110px;
        height: 110px;
        line-height: 120px;
        .el-upload-list__item-status-label {
          line-height: normal;
        }
      }
      .el-upload-list__item-thumbnail {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      &.image-exceed {
        .el-upload--picture-card {
          display: none;
        }
      }
    }
    .footprint-image {
      max-height: 100px;
      max-width: 400px;
    }
    .dialog-footer {
      text-align: left;
      padding-bottom: 10px;
    }
    .footprint-list {
      .list-title {
        font-weight: bold;
        padding: 10px 0;
      }
      .list-items {
        padding: 10px 0;
        .list-item-empty {
          padding: 20px;
          text-align: center;
          color: #666;
        }
        .list-item {
          padding: 10px 0;
          border-bottom: 1px solid rgba(0, 0, 0, .1);
          .list-item-name {
            position: relative;
            color: #666;
            padding-bottom: 5px;
            .list-item-updateTime {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .list-item-content {
            color: #333;
            img {
              display: block;
              height: 100px;
              width: auto;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
  &::v-deep .el-col .is-error {
    margin-bottom: 20px !important;
  }
}

/* 移动端适配 */
@media screen and (max-width: 500px) {
   .footprint-detail {
      width: 100% !important;
      z-index: 1000;
      // box-shadow: 2px 1px 8px 4px rgba(0, 0, 0, 0.2);

      &::v-deep .el-dialog {
        top: 50px;
        width: 90% !important;
        max-height: calc(100vh - 60px);
        &__body {
          max-height: calc(100vh - 150px);
        }
      }
      .el-select {
        width: 100%;
      }
    }
}
</style>
