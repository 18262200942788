<template>
  <el-container style="height: 100%">
    <el-header v-show="isHide == '0'">
      <!-- 右侧header -->
      <!-- <Header /> -->
      <HeaderMenu />
    </el-header>
    <!-- 右侧页面主题 -->

    <!-- 右侧主页面：地图及表格 -->
    <el-main style="padding: 0px">
      <!-- <MapSearch :isShow="mapType=='mapSearch'" @updata-type="updataMapType"></MapSearch> -->
      <MapSelect
        :isShow="mapType == 'legendScreening'"
        @updata-type="updataMapType"
      ></MapSelect>
      <!-- <MapRecommend :isShow="mapType=='ppRecommend'" @updata-type="updataMapType"></MapRecommend> -->
      <!-- <MapMark :isShow="mapType=='mapMark'" @updata-type="updataMapType"></MapMark> -->
      <router-view />
    </el-main>
  </el-container>
</template>
<script>
//import Header from "@/components/Header.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import MapSearch from "@/components/Map/MapSearch.vue";
// import MapRecommend from "@/components/Map/MapRecommend.vue";
import MapSelect from "@/components/Map/MapSelect.vue";
// import MapMark from "@/components/Map/MapMark.vue";

export default {
  components: {
    // Header,
    //MapSearch,MapMark,MapRecommend,
    MapSelect,
    HeaderMenu,
  },
  data() {
    return {
      listIndex: "/List/pipeline",
      listVisibility: "visibility:hidden",
      fit: "fill",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      isMap: true,
      mapType: "",
      isLogin: sessionStorage.getItem("isLogin") || 0,
      isMapOrList: true,
      isChange: false,
      isHide: "0",
    };
  },
  mounted() {
    this.isHide = sessionStorage.isHide;
    this.$store.state.screenWidth = document.body.clientWidth;
    this.$store.state.screenHeight =
      document.getElementById("app").offsetHeight;
    window.onresize = () => {
      return (() => {
        this.$store.state.screenWidth = document.body.clientWidth;
        this.$store.state.screenHeight = document.getElementById("app").offsetHeight;
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        this.$store.commit('updateIsMobile', !!flag);
      })();
    };
  },
  methods: {
    handleSelect(key) {
      if (this.mapType == key) this.mapType = "";
      var that = this;
      setTimeout(function () {
        that.mapType = key;
      }, 0);
      this.$store.state.mapCommand = key;
    },
    goMap(key, keyPath) {
      this.$router.push("/" + keyPath[0]);
    },
    goList(key, keyPath) {
      this.$router.push("/" + keyPath[0]);
    },
    toList() {
      this.isMap = false;
      this.mapType = ""; //切换到列表模式后 关闭地图弹框
      this.$store.state.mapCommand = "";
      this.$router.push("/List/pipeline");
      this.listVisibility = "visibility:visible";
    },
    toMap() {
      this.isMap = true;
      this.$router.push("/");
      this.listVisibility = "visibility:hidden";
    },
    updataMapType(value) {
      if (!value && !this.mapType) {
        this.handleSelect("");
      }
    },
  },
  watch: {
    $route(to) {
      if (to.path == "/" || to.path.indexOf("/List/") > -1) {
        this.isMapOrList = true;
        if (to.path == "/") {
          this.isMap = true;
          this.listVisibility = "visibility:hidden";
        } else {
          this.isMap = false;
          this.listVisibility = "visibility:visible";
        }
      } else {
        this.isMapOrList = false;
      }
    },
    "$store.state.mapType": function (val) {
      this.mapType = val;
    },
  },
};
</script>
<style>
.el-header {
  height: 50px !important;
}
</style>
