<template>
    <div class="mapsearch3-container" v-if="show">
        <div class="mapsearch3-content">
            <el-row>
                <el-button :size="size" autofocus @click="type='POI'">POI</el-button>
                <!-- <el-button :size="size" @click="type='POI指标'">POI指标</el-button>
                <el-button :size="size" @click="type='人口'">人口</el-button> -->
                <el-button :size="size" @click="type='网格'">网格</el-button>
                <el-button :size="size" type="primary" @click="confirm" style="float:right">确定</el-button>
            </el-row>
              <!-- POI -->
            <el-row style="padding-top:10px;font-size: 12px;" v-if="type=='POI'"> 
                <!-- <el-row style="padding-top:10px;">
                    <label for="">竞品:</label>
                    <el-checkbox-group v-model="brands" style="display:inline-block;padding-left: 10px;">
                    <el-checkbox label="肯德基"><span style="font-size:12px;">肯德基</span></el-checkbox>
                    <el-checkbox label="德克士"><span style="font-size:12px;">德克士</span></el-checkbox>
                    <el-checkbox label="永和大王"><span style="font-size:12px;">永和大王</span></el-checkbox>
                    <el-checkbox label="真功夫"><span style="font-size:12px;">真功夫</span></el-checkbox>
                    <el-checkbox label="老乡鸡"><span style="font-size:12px;">老乡鸡</span></el-checkbox>
                    </el-checkbox-group>
                </el-row> -->
                <el-row style="padding-top:10px;">
                    <label for="">交通:</label>
                    <el-checkbox-group v-model="traffic" style="display:inline-block;padding-left: 10px;">
                    <el-checkbox label="Bus"><span style="font-size:12px;">公交</span></el-checkbox>
                    <el-checkbox label="Metro"><span style="font-size:12px;">地铁</span></el-checkbox>
                    <el-checkbox label="Railway"><span style="font-size:12px;">火车站</span></el-checkbox>
                    <el-checkbox label="BusStation"><span style="font-size:12px;">汽车站</span></el-checkbox>
                    <el-checkbox label="Airport"><span style="font-size:12px;">机场</span></el-checkbox>
                    <el-checkbox label="Parking"><span style="font-size:12px;">停车场</span></el-checkbox>
                    </el-checkbox-group>
                </el-row>
                <el-row style="padding-top:10px;">
                    <label for="">商场:</label>
                    <el-checkbox-group v-model="mall" style="display:inline-block;padding-left: 10px;">
                    <el-checkbox label="ShoppingMall"><span style="font-size:12px;">购物中心</span></el-checkbox>
                    <el-checkbox label="Mall"><span style="font-size:12px;">商场</span></el-checkbox>
                    <el-checkbox label="Market"><span style="font-size:12px;">超市</span></el-checkbox>
                    <el-checkbox label="Convenience"><span style="font-size:12px;">便利店</span></el-checkbox>
                    </el-checkbox-group>
                </el-row>
                <el-row style="padding-top:10px;">
                    <label for="">学校:</label>
                    <el-checkbox-group v-model="education" style="display:inline-block;padding-left: 10px;">
                    <el-checkbox label="School"><span style="font-size:12px;">学校</span></el-checkbox>
                    <el-checkbox label="Training"><span style="font-size:12px;">培训机构</span></el-checkbox>
                    </el-checkbox-group>
                </el-row>
                <el-row style="padding-top:10px;">
                    <label for="">公共:</label>
                    <el-checkbox-group v-model="common" style="display:inline-block;padding-left: 10px;">
                    <el-checkbox label="Food"><span style="font-size:12px;">餐饮</span></el-checkbox>
                    <el-checkbox label="Hotel"><span style="font-size:12px;">住宿</span></el-checkbox>
                    <el-checkbox label="Tourism"><span style="font-size:12px;">景点</span></el-checkbox>
                    <el-checkbox label="Medical_Store"><span style="font-size:12px;">药店</span></el-checkbox>
                    <el-checkbox label="Medical_Hospital"><span style="font-size:12px;">医院</span></el-checkbox>
                    <el-checkbox label="Goverment"><span style="font-size:12px;">政府</span></el-checkbox>
                    </el-checkbox-group>
                </el-row>
            </el-row>
            <!-- POI指标 -->
            <el-row style="padding-top:10px;font-size: 12px;" v-if="type=='POI指标'"> 
                <el-row>
                <el-col :span="12">
                    <label for="" style="padding-right: 10px;">竞品:</label>
                    <el-input size="small" v-model="minBrands" placeholder="0" style="width: 70px;"></el-input>
                    <label for="" style="padding: 10px;">-</label>
                    <el-input size="small" v-model="maxBrands" placeholder="0" style="width: 70px;">></el-input>
                </el-col>
                <el-col :span="12">
                    <label for="" style="padding-right: 10px;">交通:</label>
                    <el-input size="small" v-model="minTraffic" placeholder="0" style="width: 70px;">></el-input>
                    <label for="" style="padding: 10px;">-</label>
                    <el-input size="small" v-model="maxTraffic" placeholder="0" style="width: 70px;">></el-input>
                </el-col>
                </el-row>
                <el-row  style="padding-top:10px">
                <el-col :span="12">
                    <label for="" style="padding-right: 10px;">商场:</label>
                    <el-input size="small" v-model="minMall" placeholder="0" style="width: 70px;">></el-input>
                    <label for="" style="padding: 10px;">-</label>
                    <el-input size="small" v-model="maxMall" placeholder="0" style="width: 70px;">></el-input>
                </el-col>
                <el-col :span="12">
                    <label for="" style="padding-right: 10px;">学校:</label>
                    <el-input size="small" v-model="minEdu" placeholder="0" style="width: 70px;">></el-input>
                    <label for="" style="padding: 10px;">-</label>
                    <el-input size="small" v-model="maxEdu" placeholder="0" style="width: 70px;">></el-input>
                </el-col>
                </el-row>
            </el-row>
            <!-- 人口 -->
            <el-row style="padding-top:10px;font-size: 12px;" v-if="type=='人口'"> 
                <el-row>
                <el-col>
                    <label for="" style="padding-right: 10px;">住宅区户数:</label>
                    <el-input size="small" v-model="minResidence" placeholder="0" style="width: 70px;"></el-input>
                    <label for="" style="padding: 10px;">-</label>
                    <el-input size="small" v-model="maxResidence" placeholder="0" style="width: 70px;">></el-input>
                </el-col>
                </el-row>
            </el-row>
            <!-- 网格 -->
            <el-row style="padding-top:10px;font-size: 12px;" v-if="type=='网格'"> 
                <el-row>
                    <el-checkbox-group v-model="filters">
                        <div style="display:inline-block; margin-right: 10px;vertical-align: top;min-width: 90px;" v-for="(item, index) in filterObj" :key="index">
                            <div style="margin-bottom: 5px;font-size: 13px;">{{item.cnName}}</div>
                            <div v-for="(children, i) in item.children" :key="i">
                                <el-checkbox :label="children.code" size="mini">
                                    <span style="font-size: 11px;">{{children.cnName}}</span>
                                </el-checkbox>
                            </div>
                        </div>
                    </el-checkbox-group>
                </el-row>
            </el-row>
        </div>
    </div>
</template>
<script>
//  import CityPicker from "@/components/Map/CityPicker/CityPicker.vue";
import api from "@/api/index.js";
export default {
    name: "MapRecommend",
    props: ["isShow"],
    data() {
        return {
            activeNames: ["1"],  //控制折叠板显示隐藏
            cityId: [],
                // cityList,
            styleObj: {},
            type: "POI",
            brands: [],
            traffic: [],
            mall: [],
            education: [],
            common: [],
            minBrands:"", //POI指标 竞品品牌最少
            maxBrands:"", //POI指标 竞品品牌最多
            minTraffic: "", //POI指标 交通最少
            maxTraffic: "", //POI指标 交通最多
            minMall: "", //商场最少
            maxMall: "", //商场最多
            minEdu: "", //学校最少
            maxEdu: "", //学校最多
            minResidence: "", //住宅区户数最少
            maxResidence: "", //住宅区户数最多
            isMapShow: false,
            value: "",
            show: false,
            opt: {},
            map: "",//this.$store.state.map
            layerList: [],
            size: "small",
            filterObj: [
                {
                    name: "",
                    children: [
                        {
                            name: ""
                        }
                    ]
                }
            ], // 筛选条件内容
            filters: [], // 选择的筛选条件
        };
    },
    components: {
      // CityPicker
    },
    methods: {
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        closeMapSearch(){
            this.show = false;
            this.activeNames = ["1"];
            this.$store.state.mapType = "";
        },
        /**点击搜索查询推荐信息
        * POI数据用“,”连接
        */
        confirm() {
            var that = this;
                if(this.map == "") {
                    this.map = this.$store.state.map;
                }
            if(this.type == "网格") {
                this.confirmFilter();
            } else {
                this.opt.city = this.$store.state.currentCity.id || sessionStorage.currentCode;
                this.opt.province = this.$store.state.currentCity.joinId;
                if(!!this.opt.city) {
                    this.opt.district = "";
                    this.opt.cusNo = sessionStorage.cusNo;
                    this.opt.brands = this.brands.join(","); //竞争品牌，如：麦当劳,肯德基
                    this.opt.traffic = this.traffic.join(","); //交通， 如：Bus,Metro,Railway,BusStation,Airport,Parking
                    this.opt.mall = this.mall.join(","); //商场，如：ShoppingMall,Mall,Market,Convenience
                    this.opt.education = this.education.join(","); //学校，如：School,Training
                    this.opt.common = this.common.join(","); // 公共
                    this.opt.minBrands = this.minBrands //POI指标 竞品品牌最少
                    this.opt.maxBrands = this.maxBrands; //POI指标 竞品品牌最多
                    this.opt.minTraffic = this.minTraffic; //POI指标 交通最少
                    this.opt.maxTraffic = this.maxTraffic; //POI指标 交通最多
                    this.opt.minMall = this.minMall; //商场最少
                    this.opt.maxMall = this.maxMall; //商场最多
                    this.opt.minEdu = this.minEdu; //学校最少
                    this.opt.maxEdu = this.maxEdu; //学校最多
                    this.opt.minResidence = this.minResidence; //住宅区户数最少
                    this.opt.maxResidence = this.maxResidence; //住宅区户数最多
                    this.$store.state.loadingMap = true;
                    api.recommend(this.opt).then(res => {
                        this.$store.state.recList = res.data || [];
                        if(res.code == 200 && res.data != null) {
                            var recommendSource = {
                                type: "FeatureCollection",
                                features: []
                            };
                            res.data.map(m => {
                                let item = {
                                    type: "Feature",
                                    properties: {
                                        name: m.name,
                                        group: m.groups
                                    },
                                    geometry: {
                                        type:"Polygon",
                                        coordinates: m.geometry
                                    }
                                };
                                recommendSource.features.push(item);
                            });
                            if(that.map.getLayer("recommendLayer")){
                                that.map.getSource("geoRecommend").setData(recommendSource);
                                //that.map.getSource("recommendLayerLine").setData(recommendSource);
                            } else {
                                let recStyle = {};
                                this.layerList.map(item => {
                                    if(item.layCode == "PT142005") {
                                        recStyle = item;
                                    } 
                                });
                                that.map.addSource("geoRecommend", {
                                    type: "geojson",
                                    data: recommendSource
                                });
                                that.map.addLayer({
                                    id: "recommendLayer",
                                    type: "fill",
                                    source: "geoRecommend",
                                    paint: {
                                        "fill-color": recStyle.fillColor || "rgba(109, 119, 216, 0.6)",
                                        // "fill-outline-color": recStyle.outlineColor ||"rgba(109, 119, 216, 1)",
                                    }
                                });
                                that.map.addLayer({
                                    id: "recommendLayerLine",
                                    type: "line",
                                    source: "geoRecommend",
                                    paint: {
                                        "line-color": recStyle.outlineColor || "rgba(109, 119, 216,1)",
                                        "line-width": Number(recStyle.outlineWidth || 3),
                                        'line-dasharray': [0.3, recStyle.outlineType == "line"?0:2],
                                    },
                                    layout: {
                                            'line-cap': 'round',
                                            'line-join': 'round',
                                        },
                                    });
                                    that.map.on("click", "recommendLayer", e => {
                                    this.$store.state.recInfoVisible = true;
                                    this.$store.state.recInfo = e.features[0].properties;
                                });
                            }
                            this.$store.state.recVisible = true;
                            this.map.flyTo({
                                center: res.data[0].geometry[0][0], // 中心点 取第一个推荐区域的第一个点
                                zoom: 10 // 缩放比例
                            });
                            this.activeNames = [];
                        } else {
                            this.$message({
                                type: "warning",
                                message: "暂无无机会推荐"
                            });
                        }
                        this.$store.state.loadingMap = false;
                        this.$emit("updata-type", false);
                    }).catch(err => {
                        this.$store.state.loadingMap = false;
                    });
                } else {
                    this.$message.error("推荐失败");
                }
            }
        },
        //确认筛选网格
        confirmFilter() {
            this.$store.state.loadingMap = true;
            let opts = {
                cusNo: sessionStorage.cusNo,
                types: "",
                geometry: []
            };
            var nw = this.map.getBounds().getNorthWest(); // [lng,lat]
            var se = this.map.getBounds().getSouthEast();
            if(this.filters.length > 0) {
                opts.types = this.filters.join(",");
            }
            let geometry = [[nw.lng, nw.lat],[se.lng, nw.lat],[se.lng, se.lat],[nw.lng, se.lat]];
            opts.geometry = JSON.stringify(geometry);
            api.getMangerOkBlock(opts).then(res => {
                if(res.code == 200) {
                    if(res.data.data.length > 0) {
                        this.$message({
                            type: "success",
                            message: "获取网格成功"
                        });
                        this.filters = []; // 清空筛选条件
                        let feas = [];
                        res.data.data.map(filter => {
                            feas.push({
                                type: "Feature",
                                properties: {
                                    "longitude": filter.longitude,
                                    "latitude": filter.latitude,
                                    "geometry": filter.location,
                                    "fillColor": filter.fillColor,
                                    "outlineColor": filter.outlineColor
                                },
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [JSON.parse(filter.location)]
                                }
                            });
                        });
                        if(this.map.getSource("filterBlock")) {
                            // 存在网格
                            this.map.getSource("filterBlock").setData({                  /* geojson数据 */
                                type: "FeatureCollection",
                                features: feas
                            });
                        } else {
                            // 不存在网格
                            this.map.addSource('filterBlock', {       /* 添加Source，类型是geojson */
                                type: 'geojson',
                                data: {                  /* geojson数据 */
                                    type: "FeatureCollection",
                                    features: feas
                                }
                            });
                            this.map.addLayer({
                                'id': 'filterBlock',
                                'type': 'fill',           /* fill类型layer */
                                'source': 'filterBlock',         
                                'layout': {},
                                'paint': {
                                    'fill-color': ["get",'fillColor'], /* fill颜色 */
                                    'fill-outline-color': ["get",'outlineColor'] 
                                }
                            });
                            }
                            this.map.flyTo({
                                center: [res.data.data[0].longitude, res.data.data[0].latitude]
                            });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "可视范围内，无符合要求的网格"
                        });
                        if(this.map.getSource("filterBlock")) {
                            this.map.getSource("filterBlock").setData({
                                type: "FeatureCollection",
                                features: []
                            });
                        }
                    }
                    this.show = false;
                } else {
                    this.$message.error("获取网格失败");
                }
                this.$store.state.loadingMap = false;
            }).catch(err => {
                console.log(err);
                this.$message.error("后台程序出错，请联系管理员处理");
                this.$store.state.loadingMap = false;
            });
        },
        //组件选择城市
        selectCity(val) {
            this.opt.city = val.id;
            this.opt.province = val.joinId;
        },
        getMangerBlockType() {
            api.getMangerBlockType().then(res => {
                if(res.code == 200 && res.data != null) {
                    this.filterObj = res.data.data;
                }
            });
        }
    },
    mounted() {
        this.layerList = JSON.parse(sessionStorage.layerList);
        if(sessionStorage.isHide == "1") {
            this.show = this.isShow;
            this.size = "mini";
        }
        this.getMangerBlockType();
    },
    watch:{
        isShow(val) {
            this.show = val;
        },
        show(val) {
            if(!val)
                this.$emit("updata-type", val);
        },
        activeNames(val) {
            this.$store.state.recVisible = (val.length == 0);
        }
    }
}
</script>
<style>
.mapsearch3-container{
    text-align: left;
}
.mapsearch3-title{
    background: #eee;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 5px 10px 5px 20px;
}
.mapsearch3-close{
    float: right;
    cursor: pointer;
    margin-right: 16px;
}
.mapsearch3-content{
    padding: 20px 10px 20px 20px;
    font-size: 13px; 
}
.el-checkbox__label{
    width: 50px;
    font-size: 12px;
}
.recomment .el-collapse-item__header{
    height: 30px;
    background: #eee;
    padding-left: 10px;
}
.mapsearch3-container .el-button--primary {
  color: #fff;
  background-color: #0188e2;
  border-color: #0188e2;
}
.mapsearch3-container .el-button--primary:hover {
    color: #fff;
    background-color: #0188e2 !important;
    border-color: #0188e2 !important;
}
.mapsearch3-container button.el-button.el-button--default.el-button--mini {
    display: inline-block;
    width: auto;
    height: 29px;
    /* line-height: 1;*/
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 0px 5px;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 5px;
}

/* 移动端适配 */
@media screen and (max-width: 500px) {
  .mapsearch3-content {
     padding: 20px 10px;
   }
}
</style>