<template>
<!--  style="" -->
    <div class="header" v-show="isHide=='0'" :style="themeColor">
        <el-row align="middle" justify="end" type="flex" style="font-size:12px;height:100%;padding-left: 10px;">
            <el-col :span="14" style="text-align: left;
            padding-top: 5px;">
            <router-link class="toHome" :to="'/home'">
                <el-image :src="url" style="width:85px;"></el-image>
                <div class="header-name">{{ $t("aside.slp")}}</div>
            </router-link>
            <!-- 当前城市 -->
            <CityPicker :isInput="false"></CityPicker>
            <!-- 智能推荐 -->
            <el-popover
                placement="top"
                width="700"
                class="header-item header-smenu"
                v-model="recVisible"
                v-show="isLogin==1"
                >
                <MapRecommend :isShow="recVisible" @updata-type="updataRecVisible"></MapRecommend>
                <el-link slot="reference"> <span style="color: #fff;font-size:16px; margin-top:-5px;position: relative;
            top: -3px;">{{ $t("header.intelligentRecommend") }} <i class="el-icon-arrow-down"></i></span></el-link>
            </el-popover>
            <!-- 评估报告 -->
            <el-dropdown trigger="click" v-show="isLogin==1" @command="handleCommandReport" style="font-size:12px;"  class="header-item header-smenu">
                <span class="el-dropdown-link header-submenu">
                    {{ $t("header.evaReport") }}
                    <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Buffer">{{ $t("aside.Buffer") }}</el-dropdown-item>
                <el-dropdown-item command="isochronousCircle">{{ $t("aside.isochronousCircle") }}</el-dropdown-item>
                <el-dropdown-item command="ta">{{ $t("aside.defineTA") }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- 数据管理 -->
            <el-dropdown trigger="click" v-show="isLogin==1" @command="handleCommandList" style="font-size:12px;"  class="header-item header-smenu">
                <span class="el-dropdown-link header-submenu">
                {{ $t("header.dataMaga") }}
                <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="pipeline">{{ $t("aside.pipeline") }}</el-dropdown-item>
                <el-dropdown-item command="store">{{ $t("aside.store") }}</el-dropdown-item>
                <el-dropdown-item command="competitor">{{ $t("aside.competitor") }}</el-dropdown-item>
                <el-dropdown-item command="ta">{{ $t("aside.havedTA") }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            </el-col>
            <el-col :span="10" style="text-align: right;padding-right: 5px;">
            <!-- 首页 -->
            <el-link :underline="false" @click="goHome" style="font-size:13px;color:#fff;" class="header-item header-icon">{{ $t("header.home") }}</el-link>
            <!-- 消息中心 -->
            <el-badge is-dot class="item header-item">
                <el-link icon="header-icon" :underline="false" @click="goHome">
                    <img src="../assets/images/Icon/bell.png" />
                </el-link>
            </el-badge>
            <!-- 帮助中心 -->
            <el-link icon="header-icon" :underline="false" @click="goMsg"  class="header-item">
                <img src="../assets/images/Icon/help.png" />
            </el-link>
            <!-- 更换皮肤 -->
            <el-link icon="header-icon" :underline="false" @click="dialogVisible = true"  class="header-item">
                <img src="../assets/images/Icon/clothes.png" />
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <!-- 语言切换 -->
            <el-dropdown @command="handleCommand" style="font-size:12px;"  class="header-item">
                <span class="el-dropdown-link header-icon">
                    {{ language }}<i class="el-icon-arrow-down el-icon--right header-icon"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="zh">{{ $t("header.cn") }}</el-dropdown-item>
                    <el-dropdown-item command="en">{{ $t("header.en") }}</el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            <el-divider direction="vertical"></el-divider>
            <!-- 未登录 -->
            <div   @click="showLogin" v-show="isLogin!=1" style="display:inline-block;">
                <el-link type="primary" :underline="false"> <span style="font-size:13px;" class="header-icon">{{ "登录/注册" }}</span></el-link>
            </div>
            <el-dropdown  @command="userOps" v-show="isLogin==1">
                <span class="el-dropdown-link">
                <el-button type="primary" size="mini" circle>
                    <i class="el-icon-user"></i>
                </el-button>
                <span style="cursor: pointer;" class="header-icon">{{ userName }}</span>
                </span>
                <!-- 已登录 -->
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="personalInfo">
                    {{ $t("header.profile") }}
                </el-dropdown-item>
                <el-dropdown-item command="sysManage" v-if="isAdmin">
                    {{ $t("header.systemMgr") }}
                </el-dropdown-item>
                <el-dropdown-item command="mc">
                    {{ $t("header.mc") }}
                </el-dropdown-item>
                <el-dropdown-item command="logout">
                    {{ $t("header.logout") }}
                </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            </el-col>
        </el-row>
        <!-- 选择皮肤弹框 -->
        <el-dialog
            title="选择主题色"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <ThemePicker />
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <ThemePicker style="visibility: hidden;" />
        <!-- 登录弹框 -->
        <el-dialog
            :visible.sync="loginDialogVisible"
            width="30%" :show-close="showClose"
            center>
            <span slot="title">
                <el-image shape="square" :size="size" :src="squareUrl" class="login-image"></el-image>
            </span>
            <div style="padding-right: 40px;padding-left: 40px;">
                <span>
                <el-input
                    placeholder="账号"
                    prefix-icon="el-icon-user"
                    v-model="loginName">
                </el-input>
                </span>
                <span class="login-warning" :style="userVisibility">请输入账户名</span>
                <span>
                <el-input 
                    type="password"
                    placeholder="密码"
                    prefix-icon="el-icon-lock"
                    v-model="password">
                </el-input>
                </span>
                <span class="login-warning" :style="psdVisibility">请输入密码</span>
                <div>
                <el-checkbox v-model="checked"><span style="font-size:13px;">自动登录</span></el-checkbox> 
                <el-link style="float:right;font-size:13px;" type="primary">忘记密码?</el-link>
                </div>
                <div class="login-warning login-warning2" :style="npVisibility">
                    <i class="el-icon-warning"></i>
                    <span>账号或者密码错误，请重新输入</span>
                </div>
                <div class="login-btn">
                <el-button style="width: 100%;" type="primary" @click="confirmLogin">登  录</el-button>
                </div>
                <div style="float:right;font-size:13px;">
                <span>还没有账号?<el-link type="primary" style="float:right;font-size:13px;" @click="openRegisterDialog">立即申请</el-link></span>
                </div>
            </div>
        </el-dialog>
    <RegisterDialog :regVisible="regVisible" @go-login="registerToLogin"></RegisterDialog>
    </div>
</template>
<script>
import CityPicker from "./Map/CityPicker/CityPicker.vue"; // 城市选择
import ThemePicker from "@/components/ThemePicker.vue";
import RegisterDialog from "@/components/Dialog/Login/RegisterDialog.vue";
//import LogoImg from "@/assets/images/logo.png";
import LogoImg from "@/assets/images/Icon/logo1.png";
import squareImg from "@/assets/images/icon.png";
import api from "../api/index.js";
import MapRecommend from "@/components/Map/MapRecommend.vue";
import md5 from 'js-md5';
import constants from "@/constants/Map.constants.js";
export default {
  data() {
    return {
      isAdmin: false,
      language: "中文",
      loginDialogVisible: false,
      showClose: false,
      input: "",
      checked: false,
      fit: "fill",
      url: LogoImg,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        
      },
      formLabelWidth: "120px",
      dialogVisible: false, //选择皮肤弹框
      color: "#df2265", //默认主题色
      visible: false,
      isLogin: sessionStorage.getItem("isLogin")||0,
      size: "large",
      squareUrl: squareImg,
      userVisibility: "visibility:hidden",
      psdVisibility: "visibility:hidden",
      npVisibility: "visibility:hidden",
      userName: "",
      loginName: "",
      password: "",
      regVisible: false,
      recVisible: false,
      isHide: "0",
      themeColor: {
          background: this.$store.state.themeColor
      }
    };
  },
  components: {
    ThemePicker, RegisterDialog, CityPicker,MapRecommend
  },
  create() {
      
  },
  mounted(){
       this.isHide = sessionStorage.isHide;
        this.language = this.$i18n.locale == "zh"?this.$t("header.cn"):this.$t("header.en");
        sessionStorage.setItem("isLogin",this.isLogin);
        this.userName = JSON.parse(sessionStorage.userInfo).userName;
        this.isAdmin = sessionStorage.isAdmin == "1";
  },
  methods: {
    //首页
    goHome() {
      this.$router.push("/home");
    },
    //消息
    goMsg() {
        let routeUrl = this.$FormatURL(constants.common.VIDEOURL);
        window.open(routeUrl, '_blank');
    },
    //弹出登录框
    showLogin() {
      this.password = "";
      this.loginName = "";
      this.npVisibility = "visibility:hidden";
      this.userVisibility = "visibility:hidden";
      this.psdVisibility = "visibility:hidden";
      this.loginDialogVisible = true;
    },
    //确认登录，验证账号密码
    confirmLogin(){
      this.npVisibility = "visibility:hidden";
      //账号为空
      if(this.loginName == ""){
        this.userVisibility = "visibility:visible";
      }else{
        this.userVisibility = "visibility:hidden";
      }
      //密码为空
      if(this.password == ""){
        this.psdVisibility = "visibility:visible";
      }else{
        this.psdVisibility = "visibility:hidden";
      }
      if(this.password !="" && this.loginName != ""){
        this.$store.commit("startLoading");
        api.userLogin({
          loginName: this.loginName, 
          pwd: md5(this.password),
          browser: navigator.userAgent,
          ip: localStorage.ip
          }).then( res => {
             this.loading = false;
             if(res.code == 0 || res.code == 200){
                this.userName = res.data.username;
                this.loginDialogVisible = false;
                this.isLogin = true;
                sessionStorage.setItem("isLogin", 1);
                sessionStorage.setItem("userName", res.data.userName);
                sessionStorage.cusNo = res.data.cusNo;
                sessionStorage.uuid = res.data.uuid;
                sessionStorage.siteIcon = res.data.siteIcon;  // 机会点
                sessionStorage.storeIcon = res.data.storeIcon;   // 门店

                location.reload();
             } else {
               this.npVisibility = "visibility:visible";
               this.$store.commit("closeLoading");
             }
        }).catch( err => {
          console.log(err);
        })
        
      }
    },
    handleCommand(command) {
      this.$i18n.locale = command;
      this.language = command=="zh"?this.$t("header.cn"):this.$t("header.en");
      this.$store.state.mapType = "";
      this.$store.state.mapCommand = "";
    },
    
    handleCommandReport(command) {
        if(command == "ta") {
            this.$store.state.isDrawPolygon = true;
            this.$store.state.drawPolygonType = "report";
            this.$store.state.repVisible = false;
        } else {
            this.$router.push("/home");
            this.$store.state.mapType = "";
            this.$store.state.mapCommand = command;
            this.$store.state.repVisible = true;
        }
    },
    handleCommandList(command) {
      this.$store.state.mapType = "";
      this.$store.state.mapCommand = "";
      this.$router.push("/list/"+command);
    },
    //用户退出和申请账号操作
    userOps(command){
      if(command=="logout"){
        this.$confirm('点击确认后将退出当前用户, 是否继续?', '退出登录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          //确认退出，修改缓存
          const spm = sessionStorage.spm;
          //this.$store.commit("startLoading");
          sessionStorage.setItem("isLogin", 0);
          sessionStorage.clear();
          localStorage.clear();
          this.$router.push("/?spm="+ spm);
          //location.reload();
        }).catch(() => {
          //取消  
        });
        
      } else if(command == "personalInfo"){
        this.$router.push("/personalInfo");
      } else if (command == "sysManage"){
        this.$router.push("/system");
      } else if(command == "mc") {
          // 管理驾驶舱
          this.$router.push("/ManagementCockpit");
      }
    },
    //打开注册弹框
    openRegisterDialog(){
      this.regVisible=true;
      this.loginDialogVisible = false;
    },
    //从注册页面跳转登录页面
    registerToLogin(val){
      this.loginDialogVisible = val;
      this.regVisible = false; //关闭注册叶敏
    },
    //关闭推荐弹框
    updataRecVisible(val) {
      this.recVisible = val;
    }

  },
  watch: {
      "$store.state.themeColor": function(val) {
          this.themeColor = {
            background: val
        }
      }
  }
};
</script>
<style>
.header {
 /* background: #0188e2; */
 border-bottom: 1px solid #ddd;
 margin: 0 -20px;
 height: 100%;
}
.header-name {
  display: inline-block;
  font-size: 18px;
  line-height: 40px;
  left: 7px; top: -8px;
  position: relative;
  color:#ffffff;
}
.header.el-link.el-link--default:hover {
  color:#ffffff;
}
.header-city {
  position: relative;
  bottom: 10px;
  left: 19px;
}
.header-menu {
  color: #ffffff;
}
.header-submenu {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  
}
.header-smenu {
  position: relative;
  bottom: 8px;
  left: 35px;
}
.login-warning{
  font-size: 8px;
  color: red;
}
.login-warning2{
  background: rgba(255, 0, 0, 0.1);
  padding: 3px 10px;
  border-radius: 3px; 
}
.login-btn{
  text-align: center;
  margin: 10px auto;
}
.el-dropdown-link{
  cursor: pointer;
}
.toHome{
  cursor: pointer;
  padding-top: 21px;
}
.header-item{
  margin: 0 8px;
  color: #ffffff;
}
.login-image {
  width: 120px;
}
.header-icon {
  color: #ffffff;
}
</style>
