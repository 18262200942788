// 自定义2次移动端触摸式点击
export default class RegisterDbltouchEvent {
  constructor(el, fn) {
      this.callback = fn;
      this.timer = null;
      this.prevPosition = {};
      this.isWaiting = false;

      // 注册touchstart事件，注意this指向
      el.on('touchstart', this.handleClick.bind(this));
  }
  handleClick(evt) {
      if(this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      let pageX = evt.point.x
      let pageY = evt.point.y
      if(this.isWaiting){
          this.isWaiting = false;
          const diffX = Math.abs(pageX - this.prevPosition.pageX);
          const diffY = Math.abs(pageY - this.prevPosition.pageY);
          // 如果满足位移小于10，则是双击
          if(diffX <= 10 && diffY <= 10) {
              // 也可以采用回调函数的方式
              this.callback && this.callback(evt);
          }
      } else {
          this.prevPosition = { pageX, pageY };
          // 开始等待第2次点击
          this.isWaiting = true;
          // 设置200ms倒计时，200ms后重新派发当前事件
          this.timer = setTimeout(() => {
              this.isWaiting = false;
          }, 200)
      }
  }
}