import Vue from "vue";
import ElementUI from "element-ui"; //前端UI框架
import "element-ui/lib/theme-chalk/index.css";

import store from "./store";

// import "./element-variables.scss"; //修改主题批复颜色
import VueI18n from "vue-i18n"; //多语言切换
import "./assets/icon/iconfont.css"; //引入新图标
import utils from "./utils/utils"; //公共方法
import dragDirective from "./utils/dragDirective"; //公共方法
import VueResource from "vue-resource";
import "babel-polyfill";

import Echarts from "echarts"; // 引入Echarts
import Print from 'vue-print-nb';
// import Blob from "./excel/Blob";
// import Export2Excel from "./excel/Export2Excel";
import App from "./App.vue";
import router from "./router";
// import VueAMap from 'vue-amap'; // 高德

import constants from "@/constants/Map.constants.js";
import {$_bus} from './postMessage/api/bus.js'; // 事件总线发布

Vue.prototype.echarts = Echarts;
Vue.use(Echarts);
Vue.use(utils);
Vue.use(dragDirective);
Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(VueResource);
Vue.use(Print);

// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: constants.iMap.WEBKEY,
//   // plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
//   plugin: ['AMap.Geolocation'],
//   v: '1.4.4'
// });

// 以form data 方式进行post请求
Vue.http.options.emulateJSON = true;
Vue.http.options.headers = {
  //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
};

Vue.http.interceptors.push((request, next) => {
  // 请求发送前的处理逻辑
  //request.headers.set('timestamp', sessionStorage.timestamp || "")
  next((response) => {
    // 请求发送后的处理逻辑
    // 根据请求的状态，response参数会返回给successCallback或errorCallback
    return response
  })
})

const i18n = new VueI18n({
  locale: "zh", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    zh: require("./assets/languages/zh.json"),
    en: require("./assets/languages/en.json")
  }
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (sessionStorage.getItem("isLogin") == "1") { // 判断当前的token是否存在 ； 登录存入的token
      next();
    }
    else {
      //this.$store.state.isMapOrList = false;
      next({
        path: '/?spm=' + sessionStorage.spm //,
        // query: {redirect: to.fullPath} // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  else {
    next();
  }
});

const VM = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线
  },
  mounted() {
    $_bus(this);
  }
}).$mount("#app");

export {
  VM
}




