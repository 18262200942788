<!--
  1. 商圈详情弹窗：顶部导出报告按钮隐藏
  2. 除基本信息外，其他信息暂时隐藏，后期介入大数据后恢复即可
  3. 地图商圈蓝图：隐藏打印报告泡泡弹窗；增加商圈名显示
 -->
<template>
  <div
    v-if="visible"
    class="pp-Detail"
    :class="{ rootWidth: isIframe && isEdit }"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(197, 197, 197, 0.6)"
  >
    <div class="pp-title">
      <el-row>
        <el-col :sm="6" :xs="12">
          <strong>商圈详情</strong>
        </el-col>
        <el-col :sm="18" :xs="12" style="text-align: right">
          <i
            class="el-icon-close"
            style="cursor: pointer; font-weight: 600"
            @click="closePPDetail"
          ></i>
        </el-col>
      </el-row>
    </div>
    <div>
      <!-- <el-button @click="isEdit = !isEdit" size="mini" v-if="!isReport" -->
      <el-button @click="isEdit = !isEdit" size="mini" v-if="false"
        ><span style="font-size: 12px" v-if="!isEdit">编辑</span
        ><span style="font-size: 12px" v-else>退出编辑</span></el-button
      >
      <el-button @click="getBigData" size="mini"
        ><span style="font-size: 12px">人口数据</span></el-button
      >
      <!-- <el-button @click="beforeSaveTAInfo" size="mini" v-if="isEdit & !isReport" -->
      <el-button @click="beforeSaveTAInfo" size="mini" v-if="false"
        ><span style="font-size: 12px">保存</span></el-button
      >
      <!-- <el-button @click="toReport" size="mini"
        ><span style="font-size: 12px">导出报告</span></el-button
      > -->
      <el-button size="mini" @click="delBtn" v-if="formData.code"
        ><span style="font-size: 12px">删除</span></el-button
      >
      <el-button size="mini" @click="editDrawTa" v-show="formData.taId"
        ><span style="font-size: 12px">区域规划</span></el-button
      >
      <el-button
        size="mini"
        @click="taModelShow = true"
        v-show="formData.tabusType == 'PT142002' && formData.taId"
      >
        <span style="font-size: 12px">商圈模型</span>
      </el-button>
    </div>
    <div class="pp-main">
      <el-collapse accordion v-model="activeName">
        <!-- 基本信息 -->
        <!-- <el-collapse-item name="1" v-if="!isReport"> -->
        <el-collapse-item name="1" v-if="false">
          <template slot="title">
            基本信息
            <i class="header-icon el-icon-info"></i>
          </template>
          <el-form
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            size="small"
            :rules="taBasicMsgCheck"
            :model="isIframe ? formData : ruleForm"
          >
            <!-- 锐力商圈 -->
            <MapTADetailBasic
              v-if="isIframe"
              :formData="formData"
              :formLst="formLst"
              :isEdit="isEdit"
            ></MapTADetailBasic>
            <div v-else>
              <el-col :sm="12" :xs="24">
                <el-form-item label="商圈名称:">
                  <el-input
                    v-if="isEdit"
                    placeholder="请输入"
                    v-model="ruleForm.cnName"
                  ></el-input>
                  <span v-else>{{ ruleForm.cnName }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="商圈类型:">
                  <el-select
                    v-if="isEdit"
                    v-model="ruleForm.taType"
                    placeholder="请选择"
                    @change="
                      changeType(ruleForm.taType, 'taTypeName', taTypeList)
                    "
                  >
                    <el-option
                      :label="item.type_Name"
                      :value="item.dict_Code"
                      v-for="item in taTypeList"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ ruleForm.taTypeName }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="商圈等级:">
                  <el-select
                    v-if="isEdit"
                    v-model="ruleForm.taLevel"
                    placeholder="请选择"
                    @change="
                      changeType(ruleForm.taLevel, 'taLevelName', taLevelList)
                    "
                  >
                    <el-option
                      :label="item.type_Name"
                      :value="item.dict_Code"
                      v-for="item in taLevelList"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                  <span v-else>{{ ruleForm.taLevelName }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="商圈面积:">
                  <el-input
                    v-if="isEdit"
                    placeholder="请输入"
                    v-model="ruleForm.taArea"
                  ></el-input>
                  <span v-else>{{ ruleForm.taArea }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="居住人口:">
                  <el-input
                    v-if="isEdit"
                    v-model="ruleForm.pHome"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.pHome }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="办公人口:">
                  <el-input
                    v-if="isEdit"
                    v-model="ruleForm.pOffice"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.pOffice }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="学生人口:">
                  <el-input
                    v-if="isEdit"
                    v-model="ruleForm.pStudent"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.pStudent }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="总人口:">
                  <el-input
                    v-if="isEdit"
                    v-model="ruleForm.pTotal"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.pTotal }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="日均人流量:">
                  <el-input
                    v-if="isEdit"
                    v-model="ruleForm.pVolume"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.pVolume }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="评分:">
                  <el-input
                    v-if="isEdit"
                    v-model="ruleForm.score"
                    placeholder="请输入"
                  ></el-input>
                  <span v-else>{{ ruleForm.score }}</span>
                </el-form-item>
              </el-col>
            </div>
          </el-form>
        </el-collapse-item>
        <!-- 分类信息 -->
        <!-- <el-collapse-item
          v-for="(item, index) in taData"
          :key="index"
          :name="index + 2"
        >
          <template slot="title">
            {{ $i18n.locale == "zh" ? item.cnName : item.enName
            }}<i class="header-icon el-icon-info"></i>
          </template>
          <el-row v-for="(dItem, dIndex) in item.items" :key="dIndex">
            <span class="ta-label">
              {{ ($i18n.locale == "zh" ? dItem.cnLabel : dItem.enLabel) + ":" }}
            </span>
            <span style="margin-left: 10px">
              {{ formatData(dItem.fieldValue) }}
            </span>
          </el-row>
        </el-collapse-item> -->
        <!-- 门店列表 -->
        <el-collapse-item name="2">
          <template slot="title">
            门店列表<i class="header-icon el-icon-info"></i>
          </template>
          <el-table
            :data="ruleForm.storeList"
            size="mini"
            style="width: 100%">
            <el-table-column
              prop="storeCode"
              label="门店编码">
            </el-table-column>
            <el-table-column
              prop="storeName"
              label="门店名称">
            </el-table-column>
            <el-table-column
              prop="sales"
              label="销售额">
            </el-table-column>
            <el-table-column
              prop="openDate"
              label="开业日期">
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- 竞品店列表 -->
        <el-collapse-item name="3">
          <template slot="title">
            竞品店列表<i class="header-icon el-icon-info"></i>
          </template>
          <el-table
            :data="ruleForm.competitorList"
            size="mini"
            style="width: 100%">
            <el-table-column
              prop="code"
              label="门店编码">
            </el-table-column>
            <el-table-column
              prop="cnName"
              label="门店名称">
            </el-table-column>
            <el-table-column
              prop="brandName"
              label="品牌">
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- 机会点列表 -->
        <el-collapse-item name="4">
          <template slot="title">
            机会点列表<i class="header-icon el-icon-info"></i>
          </template>
          <el-table
            :data="ruleForm.siteList"
            size="mini"
            style="width: 100%">
            <el-table-column
              prop="siteCode"
              label="机会点编码">
            </el-table-column>
            <el-table-column
              prop="siteName"
              label="机会点名称">
            </el-table-column>
            <el-table-column
              prop="siteStatusName"
              label="状态">
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- POI列表 -->
        <el-collapse-item name="5">
          <template slot="title">
            POI列表<i class="header-icon el-icon-info"></i>
          </template>
          <el-table
            :data="poiList"
            size="mini"
            style="width: 100%">
            <el-table-column
              prop="购物中心"
              label="购物中心">
            </el-table-column>
            <el-table-column
              prop="普通商场"
              label="普通商场">
            </el-table-column>
            <el-table-column
              prop="三级甲等医院"
              label="三级甲等医院">
            </el-table-column>
            <el-table-column
              prop="商务写字楼"
              label="商务写字楼">
            </el-table-column>
            <el-table-column
              prop="住宅小区"
              label="住宅小区">
            </el-table-column>
            <el-table-column
              prop="高等院校"
              label="高等院校">
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- 人口人流 -->
        <!-- <el-collapse-item name="5">
          <template slot="title">
            人口人流<i class="header-icon el-icon-info"></i>
          </template>
        </el-collapse-item> -->
      </el-collapse>
    </div>
    <MAPTAModel v-model="taModelShow" :tableData="taModelData"></MAPTAModel>
    <MapTAPeopleModel v-model="taPeopleModelShow" :tableData="taPeopleModelData"></MapTAPeopleModel>
  </div>
</template>
<script>
import api from "@/api/index.js";
import calGeoHash from "../../../utils/calGeoHash.js";
import apiIframe from "@/postMessage/api/index.js";
import MapTADetailBasic from "../../../postMessage/components/MapTADetail/MapTADetailBasic.vue";
import MAPTAModel from "@/components/Dialog/Map/MAPTAModel.vue";
import MapTAPeopleModel from "@/components/Dialog/Map/MapTAPeopleModel.vue";

export default {
  name: "MapTADetail",
  props: ["taVisible", "info"],
  components: {
    MapTADetailBasic,
    MAPTAModel,
    MapTAPeopleModel
  },
  data() {
    return {
      isEdit: false,
      activeName: "1",
      visible: false,
      taModelShow: false,
      taModelData: {},
      taPeopleModelShow: false,
      taPeopleModelData: undefined,
      ruleForm: {
        taType: "",
        taLevel: "",
        taArea: "",
        location: "",
        pHome: "",
        pOffice: "",
        pStudent: "",
        pTotal: "",
        pVolume: "",
        enName: "1",
        ncName: "1",
        taId: "0"
      },
      taData: [],
      isReport: false,
      taLevelList: [],
      taTypeList: [],
      newSuccess: false,
      isIframe: this.$store.state.PMStoreIndex.isIframe, // 是否内嵌为第三方子系统
      // Api: this.$store.state.PMStoreIndex.isIframe ? apiIframe : api, // 父系统对接接口&原始接口
      Api: api, // 直接调用址见接口，取消postmessage通信
      formData: {},
      formLst: {},
      loading: false,
      taBasicMsgCheck: {
        tabusType: [
          { required: true, message: "请选择商圈类型", trigger: "blur" }
        ],
        cnName: [{ required: true, message: "请填写商圈名称", trigger: "blur" }]
      },
      saveTaShake: null,

      // 商圈相关信息(测试)
      // 门店列表
      storeList: [],
      // 竞品店列表
      competitorList: [],
      // 机会点列表
      siteList: [],
      // 人口人流暂无数据 表单预留位置以供后续补添
      peopleList: [],
      // POI列表
      poiList: [],
    };
  },
  mounted() {
    this.$bus.$on("updateTaInfo", e => {
      this.ruleForm.taArea = e.area;
      this.formData.taArea = e.area;
      this.info.location = e.location;
    });
  },
  methods: {
    closePPDetail() {
      this.visible = false;
      this.$emit("update-tavisible", {
        visible: this.visible,
        newSuccess: this.newSuccess
      });
      this.taData = [];
      this.newSuccess = false;
      this.$store.state.location.type = "";
    },
    beforeSaveTAInfo() {
      if (this.saveTaShake != null) clearTimeout(this.saveTaShake);
      this.saveTaShake = setTimeout(() => {
        this.$refs["ruleForm"].validate(res => {
          if (res) {
            this.saveTAInfo();
          } else {
            return false;
          }
        });
        this.saveTaShake = null;
      }, 200);
    },
    saveTAInfo() {
      this.loading = true;
      let FormData = {};
      if (this.isIframe) {
        FormData = this.$store.state.MapTADetail.TAData;
        FormData.cusNo = sessionStorage.cusNo;
        FormData.creator = this.$store.state.PMStoreIndex.userName;
        FormData.creatorID = this.$store.state.PMStoreIndex.positionID;
        FormData.location = JSON.stringify(this.info.location).replace(
          /"/g,
          ""
        );
        FormData.createDate = this.$Format("yyyy-MM-dd", new Date());
        FormData.pHome = 1; // 1 是保存，0是锐力删除商圈
      } else {
        this.ruleForm.creator = sessionStorage.getItem("userName");
        this.ruleForm.createDate = this.$Format("yyyy-MM-dd", new Date());
        this.ruleForm.groups = [];
        this.ruleForm.location = JSON.stringify(this.info.location).replace(
          /"/g,
          ""
        );
        this.ruleForm.cusNo = sessionStorage.cusNo;
        FormData = this.ruleForm;
      }
      this.Api.saveTAInfo(FormData)
        .then(res => {
          this.loading = false;
          if (res.code == 200) {
            this.newSuccess = true;
            // this.closePPDetail();
            this.$message({
              message: "保存成功",
              type: "success"
            });
            if (this.isIframe) {
              this.formData.code = this.$store.state.MapTADetail.TAData.code;
              // Object.assign(this.formData, this.$store.state.MapTADetail.TAData);
            }
            this.$emit("update-tavisible", {
              visible: true,
              newSuccess: this.newSuccess
            });
            this.ruleForm.taId = res.data;
          } else {
            this.$message.error("保存失败");
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    //商圈数据
    getTAData(ops) {
      try {
        this.loading = true;
        this.formData = {};
        this.formLst = {};
        ops.cusNo = sessionStorage.cusNo;
        // 商圈信息
        api.getTAData(ops).then(async res => {
          if (res.code == 200 && res.data != null) {
            this.ruleForm = res.data.tasimpleView;

            const poiData = {};
            res.data.poiData?.forEach(d => {
              for(let key in d) {
                poiData[key] = d[key];
              }
            });
            this.poiList = [poiData];
            // 商圈容量
            let {
              code: rlCode,
              data: { taModelData: rlData, opportunitySum }
            } = await api.taModel({
              cusNo: sessionStorage.cusNo,
              location: ops.geometry,
              taType: this.ruleForm.ta2Type
            });

            this.taData = res.data.groups;
            this.taPeopleModelData = res.data.bigDataResult;
            this.taTypeList = res.data.taTypeList;
            this.taLevelList = res.data.taLevelList;
            if (this.info.taid == 0) this.ruleForm.taArea = this.info.area;
            if (this.isIframe) {
              this.formData = res.data.tasimpleView;
              if (this.info.taid == 0) this.formData.taArea = this.info.area;
              this.formLst = res.data;
              this.formData.adOpportunityNum = 0;
              this.formData.nkOpportunityNum = 0;
            }

            if (!!opportunitySum) {
              this.formData.nkOpportunityNum = opportunitySum;
            } else {
              opportunitySum = 0;
            }
            if (rlCode == 200 && !!rlData) {
              let bigTypeTable = [];
              rlData.forEach(e => {
                let item = bigTypeTable.filter(
                  j => j.bigTypeName == e.bigTypeName
                );
                if (Array.isArray(item) && item.length > 0) {
                  item[0].list.push(e);
                } else {
                  bigTypeTable.push({
                    bigTypeName: e.bigTypeName,
                    list: [e]
                  });
                }
              });
              let tableBody = [];
              let rowBgColorList = ["#e2efda", "#ddebf7", "#fff2cc"];
              bigTypeTable.forEach((q1, i1) => {
                q1.list.forEach((q2, i2) => {
                  q2.storeList.forEach((q3, i3) => {
                    let row = {
                      bigType: q2.bigType,
                      bigTypeName: q2.bigTypeName,
                      name: q2.name,
                      reality: q2.reality,
                      reference: q2.reference,
                      opportunity: q2.opportunity,
                      competitor: q2.competitor,
                      ongoing: q2.ongoing,
                      storeType: q3.storeType,
                      stroeNumber: q3.number,
                      storeName: q3.names,
                      bigTypeRowSpan: -1,
                      nameRowSpan: -1,
                      realityRowSpan: -1,
                      referenceRowSpan: -1,
                      opportunityRowSpan: -1,
                      competitorRowSpan: -1,
                      ongoingRowSpan: -1,
                      rowBgColor: rowBgColorList[i1]
                    };
                    // 计算第一行数据，大店类型跨行数，其余行数据的大店类型默认跨行 -1 (v-if 删除)
                    if (i3 == 0 && i2 == 0) {
                      row.bigTypeRowSpan = q1.list.reduce((a, b) => {
                        return a + b.storeList.length;
                      }, 0);
                    }
                    if (i3 == 0) {
                      row.nameRowSpan = q2.storeList.length;
                      row.realityRowSpan = q2.storeList.length;
                      row.referenceRowSpan = q2.storeList.length;
                      row.opportunityRowSpan = q2.storeList.length;
                      row.competitorRowSpan = q2.storeList.length;
                      row.ongoingRowSpan = q2.storeList.length;
                    }
                    tableBody.push(row);
                  });
                });
              });
              let title = res.data.ta2TypeList.filter(
                e => e.dict_Code == this.ruleForm.ta2Type
              );
              if (Array.isArray(title) && title.length > 0) {
                title = title[0].type_Name + " 商圈";
              } else {
                title = "";
              }
              this.taModelData = {
                title,
                body: tableBody,
                opportunitySum,
                name: this.formData.cnName
              };
            }
          } else {
            this.$message.error("加载数据失败");
          }
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    // val：code， valName: name, list: 列表， key: 列表的code的key, name: 列表的name的key
    changeType(val, valName, list, key = "dict_Code", name = "type_Name") {
      let obj = {};
      obj = list.find(item => {
        //遍历list的数据
        return item[key] === val; //筛选出匹配数据
      });
      this.ruleForm[valName] = obj[name];
    },
    //导出报告
    toReport() {
      this.$emit("loadingMap", true);
      this.loadingMap = true;
      var geoHash = calGeoHash.geoHashList([this.info.location]).join(",");
      api
        .taReport({
          cusNo: sessionStorage.cusNo,
          geometry: `${JSON.stringify(this.info.location)}`,
          geohash: geoHash,
          userName: sessionStorage.userName,
          fromType: "PC"
        })
        .then(res => {
          if (res.code == 200) {
            if (res.data == 500) {
              this.$message({
                message: res.message,
                type: "warning"
              });
            } else {
              sessionStorage.res = JSON.stringify(res.data);
              sessionStorage.info = escape(
                JSON.stringify({
                  geometry: `${JSON.stringify(this.info.location)}`, //`${this.info.location}`,
                  type: "ta",
                  longitude: this.info.lngLat.lng,
                  latitude: this.info.lngLat.lat,
                  geoHash
                })
              );
              let routeUrl = this.$router.resolve({
                name: "report",
                params: {
                  info: escape(
                    JSON.stringify({
                      reportID: res.data.reportID
                    })
                  )
                }
              });
              window.open(routeUrl.href, "_blank");
            }
          }
          this.$emit("loadingMap", false);
        })
        .catch(err => {
          this.$message.error(err);
          this.$emit("loadingMap", false);
        });
    },
    // 判断字符串是否为数字
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    //格式化数据小数点
    formatData(val) {
      let result = val;
      if (!val) result = val;
      if (this.isNumber(val) && val.indexOf(".") > -1) {
        result = Number(val).toFixed(2);
      }
      return result;
    },
    // 删除按钮动作
    delBtn() {
      this.$confirm("此操作将删除商圈信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.deleteSmartTa();
      });
    },
    //删除商圈
    deleteSmartTa() {
      if (this.isIframe) {
        let FormData = {};
        FormData = this.$store.state.MapTADetail.TAData;
        FormData.cusNo = sessionStorage.cusNo;
        FormData.creator = this.$store.state.PMStoreIndex.userName;
        FormData.creatorID = this.$store.state.PMStoreIndex.positionID;
        FormData.location = JSON.stringify(this.info.location).replace(
          /"/g,
          ""
        );
        FormData.createDate = this.$Format("yyyy-MM-dd", new Date());
        FormData.pHome = 0; // 1 是保存，0是锐力删除商圈
        apiIframe.deleteTAInfo(FormData).then(res => {
          // console.log(res);
        });
      }
      api
        .deleteSmartTa({
          taid: this.info.taid
        })
        .then(res => {
          if (res.code == 200) {
            this.visible = false;
            this.taData = [];
            this.newSuccess = true;
            this.$emit("update-tavisible", {
              visible: this.visible,
              newSuccess: this.newSuccess,
              deletedTa: {
                taId: res.data,
                taTypeCode: this.ruleForm.tabusType
              }
            });
            this.$message({
              type: "success",
              message: "删除成功"
            });
          } else {
            this.$message.error("删除失败");
          }
        });
    },
    // 向MapBox 组件请求重新规划当前商圈区域
    editDrawTa() {
      this.newSuccess = true;
      this.isEdit = true;
      let { taId, location } = this.ruleForm;
      let taInfo = {
        taId,
        location
      };
      this.$store.state.location.type = "editTa";
      this.$emit("editDrawTa", taInfo);
    },
    // 获取人口人流数据
    getBigData() {
      // if (0 === this.info.taid) {
      // if (this.taPeopleModelData) {
      //   this.taPeopleModelShow = true; // 数据在详情里了，不用再调getBigData接口
      //   // this.taPeopleModelData = res.data;
      //   return;
      // }
      this.loading = true;

      this.AddressComponent(this.info.lngLat, (provinceCode) => {
        this.Api.getTaBigData({
          taId: this.info.taid,
          provinceCode,
          geometry: JSON.stringify(this.info.location)
        })
          .then(res => {
            this.loading = false;
            if (res.code == 200) {
              this.taPeopleModelShow = true;
              this.taPeopleModelData = res.data;
            } else {
              this.$message.error("接口请求报错");
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            if (err === "Cancelled") {
              this.$message.error("操作已取消");
            } else {
              this.$message.error("接口请求报错");
            }
          });
      })
    },
    //插叙省市区信息
    AddressComponent(location = this.info.lngLat, callback) {
      api.searchAddresByLonLat({
        latitude: location['lat'],
        longitude: location['lng']
      }).then(res => {
        if (res.code == 200) {
          const provinceCode = res.data.provinceCode;
          callback && callback(provinceCode)
          // this.ruleForm.provinceName = res.data.provinceName;
          // this.ruleForm.city = res.data.cityCode;
          // this.ruleForm.cityName = res.data.cityName;
          // this.ruleForm.district = res.data.districtCode;
          // this.ruleForm.districtName = res.data.districtName;
        }
      })
      .catch(err => {
          this.loading = false;
      });
    }
  },
  watch: {
    taVisible(val) {
      this.visible = val;
      if (this.info.taid == 0) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      if (val && this.info.taid == 0) {
        this.ruleForm.location = JSON.stringify(this.info.location);
        let ops = {
          geometry: JSON.stringify(this.info.location)
        };
        if (this.info.taid != 0 && this.info.taid != "-1") {
          ops.taID = this.info.taid;
        }

        this.getTAData(ops);
      } else if (val == false) {
        this.closePPDetail();
      }
    },
    info(val) {
      if (this.visible == true) {
        this.ruleForm.location = JSON.stringify(this.info.location);
        let ops = {
          geometry: JSON.stringify(this.info.location)
        };
        if (this.info.taid != 0 && this.info.taid != "-1") {
          ops.taID = this.info.taid;
        }
        if (this.info.taid == 0) {
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
        this.getTAData(ops);
      }
    }
  }
};
</script>
<style scoped>
.rootWidth {
  /* width: 460px !important; */
}
</style>
<style>
.ppDetail {
  font-size: 13px;
  border-radius: 5px;
}

.ppDetail .el-dialog__header {
  background: #eee;
  padding: 18px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* .pp-main {
  max-height: 350px;
  overflow-y: scroll;
} */

.pp-main::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.pp-main::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

.pp-main::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

.pp-main::-webkit-scrollbar-corner {
  background: #179a16;
}

.pp-main .el-divider--horizontal {
  margin-top: 12px;
}

.pp-main .el-form-item__label {
  font-size: 11px;
}

.pp-main .el-form-item__content {
  font-size: 11px;
}

/* .pp-main .el-form-item--small.el-form-item {
  margin-bottom: 7px;
} */

.pp-partIcon {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background: #0188e2;
  display: inline-block;
  margin: 3px 5px;
}

.ppDetail .el-dialog__footer {
  box-shadow: 0 0 10px #ddd;
}

.ta-label {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
