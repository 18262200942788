<template>
    <div class="personal-main" :style="styleObj">
        <div class="personal-card">
            <!-- 个人信息 -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <!-- <el-tab-pane label="个人信息" name="first">个人信息</el-tab-pane> -->
                <el-tab-pane name="first">
                    <span slot="label" class="info-label">个人信息</span>
                    <!-- 不可编辑 -->
                    <div v-if="!isEditInfo" style="padding: 20px;">
                        <div class="info-view">
                            <div class="info-title">
                                <span style="float:left;font-size: 13px;font-weight: 600;">个人信息</span>
                                <el-link style="float:right;" @click="editInfo">
                                    <i class="el-icon-edit"></i>
                                    <span>修改</span>
                                </el-link>
                            </div>
                        </div>
                        <el-divider></el-divider> 
                        <div class="info-content">
                            <el-form class="registerForm" :model="infoForm" label-width="90px" label-position="left">
                            <el-form-item label="用户名" prop="userName">
                                <span>{{infoForm.userName}}</span>
                            </el-form-item>
                            <el-form-item label="姓名" prop="loginName">
                                <span>{{infoForm.loginName}}</span>
                            </el-form-item>
                            <el-form-item label="手机号" prop="phone">
                                <span>{{infoForm.phone}}</span>
                            </el-form-item>
                            <el-form-item label="邮箱" prop="mail">
                                <span>{{infoForm.mail}}</span>
                            </el-form-item>
                            <el-form-item label="直接上级" prop="leader">
                                <span>{{infoForm.leader}}</span>
                            </el-form-item>
                            <el-form-item label="部门" prop="dept">
                                <span>{{infoForm.dept}}</span>
                            </el-form-item>
                            <el-form-item label="职务" prop="position">
                                <span>{{infoForm.position}}</span>
                            </el-form-item>
                        </el-form>
                        </div>
                    </div>
                    <!-- 编辑内容 -->
                    <div v-else style="padding: 10px 60px 80px;">
                        <el-upload
                            class="avatar-uploader1"
                            :action="uploadAPI"
                            :headers="headersObj"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar1">
                            <i v-else class="el-icon-plus avatar-uploader-icon">上传头像</i>
                            <div v-if="imageUrl" class="avatar-uploader-change">修改</div>
                        </el-upload>
                        <el-form class="registerForm" :model="infoForm" :rules="infoRule" ref="infoForm" label-width="90px">
                            <el-form-item label="用户名" prop="userName">
                                <el-input class="register-input" v-model="infoForm.userName" size="small" placeholder="请输入用户名"></el-input>
                            </el-form-item>
                            <el-form-item label="姓名" prop="loginName">
                                <el-input class="register-input" v-model="infoForm.loginName" size="small" placeholder="请输入真是姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号" prop="phone">
                                <el-input class="register-input" v-model="infoForm.phone" size="small" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱" prop="mail">
                                <el-input class="register-input" v-model="infoForm.mail" size="small" placeholder="请输入邮箱号码"></el-input>
                            </el-form-item>
                            <el-form-item label="直接上级" prop="leader">
                                <el-input class="register-input" v-model="infoForm.leader" size="small" placeholder="请输入直接上级"></el-input>
                            </el-form-item>
                            <el-form-item label="部门" prop="dept">
                                <el-input class="register-input" v-model="infoForm.dept" size="small" placeholder="请输入部门"></el-input>
                            </el-form-item>
                            <el-form-item label="职务" prop="position">
                                <el-input class="register-input" v-model="infoForm.position" size="small" placeholder="请输入职务"></el-input>
                            </el-form-item>
                        </el-form>
                        <div>
                            <el-button @click="confirmRegister('infoForm')" type="primary" size="small" style="width: 100%;margin-top: 30px;">
                                <span>保存</span>
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 账号安全 -->
                <el-tab-pane label="账号安全" name="second">
                    <div>
                        <div class="info-view" >
                            <div class="psd-title">
                                <div style="float:left;font-size: 13px;font-weight: 600;">用户：<span>{{infoForm.userName}}</span></div>
                            </div>
                            <div style="padding: 20px;">
                                <div style="text-align:left;">
                                    <img src="../assets/images/marker/lock-psd.png" alt="" style="width:6%">
                                    <div style="font-size: 13px;display: inline-block;position: relative;top: -9px;left: 10px;">登录密码</div>
                                    <el-button style="float:right;position: relative;top: 6px;" type="primary" plain size="small" @click="showResetPsdDialog">修改密码</el-button>
                                </div>
                                <el-divider></el-divider> 
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <ResetPsdDialog :isShowResetPsdDialog="isShowResetPsdDialog" @reset-psd="updataReset"></ResetPsdDialog>
    </div>
</template>
<script>
import ResetPsdDialog from "@/components/Dialog/Info/ResetPsdDialog.vue";
import constants from "@/constants/Map.constants.js";
import api from "@/api/index.js";
export default {
    components:{
        ResetPsdDialog
    },
    data(){
        //验证手机号
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
        return {
            activeName: 'first',
            styleObj: {},
            imageUrl: null, //默认头像
            isEditInfo: false, //编辑个人信息
            uploadAPI: "",
            infoForm: {
                userId: "",
                isAdmin: null,
                userName: "Admin",
                cusNo: "ea3c3476343a383d",
                empNo: null,
                loginName: "1",
                pwd: null,
                phone: null,
                mail: null,
                dept: null,
                position: null,
                leader: null,
                status: null,
                createDate: null,
                creator: null,
                avatar: null,
                storeIcon: "images/com/mcd.png",
                siteIcon: "images/com/pipeline.png",
                uuid: "drdefsafdsakfjsakfsafsadf"
            },
            infoRule: {
                userName:[
                    {required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                loginName:[
                    {required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                phone: [
                    {required: true, message: '请输入手机号', trigger: 'blur' },
                    {validator: checkPhone, trigger: 'blur'}
                ],
                mail: [
                    {required: true, message: '请输入邮箱', trigger: 'blur' },
                    {type: "email", message: "请输入正确的邮箱", trgger: ['blur', 'change']}
                ]
            },
            isShowResetPsdDialog: false,
            headersObj: {}
        };
    },
    mounted(){
        this.styleObj = {height: (window.screen.height-43) + "px"};
        this.infoForm = JSON.parse(sessionStorage.userInfo);
        this.uploadAPI = this.formatURL("common/uploadImage");
        this.imageUrl = this.formatURL(this.infoForm.avatar);  //拼用户头像地址
        this.headersObj = {
            "time": new Date().getTime(),
            "token": sessionStorage.token || "",
            "timestamp": sessionStorage.timestamp || ""
        }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleAvatarSuccess(res, file) {
          console.log(res);
          this.$message({
              message: res.data.message,
              type: "success"
          });
        this.imageUrl = this.formatURL(res.data.url);
        this.infoForm.avatar = res.data.url;
      },
      beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // // if (!isJPG) {
        // //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        return true;//isJPG && isLt2M;
      },
      confirmRegister(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                api.saveUser(this.infoForm).then(res => {
                    if(res.code == 200) {
                        this.visible = false;
                        this.isEditInfo = false;
                        this.$message({
                            message: "保存成功",
                            type: "success"
                        });
                        sessionStorage.userInfo = JSON.stringify(res.data);
                        location.reload();
                    } else {
                        this.$message.error("保存失败");
                    }
                });
            } else {
                this.$message.error("请完善信息！");
                return false;
            }
            });
        },
        //编辑个人信息
        editInfo(){
            this.isEditInfo = true;
        },
        //打开修改密码弹框
        showResetPsdDialog(){
            this.isShowResetPsdDialog = true;
        },
        updataReset(val){
            this.isShowResetPsdDialog = val;
        },
        formatURL(url) {
            let resUrl = "";
            if(url) {
                resUrl = constants.baseURL.API + url;
            }
            return resUrl;
        }
    }
}

    
</script>
<style >
.personal-main{
    width: 100%;
    background: #F7F6FB;
    text-align: center;
    padding-top: 19px;
}
.personal-card{
    width: 40%;
    background: #fff;
    margin: 0 auto;
}
.personal-card .el-tabs__nav{
    transform: translateX(0px);
    width: 100%;
}
.personal-card .el-tabs__active-bar{
    width: 21% !important;
    margin-left: 13% !important;
}
.personal-card .el-tabs__active-bar div{
        width: 50% !important;
}
#tab-first{
    width: 50%;
    margin-top: 8px;
    margin-bottom: 8px;
}
#tab-second{
    width: 50%;
    margin-top: 8px;
    margin-bottom: 8px;
}
.infoForm label{
    font-size: 13px;
    padding-right: 20px;
}
.info-label{
    padding: 0 42%;
    border-right: 1px solid #ddd;
}
.avatar-uploader1 .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
  .avatar-uploader1 .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 15px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px !important;
    text-align: center;
  }
  .avatar1 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
  }
  .avatar-uploader-change{
    background: #0000005e;
    position: relative;
    top: -24px;
    width: 100px;
    height: 40px;
    font-size: 13px;
    padding-top: 4px;
    color: #fff;
  }
  .personal-card .el-tabs__header{
      margin-bottom: 0 !important; 
  }
  .personal-card .el-tabs__content{
      border-top: 10px solid #f7f6fb;
  }
  .info-content .el-form-item__content{
      font-size: 13px;
      text-align: left;
  }
  .psd-title{
    background: #e6f3fc;
    padding: 17px 20px;
    line-height: 1;
    height: 13px;
  }
  .el-button--primary.is-plain{
      background: #fff;
  }
</style>