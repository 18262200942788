export default {
  namespaced: true,
  actions: {},
  mutations: {
    // 商圈数据更新
    updateTAData(state,value) {
      state.TAData = value;
    }
  },
  state: {
    TAData: {}, // 商圈数据存储
  }
}