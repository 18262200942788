<template>
  <el-dialog
    :visible.sync="visible"
    class="pp-main protection-detail"
    :show-close="false"
    :loading="loading"
    center
  >
    <span slot="title">{{ protectionForDetail ? '机会点详情' : '点位保护审核'  }}
      <i
        class="el-icon-close"
        style="cursor: pointer; font-weight: 600"
        @click="cancelApproval"
      ></i>
    </span>

    <el-collapse
        accordion
        v-model="activeName"
      >
      <el-collapse-item name="1">
        <template slot="title">
          基本信息
        </template>
        <el-form
          :model="ppForm"
          ref="basicRuleForm"
          label-width="100px"
          class="demo-ruleForm"
          size="small"
        >
          <!-- 机会点 -->
          <el-row>
            <!-- 地标名称 -->
            <el-col :sm="24" :xs="24">
              <el-form-item
                label="地标名称:"
                prop="landmark"
              >
                <span>{{ ppForm.landmark }}</span>
              </el-form-item>
            </el-col>
            <!-- 业主姓名 -->
            <el-col :sm="12" :xs="24">
              <el-form-item
                label="业主姓名:"
                prop="ownersName"
              >
                <span>{{ ppForm.ownersName }}</span>
              </el-form-item>
            </el-col>
            <!-- ，业主电话 -->
            <el-col :sm="12" :xs="24">
              <el-form-item
                label="业主电话:"
                prop="ownersTelephone"
              >
                <span>{{ ppForm.ownersTelephone }}</span>
              </el-form-item>
            </el-col>
            <!-- 机会点编号 -->
            <el-col :sm="12" :xs="24">
              <el-form-item
                label="机会点编号:"
                prop="siteCode"
              >
                <span>{{ ppForm.siteCode }}</span>
              </el-form-item>
            </el-col>
            <!-- 状态 -->
            <el-col :sm="12" :xs="24">
              <el-form-item label="状态:">
                <span>{{ ppForm.siteStatusName || '' }}</span>
              </el-form-item>
            </el-col>
            <!-- 预计开业时间 -->
            <el-col :sm="12" :xs="24">
              <el-form-item label="铺位有效期:">
                <span>{{ formatDate(ppForm.storeValidityPeriod) }}</span>
              </el-form-item>
            </el-col>

            <!-- 类型 -->
            <el-col :sm="12" :xs="24">
              <el-form-item
                label="类型:"
                prop="siteType"
              >
                <span>{{ ppForm.siteTypeName }}</span>
              </el-form-item>
            </el-col>
            <!-- 楼层 -->
            <el-col :sm="12" :xs="24">
              <el-form-item label="楼层:">
                <span>{{ ppForm.floor }}</span>
              </el-form-item>
            </el-col>
            <!-- 是否超A、大门头 -->
            <el-col :sm="12" :xs="24">
              <el-form-item
                label="是否超A,大门头:"
                prop="level"
              >
                <span>{{ ppForm.levelName }}</span>
              </el-form-item>
            </el-col>
            <!-- 授权号 -->
            <!-- 授权号做逻辑必填:当选址/测评为选址时授权号必填 -->
            <el-col :sm="12" :xs="24">
              <el-form-item
                label="授权号:"
                prop="authNumber"
              >
                <span>{{ ppForm.authNumber }}</span>
              </el-form-item>
            </el-col>
            <!-- 照片 -->
            <el-col :sm="24" :xs="24">
              <el-form-item label="照片:">
                <div v-if="ppForm.image">
                  <img v-for="img in imageFileList" :src="img.url" :key="img.name" class="upload-image" />
                </div>
              </el-form-item>
            </el-col>
            <!-- 备注 -->
            <el-col>
              <el-form-item label="备注:" prop="remark">
                <span>{{ ppForm.remark }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="2" v-if="!protectionForDetail">
        <template slot="title">
          审核
        </template>
        <div class="protection-main">
          <el-form
            :model="ppForm"
            ref="ppForm"
            label-width="100px"
            size="small"
          >
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item label="保护起始时间:">
                  <span>{{ formatDate(ppForm.protectionTimeStart) }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="保护结束时间:">
                  <span>{{ formatDate(ppForm.protectionTimeEnd) }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">
                <el-form-item label="保护范围(米):">
                  <span>{{ ppForm.scopeOfProtection }}</span>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :xs="24">
                <el-form-item label="点位保护照片:">
                  <!-- <span>{{ ppForm.protectionTimeFile }}</span> -->
                  <img v-for="img in protectionTimeFileList" :src="img.url" class="protection-image" :key="img.name" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form
            :model="protectionForm"
            label-width="110px"
            ref="protectionForm"
            size="small"
          >
            <el-row>
              <!-- 审批结果 -->
              <el-col :sm="24" :xs="24">
                <el-form-item
                  label="审批结果:"
                  :rules="[{ required: true, message: '请选择审批结果', trigger: ['change', 'submit'] }]"
                  prop="result"
                >
                  <el-select
                    v-model="protectionForm.result"
                    placeholder="请选择"
                    :editable="true"
                  >
                    <el-option label="通过" value="通过" ></el-option>
                    <el-option label="驳回" value="驳回" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 审批意见 -->
              <el-col>
                <el-form-item
                  label="审批意见:"
                  prop="comment"
                  :rules="[{ required: true, message: '请填写审批意见', trigger: ['change', 'submit'] }]"
                >
                  <el-input
                    type="textarea"
                    v-model="protectionForm.comment"
                    placeholder="请填写审批意见"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 保护附件 -->
              <el-col>
                <el-form-item
                  label="保护附件:"
                  prop="protectionFile"
                >
                  <el-upload
                    style="display:inline-block;"
                    class="avatar-uploader"
                    :action="imageURL"
                    :headers="headersObj"
                    :file-list="protectionApprovalFileList"
                    :show-file-list="true"
                    list-type="picture-card"
                    multiple
                    :limit="5"
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload">
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__text">最多上传5个，不大于20M</div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div
      slot="footer"
      class="dialog-footer"
      v-if="!protectionForDetail"
    >
      <el-button
        @click="cancelApproval"
        size="small"
        style="width: 100px; margin-right: 20px"
      >取 消</el-button>
      <el-button
        type="primary"
        @click="confirmApproval"
        size="small"
        style="width: 100px"
      >确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/index.js";
import constants from "@/constants/Map.constants.js";

export default {
  name: "MapProtection",
  props: ["protectionVisible", "protectionForDetail", "ppInfo"],
  data() {
    return {
      activeName: "2",
      visible: false,
      type: "",
      ppForm: { // 机会点信息，主要是点位保护信息

      },
      imageFileList: [],
      protectionForm: {
        itemId: '', // 任务id
        executor: '', // 当前登陆人positionId
        result: '', // 审批结果 (可设置为勾选按钮:通过 驳回)
        comment: '', // 审批意见
        protectionFile: '' // 保护附件
      },
      protectionTimeFileList: [], // 机会点，点位保护图片转换后列表
      protectionApprovalFileList: [], // 机会点保护审核附件

      loading: false,
      saveDetailShake: null,
      imageURL: "", // 图片上传地址
      headersObj: {}, // 图片上传请求header
      hasFootprint: false, // 是否已有足迹信息

      protectionVal: {}, // 存初始参数
      footId: null // 父id
    };
  },
  mounted() {
    this.visible = this.protectionVisible;

    this.imageURL = this.formatURL("common/uploadImage");
    this.headersObj = {
        "time": new Date().getTime(),
        "token": sessionStorage.token || "",
        "timestamp": sessionStorage.timestamp || ""
    }
  },
  methods: {
    //关闭弹框
    cancelApproval() {
      this.visible = false;
      this.$emit("protection-visible", {
        visible: this.visible
      });
    },
    confirmApproval(evt, otherParams) {
      this.activeName = "2";
      try {
        if (this.saveDetailShake != null) clearTimeout(this.saveDetailShake);
        this.saveDetailShake = setTimeout(() => {
          // this.protectionForm.cusNo = sessionStorage.cusNo;
          this.$refs["protectionForm"].validate((valid) => {
            if (valid) {
              this.confirmProtection()
            } else {
              return false;
            }
          });
          this.saveDetailShake = null;
        }, 200);
      } catch (error) {
        console.log(error)
      }

    },
    // 保存审核
    confirmProtection(type) {
      this.loading = true;
      const protectionParams = Object.assign({}, this.protectionForm);
      console.log('info, protectionParams :>> ', protectionParams);

      api.approvalProtectSite(protectionParams).then(res => {
          this.loading = false;
          if (res.code == 200) {
            if (res.data == 0) {
              this.$message.error("无审核权限");
            } else {
              this.$message({
                message: "审核成功",
                type: "success",
              });
              this.$emit("protection-success");
              this.cancelApproval();
            }
          } else {
            this.$message.error("审核失败");
          }
      }).catch(e => {
        this.loading = false;
        this.$message.error("审核失败");
        // this.$emit("protection-success");
      })
    },
    formatURL(url) {
        let resUrl = "";
        if(url) {
            resUrl = constants.baseURL.API + url;
        }
        return resUrl;
    },
    // 日期格式化：yyyy-MM-dd
    formatDate(value, format) {
      format = format || "yyyy-MM-dd";
      if (
        value && value.toString() &&
        value.toString().indexOf("-") == -1
      ) {
        value = this.$Format(
          "yyyy-MM-dd",
          value
        );
      }
      else if (value) { value = value.slice(0, 10); }
      return value
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      var newFileList = fileList.map(f => {
          return { 
            name: f.name,
            url: f.response ? f.response.data : f.url
          }
        });
        this.protectionForm.protectionFile = newFileList.map(nF => nF.url).join(','); // 参数合成
        this.protectionApprovalFileList = fileList;
    },
    beforeUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024  < 20;

        if (!isLt2M) {
            this.$message.error('上传附件大小不能超过 20M!');
        }
        return isLt2M;
    },
    // 机会点点位保护图片
    handleSuccess(res, file, fileList) {
        var newFileList = fileList.map(f => {
          return { 
            name: f.name,
            url: f.response ? f.response.data : f.url
          }
        });
        this.protectionForm.protectionFile = newFileList.map(nF => nF.url).join(','); // 参数合成
        this.protectionApprovalFileList = fileList;
    },
  },
  watch: {
    protectionVisible(val) {
      this.visible = val != "";
      this.type = val;
    },
    ppInfo(val) {
      console.log(val);

      if (!this.protectionVisible) {
        return;
      }
      this.ppForm = JSON.parse(val.datas);

      this.imageFileList = this.ppForm.image ? this.ppForm.image.split(',').map((img, idx) => {
        return {
          name: 'img' + idx,
          url: img
        }
      }) : []

      this.protectionTimeFileList = this.ppForm.protectionTimeFile ? this.ppForm.protectionTimeFile.split(',').map((img, idx) => {
        return {
          name: 'img' + idx,
          url: img
        }
      }) : []

      this.protectionForm = {
        itemId: val.siteId, // 任务id
        executor: sessionStorage.renderPositionId, // 当前登陆人positionId
        result: '', // 审批意见 (可设置为勾选按钮:通过 驳回)
        comment: '', // 审批意见 (可设置为勾选按钮:通过 驳回)
        protectionFile: '' // 保护附件
      };
      this.protectionApprovalFileList = [];

      this.$nextTick(() => {
        if (this.$refs["protectionForm"]) { // 清空验证
          this.$refs["protectionForm"].clearValidate();
        }
      })

      this.activeName = this.protectionForDetail ? '1' : '2';
    }
  }
};
</script>
<style scoped lang="scss">
.protection-detail {
  &::v-deep .el-dialog {
    position: absolute;
    top: 50px;
    left: 50%;
    background: #ffffffe6;
    padding: 10px;
    width: 650px;
    margin: 0 !important;
    max-height: calc(100vh - 80px);
    transform: translate(-50%, 0);
    &__body {
      padding: 0;
      max-height: calc(100vh - 134px);
      overflow: auto;
    }
    .protection-title {
      text-align: left;
    }

    .protection-main {
      padding: 10px 0 20px;
      // border-bottom: 1px solid #ddd;
    }

    .protection-main .el-divider--horizontal {
      margin-top: 12px;
    }

    .protection-main label.el-form-item__label {
      font-size: 13px;
      padding-right: 6px;
      text-align: left;
    }

    .protection-main .el-form-item__content {
      font-size: 11px;
    }

    .el-dialog__header {
      padding: 0 0 10px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      font-weight: bold;

      .el-icon-close {
        cursor: pointer;
        font-weight: 600;
        float: right;
        margin-top: 3px;
      }
    }
    .el-dialog__footer {
      padding: 20px 0;
    }
    .protection-image {
      max-height: 100px;
      max-width: 400px;
    }
    .protection-list {
      .list-title {
        font-weight: bold;
        padding: 10px 0;
      }
      .list-items {
        padding: 10px 0;
        .list-item-empty {
          padding: 20px;
          text-align: center;
          color: #666;
        }
        .list-item {
          padding: 10px 0;
          border-bottom: 1px solid rgba(0, 0, 0, .1);
          .list-item-name {
            position: relative;
            color: #666;
            padding-bottom: 5px;
            .list-item-updateTime {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .list-item-content {
            color: #333;
            img {
              display: block;
              height: 100px;
              width: auto;
              padding-top: 5px;
            }
          }
        }
      }
    }

    .upload-image {
      width: 120px;
      height: 120px;
      display: inline-block;
      border-radius: 6px;
      border: 1px solid #c0ccda;
      overflow: hidden;
      margin: 0 6px 6px 0;
    }
  }
  &::v-deep .el-col .is-error {
    margin-bottom: 20px !important;
  }
}

/* 移动端适配 */
@media screen and (max-width: 500px) {
   .protection-detail {
      width: 100% !important;
      z-index: 1000;
      // box-shadow: 2px 1px 8px 4px rgba(0, 0, 0, 0.2);

      &::v-deep .el-dialog {
        top: 50px;
        width: 90% !important;
        max-height: calc(100vh - 60px);
        &__body {
          max-height: calc(100vh - 150px);
        }
      }
      .el-select {
        width: 100%;
      }
    }
}
</style>
