<template>
  <el-container>
    <el-header>
      <!-- 右侧header -->
        <Header />
    </el-header>
    <!-- 右侧页面主题 -->
    <el-container>
      <!-- 左侧导航菜单 -->
      <!-- <el-aside width="240px" style="height: 100%;text-align: left;padding-left: 13px;">
        <el-menu :default-active="listIndex"
          @select="handleSelect" @open="goList" router>
            <el-menu-item-group>
              <template slot="title">
                <span style="font-size: 14px;margin-left: -20px;color:#0188e2">
                  <i class="el-icon-mlist1" style="color:#0188e2"></i>
                  {{ $t("aside.listMode") }}
                </span>
                <span style="margin-left: 17px;cursor: pointer;" @click="toMap">切换到地图模式</span>
              </template>
              <el-menu-item index="/List/pipeline">{{ $t("aside.pipeline") }}</el-menu-item>
              <el-menu-item index="/List/store">{{ $t("aside.store") }}</el-menu-item>
              <el-menu-item index="/List/competitor">{{ $t("aside.competitor") }}</el-menu-item>
            </el-menu-item-group>
        </el-menu>
      </el-aside> -->
    <!-- 右侧主页面：地图及表格 -->
      <el-main style="padding: 5px;"  class="list-container">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Header from "@/components/HeaderMenu.vue";

export default {
  components: {
    Header
  },
  data(){
    return {
      listIndex: "/List/pipeline",
      listVisibility: "visibility:hidden",
      fit: "fill",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      isMap:true,
      mapType: "",
      isLogin: sessionStorage.getItem("isLogin")||0,
      isMapOrList: true,
      isChange: false
    };
  },
  mounted(){
    
  },
  methods: {
    handleSelect(key) {
      if(this.mapType == key)
        this.mapType = "";
        var that = this;
        setTimeout(function(){
          that.mapType = key
        }, 0);
      this.$store.state.mapCommand = key;
    },
    goMap(key, keyPath){
      this.$router.push("/" + keyPath[0]);
    },
    goList(key, keyPath){
      this.$router.push("/" + keyPath[0]);
    },
    toList(){
      this.isMap = false;
      this.mapType = "";  //切换到列表模式后 关闭地图弹框
      this.$store.state.mapCommand = "";
      this.$router.push("/List/pipeline");
      this.listVisibility = "visibility:visible";
    },
    toMap(){
      this.isMap = true;
      this.$router.push("/");
      this.listVisibility = "visibility:hidden";
    },
    updataMapType(value){
      if(!value && !this.mapType){
        this.handleSelect("");
      }
    }
  },
  watch:{
    $route(to){
      if(to.path == "/" || to.path.indexOf("/List/")>-1){
        this.isMapOrList = true;
        if(to.path == "/"){
          this.isMap = true;
          this.listVisibility = "visibility:hidden";
        } else {
          this.isMap = false;
          this.listVisibility = "visibility:visible";
        }
      }else{
        this.isMapOrList = false;
      }
    }
  }
};
</script>
<style scoped>
.el-header{
  /* height: 42px !important; */
}
</style>
