export default {
  data() {
    return {
      formLst: {
        // mainBrandList: [], // 主营品牌
        // sTypeList: [], // 店铺 常规店/大店
        // storeTypeList: [], // 店铺类型
        // busModeList: [], // 用户分类
        // busScopeList: [], // 经营分类
        // busTypeList: [], // 业务分类
        // shopClassifyList: [], // 店铺重分类
        // regionList: [], // 面积范围
      }, // 表单选项字典
      formData:{}, // 表单数据
    }
  },
  methods: {}
}