<template>
    <div id="app">
        <el-container style="height: 100%;overflow-x: hidden;">
            <router-view v-slot="{ Component, route }">
                <keep-alive>
                    <component :is="Component" :key="route.nema"></component>
                </keep-alive>
            </router-view>
        </el-container>
    </div>
</template>
<script>
//  import constants from "./constants/Map.constants.js";
export default {
    components: {
        //Header //,
        //  Aside
    },
    // created(){
    //     new DevicePixelRatio().init();
    // },
    mounted() {
        if (this._isMobile()) {
            // TEST 测试PC适配移动端
            // window.location.href = constants.baseURL.APPURL;
        }
    },
    methods: {
        _isMobile() {
            let flag = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.exec(navigator.userAgent)
            this.$store.commit('updateIsMobile', !!flag);
            return flag;
        }
    }
};
</script>
<style lang="scss">
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
}

#nav {
    a {
        font-weight: bold;
        color: #2c3e50;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
