<template>
  <div class="poi-root">
    <el-dialog
      :visible.sync="myShow"
      width="100%"
      :modal="false"
      :close-on-click-modal="false"
      v-draggable
      v-loading="loadingData"
      @close="$emit('visibleChange',false)">
      <template v-slot:title>
        <span style="font-weight: bold;color: #02283f">
          poi图层 (最多选择5个小类)
        </span>
      </template>
      <el-row :gutter="20">
        <el-col :span="8">
          <h4>大类</h4>
        </el-col>
        <el-col :span="8">
          <h4>中类</h4>
        </el-col>
        <el-col :span="8">
          <h4>小类</h4>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="poi-checkbox">
        <el-col :span="8">
          <div class="grid-content">
            <el-checkbox-group v-model="selectBig" v-if="typeBig?.length > 0">
              <el-checkbox v-for="item in typeBig" :label="item.dict_Code" :key="item.dict_Code">{{item.type_Name}}</el-checkbox>
            </el-checkbox-group>
            <span v-else>大类加载失败</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <el-checkbox-group v-model="selectMiddle" v-if="typeMiddle?.length > 0">
              <el-checkbox v-for="item in typeMiddle" :label="item.dict_Code" :key="item.dict_Code">{{item.type_Name}}</el-checkbox>
            </el-checkbox-group>
            <span v-else>无数据，先选择大类</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <el-checkbox-group v-model="selectSmall" v-if="typeSmall?.length > 0">
              <el-checkbox v-for="item in typeSmall" :label="item.dict_Code" :key="item.dict_Code">{{item.type_Name}}</el-checkbox>
            </el-checkbox-group>
            <span v-else>无数据，先选择中类</span>
          </div>
        </el-col>
      </el-row>

      <div style="overflow: hidden;">
          <el-button type="primary" @click="doSearchPoi" class="poi-leaf" size="small" style="float: right; margin-top: 10px;">
            <span>查询</span>
          </el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
//引入api
import api from "../../../api/index.js";
export default {
  name: "MapPoiSelectModel",
  model: {
    prop: 'show',
    event: 'visibleChange'
  },
  props: {
    // 展示poi类型选项
    show: {
      type: Boolean,
      default: false
    },
    // 常用小类
    poiIsCommon: {
      type: Boolean,
      default: false
    },
    typeBig: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      myShow: false,
      loadingData: false,
      typeMiddle: [], // 中类集合
      typeSmall: [], // 小类集合
      selectBig: [], // 大类选项
      selectMiddle: [], // 中类选项
      selectSmall: [] // 小类选项
    }
  },
  mounted() {
  },
  watch: {
    show(newV, oldV) {
      this.myShow = newV;
    },
    selectBig(newV, oldV) {
      console.log('selectBig: ', newV, oldV);
      if (newV?.length === 0) {
        this.selectMiddle = [];
        this.typeSmall = [];
        this.selectSmall = [];
        this.typeMiddle = [];
        return;
      }
      this.selectPoi('333', newV.join(','), data => {
        this.typeMiddle = data;
        this.selectMiddle = this.filterKeys(this.selectMiddle, this.typeMiddle, 'dict_Code');
      })
    },
    selectMiddle(newV, oldV) {
      console.log('selectMiddle: ', newV, oldV);
      if (newV?.length === 0) {
        this.selectSmall = [];
        this.typeSmall = [];
        return;
      }
      this.selectPoi('444', newV.join(','), data => {
        this.typeSmall = data;
        this.selectSmall = this.filterKeys(this.selectSmall, this.typeSmall, 'dict_Code');
      })
    },
    selectSmall(newV, oldV) {
      if (newV.length > 5) {
        this.$message({
          message: `小类最多选择5个！`,
          type: "warning"
        });
        this.selectSmall = oldV;
        return;
      }
      const newVName = this.selectSmall.map(s => {
        return this.typeSmall.find(i => i.dict_Code === s)?.type_Name;
      })
      this.$emit("small-type", newV, newVName);
    }
  },
  methods: {
    // 选择Poi
    selectPoi(type, codes, cb) {
      this.loadingData = true;
      api.getPoiDataType({
        type,
        codes
      })
        .then(res => {
          this.loadingData = false;
          if (res.code == 200) {
            console.log('getPoiDataType: ', res.data);
            cb?.(res.data);
          } else {
            this.$message.error("接口请求报错");
          }
        })
        .catch(err => {
          this.loadingData = false;
          console.log(err);
          if (err === "Cancelled") {
            this.$message.error("操作已取消");
          } else {
            this.$message.error("接口请求报错");
          }
        });
    },
    // 查询
    doSearchPoi() {
      this.$emit("do-search-poi");
    },
    // 过滤选中的数组，只保留值还在来源数组里的值
    filterKeys(array, sourceArray, key, isObj) {
      return array.filter(a => sourceArray.find(s => s[key] === (isObj ? a[key] : a)))
    }
  }

}
</script>

<style scoped lang="scss">
.poi-root {
  &::v-deep .el-dialog__body {
    padding-top: 0;
    
  }
  &::v-deep .el-dialog {
    margin-top: 0 !important;
    max-width: 700px;
  }

  h4 {
    margin: 10px 0;
  }
  .poi-checkbox {
    display: flex;
    gap: 10px;
    .el-col {
      border: 1px solid #ddd;
      padding: 10px;
      height: 500px;
      max-height: 80vh;
      overflow: auto;
      background: rgba(0, 0, 0, .05);
      .el-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}

h3 {
  padding: 0;
  margin: 10px 0;
}
/* 移动端适配 */
@media screen and (max-width: 500px) {
  div.poi-root ::v-deep .el-dialog {
    left: 10px !important;
    top: 10px !important;
    max-width: calc(100vw - 20px);
  }
}
</style>