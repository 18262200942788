<template>
    <el-dialog :visible.sync="visible" width="30%" :show-close="false" class="register-dialog resetPsd-dialog" 
        center  :before-close="closeResetPsd">
        <span slot="title">修改密码</span>
        <el-divider></el-divider>
        <div>
            <el-form :model="resetPsdForm" :rules="resetPsdRules" ref="resetPsdForm" label-width="90px">
                <el-form-item label="旧密码" prop="oldPsd">
                    <el-input type="password" class="register-input" v-model="resetPsdForm.oldPsd" size="small"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPsd">
                    <el-input type="password" class="register-input" v-model="resetPsdForm.newPsd" size="small"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPsd">
                    <el-input type="password" class="register-input" v-model="resetPsdForm.confirmPsd" size="small"></el-input>
                </el-form-item>
            </el-form>
            <el-row style="text-align:center">
                <el-button style="width: 40%;margin-right:20px;" @click="closeResetPsd">取消</el-button>
                <el-button type="primary" style="width: 40%" @click="confirmResetPsd('resetPsdForm')">保存</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>
<script>
import api from "@/api/index.js";
import md5 from 'js-md5';
export default {
    name: "ResetPsdDialog",
    props: ["isShowResetPsdDialog"],
    data(){
        //验证旧密码
        var checkOldPsd = (rule, value, callback) => {
            if(value === ""){
              callback(new Error("密码不能为空"));
            // } else if(false){
            //     //验证原始密码输入是否正确
            //     callback(new Error("旧密码错误"));
            } else {
                callback();
            }
        };
        //验证新密码是否符合要求
        var checkNewPsd = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('密码不能为空'));
            } else if (value.length<6||value.length>16) {
                callback(new Error('6-16位字符组成，区分大小写'));
            } else {
                callback();
            }
        };
        //验证两次密码是否一致
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.resetPsdForm.newPsd) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        };
        return {
            visible: false,
            resetPsdForm: {
                oldPsd: "",
                newPsd: "",
                confirmPsd: ""
            },
            resetPsdRules: {
                oldPsd: [{validator: checkOldPsd, trigger: 'blur' }],
                newPsd: [{validator: checkNewPsd, trigger: 'blur' }],
                confirmPsd: [{validator: validatePass, trigger: 'blur' }]
            },
            userInfo: {},
            cusNo: ""
        }
    },
    mounted(){
        this.visible = this.isShowResetPsdDialog;
        this.cusNo = sessionStorage.cusNo;
        this.resetPsdForm = {
            oldPsd: "",
            newPsd: "",
            confirmPsd: ""
        };
        this.userInfo = JSON.parse(sessionStorage.userInfo);
    },
    methods:{
        confirmResetPsd(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                let ops = {
                    loginName: this.userInfo.loginName,
                    newPwd: md5(this.resetPsdForm.newPsd),
                    pwd: md5(this.resetPsdForm.oldPsd),
                    cusNo: this.cusNo
                };
                api.changPwd(ops).then(res => {
                    if(res.code == 200) {
                        this.$message({
                            message: res.message,
                            type: "success"
                        });
                        this.visible = false;
                        this.$emit("reset-psd",this.visible);
                    }
                    else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        closeResetPsd(){
            this.visible = false;
            this.$emit("reset-psd",this.visible);
        }
    },
    watch:{
        isShowResetPsdDialog(val){
            this.visible = val;
        }
    }
}
</script>
<style>
.resetPsd-dialog .el-divider--horizontal{
    margin-top: 10px; 
    margin-left: -25px;
    padding-left: 50px;
}
</style>