<template>
    <div class="rec-container" v-if="show">
        <div class="rec-title">
            <span style="font-size: 16px;font-weight: 600;
    color: rgb(138, 132, 132);">{{recInfo.name + "商圈详情"}}</span>
            <span class="rec-close" @click="closeRecInfo"><i class="el-icon-close" style="font-weight: 600;"></i></span>
        </div>
        <div class="rec-main">
            <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in recInfo.group" :key="index">
                    <template slot="title">
                    {{$i18n.locale=="zh"?item.cnName:item.enName}}<i class="header-icon el-icon-info"></i>
                    </template>
                    <el-row v-for="(dItem, dIndex) in item.items" :key="dIndex">
                        <span class="ta-label">
                            {{ ($i18n.locale=='zh'?dItem.cnLabel:dItem.enLabel)+':' }}
                        </span>
                        <span style="margin-left: 10px;">
                            {{formatData(dItem.fieldValue)}}
                        </span>
                    </el-row>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>
<script>
import api from "@/api/index.js";
export default {
    name: "MapRecommendInfo",
    props: ["isShow"],
    data() {
      return {
        styleObj: {},
        isMapShow: false,
        show: false,
        opt: {},
        recInfo: {},
        recGroups: {}
      };
    },
    methods: {
      closeRecInfo(){
          this.$store.state.recInfoVisible = false;
      },
      // 判断字符串是否为数字
        isNumber(val) {
            var regPos = /^\d+(\.\d+)?$/; //非负浮点数
            var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
            if(regPos.test(val) || regNeg.test(val)) {
                return true;
            } else {
                return false;
            }
        },
        //格式化数据小数点
        formatData(val) {
            let result = val;
            if (!val) result = val;
            if(this.isNumber(val) && val.indexOf(".") > -1) {
                result = Number(val).toFixed(2);
            } 
            return result;
        },
    },
    mounted() {
    //   this.styleObj = {
    //     width: (this.$store.state.screenWidth -40 ) + "px"
    //   };
      
    },
    watch:{
    //   "$store.state.screenWidth":function(value){
    //     this.styleObj = {
    //       width: (value-40) + "px"
    //     }
    //   },
      "$store.state.recInfoVisible": function(value) {
          this.show = value;
          if(this.show) {
              this.recInfo = this.$store.state.recInfo;
              this.recInfo.group = JSON.parse(this.recInfo.group);
              console.log(this.recInfo);
          } else {
              this.recInfo = {
                  name: "",
                  group: []
              };
          }
      }
     
    }
}
</script>
<style>
.rec-container {
    position: absolute;
    z-index: 10;
    background: #ffffffeb;
    text-align: left;
    top: 80px;
    left: 50px;
    height: 500px;
    border-radius: 4px;
    padding: 10px;
    overflow: hidden;
    width: 300px;
    -webkit-box-shadow: 2px 1px 8px 4px #bbb;
    box-shadow: 2px 1px 8px 4px #bbb;
}
.rec-title {
    margin: 0 -10px;
    padding: 0 10px 10px;
    border-bottom: 1px solid #ddd;
}
.rec-close {
    float: right;
    cursor: pointer;
}
.rec-main {
    overflow-y: auto;
    max-height: 464px;
    margin-right: -10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.rec-main .el-divider--horizontal{
    margin-top: 12px;
}
.rec-main .el-form-item__label{
    font-size: 11px;
}
.rec-main .el-form-item__content{
    font-size: 11px;
}
.rec-main .el-form-item--small.el-form-item{
    margin-bottom: 7px;
}
/* 滚动条 */
.rec-main::-webkit-scrollbar{
  width:8px;
  height:8px;
}
.rec-main::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
.rec-main::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
.rec-main::-webkit-scrollbar-thumb:hover{
  background: #333;
}
.rec-main::-webkit-scrollbar-corner{
  background: #179a16;
}
.rec-main .el-collapse-item__wrap {
    background-color: #ffffff00;
}
.rec-main .el-collapse-item__header {
    background-color: #ffffff00;
    border-bottom: 0 solid;
    font-size: 13px;
    font-weight: 600;
    color: #908c8c;
    height: 40px;
    line-height: 40px;
}
.rec-main .el-collapse-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-left: 7px;
    font-size: 12px;
}
.rec-main .el-collapse {
    border: 0 solid;
}
</style>