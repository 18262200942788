<template>
    <el-dialog :visible.sync="visible"
        width="30%" :show-close="false" class="register-dialog" 
        center 
        >
        <span slot="title">
            <el-avatar shape="square" :size="size" :src="squareUrl"></el-avatar>
        </span>
        <div style="padding: 0 40px;">
            <el-form class="registerForm" :model="registerForm" :rules="registerRules" ref="registerForm" label-width="90px">
                <el-form-item label="用户名" prop="userName">
                    <el-input class="register-input" v-model="registerForm.userName" size="small"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="psw">
                    <el-input type="password" class="register-input" v-model="registerForm.psw" size="small"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPsw">
                    <el-input type="password" class="register-input" v-model="registerForm.confirmPsw" size="small"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input class="register-input" v-model="registerForm.name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input class="register-input" v-model="registerForm.phone" size="small"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input class="register-input" v-model="registerForm.email" size="small"></el-input>
                </el-form-item>
                <el-form-item label="直接上级" prop="leader">
                    <el-input class="register-input" v-model="registerForm.leader" size="small"></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="department">
                    <el-input class="register-input" v-model="registerForm.department" size="small"></el-input>
                </el-form-item>
                <el-form-item label="职务" prop="job">
                    <el-input class="register-input" v-model="registerForm.job" size="small"></el-input>
                </el-form-item>
            </el-form>
            <div>
                <el-button @click="confirmRegister('registerForm')" type="primary" style="width: 100%;margin-top: 10px;">
                    <span>申请账号</span>
                </el-button>
            </div>
            <div style="text-align: right; font-size: 13px; margin-top: 10px;color: #bebebe">
                已有账号？
                <el-link type="primary" @click="goLoginDialog">
                    <span style="font-size: 13px;">立即登录</span>
                </el-link>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import md5 from 'js-md5';
export default {
    name: "RegisterDialog",
    props:["regVisible"],
    data(){
        //验证两次密码是否一致
        var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.registerForm.psw) {
          callback(new Error('两次输入密码不一致'));
        } else {
          callback();
        }
      };
      //验证手机号
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
        return{
            visible: false,
            loginVisible: false,  //跳转登录弹框
            size: "large",
            squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            registerForm:{
                userName:"",
                psw:"",
                confirmPsw: "",
                name: "",
                phone: "",
                email: "",
                leader: "",
                department: "",
                job: ""
            },
            registerRules:{
                userName:[
                    {required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                psw: [
                    {required: true, message: '请输入密码', trigger: 'blur' },
                    {min: 6, max: 16, message: '6-16位字符组成，区分大小写', trigger: 'blur' }
                    ],
                confirmPsw: [
                    {required: true, message: '请再次输入密码', trigger: 'blur' },
                    {validator: validatePass, trigger: 'blur' }
                    ],
                name:[
                    {required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                phone: [
                    {required: true, message: '请输入手机号', trigger: 'blur' },
                    {validator: checkPhone, trigger: 'blur'}
                ],
                email: [
                    {required: true, message: '请输入邮箱', trigger: 'blur' },
                    {type: "email", message: "请输入正确的邮箱", trgger: ['blur', 'change']}
                ]

            }
        }
    },
    mounted(){
        this.visible = this.regVisible;

    },
    methods: {
        showClose(){
            this.visible = false;
        },
        confirmRegister(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.visible = false;
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        goLoginDialog(){
            this.visible = false;
            this.loginVisible = true;
            this.$emit("go-login",this.loginVisible);
        }
    },
    watch:{
        regVisible(val){
            this.visible = val;
        }
    }
}
</script>
<style>
.register-dialog .el-dialog--center{
    margin-top: 8vh !important;
}
.register-dialog .el-dialog__body{
    padding-top: 0 !important;
}
.registerForm label{
    font-size: 13px;
    padding-right: 20px;
}
.registerForm .el-form-item{
    margin-bottom: 10px;
}
.registerForm .el-form-item__error{
    padding-top: 0;
}
.register-input input{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    font-size: 13px;
}
</style>