import { fetch } from "./fetch.js";
import qs from 'qs';

export default {
	//登录(截取登录响应数据，获取锐力用户登录结果)
	async userLogin(data) {
		return fetch({
			url: "/user/login",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//获取登陆页配置
	userInit(data) {
		return fetch({
			url: "/user/init",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//修改用户信息
	saveUser(data) {
		return fetch({
			url: "/user/saveUser",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//修改密码
	changPwd(data) {
		return fetch({
			url: "/user/changPwd",
			method: "post",
			data: qs.stringify(data)
		});
	},
	// 图例筛选 用于门店，机会点，竞品
	renderMarker(data) {
		return fetch({
			url: "/map/sign",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//查询竞品品牌
	getCompetitorBrand(data) {
		return fetch({
			url: "/common/brand",
			method: "get",
			data
		});
	},
	//图例筛选
	getLegendList(data) {
		return fetch({
			url: "/common/legend",
			method: "get",
			data
		});
	},
	// 选择Poi
	getPoiDataType(data) {
		return fetch({
			url: "/map/getPoiDataType",
			method: "get",
			data
		});
	},
	// 查找Poi地址
	getPoiData(data) {
		return fetch({
			url: "/map/getPoiData",
			method: "get",
			data
		});
	},
	// 查找常用Poi地址
	getPoiDataTypeOften(data) {
		return fetch({
			url: "/map/getPoiDataTypeOften",
			method: "get",
			data
		});
	},
	//图例标注：保存机会点信息
	savePipelineInfo(data) {
		return fetch({
			url: "/mark/pipeline",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//机会点详情
	initPipelineInfo(data) {
		return fetch({
			url: "/mark/siteDetail",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//门店详情
	initStoreInfo(data) {
		return fetch({
			url: "/mark/storeDetail",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//竞品详情
	initVSInfo(data) {
		return fetch({
			url: "/mark/competitorDetail",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//图例标注：保存门店信息
	saveStoreInfo(data) {
		return fetch({
			url: "/mark/store",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//图例标注：保存竞品信息
	saveCompetitorInfo(data) {
		return fetch({
			url: "/mark/competitor",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//商圈详情保存
	saveTAInfo(data) {
		return fetch({
			url: "/mark/business",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//机会店推荐
	recommend(data) {
		return fetch({
			url: "/map/recommend",
			method: "post",
			data: qs.stringify(data)
		});
	},
	// 等时圈
	isochronous(data) {
		return fetch({
			url: "/map/isochronous",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//等时圈报告
	isochronousReport(data) {
		return fetch({
			url: "/map/isochronousReport",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//热力图
	hotMap(data) {
		return fetch({
			url: "/map/hotMap",
			method: "post",
			data: qs.stringify(data)
		});
	},
	//商圈详情
	getTAInfo(data) {
		return fetch({
			url: "/mark/taDetail",
			method: "post",
			data: qs.stringify(data)
		})
	},
	//商圈数据
	getTAData(data) {
		return fetch({
			url: "/map/taData",
			method: "post",
			data: qs.stringify(data)
		})
	},
	// 商圈容量数据
	taModel(data) {
		return fetch({
			url: "/taModel/taModel",
			method: "post",
			data: qs.stringify(data)
		})
	},
	//获取公司品牌
	getCompany(data) {
		return fetch({
			url: "/common/company",
			method: "get",
			data
		})
	},
	//商圈报告
	taReport(data) {
		return fetch({
			url: "/map/taReport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//缓冲区报告
	bufferReport(data) {
		return fetch({
			url: "/map/bufferReport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//机会点删除
	deleteSite(data) {
		return fetch({
			url: "/mark/deleteSite",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//更新进度管理
	updateProgress(data) {
		return fetch({
			url: "/mark/updateProgress",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//商圈删除
	deleteSmartTa(data) {
		return fetch({
			url: "/map/deleteSmartTa",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//机会点转化为门店
	pipelineTurnStore(data) {
		return fetch({
			url: "/mark/pipelineTurnStore",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//获取管理端POI数据类型获取
	getMangerBlockType(data) {
		return fetch({
			url: "/map/getMangerBlockType",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//获取管理端满足条件网格获取
	getMangerOkBlock(data) {
		return fetch({
			url: "/map/getMangerOkBlock",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 保存足迹
	saveFootprint(data) {
		return fetch({
			url: "/mark/footprint",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 足迹详情
	footprintInfo(data) {
		return fetch({
			url: "/mark/getFootprint",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 删除足迹
	deleteFootprint(data) {
		return fetch({
			url: "/mark/deleteFootprint",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 省市区边界
	getRegion(data) {
		return fetch({
				url: "/map/getRegion",
				method: "POST",
				data: qs.stringify(data)
			});
	},
	// 蜂巢图
	getHoneycomb(data) {
		return fetch({
			url: "/map/getHoneycomb",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 预测销售额蜂巢图
	getHoneyCombPredict(data) {
		return fetch({
			url: "/map/getHoneyCombPredict",
			method: "get",
			data
		});
	},
	// 网格图
	getGrid(data) {
		return fetch({
			url: "/map/getGrid",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 人口人流数据
	getBigData(data) {
		return fetch({
			url: "/map/getBigData",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 蜂巢图 人口人流数据
	getHoneycombBigData(data) {
		return fetch({
			url: "/bigData/getHoneycombBigData",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 更新蜂巢块showFlag
	updateHoneycombStatus(data) {
		return fetch({
			url: "/map/updateHoneycombStatus",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 商圈 人口人流数据
	getTaBigData(data) {
		return fetch({
			url: "/bigData/getTaBigData",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 点位保护审批
	approvalProtectSite(data) {
		return fetch({
			url: "/mark/approvalProtectSite",
			method: "POST",
			data: qs.stringify(data)
		});
	},

	/******************位置查询*************************/
	//查询省市区三级联动  TypeID:101 省   102 市   103 区
	searchSmartDataarea(data) {
		return fetch({
			url: "/common/dataArea",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//根据经纬度查询省市区Code
	searchAddresByLonLat(data) {
		return fetch({
			url: "/common/searchAddresByLonLat",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//上传图片
	uploadImage(data) {
		return fetch({
			url: "/common/uploadImage",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//报告图片上传
	uploadReportImage(data) {
		return fetch({
			url: "/map/uploadReportImage",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	///
	//查询企业城市
	searchCusCity(data) {
		return fetch({
			url: "/user/searchCusCity",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	/**********************LIST************************ */
	//机会点列表查询
	searchSmartSite(data) {
		return fetch({
			url: "/listView/sites",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//门店列表查询
	searchStores(data) {
		return fetch({
			url: "/listView/stores",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//竞品列表查询
	searchCompetitor(data) {
		return fetch({
			url: "/listView/competitor",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//机会点导出
	sitesExport(data) {
		return fetch({
			url: "/listView/sitesExport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//门店导出
	storesExport(data) {
		return fetch({
			url: "/listView/storesExport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//商圈列表
	ta(data) {
		return fetch({
			url: "/listView/ta",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	// 商圈導出
	tAsExport(data) {
		return fetch({
			url: "/listView/tAsExport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	/******************系统管理**********************/
	//企业信息
	companyInfo(data) {
		return fetch({
			url: "/system/companyInfo",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//保存企业信息
	saveCompanyInfo(data) {
		return fetch({
			url: "/system/saveCompanyInfo",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//用户列表
	userList(data) {
		return fetch({
			url: "/system/userList",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//新增用户
	addUser(data) {
		return fetch({
			url: "/system/addUser",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//竞品维护-查询
	competitorList(data) {
		return fetch({
			url: "/system/competitorList",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//竞品维护-编辑
	saveCompetitor(data) {
		return fetch({
			url: "/system/saveCompetitor",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//获取地图样式
	mapStyle(data) {
		return fetch({
			url: "/system/mapStyle",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//保存图层样式
	saveMapStyle(data) {
		return fetch({
			url: "/system/saveMapStyle",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//企业应用信息保存
	saveCompanyAppInfo(data) {
		return fetch({
			url: "/system/saveCompanyAppInfo",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//修改用户状态
	changeUserStatus(data) {
		return fetch({
			url: "/system/changeUserStatus",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//修改皮肤
	changColor(data) {
		return fetch({
			url: "/user/changColor",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//查询统计报告记录
	cusReportList(data) {
		return fetch({
			url: "/system/cusReportList",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//查询日志
	cusLogList(data) {
		return fetch({
			url: "/system/cusLogList",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//查询城市维护数据
	cusAllCityList(data) {
		return fetch({
			url: "/system/cusAllCityList",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//保存城市维护
	saveCusCity(data) {
		return fetch({
			url: "/system/saveCusCity",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//查询日志导出
	cusLogListExport(data) {
		return fetch({
			url: "/system/cusLogListExport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//查询统计报告记录导出复制
	cusReportListExport(data) {
		return fetch({
			url: "/system/cusReportListExport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//用户列表导出
	userListExport(data) {
		return fetch({
			url: "/system/userListExport",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----增加品牌
	insertCusbrand(data) {
		return fetch({
			url: "/common/insertCusbrand",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----删除数据项分类数据
	deleteCusdictionaryById(data) {
		return fetch({
			url: "/common/deleteCusdictionaryById",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----删除数据项明细数据
	deleteCusdictionaryitemById(data) {
		return fetch({
			url: "/common/deleteCusdictionaryitemById",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----增加数据项分类
	insertCusdictionary(data) {
		return fetch({
			url: "/common/insertCusdictionary",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----增加数据项明细数据
	insertCusdictionaryitem(data) {
		return fetch({
			url: "/common/insertCusdictionaryitem",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----获取数据项分类数据
	searchCusdictionary(data) {
		return fetch({
			url: "/common/searchCusdictionary",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----获取数据项明细数据
	searchCusdictionaryitem(data) {
		return fetch({
			url: "/common/searchCusdictionaryitem",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----变更数据项明细数据顺序
	updateCusdictionaryitemSortById(data) {
		return fetch({
			url: "/common/updateCusdictionaryitemSortById",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----品牌删除
	deleteCusbrand(data) {
		return fetch({
			url: "/common/deleteCusbrand",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//系统管理-----品牌编辑
	updateCusbrand(data) {
		return fetch({
			url: "/common/updateCusbrand",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	/********************************动态字段***********************************/
	//删除分组详细信息
	deleteCusdatacolumn(data) {
		return fetch({
			url: "/dynamic/deleteDictById",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//增加分组
	insertDataGroup(data) {
		return fetch({
			url: "/dynamic/insertDataGroup",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//增加分组详细信息
	insertCusdatacolumn(data) {
		return fetch({
			url: "/dynamic/insertDataColumn",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//获取字段值
	legend(data) {
		return fetch({
			url: "/dynamic/legend",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//获取分组信息
	searchCusdatacolumn(data) {
		return fetch({
			url: "/dynamic/searchDataColumn",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//变更分组详情数据顺序
	updateCusdatacolumnSortByFieldId(data) {
		return fetch({
			url: "/dynamic/updateCusdatacolumnSortByFieldId",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//动态信息获取
	getDataInfo(data) {
		return fetch({
			url: "/dynamic/getDataInfo",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	//动态信息保存
	saveDataInfo(data) {
		return fetch({
			url: "/dynamic/saveDataInfo",
			method: "POST",
			data: qs.stringify(data)
		});
	},
	/***************************portal*************************************** */
	//企业驾驶仓
	portal(data) {
		return fetch({
			url: "/portal/portal",
			method: "GET",
			data
		});
	},
	/*******************************common********************************** */
	//数据字典-TypeID
	dataTypeByTypeID(data) {
		return fetch({
			url: "/common/dataTypeByTypeID",
			method: "POST",
			data: qs.stringify(data)
		});
	},
}