<template>
    <div class="rec-table" v-show="visible">
        <div style="border-bottom: 1px solid #ddd; padding: 10px;margin-bottom:10px;">
            <span style="font-size: 16px;color: #8a8484;font-weight:600;">
                智能推荐结果展示
            </span>
            <div style="display: inline-block; float: right;cursor: pointer;" @click="closeRecList">
                <i class="el-icon-close"></i>
            </div>
        </div>
        <el-table ref="recTable" :data="recList" height="330" class="rectable">
            <el-table-column
            type="index"
            align="center"
            >
                <template slot="header">
                    <div>序号</div>
                </template>
                <template slot-scope="scope">
                    <span style="font-size: 12px">{{scope.$index + 1}}</span>
                </template>
            </el-table-column>
            <el-table-column
            align="left">
                <template slot="header">
                    <div>区块</div>
                </template>
                <template slot-scope="scope">
                    <el-link type="primary" @click="flyToRec(scope.$index, scope.row)">
                        <span style="font-size: 12px">{{scope.row.name}}</span>
                    </el-link>
                </template>
            </el-table-column>
            <!-- <el-table-column
            width="55"
            align="right">
                <template slot="header">
                    <div>人口指数</div>
                </template>
                <template  slot-scope="scope">
                    <span style="font-size: 12px">{{scope.row.t || ""}}</span>
                </template>
            </el-table-column> -->
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            recList: [],
            map: ""
        };
    },
    methods: {
        //跳转到指定推荐区域
        flyToRec(index, data = this.recList[0]) {
            this.$refs.recTable.setCurrentRow(data);
            this.$store.state.recInfoVisible = false;
            this.map.flyTo({
                center: data.geometry[0][0],
                zoom: 15
            });
        },
        //关闭 弹框列表
        closeRecList() {
            this.$store.state.recVisible = false;
            if(this.map.getSource("geoRecommend")) {
                var recommendSource = {
                    type: "FeatureCollection",
                    features: []
                };
                this.map.getSource("geoRecommend").setData(recommendSource);
               // this.map.getSource("recommendLayerLine").setData(recommendSource);
            }
        }
    },
    watch: {
        "$store.state.recList"(val) {
            this.recList = val;
        },
        "$store.state.recVisible"(val) {
            this.visible = val && this.recList.length > 0;
            if(val == true && this.map == "") {
                this.map = this.$store.state.map;
            }
        }

    }
}
</script>
<style>
.rec-table {
    background: #ffffff;
    /* max-height: 340px; */
    width: 250px;
    border-radius: 4px;
    position: absolute;
    z-index: 100;
    top: 80px;
    right: 10px;
    overflow-y: hidden;
    -webkit-box-shadow: 2px 1px 8px 4px #bbb;
    box-shadow: 2px 1px 8px 4px #bbb;

}
.rectable.el-table--fit.el-table--scrollable-y.el-table--enable-row-hover.el-table--enable-row-transition > div.el-table__body-wrapper.is-scrolling-none::-webkit-scrollbar{
  width:8px;
  height:8px;
}
.rectable.el-table--fit.el-table--scrollable-y.el-table--enable-row-hover.el-table--enable-row-transition > div.el-table__body-wrapper.is-scrolling-none::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
.rectable.el-table--fit.el-table--scrollable-y.el-table--enable-row-hover.el-table--enable-row-transition > div.el-table__body-wrapper.is-scrolling-none::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
.rectable.el-table--fit.el-table--scrollable-y.el-table--enable-row-hover.el-table--enable-row-transition > div.el-table__body-wrapper.is-scrolling-none::-webkit-scrollbar-thumb:hover{
  background: #333;
}
.rectable.el-table--fit.el-table--scrollable-y.el-table--enable-row-hover.el-table--enable-row-transition > div.el-table__body-wrapper.is-scrolling-none::-webkit-scrollbar-corner{
  background: #179a16;
}
.rectable .el-table {
    font-size: 12px !important;
}
div.rec-table th{
    padding: 3px 0 !important;
}
div.rec-table td{
    padding: 3px 0 !important;
}
div.el-table {
    font-size: 12px;
}
.rectable {
    padding-left: 10px;
    padding-right: 10px;
}

</style>